import React, { useCallback, useMemo, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import { isEmpty } from 'lodash'
import { useForm } from 'react-hook-form'
import { Grid, Container } from '@mui/material'
import { FormatDDMMYYYY, DDLabel } from '../../../utils/app'
import Backdrop from '../../../components/useBackdrop'
import AlertBox from '../../../components/alertBox/alertBox'
import DialogForm from '../../../components/useDialog'
import PopupForm from '../../../components/usePopup'
import { Stepper } from './stepper-form-send-money'
import SenderDetails from '../../../containers/send-money/form-sender/sender-details'
import SelectReceiver from '../../../containers/send-money/form-sender/select-receiver'
import ReceiverDetail from '../../../containers/send-money/form-sender/receiver-detail'
import RemittanceDetails from '../../../containers/send-money/form-sender/remittance-details'
import ConfirmTransaction from '../../../containers/send-money/form-sender/confirm-send-transaction'
import SuspiciousNote from '../../../containers/send-money/form-sender/dialog-suspicious-note'
import UploadDocumentFiles from '../../../containers/send-money/form-sender/upload-document/upload-document-form'
import { fetchPreviewSendTransaction } from '../../../redux/send-money/action'
import {
  fetchKYCMode,
  fetchIDType,
  fetchGender,
  fetchCountry,
  fetchBankList,
  fetchVisaType,
  fetchSenderType,
  fetchFieldRules,
  fetchOccupation,
  fetchAccountType,
  fetchRelationship,
  fetchTestQuestion,
  fetchSourceOfFund,
  fetchCalculateMode,
  selectCustomerDetail,
  fetchTransactiontype,
  fetchReceiverDelivery,
  fetchReceivFieldRule,
  selectedTransactiontype,
  fetchPurposeOfRemittance,
  selectedModeOfRemittance,
  fetchModeOfRemittance,
  fetchBeneficiaryCountry,
} from '../../../redux/master/action'
import {
  apiMasterGender,
  apiGetIDTypeList,
  apiMasterCountryList,
  apiMasterCalculateMode,
  apiMasterSenderTypeList,
  apiMasterAccountTypeList,
} from '../../../api/master'
import {
  apiGetKYCMode,
  apiGetFieldRules,
  apiMasterBankList,
  apiGetVisaTypeList,
  apiGetSourceOfFund,
  apiGetDeliveryOption,
  apiGetTestQuestionList,
  apiMasterOccupationList,
  apiMasterTransactionType,
  apiMasterRelationshipList,
  apiGetCustomerDetailBysno,
  apiGetBeneficiaryCountryList,
  apiMasterPurposeOfRemittanceList,
  apiGetConfirmSendPreviewTransaction,
  apiConfirmSendTransaction,
  apiSetsessionDeposit,
} from '../../../api/send-money'

const initState = {
  values: {},
  activeStep: 0,
  isOpen: false,
  isOpenNote: false,
  isWaiting: false,
  isSending: false,
  deliveryOption: {},
  notify: {
    open: false,
    message: '',
    type: '',
  },
  confirmDialog: {
    isOpen: false,
    title: '',
    subTitle: '',
  },
}

const defaultValues = {
  // ###### 1 SELECT COUNTRY ######
  country_code: null,
  transaction_type: null,
  sender_type: null,
  receiver_type: null,
  customerid: null,
  // ###### SENDER DETAILS ######
  s_firstName: '',
  s_middleName: '',
  s_lastName: '',
  s_gender: '',
  s_phoneNumber: '',
  s_email: '',
  s_dateOfBirth: null,
  s_nationality: null,
  s_occupation: null,
  s_occupation_other: '',
  // ###### SENDER ADDRESS DETAILS ######
  s_country: null,
  s_postalCode: '',
  s_state: '',
  s_city: '',
  s_street: '',
  // ###### SENDER DOCUMENT DETAILS ######
  s_visa_type: null,
  s_visa_type_other: '',
  s_id_type: null,
  s_id_type_other: '',
  s_id_number: '',
  s_id_issue_country: null,
  s_issue_date: null,
  s_expire_date: null,
  s_source_of_fund: null, //null,
  s_source_of_fund_other: '',
  // ###### 2 RECEIVER ######
  // ###### 3 RECEIVER DETAILS ######
  r_firstName: '',
  r_middleName: '',
  r_lastName: '',
  r_gender: '',
  r_phoneNumber: '',
  r_dateOfBirth: null,
  r_email: '',
  r_nationality: null,
  r_occupation: null,
  r_occupation_other: '',
  r_purposeofremittance: null,
  r_purposeofremittance_other: '',
  r_relationship: null,
  r_relationship_other: '',
  // ###### RECEIVER ADDRESS DETAILS ######
  r_country: null,
  r_postalCode: '',
  r_state: '',
  r_city: '',
  r_district: '',
  r_street: '',
  // ###### RECEIVER PAYMENT INFORMATION ######
  r_modeofremittance: null,
  // r_bank: null,
  r_account_type: null,
  r_branchname: '',
  r_account_number: '',
  // ###### ADDITIONAL INFORMATION ######
  r_add_alternative_name: '',
  r_add_alternate_midName: '',
  r_add_alternate_lastName: '',
  r_add_alternative_address: '',
  // ###### ADDITIONAL DOCUMENT INFORMATION ######
  r_add_id_type: null,
  r_add_id_type_other: '',
  r_add_id_number: '',
  r_add_issue_date: null,
  r_add_expire_date: null,
  r_add_id_issue_country: null,
  r_add_id_issue_state: '',
  r_add_testquestion: null,
  r_add_testanswer: '',
  r_add_message1: '',
  r_add_message2: '',
  r_add_remark: '',
  // ###### ADDITIONAL BANK INFORMATION ######
  r_add_abbbankname: '',
  r_add_BICB: '',
  r_add_IBAN: '',
  r_add_SWIFTBIC: '',
  r_add_NID: '',
  r_add_SUFFIX: '',
  r_add_subranch: '',
  // ###### 4 REMITTANCE DETAILS ######
  remittance_bank: null,
  remittance_pinNo: '',
  remittance_promoCode: '',
  calcMode: null,
  remittance_amount: '',
  fileSTR: false,
  kycMode: null,
}

export default function CreateNewSender() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const params = useParams()
  const stepsContainer = []

  const { handleSubmit, control, formState, watch, reset } = useForm({
    defaultValues,
  })
  const { errors } = formState
  const [loading, setLoading] = useState(initState.loading)
  const [isOpen, setIsOpen] = useState(initState.isOpen)
  const [notify, setNotify] = useState(initState.notify)
  const [isSending, setIsSending] = useState(initState.isSending)
  const [isWaiting, setIsWaiting] = useState(initState.isWaiting)
  const [isOpenNote, setIsOpenNote] = useState(initState.isOpenNote)
  const [values, setValues] = useState(initState.values)
  // const [errorMessage, setErrorMessage] = useState(initState.errorMessage)
  const [confirmDialog, setConfirmDialog] = useState(initState.confirmDialog)
  const [deliveryOption, setDeliveryOption] = useState(initState.deliveryOption)
  const [activeStep, setActiveStep] = useState(initState.activeStep)

  const SESSION_ID = sessionStorage.getItem('PROCESS_SESSION_ID') || ''

  const PREVIEW_TRANSACTION = useSelector(
    (state) => state?.sendMoney?.display_preview_transaction,
  )
  const DISPLAY_SERVICE_CHARGE = useSelector(
    (state) => state?.master?.display_servicecharge,
  )
  const OPTION_BANK = useSelector((state) => state?.master?.banks)

  const OPTION_ID_TYPE = useSelector((state) => state?.master?.id_types)

  const CUSTOMER_DETAIL = useSelector((state) => state?.master?.customer_detail)

  const RECEIVER_DETAIL = useSelector((state) => state?.master?.receiver_detail)

  const OPTION_TRANSACTION_TYPE = useSelector((state) => state?.master?.transaction_type)

  const OPTION_MODEOFREMITTANCE = useSelector((state) => state?.master?.modeofremittance)

  const SELECTED_MODEOFREMITTANCE = useSelector(
    (state) => state?.master?.selected_modeofremittance,
  )
  // const OPTION_PURPOSE_OF_REMITTANCE = useSelector(
  //   (state) => state?.master?.purpose_of_remittances,
  // )
  const SELECTED_TRANSACTION = useSelector(
    (state) => state?.master?.selected_transaction_type,
  )
  const RECEIVER_DELIVERY = useSelector((state) => state?.master?.receiver_delivery)

  const countrycode = values?.country_code
  let deleiveryoption =
    SELECTED_TRANSACTION?.find(
      (e) =>
        e?.deleiveryoption === DDLabel(OPTION_TRANSACTION_TYPE, values?.transaction_type),
    )?.deleiveryoption || ''
  let deliverymodename =
    SELECTED_TRANSACTION?.find(
      (e) =>
        e?.deleiveryoption === DDLabel(OPTION_TRANSACTION_TYPE, values?.transaction_type),
    )?.deliverymodename || ''
  let currency = deliveryOption?.currency
  let customer_beneficiary = '1' // "1" for Customer & "2" for Receiver
  let receiver_beneficiary = '2' // "1" for Customer & "2" for Receiver
  let customertype = values?.sender_type
  let receivertype = values?.receiver_type
  let delivery_option_id = deliveryOption?.deliveryoptionid || ''
  let receiver_agent_id = RECEIVER_DELIVERY?.receiveragentid || ''
  let receiver_country = values?.r_country || null
  let receiver_modeofremittance = DDLabel(
    OPTION_MODEOFREMITTANCE,
    values?.r_modeofremittance,
  )
  let CUST_DETAIL = CUSTOMER_DETAIL?.customerDetail
  let receiver_deleiveryoption =
    SELECTED_MODEOFREMITTANCE?.find(
      (e) =>
        e?.deleiveryoption ===
        DDLabel(OPTION_MODEOFREMITTANCE, values?.r_modeofremittance),
    )?.deleiveryoption || ''
  let receiver_deliverymodename =
    SELECTED_MODEOFREMITTANCE?.find(
      (e) =>
        e?.deleiveryoption ===
        DDLabel(OPTION_MODEOFREMITTANCE, values?.r_modeofremittance),
    )?.deliverymodename || ''

  const fetchCustomerDetail = useCallback(async () => {
    setLoading(true)

    if (!params?.id) return

    const response = await apiGetCustomerDetailBysno(params?.id)
    if (response?.code !== 0) {
      return
    }

    dispatch(selectCustomerDetail(response?.data))
    setLoading(false)
  }, [dispatch, params])

  useMemo(() => {
    fetchCustomerDetail()
  }, [fetchCustomerDetail])

  const fetchMasterGenderList = useCallback(async () => {
    const response = await apiMasterGender()
    const options = response?.data?.map((e) => {
      return {
        value: e?.value === 'Male' ? 'M' : 'F',
        label: e?.text,
      }
    })
    dispatch(fetchGender(options))
  }, [dispatch])

  useMemo(() => {
    fetchMasterGenderList()
  }, [fetchMasterGenderList])

  const fetchMasterCountryList = useCallback(async () => {
    const response = await apiMasterCountryList()
    const options = response?.data?.map((e) => {
      return {
        value: e?.value,
        label: e?.text,
      }
    })

    dispatch(fetchCountry(options))
    dispatch(fetchFieldRules([]))
    dispatch(fetchReceivFieldRule([]))
  }, [dispatch])

  useMemo(() => {
    fetchMasterCountryList()
  }, [fetchMasterCountryList])

  const fetchBeneficiaryCountryList = useCallback(async () => {
    const response = await apiGetBeneficiaryCountryList()
    const options = response?.data?.map((e) => {
      return {
        value: e?.country_code,
        label: e?.rec_country,
      }
    })

    dispatch(fetchBeneficiaryCountry(options))
    dispatch(fetchReceiverDelivery([]))
    // dispatch(fetchFieldRules([]))
  }, [dispatch])

  useMemo(() => {
    fetchBeneficiaryCountryList()
  }, [fetchBeneficiaryCountryList])

  const fetchTransactionTypeList = useCallback(async () => {
    if (!countrycode) return
    const response = await apiMasterTransactionType(countrycode)
    if (response?.code !== 0) {
      return
    }
    const options = response?.data?.map((e, i) => {
      return {
        value: e?.deliverymodename,
        label: e?.deleiveryoption,
      }
    })
    dispatch(selectedTransactiontype([...response?.data]))
    dispatch(fetchTransactiontype(options))
  }, [dispatch, countrycode])

  useMemo(() => {
    fetchTransactionTypeList()
  }, [fetchTransactionTypeList])

  const fetchModeOfRemittanceList = useCallback(async () => {
    if (!receiver_country) return
    const response = await apiMasterTransactionType(receiver_country)
    if (response?.code !== 0) {
      return
    }
    const options = response?.data?.map((e, i) => {
      return {
        value: e?.deliverymodename,
        label: e?.deleiveryoption,
      }
    })
    dispatch(selectedModeOfRemittance([...response?.data]))
    dispatch(fetchModeOfRemittance(options))
  }, [dispatch, receiver_country])

  useMemo(() => {
    fetchModeOfRemittanceList()
  }, [fetchModeOfRemittanceList])

  const fetchVisaTypeList = useCallback(async () => {
    const response = await apiGetVisaTypeList()
    const options = response?.data?.map((e) => {
      return {
        value: e?.value,
        label: e?.text,
      }
    })

    dispatch(fetchVisaType(options))
  }, [dispatch])

  useMemo(() => {
    fetchVisaTypeList()
  }, [fetchVisaTypeList])

  const fetchSourceOfFundList = useCallback(async () => {
    const response = await apiGetSourceOfFund()
    const options = response?.data?.map((e) => {
      return {
        value: e?.value,
        label: e?.text,
      }
    })

    dispatch(fetchSourceOfFund(options))
  }, [dispatch])

  useMemo(() => {
    fetchSourceOfFundList()
  }, [fetchSourceOfFundList])

  const fetchMasterSenderType = useCallback(async () => {
    const response = await apiMasterSenderTypeList()
    const options = response?.data?.map((e) => {
      return {
        value: e?.value,
        label: e?.text,
      }
    })

    dispatch(fetchSenderType(options))
  }, [dispatch])

  useMemo(() => {
    fetchMasterSenderType()
  }, [fetchMasterSenderType])

  const fetchDeliveryOption = useCallback(async () => {
    if (!countrycode || (!deleiveryoption && !deliverymodename)) return

    const response = await apiGetDeliveryOption(
      countrycode,
      deleiveryoption,
      deliverymodename,
    )
    if (response?.code !== 0) {
      return
    }

    setDeliveryOption(...response?.data)
  }, [countrycode, deleiveryoption, deliverymodename])

  useMemo(() => {
    fetchDeliveryOption()
  }, [fetchDeliveryOption])

  const fetchReceiverDeliveryList = useCallback(async () => {
    if (!receiver_country || (!receiver_deleiveryoption && !receiver_deliverymodename))
      return

    const response = await apiGetDeliveryOption(
      receiver_country,
      receiver_deleiveryoption,
      receiver_deliverymodename,
    )
    if (response?.code !== 0) {
      return
    }

    dispatch(fetchReceiverDelivery(...response?.data))
  }, [dispatch, receiver_country, receiver_deleiveryoption, receiver_deliverymodename])

  useMemo(() => {
    fetchReceiverDeliveryList()
  }, [fetchReceiverDeliveryList])

  const fetchFieldRule = useCallback(async () => {
    if (!delivery_option_id || !customer_beneficiary || !customertype || !receivertype)
      return

    const response = await apiGetFieldRules(
      delivery_option_id,
      customer_beneficiary,
      customertype,
      receivertype,
    )
    if (response?.code !== 0) {
      return
    }

    dispatch(fetchFieldRules(response?.data?.fieldvalidateModel))
  }, [dispatch, delivery_option_id, customer_beneficiary, customertype, receivertype])

  useMemo(() => {
    fetchFieldRule()
  }, [fetchFieldRule])

  const fetchReceivFieldRuleList = useCallback(async () => {
    if (!delivery_option_id || !receiver_beneficiary || !customertype || !receivertype)
      return

    const response = await apiGetFieldRules(
      delivery_option_id,
      receiver_beneficiary,
      customertype,
      receivertype,
    )
    if (response?.code !== 0) {
      return
    }
    dispatch(fetchReceivFieldRule(response?.data?.fieldvalidateModel))
  }, [dispatch, receiver_beneficiary, delivery_option_id, customertype, receivertype])

  useMemo(() => {
    fetchReceivFieldRuleList()
  }, [fetchReceivFieldRuleList])

  const fetchMasterPurposeOfRemittance = useCallback(async () => {
    const response = await apiMasterPurposeOfRemittanceList()
    const options = response?.data?.map((e) => {
      return {
        value: e?.value,
        label: e?.text,
      }
    })

    dispatch(fetchPurposeOfRemittance(options))
  }, [dispatch])

  useMemo(() => {
    fetchMasterPurposeOfRemittance()
  }, [fetchMasterPurposeOfRemittance])

  const fetchMasterOccupation = useCallback(async () => {
    const response = await apiMasterOccupationList()
    const options = response?.data?.map((e) => {
      return {
        value: e?.value,
        label: e?.text,
      }
    })

    dispatch(fetchOccupation(options))
  }, [dispatch])

  useMemo(() => {
    fetchMasterOccupation()
  }, [fetchMasterOccupation])

  const fetchMasterRelationship = useCallback(async () => {
    const response = await apiMasterRelationshipList()
    const options = response?.data?.map((e) => {
      return {
        value: e?.value,
        label: e?.text,
      }
    })

    dispatch(fetchRelationship(options))
  }, [dispatch])

  useMemo(() => {
    fetchMasterRelationship()
  }, [fetchMasterRelationship])

  const fetchMasterBankList = useCallback(async () => {
    if (!receiver_agent_id || !receiver_modeofremittance || !receiver_country) return

    const payload = {
      mode: receiver_modeofremittance,
      receiveAgentID: receiver_agent_id,
      payoutCountry: receiver_country,
    }

    const response = await apiMasterBankList(payload)
    const options = response?.data?.map((e) => {
      return {
        value: e?.commercialbank,
        label: e?.bankname,
      }
    })

    dispatch(fetchBankList(options))
  }, [dispatch, receiver_modeofremittance, receiver_agent_id, receiver_country])

  useMemo(() => {
    fetchMasterBankList()
  }, [fetchMasterBankList])

  const fetchMasterAccountType = useCallback(async () => {
    const response = await apiMasterAccountTypeList()
    const options = response?.data?.map((e) => {
      return {
        value: e?.value,
        label: e?.text,
      }
    })

    dispatch(fetchAccountType(options))
  }, [dispatch])

  useMemo(() => {
    fetchMasterAccountType()
  }, [fetchMasterAccountType])

  const fetchMasterIDTypeList = useCallback(async () => {
    const response = await apiGetIDTypeList()
    const options = response?.data?.map((e) => {
      return {
        value: e?.value,
        label: e?.text,
      }
    })

    dispatch(fetchIDType(options))
  }, [dispatch])

  useMemo(() => {
    fetchMasterIDTypeList()
  }, [fetchMasterIDTypeList])

  const fetchMasterTestQuestionList = useCallback(async () => {
    const response = await apiGetTestQuestionList()
    const options = response?.data?.map((e) => {
      return {
        value: e?.value,
        label: e?.text,
      }
    })

    dispatch(fetchTestQuestion(options))
  }, [dispatch])

  useMemo(() => {
    fetchMasterTestQuestionList()
  }, [fetchMasterTestQuestionList])

  const fetchMasterCalculateModeList = useCallback(async () => {
    const response = await apiMasterCalculateMode()
    const options = response?.data?.map((e) => {
      return {
        value: e?.value,
        label: e?.text,
      }
    })

    dispatch(fetchCalculateMode(options))
  }, [dispatch])

  useMemo(() => {
    fetchMasterCalculateModeList()
  }, [fetchMasterCalculateModeList])

  const fetchMasterKYCModeList = useCallback(async () => {
    const response = await apiGetKYCMode()
    const options = response?.data?.map((e) => {
      return {
        value: e?.value,
        label: e?.text,
      }
    })

    dispatch(fetchKYCMode(options))
  }, [dispatch])

  useMemo(() => {
    fetchMasterKYCModeList()
  }, [fetchMasterKYCModeList])

  //go back to previous step
  const prevStep = () => {
    if (activeStep === 2 && isEmpty(CUST_DETAIL)) {
      setActiveStep((prevActiveStep) => prevActiveStep - 2)
      return
    }
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  //go to next step
  const nextStep = (val) => {
    if (activeStep === 0 && isEmpty(CUST_DETAIL)) {
      setActiveStep((prevActiveStep) => prevActiveStep + 2)
      return
    }

    if (activeStep === 3) {
      PreviewSendTransaction(values)
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  // Confirm Send Transaction (Preview)
  const PreviewSendTransaction = async (data) => {
    setIsWaiting(true)

    if (DISPLAY_SERVICE_CHARGE && RECEIVER_DELIVERY) {
      const sessionDeposit = await apiSetsessionDeposit({
        collectedAmount: `${DISPLAY_SERVICE_CHARGE?.collect_Amount}`,
        processID: SESSION_ID,
      })
      if (sessionDeposit?.code !== 0) return

      if (!isEmpty(sessionDeposit?.data)) {
        const response = await apiGetConfirmSendPreviewTransaction(
          data,
          OPTION_BANK,
          OPTION_ID_TYPE,
          RECEIVER_DELIVERY,
          DISPLAY_SERVICE_CHARGE,
          OPTION_TRANSACTION_TYPE,
        )
        if (response?.code !== 0) {
          setIsWaiting(true)
          setConfirmDialog({
            ...confirmDialog,
            isOpen: true,
            title: 'ERROR',
            subTitle: response?.message || response?.title || response?.Message,
            type: 'alertMsgError',
          })
          return
        }
        dispatch(fetchPreviewSendTransaction(response?.data))
      }
      setIsWaiting(false)
    }
  }

  // Send Transaction (New/Update)
  const SendTransaction = () => {
    if (!PREVIEW_TRANSACTION) return

    setConfirmDialog({
      isOpen: true,
      type: 'confirmToSendTransaction',
      title: `Total Amount ${PREVIEW_TRANSACTION?.receiver?.collectedAmount} ${PREVIEW_TRANSACTION?.receiver?.currency}`,
      subTitle: 'Please confirm to proceed to send transaction.',
      onConfirm: () => {
        setConfirmDialog({
          ...initState.confirmDialog,
        })
        confirmTransaction(PREVIEW_TRANSACTION)
      },
    })
  }

  // Confirm Transaction
  const confirmTransaction = async (data) => {
    setIsSending(true)
    setConfirmDialog({
      isOpen: true,
      loading: isSending,
      type: 'DialogWaitingTransaction',
    })

    const response = await apiConfirmSendTransaction(data)
    if (response?.code === 0) {
      setIsSending(false)
      setConfirmDialog({
        isOpen: true,
        type: 'transactionSubmitted',
        title: `transaction submitted`,
        subTitle: 'Please wait for the officer to approve for you.',
        subTitle2: 'If you want to see the transaction process, click the button below.',
        onView: () => {
          setConfirmDialog({
            ...initState.confirmDialog,
          })
          reset()
          navigate(`/transacion-details/${response?.data?.tranno}`)
        },
        onDownLoad: () => {
          setConfirmDialog({
            ...initState.confirmDialog,
          })
          reset()
        },
        onBack: () => {
          setConfirmDialog({
            ...initState.confirmDialog,
          })
          reset()
          navigate(`/send-money`)
        },
      })
      return
    }

    setIsSending(true)
    setConfirmDialog({
      ...confirmDialog,
      isOpen: true,
      title: 'ERROR',
      subTitle: response?.message || response?.title,
      type: 'alertMsgError',
    })
    return
  }

  // Upload File
  const UploadFile = () => setIsOpen(!isOpen)

  // Note
  const EnterNote = () => setIsOpenNote(!isOpenNote)

  // get data from fields
  useEffect(() => {
    const val = watch((data) => {
      setValues(data)
    })
    return () => val.unsubscribe()
  }, [watch])

  useMemo(
    () =>
      reset(
        !isEmpty(CUST_DETAIL)
          ? {
              country_code: null,
              transaction_type: null,
              sender_type: CUST_DETAIL?.customertype || '',
              receiver_type: null,
              is_selected: values?.is_selected,
              customerid: CUST_DETAIL?.customerid,
              customersno: CUST_DETAIL?.sno,
              s_firstName: CUST_DETAIL?.firstName || '',
              s_middleName: CUST_DETAIL?.middleName || '',
              s_lastName: CUST_DETAIL?.lastName || '',
              s_gender: CUST_DETAIL?.gender || '',
              s_phoneNumber: CUST_DETAIL?.mobileNum || '',
              s_email: CUST_DETAIL?.email || '',
              s_dateOfBirth: dayjs(
                CUST_DETAIL?.dob?.substring(0, CUST_DETAIL?.dob.indexOf(' ')),
              ).$d,
              s_nationality: CUST_DETAIL?.nationality || '',
              s_occupation: CUST_DETAIL?.occupation || '',
              s_occupation_other: CUST_DETAIL?.occupationOther || '',
              s_country: CUST_DETAIL?.country || '',
              s_postalCode: CUST_DETAIL?.zipCode || '',
              s_state: CUST_DETAIL?.prefecture || '',
              s_city: CUST_DETAIL?.city || '',
              s_street: CUST_DETAIL?.address || '',
              s_visa_type: CUST_DETAIL?.typeVisa || '',
              s_visa_type_other: CUST_DETAIL?.visaTypeOther || '',
              s_id_type: CUST_DETAIL?.idType || '',
              s_id_type_other: CUST_DETAIL?.idTypeOther || '',
              s_id_number: CUST_DETAIL?.idNumber || '',
              s_id_issue_country: CUST_DETAIL?.idIssueCountry || '',
              s_issue_date: dayjs(FormatDDMMYYYY(CUST_DETAIL?.issueDate)).$d,
              s_expire_date: dayjs(FormatDDMMYYYY(CUST_DETAIL?.expDate)).$d,
              s_source_of_fund: CUST_DETAIL?.sourceOfFund || '',
              s_source_of_fund_other: CUST_DETAIL?.sourceOfIncomeOther || '',
              remittance_pinNo: CUSTOMER_DETAIL?.pinno,
              remittance_promoCode: CUSTOMER_DETAIL?.promotionCode,
              // promotionMsg
              ...values,
            }
          : {
              ...values,
            },
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [CUST_DETAIL, CUSTOMER_DETAIL, reset],
  )
  useMemo(
    () =>
      reset(
        !isEmpty(RECEIVER_DETAIL)
          ? {
              ...values,
              r_sno: RECEIVER_DETAIL?.receiversno,
              r_firstName: RECEIVER_DETAIL?.firstName,
              r_middleName: RECEIVER_DETAIL?.middleName,
              r_lastName: RECEIVER_DETAIL?.lastName,
              r_gender: RECEIVER_DETAIL?.gender,
              r_phoneNumber: RECEIVER_DETAIL?.mobileNo,
              r_dateOfBirth: dayjs(FormatDDMMYYYY(RECEIVER_DETAIL?.dateOfBirth || '')).$d,
              r_email: RECEIVER_DETAIL?.eMail,
              r_nationality: RECEIVER_DETAIL?.nationality,
              r_occupation: RECEIVER_DETAIL?.occupation,
              r_occupation_other: RECEIVER_DETAIL?.occupationOther,
              r_purposeofremittance: RECEIVER_DETAIL?.purposeofremittance,
              r_purposeofremittance_other: RECEIVER_DETAIL?.purposeofremittanceother,
              r_relationship: RECEIVER_DETAIL?.relationship,
              r_relationship_other: RECEIVER_DETAIL?.relationshipother,
              r_country: values?.country_code || RECEIVER_DETAIL?.receiverCountry,
              r_postalCode: RECEIVER_DETAIL?.zipCode,
              r_state: RECEIVER_DETAIL?.prefecture,
              r_city: RECEIVER_DETAIL?.city,
              r_district: RECEIVER_DETAIL?.district,
              r_street: RECEIVER_DETAIL?.address,
              r_modeofremittance: RECEIVER_DETAIL?.modeofremittance,
              // r_bank: RECEIVER_DETAIL?.bankList,
              r_account_type: RECEIVER_DETAIL?.receiverAccounttype,
              r_branchname: RECEIVER_DETAIL?.branchname,
              r_account_number: RECEIVER_DETAIL?.receiverAc,
              // ###### ADDITIONAL INFORMATION ######
              r_add_alternative_name: RECEIVER_DETAIL?.aLlternativeName,
              r_add_alternate_midName: RECEIVER_DETAIL?.alternateMidName,
              r_add_alternate_lastName: RECEIVER_DETAIL?.alternateLastName,
              r_add_alternative_address: RECEIVER_DETAIL?.alternativeAddress,
              // ###### ADDITIONAL DOCUMENT INFORMATION ######
              r_add_id_type: RECEIVER_DETAIL?.idType,
              r_add_id_type_other: RECEIVER_DETAIL?.idTypeOther,
              r_add_id_number: RECEIVER_DETAIL?.idNumber,
              r_add_issue_date: dayjs(FormatDDMMYYYY(RECEIVER_DETAIL?.idIssueDate || ''))
                .$d,
              r_add_expire_date: dayjs(
                FormatDDMMYYYY(RECEIVER_DETAIL?.idExpirydate || ''),
              ).$d,
              r_add_id_issue_country: RECEIVER_DETAIL?.primaryIdPlaceOfIssue,
              r_add_id_issue_state: RECEIVER_DETAIL?.idissuestate,
              r_add_testquestion: RECEIVER_DETAIL?.testquestion,
              r_add_testanswer: RECEIVER_DETAIL?.testanswer,
              r_add_message1: RECEIVER_DETAIL?.message1,
              r_add_message2: RECEIVER_DETAIL?.message2,
              r_add_remark: RECEIVER_DETAIL?.remark,
              // ###### ADDITIONAL BANK INFORMATION ######
              r_add_abbbankname: RECEIVER_DETAIL?.abbbankname,
              r_add_BICB: RECEIVER_DETAIL?.receiverBIc,
              r_add_IBAN: RECEIVER_DETAIL?.receiveriBan,
              r_add_SWIFTBIC: RECEIVER_DETAIL?.receiverswift,
              r_add_NID: RECEIVER_DETAIL?.receiverbanknationalID,
              r_add_SUFFIX: RECEIVER_DETAIL?.receiversuffix,
              r_add_subranch: RECEIVER_DETAIL?.subranch,
              fileSTR: RECEIVER_DETAIL?.fileSTR,
              remittance_bank: RECEIVER_DETAIL?.bankList,
              calcMode: RECEIVER_DETAIL?.calcMode,
              remittance_amount: RECEIVER_DETAIL?.remittanceAmt,
              transfer_amount: RECEIVER_DETAIL?.transferAmount,
              // kycmode: ,
              //
            }
          : {
              ...values,
            },
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [RECEIVER_DETAIL, reset],
  )

  const funcContainer = () => {
    switch (activeStep) {
      case 0:
        return <SenderDetails control={control} watch={watch} currency={currency} />
      case 1:
        return (
          !isEmpty(params) && (
            <SelectReceiver
              control={control}
              sno={params?.id}
              values={values}
              next={nextStep}
              reset={reset}
            />
          )
        )
      case 2:
        return <ReceiverDetail control={control} watch={watch} />
      case 3:
        return <RemittanceDetails control={control} data={values} />
      case 4:
        return (
          <ConfirmTransaction
            control={control}
            data={PREVIEW_TRANSACTION}
            isWaiting={isWaiting}
            EnterNote={EnterNote}
          />
        )
      default:
      //do nothing
    }
    return <></>
  }

  stepsContainer.push(funcContainer())
  const stepLabel = [
    { step: 1, label: 'SENDER DETAILS' },
    { step: 2, label: 'SELECT RECEIVER' },
    { step: 3, label: 'RECEIVER DETAILS' },
    { step: 4, label: 'REMITTANCE DETAILS' },
    { step: 5, label: 'PREVIEW' },
  ]

  return (
    <Container>
      <Grid container justifyContent="center" spacing={3}>
        <Grid item sm={8}>
          <Stepper
            prevStep={prevStep}
            nextStep={nextStep}
            handleUploadFile={UploadFile}
            handleClose={SendTransaction}
            handleSubmit={handleSubmit}
            stepContainer={stepsContainer}
            activeStep={activeStep}
            stepLabel={stepLabel}
            errors={errors}
            btnHiden={isWaiting}
            btnText="proceed transaction"
          />
        </Grid>
      </Grid>
      <AlertBox notify={notify} setNotify={setNotify} />
      <Backdrop openBackdrop={!!params?.id ? loading : false} />
      <PopupForm title="UPLOAD DOCUMENT" openPopup={isOpen} setOpenPopup={setIsOpen}>
        <UploadDocumentFiles
          sno={params?.id}
          onclose={() => setIsOpen(false)}
          setNotify={setNotify}
        />
      </PopupForm>
      <PopupForm
        title="ENTER THE NOTE"
        openPopup={isOpenNote}
        setOpenPopup={setIsOpenNote}
      >
        <SuspiciousNote control={control} onclose={() => setIsOpenNote(false)} />
      </PopupForm>
      <DialogForm confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
    </Container>
  )
}
