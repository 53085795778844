// SETUP COLORS

const GREY = {
  0: '#FFFFFF',
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#212B36',
  900: '#161C24',
}

const STATUS = {
  info: '#618ADE',
  success: '#02DC6A',
  error: '#DC6868',
  warning: '#CCA43C',
  waiting: '#FFC824',
  hold: '#FFEF63',
  paid: '#35E1A9',
  post: '#615CFF',
  cancel: '#FF6Ab2',
}

const TEXT = {
  primary: '#FFFFFF',
  secondary: '#9D9EAF',
  disabled: '#9D9EAF',
  gray: '#28252A',
  popup: '#444A81',
  inherit: '#646671',
  error: '#FF5858',
}

const BUTTON = {
  default: '#8655D1',
  hover: '#AF7AFF',
  active: '#C39AFF',
  disable: '#9D9EAF',
}

const BACKGROUND = {
  primary: '#191F37',
  secondary: 'rgba(39, 44, 83, 0.5)',
  dark: '#272C53',
  light: '#5966DA',
  popup: '#444A81',
  disabledBackground: 'rgba(168, 172, 193, 0.10)',
}

const GRADIENTCOLOR = {
  darkpurple: '#2B1848',
  mainpurple: '#6C3DF3',
  mainpink: '#E43FFF',
  mainblue: '#4A6EFF',
  lightblue: '#869FFF',
  lightyellow: '#F2AF7B',
  lightbluemagenta: '#6655EF',
}

const OUTLINECOLOR = {
  default: '#444A81',
  primary: '#FFFFFF',
  secondary: '#9D9EAF',
  hover: '#5966DA',
  focus: '#8655D1',
  disabled: '#A8ACC1',
  error: '#FF5858',
  popup: '#444A81',
}

const palette = {
  grey: GREY,
  text: TEXT,
  button: BUTTON,
  status: STATUS,
  outline: OUTLINECOLOR,
  background: BACKGROUND,
  gradientcolor: GRADIENTCOLOR,
}

export default palette
