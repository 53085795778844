import { restApi } from '../utils/rest'

export const apiUserSignIn = async (body = {}, callback = {}) => {
  try {
    return await restApi(
      {
        url: `${process.env.REACT_APP_SERVICE_API}/api/v1/auth/login`,
        method: 'POST',
        body,
      },
      callback,
    )
  } catch (error) {
    return error
  }
}

export const apiUserSignOut = async (body = {}, callback = {}) => {
  try {
    return await restApi(
      {
        url: `${process.env.REACT_APP_SERVICE_API}/api/v1/auth/logout`,
        method: 'POST',
        body,
      },
      callback,
    )
  } catch (error) {
    return error
  }
}

export const apiUserInfo = async (callback = {}) => {
  try {
    return await restApi(
      {
        url: `${process.env.REACT_APP_SERVICE_API}/api/v1/auth/user-info`,
        method: 'GET',
      },
      callback,
    )
  } catch (error) {
    return error
  }
}
