import { TableRow } from '@mui/material'
import { styled } from '@mui/material/styles'

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: theme.palette.background.dark,
  //borderBottom: `6px solid ${theme.palette.background.dark}`,
  border: `2px solid ${theme.palette.background.light}`,
  borderRadius: `25px`,
  '& .MuiTableRow-root': {
    backgroundColor: theme.palette.background.dark,
    //borderBottom: `6px solid ${theme.palette.background.dark}`,
    border: `2px solid ${theme.palette.background.light}`,
    borderRadius: `25px`,
  },

  '&:last-child td, &:last-child th': {
    borderBottom: `6px solid ${theme.palette.background.dark}`,
  },
}))

export default StyledTableRow
