import {
  Container,
  Grid,
  Typography,
  TableBody,
  TableCell,
  Stack,
  TableRow,
  Collapse,
  Box,
} from '@mui/material'
import React, { useState, useCallback, useEffect } from 'react'
import Button, { MiniButton } from '../../../components/common/Button'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import IconBtn from '../../../components/common/IconBtn'
import { useNavigate } from 'react-router-dom'
import useTableCallback from '../../../components/useTableCallback'
import Loading from '../../../components/loading/loading'
import {
  apiTransactionPendingList,
  apiEditPendingTransaction,
  apiUpdatePendingTransaction,
  apiTransactionReprocess,
  apiTransactionPendingDelete,
} from '../../../api/transaction'
import {
  apiMasterCountryList,
  apiMasterPaymentModeList,
  apiMasterPurposeOfRemittanceList,
} from '../../../api/master'
import StyledTableRow from '../../../theme/styledTableRow'
import { fNumber } from '../../../utils/formatNumer'
import { useHistoryStyles } from '../../../containers/history/historyStyles'
import AutocompleteField from '../../../components/common/AutocompleteField'
import ConfirmDialog from '../../../components/useDialog'
import InputField from '../../../components/common/InputField'
import { useForm } from 'react-hook-form'
import AlertBox from '../../../components/alertBox/alertBox'
import { isEmpty, isNull } from 'lodash'
import { ConvertDateTimeFormat } from '../../../utils/app'

//#region Initial
const initState = {
  loading: false,
  records: [],
  expanded: [],
  totalRecords: [],
  errorMessage: null,
  notify: {
    open: false,
    message: '',
    type: '',
  },
  confirmDialog: {
    isOpen: false,
    type: '',
    title: '',
    subTitle: '',
    container: '',
  },
}

const headCells = [
  {
    id: 'filE_REFNO',
    label: 'Pin no.',
    disableSorting: true,
  },
  {
    id: 'importdate',
    label: 'Import Date',
    disableSorting: true,
  },
  {
    id: 'sendername',
    label: 'Remitter name',
    disableSorting: true,
  },
  {
    id: 'payoutamount',
    label: 'Amount',
    disableSorting: true,
  },
  {
    id: 'remarks',
    label: 'Remarks',
    disableSorting: true,
  },
  {
    id: 'action',
    label: '',
    disableSorting: true,
  },
  {
    id: 'expand',
    label: '',
    disableSorting: true,
  },
]
//#endregion

export default function BatchFileTransaction() {
  const { control, handleSubmit, setValue, register } = useForm()
  const { classes } = useHistoryStyles()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(initState?.loading)
  const [records, setRecords] = useState(initState?.records)
  const [totalRecords, setTotalRecords] = useState(initState?.totalRecords)
  const [expanded, setExpanded] = useState(initState.expanded)
  const [confirmDialog, setConfirmDialog] = useState(initState.confirmDialog)
  //const [details, setDetails] = useState(null)
  const [countryList, setCountryList] = useState([])
  const [paymentList, setPaymentList] = useState([])
  const [purposeOfRemittanceList, setPurposeOfRemittanceList] = useState([])
  const [notify, setNotify] = useState(initState.notify)
  //#region Event Table
  const handlePageChange = (event, newPage) => {
    setPage(newPage)
    fecthTransactionPending(newPage + 1, rowsPerPage)
  }

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
    fecthTransactionPending(1, parseInt(event.target.value, 10))
  }

  const {
    TblContainer,
    TblHead,
    TblPagination,
    recordsAfterPaginationAndSorting,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
  } = useTableCallback(
    records,
    totalRecords,
    headCells,
    handlePageChange,
    handleRowsPerPageChange,
  )

  const handleExpand = (item) => {
    setExpanded(
      expanded?.map((boolean_value, i) => {
        if (item === i) {
          return !boolean_value
        } else {
          return false
        }
      }),
    )
  }

  //#endregion

  //#region Fecth data
  const fecthTransactionPending = useCallback(async (cPage, rPerPage) => {
    setLoading(true)
    const response = await apiTransactionPendingList({
      currentPage: cPage,
      pageSize: rPerPage,
    })

    if (response?.code !== 0) {
      setRecords([])
      setLoading(false)
      return
    }

    setRecords(response?.data)
    setTotalRecords(response?.totalRecord)
    setExpanded([...Array(response?.data?.length)].map((val) => false))
    setLoading(false)
  }, [])

  const fetchEditPendingTransaction = async (route) => {
    const response = await apiEditPendingTransaction({
      route: route,
    })

    if (response?.code !== 0) {
      setNotify({
        open: true,
        message: response?.message,
        type: 'error',
      })
      return
    }

    setValue('sno', response?.data?.sno)
    setValue('filE_REFNO', response?.data?.filE_REFNO)
    setValue('beneficiaryCountry', response?.data?.beneficiaryCountry)
    setValue('beneficiaryBankCode', response?.data?.beneficiaryBankCode)
    setValue('beneficiaryBankName', response?.data?.beneficiaryBankName)
    setValue('purposeOfRemittance', response?.data?.purposeOfRemittance)
    setValue('paymentMode', response?.data?.paymentMode)
  }

  const fetchCountryList = useCallback(async () => {
    const response = await apiMasterCountryList()
    if (response?.code !== 0) {
      setNotify({
        open: true,
        message: response?.message,
        type: 'error',
      })
      return
    }
    const optionCountry = response?.data?.map((e) => {
      return { label: e?.text, value: e?.value }
    })
    setCountryList(optionCountry)
  }, [])

  const fetchPaymentModeList = useCallback(async () => {
    const response = await apiMasterPaymentModeList()
    if (response?.code !== 0) {
      setNotify({
        open: true,
        message: response?.message,
        type: 'error',
      })
      return
    }
    const optionPayment = response?.data?.map((e) => {
      return { label: e?.text, value: e?.value }
    })
    setPaymentList(optionPayment)
  }, [])

  const fetchPurposeOfRemittanceList = useCallback(async () => {
    const response = await apiMasterPurposeOfRemittanceList()
    if (response?.code !== 0) {
      setNotify({
        open: true,
        message: response?.message,
        type: 'error',
      })
      return
    }
    const optionPayment = response?.data?.map((e) => {
      return { label: e?.text, value: e?.value }
    })
    setPurposeOfRemittanceList(optionPayment)
  }, [])

  //#endregion

  //#region Event Click
  const onClickUpdate = async (data) => {
    const payload = {
      paymentMode: data?.paymentMode,
      purposeOfRemittance: data?.purposeOfRemittance,
      beneficiaryBankCode: data?.beneficiaryBankCode,
      beneficiaryCountry: data?.beneficiaryCountry,
      beneficiaryBankName: data?.beneficiaryBankName,
      filE_REFNO: data?.filE_REFNO,
      sno: data?.sno,
    }
    const response = await apiUpdatePendingTransaction(payload)
    if (response?.code !== 0) {
      errorMessageDialog(response)
      return
    }

    successMessageDialog(response)
  }

  const onClickReprocess = async (routeReprocessTxn) => {
    const payload = { route: routeReprocessTxn }
    const response = await apiTransactionReprocess(payload)
    if (response?.code !== 0) {
      errorMessageDialog(response)
      return
    }

    successResultDialog(response)
  }

  const onClickDelete = async (routeDeleteTxn) => {
    setConfirmDialog({
      isOpen: true,
      title: 'Transaction Delete',
      type: 'deleteDialog',
      subTitle: 'Are you sure you want to delete this transaction ?',
      onConfirm: () => {
        deleteTransactionPending(routeDeleteTxn)
      },
    })
  }

  const deleteTransactionPending = async (routeDeleteTxn) => {
    const payload = { route: routeDeleteTxn }
    const response = await apiTransactionPendingDelete(payload)

    if (response?.code !== 0) {
      errorMessageDialog(response)
      return
    }

    successMessageDialog(response)
  }
  //#endregion

  //#region Dialog
  const successResultDialog = async (result) => {
    setConfirmDialog({
      isOpen: true,
      title: 'Successfully create transaction',
      type: 'successResult',
      subTitle: `Pin No : ${result?.data.refNo}`,
      handlerRefresh: () => {
        fecthTransactionPending(page + 1, rowsPerPage)
      },
    })
  }
  const successMessageDialog = async (result) => {
    setConfirmDialog({
      successOpen: true,
      title: `${result?.message}`,
      type: 'successDialog',
      subTitle: '',
      handlerRefresh: () => {
        fecthTransactionPending(page + 1, rowsPerPage)
      },
    })
  }
  const errorMessageDialog = async (result) => {
    setConfirmDialog({
      isOpen: true,
      title: 'Error',
      type: 'errorDialog',
      subTitle: `${result?.message}`,
      onConfirm: () => {
        setConfirmDialog({
          ...confirmDialog,
          isOpen: false,
        })
      },
    })
  }

  const DialogEdit = async (route) => {
    fetchEditPendingTransaction(route)
    setConfirmDialog({
      isOpen: true,
      type: 'submitResult',
      title: 'Update Transaction Pending',
      subTitle: '',
      container: await TransactionPendingDetail(),
    })
  }

  const TransactionPendingDetail = async () => {
    return (
      <React.Fragment>
        <form control={control} onSubmit={handleSubmit(onClickUpdate)}>
          <Grid container spacing={{ xs: 1, sm: 2 }} columns={{ xs: 1, sm: 8, md: 8 }}>
            <Grid item xs={2} sm={4} md={4}>
              <AutocompleteField
                control={control}
                name="beneficiaryCountry"
                label="Coutry"
                placeholder="Coutry"
                options={countryList}
              />
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <InputField
                control={control}
                name="beneficiaryBankCode"
                label="Bank code"
                placeholder="Bank code"
              />
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <InputField
                control={control}
                name="beneficiaryBankName"
                label="Bank name"
                placeholder="Bank name"
              />
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <AutocompleteField
                control={control}
                name="purposeOfRemittance"
                label="Purpose of remittance"
                placeholder="Purpose of remittance"
                options={purposeOfRemittanceList}
              />
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <AutocompleteField
                control={control}
                name="paymentMode"
                label="Payment mode"
                placeholder="Payment mode"
                options={paymentList}
              />
            </Grid>
            <Grid item xs={4} sm={8} md={8} sx={{ textAlign: 'center' }}>
              <Button type="submit" variant="contained" text="update" />
            </Grid>
          </Grid>
        </form>
        <ConfirmDialog
          confirmDialog={confirmDialog}
          setConfirmDialog={setConfirmDialog}
        />
      </React.Fragment>
    )
  }

  //#endregion

  //#region Use Effect
  useEffect(() => {
    fecthTransactionPending(1, rowsPerPage)
    fetchCountryList()
    fetchPaymentModeList()
    fetchPurposeOfRemittanceList()
  }, [])
  //#endregion

  return (
    <React.Fragment>
      <Container>
        <Grid container mt={3} spacing={3}>
          <Grid item sm={1} key="Back">
            <Button
              onClick={() => {
                navigate(`/history`, {
                  replace: false,
                })
              }}
              startIcon={<ArrowBackIcon />}
              text="Back"
              variant="outlined"
              size="small"
            />
          </Grid>
          <Grid item sm={12} key="Topic">
            <Typography sx={{ mb: '30px' }} component={'div'} variant="H3_Bold">
              Batch File Transaction
            </Typography>
          </Grid>
          <Grid item sm={12} key="Container">
            <TblContainer>
              <TblHead />
              {!loading && (
                <TableBody>
                  {recordsAfterPaginationAndSorting().map((item, index) => {
                    return (
                      <React.Fragment key={`tableBatchFileTransaction-${index}`}>
                        <StyledTableRow key={index} hover>
                          <TableCell head-table="Pin no.">
                            <Typography variant="Paragraph_sm_Light">
                              {item?.filE_REFNO}
                            </Typography>
                          </TableCell>
                          <TableCell head-table="Import Date">
                            <Typography variant="Paragraph_sm_Light">
                              {ConvertDateTimeFormat(item?.importdate)}
                            </Typography>
                          </TableCell>
                          <TableCell head-table="Remitter name">
                            <Typography variant="Paragraph_sm_Light">
                              {item?.sendername}
                            </Typography>
                          </TableCell>
                          <TableCell
                            head-table="Amount"
                            sx={{ textAlign: 'right' }}
                            colSpan={1}
                          >
                            <Typography variant="Paragraph_sm_Light">
                              {fNumber(item?.payoutamount)}
                            </Typography>
                          </TableCell>
                          <TableCell head-table="Remarks">
                            <Typography variant="Paragraph_sm_Light">
                              {item?.remarks}
                            </Typography>
                          </TableCell>
                          <TableCell head-table="" size="small">
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={{
                                xs: 0.5,
                                sm: 1,
                              }}
                              gap={1}
                            >
                              <MiniButton
                                onClick={() => onClickReprocess(item.routeReprocessTxn)}
                                text="Re-process"
                                size="small"
                                variant="contained"
                                style={{ width: 110 }}
                              />
                              <MiniButton
                                onClick={() => DialogEdit(item.routeEditTxn)}
                                text="Edit"
                                size="small"
                                variant="outlined"
                                style={{ width: 65 }}
                              />
                              <MiniButton
                                onClick={() => onClickDelete(item.routeDeleteTxn)}
                                text="Delete"
                                size="small"
                                variant="outlined"
                                style={{ width: 81 }}
                              />
                            </Stack>
                          </TableCell>
                          <TableCell head-table="">
                            <IconBtn
                              onClick={() => {
                                handleExpand(index)
                              }}
                              alt="expand"
                              src={
                                expanded[index]
                                  ? `/assets/icons/icon_arrow_up.svg`
                                  : `/assets/icons/icon_arrow_down.svg`
                              }
                            />
                          </TableCell>
                        </StyledTableRow>
                        <TableRow key={`collapse${index}`} hover>
                          <TableCell
                            style={{
                              padding: 0,
                              display: !expanded[index] && 'none',
                            }}
                            colSpan={7}
                          >
                            <Collapse
                              key={`child-${index}`}
                              in={expanded[index]}
                              timeout="auto"
                              unmountOnExit
                              //className={classes.layoutCollapse}
                            >
                              <Box sx={{ flexGrow: 1 }}>
                                <Grid
                                  container
                                  columns={{ xs: 1, sm: 6, md: 6 }}
                                  key={`grid${index}`}
                                  className={classes.copytext}
                                >
                                  <Grid item xs={1} sm={1} md={1}>
                                    <Typography variant="Paragraph_xs_Light" gutterBottom>
                                      Transaction Ref (Pin no.)
                                    </Typography>
                                    <Typography
                                      variant="subtitle2"
                                      color={'text.primary'}
                                      gutterBottom
                                    >
                                      {item?.filE_REFNO}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={1} sm={1} md={1}>
                                    <Typography variant="Paragraph_xs_Light" gutterBottom>
                                      Imported date
                                    </Typography>
                                    <Typography
                                      variant="subtitle2"
                                      color={'text.primary'}
                                      gutterBottom
                                    >
                                      {ConvertDateTimeFormat(item?.importdate)}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={1} sm={1} md={1}>
                                    <Typography variant="Paragraph_xs_Light" gutterBottom>
                                      Customer ID
                                    </Typography>
                                    <Typography
                                      variant="subtitle2"
                                      color={'text.primary'}
                                      gutterBottom
                                    >
                                      {item?.customerid}
                                    </Typography>
                                  </Grid>

                                  <Grid item xs={1} sm={1} md={1}>
                                    <Typography variant="Paragraph_xs_Light" gutterBottom>
                                      Remitter name
                                    </Typography>
                                    <Typography
                                      variant="subtitle2"
                                      color={'text.primary'}
                                      gutterBottom
                                    >
                                      {item?.sendername}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={1} sm={1} md={1}>
                                    <Typography variant="Paragraph_xs_Light" gutterBottom>
                                      Beneficiary name
                                    </Typography>
                                    <Typography
                                      variant="subtitle2"
                                      color={'text.primary'}
                                    >
                                      {item?.beneficiaryname}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={1} sm={1} md={1}>
                                    <Typography variant="Paragraph_xs_Light" gutterBottom>
                                      Beneficiary contact
                                    </Typography>
                                    <Typography
                                      variant="subtitle2"
                                      color={'text.primary'}
                                      gutterBottom
                                    >
                                      {item?.beneficiaryContact}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={1} sm={1} md={1}>
                                    <Typography variant="Paragraph_xs_Light" gutterBottom>
                                      Beneficiary bank code
                                    </Typography>
                                    <Typography
                                      variant="subtitle2"
                                      color={'text.primary'}
                                    >
                                      {item?.beneficiaryBankCode}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={1} sm={1} md={1}>
                                    <Typography variant="Paragraph_xs_Light" gutterBottom>
                                      Beneficiary bank name
                                    </Typography>
                                    <Typography
                                      variant="subtitle2"
                                      color={'text.primary'}
                                      gutterBottom
                                    >
                                      {item?.beneficiaryBankName}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={1} sm={1} md={1}>
                                    <Typography variant="Paragraph_xs_Light" gutterBottom>
                                      Beneficiary account no.
                                    </Typography>
                                    <Typography
                                      variant="subtitle2"
                                      color={'text.primary'}
                                      gutterBottom
                                    >
                                      {item?.bankAccountNo}
                                    </Typography>
                                  </Grid>

                                  <Grid item xs={1} sm={1} md={1}>
                                    <Typography variant="Paragraph_xs_Light" gutterBottom>
                                      Amount
                                    </Typography>
                                    <Typography
                                      variant="subtitle2"
                                      color={'text.primary'}
                                      gutterBottom
                                    >
                                      {fNumber(item?.payoutamount)}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={2} sm={2} md={2}>
                                    <Typography variant="Paragraph_xs_Light" gutterBottom>
                                      Remark
                                    </Typography>
                                    <Typography
                                      variant="subtitle2"
                                      color={'text.primary'}
                                      gutterBottom
                                    >
                                      {item?.remarks}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Box>
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      </React.Fragment>
                    )
                  })}
                </TableBody>
              )}
            </TblContainer>
            <Loading loading={loading} />
            <TblPagination />
          </Grid>
        </Grid>
      </Container>
      <AlertBox notify={notify} setNotify={setNotify} />
      <ConfirmDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
    </React.Fragment>
  )
}
