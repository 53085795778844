import React from 'react'
import { Grid, Stack } from '@mui/material'
import CardExpand from '../../../components/common/CardExpand'
import CustomerDetailsContainer from './customer-details'
import SenderTypeContainer from './sender-type'
import SenderDetailsContainer from './sender-details'
import SenderAddressDetailsContainer from './sender-address-details'
import SenderKYCStatusDetailsContainer from './sender-kyc-status-details'

export default function SenderDetailsTab(props) {
  return (
    <div>
      <Stack spacing={3}>
        {props.customerInfo === undefined ? (
          <Grid item sm={6}>
            <CardExpand
              title="Select customer type to register new customer"
              defaultOpen={true}
            >
              <SenderTypeContainer {...props} />
            </CardExpand>
          </Grid>
        ) : (
          <Grid item sm={6}>
            <CardExpand
              title={
                'CUSTOMER ID : ' +
                (props.customerInfo?.customerdetailModel?.customerId !== undefined
                  ? props.customerInfo?.customerdetailModel?.customerId
                  : '')
              }
              defaultOpen={true}
            >
              <CustomerDetailsContainer {...props} />
            </CardExpand>
          </Grid>
        )}
        <Grid item sm={6}>
          <CardExpand
            title="Personal details"
            defaultOpen={props.customerInfo !== undefined ? false : true}
          >
            <SenderDetailsContainer {...props} />
          </CardExpand>
        </Grid>
        <Grid item sm={6}>
          <CardExpand
            title="Address details"
            defaultOpen={props.customerInfo !== undefined ? false : true}
          >
            <SenderAddressDetailsContainer {...props} />
          </CardExpand>
        </Grid>
        {props.customerInfo !== undefined && (
          <Grid item sm={6}>
            <CardExpand title="KYC Status details" defaultOpen={false}>
              <SenderKYCStatusDetailsContainer {...props} />
            </CardExpand>
          </Grid>
        )}
      </Stack>
    </div>
  )
}
