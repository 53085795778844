import { types } from './action'

const auth = localStorage.getItem('authorization')

const INITIAL_STATE = auth ? { isAuth: true, auth } : { isAuth: false, auth: null }

/* eslint import/no-anonymous-default-export: [2, {"allowAnonymousFunction": true}] */
export default function (state = INITIAL_STATE, { type, payload }) {
  switch (type) {
    case types.LOGIN_SUCCESS:
      return {
        ...state,
        isAuth: true,
        auth: payload.auth,
      }
    case types.LOGIN_FAIL:
      return {
        ...state,
        isAuth: false,
        auth: null,
      }
    case types.LOG_OUT:
      return {
        ...state,
        isAuth: false,
        auth: null,
      }
    default:
      return state
  }
}
