import React, { useEffect, useState, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { makeStyles } from 'tss-react/mui'
import { isEmpty, isNil } from 'lodash'
import { FormProvider, useForm } from 'react-hook-form'
import { Box, Stack, Typography, Divider } from '@mui/material'
import Button from '../../../../components/common/Button'
import ProfileDocument from '../upload-document/profile-document'
import UploadDocumentType from '../upload-document/document-type'
import UploadTransactionDocType from '../upload-document/transaction-document-type'
import { apiUploadImage, apiCheckCustomerDoc } from '../../../../api/send-money'
import { DDLabel } from '../../../../utils/app'

const initState = {
  loading: false,
  checked: false,
}

const FormUploadFiles = ({ sno, onclose, setNotify }) => {
  const { classes } = useStyles()
  const methods = useForm()
  const [loading, setLoading] = useState(initState.loading)
  const [checked, setChecked] = useState(initState.checked)
  const SESSION_ID = sessionStorage.getItem('PROCESS_SESSION_ID') || ''
  const OPTION_DOCTYPE = useSelector((state) => state?.master?.doctype)

  const fetchCheckCustomerDoc = useCallback(async () => {
    setLoading(true)

    if (!sno) return

    const response = await apiCheckCustomerDoc(sno)
    if (response?.code !== 0) {
      return
    }

    setChecked(response?.data)
    setLoading(false)
  }, [sno])

  useEffect(() => {
    fetchCheckCustomerDoc()
  }, [fetchCheckCustomerDoc])

  const onSubmitHandler = async (values) => {
    var available = Object.keys(values).reduce((av, elem) => {
      if (values[elem] || !isEmpty(values[elem])) {
        av[elem] = values[elem]
      }
      return av
    }, {})

    // Call the Upload API
    const fetchUpladAPI = async (form) => {
      const response = await apiUploadImage(form)
      if (response?.code !== 0 || !isNil(response?.error)) {
        setNotify({
          open: true,
          message: response?.title || response?.message,
          type: 'error',
        })
        return
      }
      setNotify({
        open: true,
        message: response?.message,
        type: 'success',
      })
    }

    for (const [key, value] of Object.entries(available)) {
      const formData = new FormData()

      formData.append('ProcessID', SESSION_ID)
      switch (key) {
        case 'profile_img':
          formData.append('PostedFile', value)
          formData.append('CustomerIdType', 'Profile')
          formData.append('DocType', 'Profile')
          fetchUpladAPI(formData)
          break

        case 'front_side_img':
          formData.append('PostedFile', value)
          formData.append('CustomerIdType', available['type_id'])
          formData.append('DocType', 'Front')
          fetchUpladAPI(formData)
          break

        case 'back_side_img':
          formData.append('PostedFile', value)
          formData.append('CustomerIdType', available['type_id'])
          formData.append('DocType', 'Back')
          fetchUpladAPI(formData)
          break

        case 'type_id':
          break

        case 'transtype_id':
          break

        default:
          formData.append('PostedFile', value)
          formData.append('CustomerIdType', available['transtype_id'])
          formData.append('DocType', DDLabel(OPTION_DOCTYPE, available['transtype_id']))
          fetchUpladAPI(formData)
          break
      }
    }

    methods.reset()
    onclose()
  }

  return (
    <div>
      <FormProvider {...methods}>
        <Box
          noValidate
          component="form"
          autoComplete="off"
          onSubmit={methods.handleSubmit(onSubmitHandler)}
        >
          <Stack className={classes.file}>
            <Typography
              color="error"
              variant="Paragraph_lg_Bold"
              className={classes.filespan}
            >
              Please select only jpeg, jpg, png or pdf files.
            </Typography>
            {!loading ? (
              <>
                {!checked?.isDoc && (
                  <>
                    <ProfileDocument />
                    <Divider />
                    <UploadDocumentType />
                    <Divider />
                  </>
                )}
                <UploadTransactionDocType />
              </>
            ) : null}
            <Stack alignItems="center">
              <Button
                type="submit"
                variant="contained"
                text="UPLOAD FILE"
                fullWidth
                sx={{
                  width: 162,
                }}
              />
            </Stack>
          </Stack>
        </Box>
      </FormProvider>
    </div>
  )
}

export default FormUploadFiles

const useStyles = makeStyles()((theme) => ({
  file: {
    paddingTop: '40px',
    gap: 20,
    [theme.breakpoints.up('md')]: {
      width: 640,
      gap: 40,
      margin: 'auto',
    },

    button: {
      display: 'flex',
      justifyContent: 'center',
    },

    '& .MuiDivider-root': {
      backgroundPosition: 'top',
      backgroundSize: '30px 1px',
      backgroundRepeat: 'repeat-x',
      backgroundImage: `linear-gradient(to right, transparent 50%, #444A81 50%)`,
    },
  },

  filespan: {
    padding: '16px 20px',
    margin: 'initial',
    [theme.breakpoints.up('md')]: {
      padding: '0px 80px',
    },
  },
}))
