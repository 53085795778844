import React, { useRef, useState, useCallback, useEffect } from 'react'
import { Controller, useController, useFormContext } from 'react-hook-form'
import { Box, IconButton, Stack, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { styled } from '@mui/material/styles'
import { isEmpty } from 'lodash'
import { FileUploader } from 'react-drag-drop-files'
import Button from '../../components/common/Button'
import useResponsive from '../../hooks/useResponsive'
import ConfirmDialog from '../../components/useDialog'

const initState = {
  confirmDialog: {
    isOpen: false,
    title: '',
    subTitle: '',
  },
}

// 👇 Custom Styles for the Box Component
const CustomBox = styled(Box)(({ theme }) => ({
  '&.MuiBox-root': {
    padding: '1rem',
    maxWidth: 500,
    margin: 'auto',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  '&.MuiBox-root:hover, &.MuiBox-root.dragover': {
    opacity: 0.6,
  },
}))

// 👇 FileUpload Component
const FileUpload = ({ limit, multiple, name, options = [] }) => {
  // 👇 Form Context
  const {
    control,
    // reset,
    setValue,
    formState: { isSubmitting },
  } = useFormContext()

  const theme = useTheme()
  const isMobile = useResponsive('between', 'xs', 'md')
  const [confirmDialog, setConfirmDialog] = useState(initState.confirmDialog)

  // 👇 State with useState()
  const { field } = useController({ name, control })
  const [singleFile, setSingleFile] = useState([])
  const [fileList, setFileList] = useState([])
  const wrapperRef = useRef(null)

  // 👇 Toggle the dragover class
  const onDragEnter = () => wrapperRef.current?.classList.add('dragover')
  const onDragLeave = () => wrapperRef.current?.classList.remove('dragover')

  // 👇 Check Image Upload is Duplicate key ?
  const isDuplicateKey = (list, arr) => {
    if (isEmpty(list)) return

    var isDuplicate = list.some(function (item) {
      return Object.keys(item)[0] === Object.keys(arr)[0]
    })

    return isDuplicate
  }

  // 👇 Image Upload Service
  const onFileDrop = useCallback(
    (e) => {
      const target = e
      if (!target) return

      if (limit === 1) {
        const newFile = Object.values(target).map((file) => file)

        setSingleFile(newFile)
        field.onChange(newFile[0])
      }

      if (multiple) {
        let objMap = {}
        const newFiles = Object.values(target).map((file) => file)
        const mapNew = [name, ...newFiles]
        objMap[mapNew[0]] = mapNew[1]

        if (newFiles) {
          const check = isDuplicateKey(fileList, objMap)
          if (check) {
            setConfirmDialog({
              ...confirmDialog,
              isOpen: true,
              title: 'Error',
              subTitle: 'Agreement is already uploaded',
              type: 'alertMsgError',
            })
            return
          }

          const updated = [...fileList, objMap]
          const updatedList = [...newFiles]

          setFileList(updated)
          field.onChange(...updatedList)
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [field, fileList, limit, multiple, singleFile],
  )

  // 👇 remove multiple images
  const fileRemove = (file) => {
    const updatedList = [...fileList]
    updatedList.splice(fileList.indexOf(file), 1)
    setFileList(updatedList)
    setValue(...Object.keys(file), undefined)
  }

  // 👇 remove single image
  const fileSingleRemove = (file) => {
    setSingleFile([])
    setValue(name, undefined)
  }

  // 👇 Calculate Size in KiloByte and MegaByte
  const calcSize = (size) => {
    return size < 1000000
      ? `${Math.floor(size / 1000)} kb`
      : `${Math.floor(size / 1000000)} kb`
  }

  // 👇 Reset the State
  useEffect(() => {
    if (isSubmitting) {
      setFileList([])
      setSingleFile([])
      // reset()
    }
  }, [isSubmitting])

  const UploaderStyle = (
    <CustomBox>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          width: '100%',
          height: '16rem',
          backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='30' ry='30' stroke='%23444A81FF' stroke-width='3' stroke-dasharray='12%2c 30' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e");
          border-radius: 30px`,
        }}
        ref={wrapperRef}
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
        onDrop={onDragLeave}
      >
        <Stack
          justifyContent="center"
          sx={{ gap: 1, textAlign: 'center', alignItems: 'center' }}
        >
          <img
            src={`/assets/images/svg/cloud-upload.svg`}
            alt="file_upload"
            style={{ width: '5rem' }}
          />
          <Typography
            variant="Paragraph_md_Light"
            color="text.secondary"
            component="span"
          >
            Drag and Drop to Upload
          </Typography>
          <Button
            sx={{
              height: 42,
              textTransform: 'lowercase',
            }}
            text="choose a file"
            size="medium"
            variant="outlined"
          />
        </Stack>
      </Box>
    </CustomBox>
  )

  /* 👇 Image Preview 👇 */
  const ImagePreview = () => {
    return fileList.length > 0 || singleFile.length > 0 ? (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        sx={{
          gap: '14px',
          padding: '16px 20px',
          margin: 'initial',
          [theme.breakpoints.up('md')]: {
            padding: '16px 80px',
          },
        }}
      >
        {multiple
          ? fileList.map((item, index) => {
              const DocumentType = options.find(
                (e) => e.value === Object.keys(item)[0],
              )?.label

              return (
                <Box
                  key={index}
                  sx={{
                    width: '100%',
                    position: 'relative',
                    borderRadius: '30px',
                    p: 3,
                    backgroundColor: (theme) => theme.palette.background.popup,
                  }}
                >
                  <Box display="flex" alignItems="center">
                    <Typography
                      my={1}
                      sx={{
                        width: isMobile ? '130px' : '330px',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                      variant="Paragraph_md_Light"
                      component={'div'}
                    >
                      {DocumentType}
                    </Typography>
                    <Box sx={{ ml: 2 }}>
                      <Typography
                        my={1}
                        sx={{
                          width: isMobile ? '130px' : '330px',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                        variant="Paragraph_md_Light"
                        component={'div'}
                      >
                        {Object.values(item)[0].name}
                      </Typography>
                    </Box>
                  </Box>
                  <IconButton
                    onClick={() => {
                      if (multiple) {
                        fileRemove(item)
                      } else {
                        fileSingleRemove()
                      }
                    }}
                    sx={{
                      position: 'absolute',
                      right: '1rem',
                      top: '50%',
                      transform: 'translateY(-50%)',
                      span: { p: 0 },
                    }}
                  >
                    <img
                      src={`/assets/images/svg/close-square.svg`}
                      alt="close-btn"
                      width={30}
                      height={30}
                    />
                  </IconButton>
                </Box>
              )
            })
          : singleFile.map((item, index) => {
              const imageType = item.type.split('/')[1]
              return (
                <Box
                  key={index}
                  sx={{
                    width: '100%',
                    position: 'relative',
                    borderRadius: '30px',
                    p: 2,
                    backgroundColor: (theme) => theme.palette.background.popup,
                  }}
                >
                  <Box display="flex" alignItems="center">
                    <img
                      src={`/assets/images/svg/document.svg`}
                      alt="upload"
                      width={isMobile ? 36 : 56}
                      height={isMobile ? 36 : 56}
                      style={{
                        // height: '3.5rem',
                        objectFit: 'contain',
                      }}
                    />
                    <Box sx={{ ml: 2 }}>
                      <Typography
                        my={1}
                        sx={{
                          width: isMobile ? '130px' : '330px',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                        variant="Paragraph_lg_Bold"
                        component={'div'}
                      >
                        {item.name}
                      </Typography>
                      <Typography variant="Paragraph_md_Light" component={'div'}>
                        {imageType} - {calcSize(item.size)}
                      </Typography>
                    </Box>
                  </Box>
                  <IconButton
                    onClick={() => {
                      fileSingleRemove(item)
                    }}
                    sx={{
                      // color: '#df2c0e',
                      position: 'absolute',
                      right: '1rem',
                      top: '50%',
                      transform: 'translateY(-50%)',
                      span: { p: 0 },
                    }}
                  >
                    <img
                      src={`/assets/images/svg/close-square.svg`}
                      alt="close-btn"
                      width={30}
                      height={30}
                    />
                  </IconButton>
                </Box>
              )
            })}
      </Box>
    ) : null
  }

  return multiple ? (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field: { name, onBlur, ref } }) => (
          <FileUploader
            classes="file-drop-drag"
            name={name}
            onBlur={onBlur}
            ref={ref}
            multiple={true}
            types={['pdf', 'jpg', 'png', 'jpeg']}
            handleChange={onFileDrop}
            children={UploaderStyle}
          />
        )}
      />
      <ImagePreview />
      <ConfirmDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
    </>
  ) : singleFile.length > 0 ? (
    <ImagePreview />
  ) : (
    <Controller
      name={name}
      control={control}
      render={({ field: { name, onBlur, ref } }) => (
        <FileUploader
          classes="file-drop-drag"
          name={name}
          onBlur={onBlur}
          ref={ref}
          multiple={true}
          types={['pdf', 'jpg', 'png', 'jpeg']}
          handleChange={onFileDrop}
          children={UploaderStyle}
        />
      )}
    />
  )
}

export default FileUpload
