const RECEIVER = [
  {
    id: 'is_selected',
    label: '',
    disableSorting: true,
  },
  {
    id: 'receiverId',
    label: 'Receiver ID',
    disableSorting: true,
  },
  {
    id: 'receiverName',
    label: 'Receiver Name',
    disableSorting: true,
  },
  {
    id: 'rAddress',
    label: 'Address',
    disableSorting: true,
  },
  {
    id: 'rCountry',
    label: 'Country',
    disableSorting: true,
  },
  {
    id: 'relation', // realtion
    label: 'Relationship',
    disableSorting: true,
  },
  {
    id: 'payout',
    label: 'Payout Partner',
    disableSorting: true,
  },
]

const HEADERTABLE = {
  ReceiverHeader: RECEIVER,
}

export default HEADERTABLE
