import React from 'react'
import { makeStyles } from 'tss-react/mui'
import { styled } from '@mui/material/styles'
import { Controller } from 'react-hook-form'
import { Autocomplete, InputLabel, Typography, autocompleteClasses } from '@mui/material'
import SvgColor from '../../components/svg-color/svg-color'
import Input from '../../components/common/Input'
import { hideScrollbarY, hideScrollbarX } from '../../utils/stylecss'

const AutocompleteField = ({
  control,
  name,
  options,
  label,
  required,
  disabled,
  placeholder,
  disableClearable,
  disableErrorSpace,
  disabledLabels = [],
  // getOptionLabel,
  InputProp,
  sx,
  ...restProps
}) => {
  const { classes } = useStyles()

  return (
    <Controller
      control={control}
      render={({ field: { ref, onChange, ...restField }, fieldState: { error } }) => (
        <>
          <InputLabel className={classes?.labelStyle} htmlFor={name}>
            {error ? (
              <Typography
                variant="Paragraph_sm_Light"
                color="text.error"
                component="div"
                sx={{ mb: 1 }}
              >
                {label ? `${label}${required ? '  *' : ''}` : ''}
              </Typography>
            ) : (
              <Typography variant="Paragraph_sm_Light" component="div" sx={{ mb: 1 }}>
                {label ? `${label}${required ? '  *' : ''}` : ''}
              </Typography>
            )}
          </InputLabel>
          <Autocomplete
            {...restField}
            {...restProps}
            disableClearable={disableClearable}
            disabled={disabled}
            options={options}
            autoSelect
            autoHighlight
            // getOptionLabel={getOptionLabel}
            // getOptionLabel={(option) => `${option?.label}`}
            isOptionEqualToValue={(option, val) =>
              val === undefined ||
              option?.value?.toString() === (val?.value ?? val)?.toString()
            }
            // isOptionEqualToValue={(option, val) =>
            //   val === undefined ||
            //   val === '' ||
            //   option?.value?.toString() === (val?.value ?? val)?.toString()
            // }
            getOptionDisabled={(option) => disabledLabels.includes(option?.label)}
            renderInput={(params) => (
              <Input
                {...params}
                inputRef={ref}
                error={!!error}
                placeholder={placeholder}
                helperText={error?.message || (disableErrorSpace ? '' : ' ')}
                fullWidth
                sx={{
                  width: '100%',
                  ...sx,
                }}
              />
            )}
            PaperComponent={PopperComponent}
            popupIcon={
              <React.Fragment>
                <SvgColor
                  src={`/assets/icons/icon_arrow_down.svg`}
                  sx={{ px: 2, color: 'outline.primary' }}
                />
                <div
                  style={{
                    position: 'absolute',
                    right: InputProp && '-6px',
                    padding: InputProp && 20,
                    borderRadius: 19,
                    width: 86,
                  }}
                >
                  {InputProp}
                </div>
              </React.Fragment>
            }
            // onChange={(_, data) => onChange(data)}
            onChange={(_, data) => {
              if (data) {
                onChange(data.value)
              }
            }}
            value={
              restField.value !== undefined && options.length > 0
                ? options.find((o) => o.value === restField.value)
                : null
            }
          />
        </>
      )}
      rules={{
        required: required ? 'Required' : '',
      }}
      name={name}
    />
  )
}

export default AutocompleteField

const useStyles = makeStyles()(() => ({
  labelStyle: {
    whiteSpace: 'wrap',
  },
}))

const StyledAutocompletePopper = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.dark,
  [`& .${autocompleteClasses.paper}`]: {
    boxShadow: 'none',
    backgroundColor: theme.palette.background.dark,

    '.MuiAutocomplete-noOptions': {
      display: 'none',
    },
  },
  [`& .${autocompleteClasses.listbox}`]: {
    padding: 0,
    borderRadius: 25,
    border: `2px solid ${theme.palette.outline.default}`,
    ...hideScrollbarY,
    ...hideScrollbarX,
    [`& .${autocompleteClasses.option}`]: {
      minHeight: 'auto',
      alignItems: 'flex-start',
      padding: '14px 16px',

      '&[aria-selected="true"]': {
        borderRadius: 20,
        border: `2px solid ${theme.palette.outline.default}`,
        backgroundColor: theme.palette.background.popup,
      },

      [`&.${autocompleteClasses.focused}, &.${autocompleteClasses.focused}[aria-selected="true"]`]:
        {
          borderRadius: 20,
          backgroundColor: theme.palette.background.popup,
        },
    },
  },
  [`&.${autocompleteClasses.popperDisablePortal}`]: {
    position: 'relative',
  },
}))

function PopperComponent(props) {
  const { disablePortal, anchorEl, open, ...other } = props
  return <StyledAutocompletePopper {...other} />
}
