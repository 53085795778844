import { Routes, Route } from 'react-router-dom'
import PrivateRoutes from '../utils/PrivateRoutes'
import MainLayout from '../layouts/main-layout'
import Login from '../pages/authentication/login'

// Dashboard
import DashboardPage from '../pages/dashboard'

// SendMoney
import SendMoneyPage from '../pages/send-money'
import CreateNewSender from '../pages/send-money/form-sender'
import EditSender from '../pages/send-money/form-sender'
import TransactionDetails from '../pages/send-money/transaction-details'

// Import
import ImportPage from '../pages/im-port'

// History
import HistoryPage from '../pages/history'
import TransactionHistory from '../pages/history/TransactionHistory'
import SuspiciousReportPage from '../pages/history/SuspiciousReportPage'
import BatchFileTransaction from '../pages/history/transaction-pending/BatchFileTransaction'

// Customer
import CustomerPage from '../pages/customer'
import SenderNewPage from '../pages/customer/sender-new-page'
import SenderDetailsPage from '../pages/customer/sender-details-page'
import ReceiverNewPage from '../pages/receiver/receiver-new-page'
import ReceiverDetailsPage from '../pages/receiver/receiver-details-page'

const MapRouter = () => {
  return (
    <Routes>
      <Route element={<PrivateRoutes />}>
        <Route path="/" element={<MainLayout />}>
          <Route path="/dashboard" element={<DashboardPage />} />
          <Route path="/import" element={<ImportPage />} />
          <Route path="/history" element={<HistoryPage />} />
          <Route path="/history/transaction-history" element={<TransactionHistory />} />
          <Route path="/history/suspicious-report" element={<SuspiciousReportPage />} />
          <Route
            path="/history/batch-file-transaction"
            element={<BatchFileTransaction />}
          />
          <Route path="/customer" element={<CustomerPage />} />
          <Route path="/customer/new" element={<SenderNewPage />} />
          <Route path="/customer/details" element={<SenderDetailsPage />} />
          <Route path="/receiver/new" element={<ReceiverNewPage />} />
          <Route path="/receiver/details" element={<ReceiverDetailsPage />} />
          <Route path="/send-money" element={<SendMoneyPage />} />
          <Route path="/send-money/create-new-sender" element={<CreateNewSender />} />
          <Route path="/send-money/:id" element={<EditSender />} />
          <Route path="/transaction-details/:id" element={<TransactionDetails />} />
        </Route>
      </Route>
      <Route path="/login" element={<Login />} />
    </Routes>
  )
}

export default MapRouter
