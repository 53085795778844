import React, { useState, useRef, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { styled } from '@mui/material/styles'
import { motion } from 'framer-motion'
import { Container, Box, Typography, TextField, InputAdornment } from '@mui/material'
import Autocompleted from '../../../components/common/Autocompleted'
import { apiSearchCustomerList } from '../../../api/send-money'
import SvgColor from '../../../components/svg-color/svg-color'
import typography from '../../../theme/typography'
import {
  selectCustomerDetail,
  selectReceiverDetail,
  fetchReceiverDelivery,
} from '../../../redux/master/action'
import { fetchPreviewSendTransaction } from '../../../redux/send-money/action'

const typo = typography
const init = {
  option: [],
  keyEnter: [],
  loading: false,
  searchText: {},
}

export default function SearchSender() {
  const dispatch = useDispatch()
  const [options, setOptions] = useState(init.option)
  const [loading, setLoading] = useState(init.loading)
  const [keyEnter, setKeyEnter] = useState(init.keyEnter)
  const [searchText, setSearchText] = useState(init.searchText)
  const previousController = useRef()

  const navigate = useNavigate()
  const location = useLocation()
  const currentPath = location.pathname

  const variants = {
    initial: { x: 0 },

    final: {
      rotate: '360deg',

      transition: {
        duration: 2,
        repeat: Infinity,
        ease: 'linear',
      },
    },
  }

  const onInputChange = async (_, value) => {
    if (!!value) {
      setSearchText(value)
      setLoading(true)
      if (previousController.current) {
        previousController.current.abort()
      }
      const controller = new AbortController()
      const signal = controller.signal
      const response = await apiSearchCustomerList({ searchvalue: value }, signal)
      if (response?.code !== 0) {
        setOptions([])
        setLoading(false)
        return
      }
      const option = response?.data?.map((e) => {
        return {
          id: e?.sno,
          title: e?.senderName,
          customerId: e?.customerId,
        }
      })
      setOptions(option)
    } else {
      setOptions([])
    }
    setLoading(false)
  }

  const handleKeyDown = (event) => {
    switch (event.key) {
      case 'Enter': {
        event.preventDefault()
        event.stopPropagation()
        if (event.target.value.length > 0) {
          navigate(`${currentPath}/${keyEnter?.id}`, {
            replace: false,
          })
        }
        break
      }
      default:
    }
  }

  useEffect(() => {
    dispatch(selectCustomerDetail({}))
    dispatch(selectReceiverDetail({}))
    dispatch(fetchReceiverDelivery([]))
    dispatch(fetchPreviewSendTransaction({}))
    onInputChange()
  }, [dispatch])

  return (
    <Container>
      <StyledAutocomplete>
        <Autocompleted
          freeSolo
          loading={loading}
          id="search-customer"
          options={options}
          autoSelect
          autoHighlight
          onInputChange={onInputChange}
          onHighlightChange={(_, option) => setKeyEnter(option)}
          getOptionLabel={() => {
            return searchText
          }}
          renderOption={(props, option) => {
            return (
              <Box
                {...props}
                sx={{ '& > span': { mr: 2, flexShrink: 0 } }}
                key={option?.id}
                onClick={() =>
                  navigate(`${currentPath}/${option?.id}`, {
                    replace: false,
                  })
                }
              >
                <SvgColor
                  src={`/assets/icons/icon_profile_circle.svg`}
                  sx={{ width: 28, height: 28, color: 'outline.primary' }}
                />
                <Box sx={{ '& > span': { mr: 2, flexShrink: 0 } }}>
                  <Typography variant="Paragraph_lg_Light" component="span">
                    {option?.title}
                  </Typography>
                  <Typography variant="Paragraph_lg_Bold" component="span">
                    ({option?.customerId})
                  </Typography>
                </Box>
              </Box>
            )
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgColor
                      src={`/assets/icons/icon_search.svg`}
                      sx={{ width: 28, height: 28, color: 'outline.primary' }}
                    />
                  </InputAdornment>
                ),
                endAdornment: loading ? (
                  <motion.div variants={variants} initial="initial" animate="final">
                    <img
                      alt="loading icon"
                      src={`/assets/icons/icon_loading.svg`}
                      style={{ width: 28, height: 28, color: 'outline.primary' }}
                    />
                  </motion.div>
                ) : null,
              }}
              placeholder="Search"
              onKeyDown={handleKeyDown}
            />
          )}
        />
      </StyledAutocomplete>
    </Container>
  )
}

const StyledAutocomplete = styled('div')(({ theme }) => ({
  '& .MuiAutocomplete-root': {
    '& .MuiOutlinedInput-root': {
      padding: '0px 27px',
      borderRadius: '30px',
      background: theme.palette.background.popup,
      border: `1px solid ${theme.palette.outline.default}`,
      '& .MuiOutlinedInput-notchedOutline': {
        border: `none`,
      },
      '&:hover': {
        '& .MuiOutlinedInput-notchedOutline': {
          border: `2px solid ${theme.palette.outline.hover}`,
        },
      },
      '&.Mui-focused': {
        '& .MuiOutlinedInput-notchedOutline': {
          border: `2px solid ${theme.palette.outline.focus}`,
        },
      },
      input: {
        color: theme.palette.text.primary,
        ...typo['Paragraph_lg_Light'],
        padding: 12,
      },
    },
  },
}))
