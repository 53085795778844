import typography from '../typography'

const typo = typography

export default function Autocomplete(theme) {
  return {
    MuiAutocomplete: {
      styleOverrides: {
        paper: {
          // boxShadow: theme.customShadows.z20,
        },
        popupIndicator: {
          height: '-webkit-fill-available',

          '&[aria-label="Open"]': {
            '& div': {
              backgroundColor: theme.palette.background.popup,
              color: theme.palette.text.primary,
              // height: '-webkit-fill-available',
              ...typo['Paragraph_lg_Light'],
            },
          },

          '&[aria-label="Close"]': {
            '& div': {
              display: 'none',
            },
          },
        },
      },
    },
  }
}
