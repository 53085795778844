import { makeStyles } from 'tss-react/mui'

export const useHistoryStyles = makeStyles()((theme) => ({
  search: {
    width: '100%',
    marginBottom: 8,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 0,
    },
  },
  copytext: {
    borderRadius: 25,
    backgroundColor: theme.palette.background.dark,
    //border: `1px solid ${theme.palette.background.dark}`,
    justifyContent: 'left',
    flexDirection: 'row',
    textAlign: 'initial',
    padding: '22px',
    alignItems: 'left',
    // [theme.breakpoints.down('md')]: {
    //   padding: '22px',
    //   flexDirection: 'column',
    // },
    '&:hover': {
      // transition: 'all 0.6s ease',
      // opacity: 0.6,
      backgroundColor: theme.palette.background.primary,
    },
  },
  icon: {
    width: 24,
    height: 24,
    color: theme.palette.text.primary,
  },
  buttonHeight: {
    height: 56,
  },
  btnFilter: {
    color: theme.palette.text.primary,
    display: 'flex',
    textTransform: 'uppercase',
    width: 'auto',
    padding: '0px 0px',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('md')]: {
      padding: '0px 0px',
      alignItems: 'center',
    },
  },
  paper: {
    boxShadow: 0,
    marginTop: '25px',
    padding: '16px',
    width: '600px',
    bgcolor: theme.palette.background.popup,
    border: `solid 1px ${theme.palette.outline.default}`,
  },
  layoutCollapse: {
    '& .MuiCollapse-wrapper': {
      justifyContent: 'center',
    },
    [theme.breakpoints.down('sm')]: {
      '& .MuiCollapse-wrapperInner': {
        width: 330,
      },
    },
    [theme.breakpoints.up('sm')]: {
      '& .MuiCollapse-wrapperInner': {
        width: 600,
      },
    },
    [theme.breakpoints.up('md')]: {
      '& .MuiCollapse-wrapperInner': {
        width: '100%',
      },
    },
  },
  fields: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      margin: '20px',
    },
  },
}))
