import { combineReducers } from 'redux'
import loginReducer from '../redux/auth/reducer'
import masterReducer from '../redux/master/reducer'
import sendMoneyReducer from '../redux/send-money/reducer'

export default combineReducers({
  login: loginReducer,
  master: masterReducer,
  sendMoney: sendMoneyReducer,
})
