import React, { useState, useEffect } from 'react'
import { Grid } from '@mui/material'
import { isNil } from 'lodash'
import AutocompleteField from '../../../components/common/AutocompleteField'
import NumberField from '../../../components/common/NumberField'
import InputField from '../../../components/common/InputField'
import { apiGetAgentCountry } from '../../../api/customer'

export default function SenderAddressDetails(props) {
  const { control, setNotify } = props

  const [agentCountryRecords, setAgentCountryRecords] = useState([])

  const fetchAgentCountryList = async () => {
    const response = await apiGetAgentCountry()
    if (response?.code !== 0 || !isNil(response?.error)) {
      setNotify({
        open: true,
        message: response?.message,
        type: 'error',
      })
      return
    }
    const optionAgentCountry = response?.data?.map((e) => {
      return { label: e?.rec_country, value: e?.country_code }
    })
    setAgentCountryRecords(optionAgentCountry)
  }

  useEffect(() => {
    fetchAgentCountryList()
  }, [])

  return (
    <Grid container spacing={{ xs: 1, sm: 2 }} columns={{ xs: 1, sm: 8, md: 8 }}>
      <Grid item xs={2} sm={4} md={4}>
        <AutocompleteField
          control={control}
          name="senderAddressCountry"
          label="Country"
          placeholder="Select country"
          options={agentCountryRecords}
          required
        />
      </Grid>
      <Grid item xs={2} sm={4} md={4}>
        <NumberField
          control={control}
          name="senderAddressZipCode"
          label="Zip code"
          placeholder="Zip Code"
        />
      </Grid>
      <Grid item xs={2} sm={4} md={4}>
        <InputField
          control={control}
          name="senderAddressState"
          label="State"
          placeholder="State"
          required
        />
      </Grid>
      <Grid item xs={2} sm={4} md={4}>
        <InputField
          control={control}
          name="senderAddressCity"
          label="City"
          placeholder="City"
          required
        />
      </Grid>
      <Grid item xs={2} sm={4} md={4}>
        <InputField
          control={control}
          name="senderAddressDistrict"
          label="District"
          placeholder="District"
        />
      </Grid>
      <Grid item xs={2} sm={4} md={4}>
        <InputField
          control={control}
          name="senderAddressAddressStreet"
          label="Street"
          placeholder="Street"
        />
      </Grid>
    </Grid>
  )
}
