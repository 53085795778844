import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm, useWatch } from 'react-hook-form'
import { makeStyles } from 'tss-react/mui'
import { Container, Tabs, Tab, Box, Stack, Paper, Typography, Grid } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { useTheme } from '@mui/material/styles'
import Button from '../../components/common/Button'
import Backdrop from '../../components/useBackdrop'
import AlertBar from '../../components/common/AlertBar'
import ConfirmDialog from '../../components/useDialog'
import AlertBox from '../../components/alertBox/alertBox'
import SenderDetailsTab from '../../containers/customers/sender/sender-details-tab'
import SenderAdditionalInformationTab from '../../containers/customers/sender/sender-additional-information-tab'
import { apiCreateCustomer } from '../../api/customer'
import moment from 'moment'

const useStyles = makeStyles()((theme) => ({
  stepBtn: {
    marginTop: '40px',
    marginBottom: '0px',
    display: 'flex',
    justifyContent: 'end',
  },
}))

export default function SenderNewPage() {
  const navigate = useNavigate()

  const theme = useTheme()

  const { classes } = useStyles()

  const { control, handleSubmit } = useForm()

  const [tabValue, setTabValue] = useState(0)
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    type: '',
    title: '',
    subTitle: '',
    container: '',
  })
  const [notify, setNotify] = useState({
    open: false,
    message: '',
    type: '',
  })
  const [openBackdrop, setOpenBackdrop] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)

  const TabPanel = (props) => {
    const { children, value, index, ...other } = props
    return (
      <Stack
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Container
            style={{ maxWidth: '-webkit-fill-available' }}
            sx={{
              '@media (min-width:600px)': {
                padding: '0px 0px',
              },
            }}
          >
            <Box
              sx={{
                p: 1,
                '& .MuiContainer-root': {
                  maxWidth: '100%',
                },
              }}
            >
              <Typography component={'div'}>{children}</Typography>
            </Box>
          </Container>
        )}
      </Stack>
    )
  }

  const onSubmit = async (data) => {
    const invalidRequiredFieldList = await validateRequiredField(data)
    if (invalidRequiredFieldList !== '') {
      setConfirmDialog({
        isOpen: true,
        type: 'alertWarning',
        title: 'Validation failed',
        subTitle: invalidRequiredFieldList + ' is required',
      })
      return
    }
    setConfirmDialog({
      isOpen: true,
      type: 'customDialogWithContainer',
      title: 'CONFIRM DETAILS',
      subTitle: '',
      container: await containerConfirmData(data),
    })
  }

  const containerConfirmData = async (data) => {
    return (
      <Grid container spacing={{ xs: 1, sm: 2 }} columns={{ xs: 1, sm: 8, md: 8 }}>
        <Grid item xs={4} sm={8} md={8} sx={{ color: '#FFFFFF' }}>
          Please confirm details
        </Grid>
        <Grid item xs={2} sm={4} md={4}>
          Customer ID :
        </Grid>
        <Grid item xs={2} sm={4} md={4} sx={{ color: '#FFFFFF' }}>
          Auto Generate
        </Grid>
        <Grid item xs={2} sm={4} md={4}>
          Sender name :
        </Grid>
        <Grid item xs={2} sm={4} md={4} sx={{ color: '#FFFFFF' }}>
          {data.senderFirstName ? data.senderFirstName : ''}&nbsp;
          {data.senderMiddleName ? data.senderMiddleName : ''}&nbsp;
          {data.senderLastName ? data.senderLastName : ''}
        </Grid>
        <Grid item xs={2} sm={4} md={4}>
          Date of birth :
        </Grid>
        <Grid item xs={2} sm={4} md={4} sx={{ color: '#FFFFFF' }}>
          {data.senderDateOfBirth
            ? moment(data.senderDateOfBirth).format('YYYY-MM-DD')
            : ''}
        </Grid>
        <Grid item xs={2} sm={4} md={4}>
          Nationality :
        </Grid>
        <Grid item xs={2} sm={4} md={4} sx={{ color: '#FFFFFF' }}>
          {data.senderNationality ? data.senderNationality : ''}
        </Grid>
        <Grid item xs={2} sm={4} md={4}>
          Post code :
        </Grid>
        <Grid item xs={2} sm={4} md={4} sx={{ color: '#FFFFFF' }}>
          {data.senderAddressZipCode ? data.senderAddressZipCode : ''}
        </Grid>
        <Grid item xs={2} sm={4} md={4}>
          City :
        </Grid>
        <Grid item xs={2} sm={4} md={4} sx={{ color: '#FFFFFF' }}>
          {data.senderAddressCity ? data.senderAddressCity : ''}
        </Grid>
        <Grid item xs={2} sm={4} md={4}>
          Street :
        </Grid>
        <Grid item xs={2} sm={4} md={4} sx={{ color: '#FFFFFF' }}>
          {data.senderAddressAddressStreet ? data.senderAddressAddressStreet : ''}
        </Grid>
        <Grid item xs={2} sm={4} md={4}>
          Mobile No :
        </Grid>
        <Grid item xs={2} sm={4} md={4} sx={{ color: '#FFFFFF' }}>
          {data.senderMobileNo ? data.senderMobileNo : ''}
        </Grid>
        <Grid item xs={2} sm={4} md={4}>
          Sender email :
        </Grid>
        <Grid item xs={2} sm={4} md={4} sx={{ color: '#FFFFFF' }}>
          {data.senderEmail ? data.senderEmail : ''}
        </Grid>
        <Grid item xs={4} sm={8} md={8} sx={{ textAlign: 'center' }}>
          <Button
            text="confirm"
            size="medium"
            color="success"
            onClick={() => {
              setConfirmDialog({
                ...confirmDialog,
                isOpen: false,
              })
              addOrEdit(data)
            }}
          />
        </Grid>
      </Grid>
    )
  }

  const addOrEdit = async (data) => {
    setOpenBackdrop(true)
    const payload = {
      // Sender Type
      customertype: data.senderType ? data.senderType : '',
      // Sender Details
      firstName: data.senderFirstName ? data.senderFirstName : '',
      middleName: data.senderMiddleName ? data.senderMiddleName : '',
      lastName: data.senderLastName ? data.senderLastName : '',
      gender: data.senderGender ? (data.senderGender === 'Male' ? 'M' : 'F') : '',
      nationality: data.senderNationality ? data.senderNationality : '',
      dateOfBirth: data.senderDateOfBirth
        ? moment(data.senderDateOfBirth).format('YYYY-MM-DD')
        : '',
      email: data.senderEmail ? data.senderEmail : '',
      mobileNo: data.senderMobileNo ? data.senderMobileNo : '',
      occupation: data.senderOccupation ? data.senderOccupation : '',
      occupationOther: data.senderOccupationOther ? data.senderOccupationOther : '',
      // Sender Address
      country: data.senderAddressCountry ? data.senderAddressCountry : '',
      zipCode: data.senderAddressZipCode ? data.senderAddressZipCode : '',
      state: data.senderAddressState ? data.senderAddressState : '',
      city: data.senderAddressCity ? data.senderAddressCity : '',
      addressStreet: data.senderAddressAddressStreet
        ? data.senderAddressAddressStreet
        : '',
      district: data.senderAddressDistrict ? data.senderAddressDistrict : '',
      // Sender Additional Details
      typeVisa: data.senderAdditionalTypeVisa ? data.senderAdditionalTypeVisa : '',
      sourceOfIncome: data.senderAdditionalSourceOfIncome
        ? data.senderAdditionalSourceOfIncome
        : '',
      sourceOfIncomeOther: data.senderAdditionalSourceOfIncomeOther
        ? data.senderAdditionalSourceOfIncomeOther
        : '',
      companyName: data.senderAdditionalCompanyName
        ? data.senderAdditionalCompanyName
        : '',
      companyAddress: data.senderAdditionalCompanyAddress
        ? data.senderAdditionalCompanyAddress
        : '',
      companyContact: data.senderAdditionalCompanyContact
        ? data.senderAdditionalCompanyContact
        : '',
      primaryIdType: data.senderAdditionalPrimaryIdType
        ? data.senderAdditionalPrimaryIdType
        : '',
      primaryIdTypeOther: data.senderAdditionalPrimaryIdTypeOther
        ? data.senderAdditionalPrimaryIdTypeOther
        : '',
      primaryIdNumber: data.senderAdditionalPrimaryIdNumber
        ? data.senderAdditionalPrimaryIdNumber
        : '',
      primaryIdPlaceOfIssue: data.senderAdditionalPrimaryIdPlaceOfIssue
        ? data.senderAdditionalPrimaryIdPlaceOfIssue
        : '',
      primaryIdIssueDate: data.senderAdditionalPrimaryIdIssueDate
        ? moment(data.senderAdditionalPrimaryIdIssueDate).format('YYYY-MM-DD')
        : '',
      primaryIdExpirydate: data.senderAdditionalPrimaryIdExpirydate
        ? moment(data.senderAdditionalPrimaryIdExpirydate).format('YYYY-MM-DD')
        : '',
      kycinputby: data.senderAdditionalKycinputby ? data.senderAdditionalKycinputby : '',
      customerRemarks: data.senderAdditionalCustomerRemarks
        ? data.senderAdditionalCustomerRemarks
        : '',
    }
    const response = await apiCreateCustomer(payload)
    setOpenBackdrop(false)
    if (response?.code !== 0) {
      setErrorMessage(response?.message)
      return
    }
    setConfirmDialog({
      isOpen: true,
      title: '',
      type: 'customDialogWithContainer',
      subTitle: '',
      container: await containerSubmitResultData(response.data),
    })
  }

  const validateRequiredField = async (data) => {
    let temp = ''
    temp += data.senderType ? '' : '[Customer type] '
    temp += data.senderFirstName ? '' : '[First name] '
    temp += data.senderGender ? '' : '[Gender] '
    temp += data.senderNationality ? '' : '[Nationality] '
    temp += data.senderMobileNo ? '' : '[Mobile no] '
    temp += data.senderOccupation ? '' : '[Occupation] '
    temp += data.senderAddressCountry ? '' : '[Country] '
    temp += data.senderAddressState ? '' : '[State] '
    temp += data.senderAddressCity ? '' : '[City] '
    temp += data.senderAdditionalSourceOfIncome ? '' : '[Source of fund] '
    return temp
  }

  const containerSubmitResultData = async (data) => {
    return (
      <Grid container spacing={{ xs: 1, sm: 2 }} columns={{ xs: 1, sm: 8, md: 8 }}>
        <Grid item xs={4} sm={8} md={8} sx={{ textAlign: 'center' }}>
          <img
            src={`/assets/icons/create_successfully.svg`}
            alt="successfully"
            style={{
              display: 'block',
              marginLeft: 'auto',
              marginRight: 'auto',
              width: '50%',
            }}
          />
        </Grid>
        <Grid item xs={4} sm={8} md={8} sx={{ textAlign: 'center' }}>
          <Typography
            variant="H5_Bold"
            sx={{
              fontFamily: 'Overpass',
              fontsize: '24px',
              fontWeight: 700,
              lineHeight: '24px',
              letterSpacing: '0.0015em',
              color: '#FFFFFF',
            }}
          >
            CUSTOMER CREATE SUCCESSFULLY
          </Typography>
        </Grid>
        <Grid item xs={4} sm={8} md={8} sx={{ textAlign: 'center' }}>
          <Typography
            variant="H5_Bold"
            sx={{
              fontFamily: 'Overpass',
              fontsize: '20px',
              fontWeight: 600,
              lineHeight: '20px',
              letterSpacing: '0.0015em',
              color: '#FFFFFF',
            }}
          >
            Customer ID : {data.customerId}
          </Typography>
        </Grid>
        <Grid item xs={4} sm={8} md={8} sx={{ textAlign: 'center' }}>
          Do you want to upload documents ? Click here
        </Grid>
        <Grid item xs={4} sm={8} md={8} sx={{ textAlign: 'center' }}>
          <Button
            text="view customer details"
            size="medium"
            color="success"
            onClick={() => {
              setConfirmDialog({
                ...confirmDialog,
                isOpen: false,
              })
              navigate(`/customer/details?routeEdit=${data.routeEdit}`, {
                replace: false,
              })
            }}
            sx={{ width: '60%' }}
          />
        </Grid>
        <Grid item xs={4} sm={8} md={8} sx={{ textAlign: 'center' }}>
          <Typography
            variant="H5_Bold"
            onClick={() => {
              setConfirmDialog({
                ...confirmDialog,
                isOpen: false,
              })
              navigate(`/customer`, {
                replace: false,
              })
            }}
            sx={{
              fontFamily: 'Overpass',
              fontSize: '14px !important',
              fontWeight: 300,
              lineHeight: '18px',
              letterSpacing: '0.0015em',
              cursor: 'pointer',
            }}
          >
            GO TO CUSTOMER LIST PAGE
          </Typography>
        </Grid>
      </Grid>
    )
  }

  const tabProps = (index) => {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    }
  }
  const onTabChange = (e, value) => {
    e.preventDefault()
    setTabValue(value)
  }

  const tabList = [
    {
      key: 'personaldetails',
      label: 'Personal Details',
      tab: (
        <SenderDetailsTab control={control} useWatch={useWatch} setNotify={setNotify} />
      ),
    },
    {
      key: 'additionalinformation',
      label: 'Additional Information',
      tab: (
        <SenderAdditionalInformationTab
          control={control}
          useWatch={useWatch}
          setNotify={setNotify}
        />
      ),
    },
  ]

  return (
    <>
      <Container>
        <React.Fragment>
          <Paper sx={{ paddingLeft: 6 }}>
            <Grid item sm={1} key="Back">
              <Button
                onClick={() => {
                  navigate(`/customer`, {
                    replace: false,
                  })
                }}
                startIcon={<ArrowBackIcon />}
                text="Back"
                variant="outlined"
                size="small"
              />
            </Grid>
          </Paper>
          <Paper sx={{ paddingTop: 3 }}>
            <form control={control} onSubmit={handleSubmit(onSubmit)}>
              <Box
                sx={{
                  display: 'grid',
                  '@media (min-width:400px)': {
                    gridTemplateColumns: 'repeat(auto-fill, minmax(1fr 3fr))',
                  },
                  '@media (min-width:800px)': {
                    gridTemplateColumns: '1fr 3fr',
                  },
                  borderBottom: 0,
                  borderColor: theme.palette.background.basebg,
                }}
              >
                <Box>
                  <Tabs
                    value={tabValue}
                    onChange={onTabChange}
                    orientation="vertical"
                    centered
                    sx={{
                      '& .MuiTabs-flexContainer': {
                        display: 'flex',
                        justifyContent: 'flex-start',
                        padding: '18px 38px',
                        '& .Mui-selected': {
                          color: '#FFFFFF',
                          bgcolor: theme.palette.background.cardbg,
                          borderRadius: '4px',
                        },
                      },
                      '& .MuiTabs-indicator': {
                        height: 0,
                        width: 0,
                      },
                    }}
                  >
                    {tabList.map((e, i) => {
                      return (
                        <Tab
                          key={`tab-${i}`}
                          label={e.label}
                          icon={e.icon}
                          {...tabProps(i)}
                          sx={{
                            '@media (min-width:600px)': {
                              alignItems: 'start',
                            },
                            width: '240px',
                            height: '48px',
                            padding: '22px',
                            borderRadius: 2,
                            border: '1px solid #8655D1',
                            margin: '8px 0',
                            '&.Mui-selected': {
                              backgroundColor: '#8655D1',
                            },
                          }}
                        />
                      )
                    })}
                  </Tabs>
                </Box>
                <Box>
                  {tabList.map((e, i) => {
                    return (
                      <TabPanel key={i} value={tabValue} index={i}>
                        {e.tab}
                      </TabPanel>
                    )
                  })}
                </Box>
              </Box>
              <Backdrop openBackdrop={openBackdrop} />
              <AlertBox notify={notify} setNotify={setNotify} />
              <ConfirmDialog
                confirmDialog={confirmDialog}
                setConfirmDialog={setConfirmDialog}
              />
              <div className={`${classes.stepBtn}`}>
                <AlertBar type="error" message={errorMessage} />
                <Button type="submit" variant="contained" text="SAVE" />
              </div>
            </form>
          </Paper>
        </React.Fragment>
      </Container>
    </>
  )
}
