import { setCookie, getCookie, removeCookieAll } from '../../utils/cookie'
import { apiUserSignIn, apiUserSignOut } from '../../api/auth'

export const types = {
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAIL: 'LOGIN_FAIL',
  LOG_OUT: 'LOG_OUT',
  // SET_PERMISSION: "SET_PERMISSION",
}

export const usernameSignIn = (username, password, access_code) => {
  const payload = {
    userName: username,
    password: password,
    accessCode: access_code,
    loginSessionId: 'loginSessionId',
  }
  return async (dispatch) => {
    const response = await apiUserSignIn(payload)

    if (response && response?.code === 0) {
      setCookie('authorization', response.data)
      dispatch({
        type: types.LOGIN_SUCCESS,
        payload: { auth: response.data },
      })
      return 'IS_AUTH'
    } else {
      const message = response?.message
      dispatch({
        type: types.LOGIN_FAIL,
      })
      return message
    }
  }
}

export const logout = (router) => {
  const activeSession = getCookie('authorization')?.activeSession

  const payload = {
    loginSessionId: activeSession,
  }

  return async (dispatch) => {
    const response = await apiUserSignOut(payload)

    if (response && response?.code === 0) {
      dispatch({
        type: types.LOG_OUT,
      })

      removeCookieAll()

      setTimeout(() => {
        window.location.href = '/login'
      }, 1000)
    }
  }
}
