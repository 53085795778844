import React from "react";
import { Helmet } from "react-helmet-async";

export default function Dashboard() {
  return (
    <>
      <Helmet>
        <title> Dashboard </title>
      </Helmet>
    </>
  );
}
