import React from 'react'
import { Stack, InputLabel, Typography, Checkbox as MuiCheckbox } from '@mui/material'
import { styled } from '@mui/material/styles'

const convertToDefEventPara = (name, value) => ({
  target: {
    name,
    value,
  },
})

export default function CheckBox(props) {
  const { name, label, value, onChange, icon, ...other } = props
  return (
    <Stack direction="row" sx={{ alignItems: 'center' }}>
      <MuiCheckbox
        name={name}
        color="primary"
        checked={value}
        icon={
          icon === undefined || icon === null ? (
            <img alt="uncheck" src={`/assets/icons/icon_uncheckbox.svg`} />
          ) : (
            icon
          )
        }
        checkedIcon={<img alt="check" src={`/assets/icons/icon_checkbox.svg`} />}
        onChange={(e) => {
          onChange(convertToDefEventPara(name, e.target.checked))
        }}
        {...other}
      />
      {label && (
        <InputLabel htmlFor={name}>
          <Typography variant="Paragraph_lg_Light" color="text.primary">
            {label}
          </Typography>
        </InputLabel>
      )}
    </Stack>
  )
}
