import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useForm, useWatch } from 'react-hook-form'
import { makeStyles } from 'tss-react/mui'
import dayjs from 'dayjs'
import { Container, Tabs, Tab, Box, Stack, Paper, Typography, Grid } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { useTheme } from '@mui/material/styles'
import { isNil } from 'lodash'
import Button from '../../components/common/Button'
import Backdrop from '../../components/useBackdrop'
import AlertBar from '../../components/common/AlertBar'
import ConfirmDialog from '../../components/useDialog'
import AlertBox from '../../components/alertBox/alertBox'
import ReceiverDetailsTab from '../../containers/customers/receiver/receiver-details-tab'
import ReceiverAdditionalInformationTab from '../../containers/customers/receiver/receiver-additional-information-tab'
import { apiGetReceiverDetails, apiUpdateReceiver } from '../../api/customer'
import moment from 'moment'

const useStyles = makeStyles()((theme) => ({
  stepBtn: {
    marginTop: '40px',
    marginBottom: '0px',
    display: 'flex',
    justifyContent: 'end',
  },
}))

export default function ReceiverDetailsPage() {
  const navigate = useNavigate()

  const location = useLocation()

  const theme = useTheme()

  const { classes } = useStyles()

  const { control, setValue, handleSubmit } = useForm()

  const [tabValue, setTabValue] = useState(0)
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    type: '',
    title: '',
    subTitle: '',
    container: '',
  })
  const [notify, setNotify] = useState({
    open: false,
    message: '',
    type: '',
  })
  const [openBackdrop, setOpenBackdrop] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const [receiverInfo, setReceiverInfo] = useState(null)

  const TabPanel = (props) => {
    const { children, value, index, ...other } = props
    return (
      <Stack
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Container
            style={{ maxWidth: '-webkit-fill-available' }}
            sx={{
              '@media (min-width:600px)': {
                padding: '0px 0px',
              },
            }}
          >
            <Box
              sx={{
                p: 1,
                '& .MuiContainer-root': {
                  maxWidth: '100%',
                },
              }}
            >
              <Typography component={'div'}>{children}</Typography>
            </Box>
          </Container>
        )}
      </Stack>
    )
  }

  const onSubmit = async (data) => {
    addOrEdit(data)
  }

  const addOrEdit = async (data) => {
    setOpenBackdrop(true)
    const payload = {
      // sendesno: customerInfo?.customerdetailModel?.customersno,
      // // Receiver Type
      // receivertype: data.receiverType ? data.receiverType : '',

      receiversno: receiverInfo.receiverdetailModel.receiversno,
      // Receiver Details
      firstName: data.receiverFirstName ? data.receiverFirstName : '',
      middleName: data.receiverMiddleName ? data.receiverMiddleName : '',
      lastName: data.receiverLastName ? data.receiverLastName : '',
      gender: data.receiverGender ? (data.receiverGender === 'Male' ? 'M' : 'F') : '',
      mobileNo: data.receiverMobileNo ? data.receiverMobileNo : '',
      eMail: data.receiverEmail ? data.receiverEmail : '',
      dateOfBirth: data.receiverDateOfBirth
        ? moment(data.receiverDateOfBirth).format('YYYY-MM-DD')
        : '',
      nationality: data.receiverNationality ? data.receiverNationality : '',
      occupation: data.receiverOccupation ? data.receiverOccupation : '',
      occupationOther: data.receiverOccupationOther ? data.receiverOccupationOther : '',
      purposeofremittance: data.receiverPurposeofremittance
        ? data.receiverPurposeofremittance
        : '',
      purposeofremittanceother: data.receiverPurposeofremittanceother
        ? data.receiverPurposeofremittanceother
        : '',
      relationship: data.receiverRelationship ? data.receiverRelationship : '',
      relationshipother: data.receiverRelationshipother
        ? data.receiverRelationshipother
        : '',

      // Receiver Address
      country: data.receiverAddressCountry ? data.receiverAddressCountry : '',
      zipCode: data.receiverAddressZipCode ? data.receiverAddressZipCode : '',
      prefecture: data.receiverAddressState ? data.receiverAddressState : '',
      city: data.receiverAddressCity ? data.receiverAddressCity : '',
      district: data.receiverAddressDistrict ? data.receiverAddressDistrict : '',
      // xxx: data.receiverAddressAddressStreet ? data.receiverAddressAddressStreet : '',

      // Receiver Payment Information
      countryofshipment: data.receiverPaymentCountryofshipment
        ? data.receiverPaymentCountryofshipment
        : '',
      modeofremittance: data.receiverPaymentModeofremittance
        ? data.receiverPaymentModeofremittance
        : '',
      bankList: data.receiverPaymentBankList ? data.receiverPaymentBankList : '',
      receiverAccounttype: data.receiverPaymentReceiverAccounttype
        ? data.receiverPaymentReceiverAccounttype
        : '',
      branchname: data.receiverPaymentBranchname ? data.receiverPaymentBranchname : '',
      receiverAc: data.receiverPaymentReceiverAc ? data.receiverPaymentReceiverAc : '',

      // Receiver Alternative
      aLlternativeName: data.receiverAlternativeALlternativeName
        ? data.receiverAlternativeALlternativeName
        : '',
      alternateMidName: data.receiverAlternativeAlternateMidName
        ? data.receiverAlternativeAlternateMidName
        : '',
      alternateLastName: data.receiverAlternativeAlternateLastName
        ? data.receiverAlternativeAlternateLastName
        : '',
      // xxx: data.receiverAlternativeAlternativeEmail ? data.receiverAlternativeAlternativeEmail : '',
      // xxx: data.receiverAlternativeAlternativeAddress ? data.receiverAlternativeAlternativeAddress : '',

      // Receiver Additional
      idType: data.receiverAdditionalIdType ? data.receiverAdditionalIdType : '',
      // xxx: data.receiverAdditionalPrimaryIdTypeOther ? data.receiverAdditionalPrimaryIdTypeOther : '',
      idNumber: data.receiverAdditionalIdNumber ? data.receiverAdditionalIdNumber : '',
      primaryIdPlaceOfIssue: data.receiverAdditionalPrimaryIdPlaceOfIssue
        ? data.receiverAdditionalPrimaryIdPlaceOfIssue
        : '',
      idissuestate: data.receiverAdditionalIdissuestate
        ? data.receiverAdditionalIdissuestate
        : '',
      idIssueDate: data.receiverAdditionalIdIssueDate
        ? moment(data.receiverAdditionalIdIssueDate).format('YYYY-MM-DD')
        : '',
      idExpirydate: data.receiverAdditionalIdExpirydate
        ? moment(data.receiverAdditionalIdExpirydate).format('YYYY-MM-DD')
        : '',
      testquestion: data.receiverAdditionalTestquestion
        ? data.receiverAdditionalTestquestion
        : '',
      testanswer: data.receiverAdditionalTestanswer
        ? data.receiverAdditionalTestanswer
        : '',
      message1: data.receiverAdditionalMessage1 ? data.receiverAdditionalMessage1 : '',
      message2: data.receiverAdditionalMessage2 ? data.receiverAdditionalMessage2 : '',
      receiremarkverAc: data.receiverAdditionalRemarks
        ? data.receiverAdditionalRemarks
        : '',

      // Receiver Bank
      abbbankname: data.receiverBankAbbbankname ? data.receiverBankAbbbankname : '',
      receiverBIc: data.receiverBankReceiverBIc ? data.receiverBankReceiverBIc : '',
      receiveriBan: data.receiverBankReceiveriBan ? data.receiverBankReceiveriBan : '',
      receiverswift: data.receiverBankReceiverswift ? data.receiverBankReceiverswift : '',
      receiverbanknationalID: data.receiverBankReceiverbanknationalID
        ? data.receiverBankReceiverbanknationalID
        : '',
      receiversuffix: data.receiverBankAccountsuffix
        ? data.receiverBankAccountsuffix
        : '',
      subranch: data.receiverBankSubranch ? data.receiverBankSubranch : '',
    }
    const response = await apiUpdateReceiver(payload)
    setOpenBackdrop(false)
    if (response?.code !== 0) {
      setErrorMessage(response?.message)
      return
    }
    const params = new URLSearchParams(location.search)
    fetchGetReceiverDetails(params.get('routeEdit'))
    setConfirmDialog({
      isOpen: true,
      title: '',
      type: 'alertSuccessful',
      subTitle: 'EDIT COMPLETE',
    })
  }

  const tabProps = (index) => {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    }
  }
  const onTabChange = (e, value) => {
    e.preventDefault()
    setTabValue(value)
  }

  const tabList = [
    {
      key: 'personaldetails',
      label: 'Personal Details',
      tab: (
        <ReceiverDetailsTab
          control={control}
          useWatch={useWatch}
          setNotify={setNotify}
          receiverInfo={receiverInfo}
        />
      ),
    },
    {
      key: 'additionalinformation',
      label: 'Additional Information',
      tab: (
        <ReceiverAdditionalInformationTab
          control={control}
          useWatch={useWatch}
          setNotify={setNotify}
          receiverInfo={receiverInfo}
        />
      ),
    },
  ]

  const fetchGetReceiverDetails = async () => {
    setOpenBackdrop(true)
    const params = new URLSearchParams(location.search)
    const response = await apiGetReceiverDetails(params.get('routeEdit'))
    if (response?.code !== 0 || !isNil(response?.error)) {
      setOpenBackdrop(false)
      setConfirmDialog({
        isOpen: true,
        title: '',
        type: 'alertError',
        subTitle: response?.message,
      })
      return
    }
    setReceiverInfo(response?.data)

    // Receiver Details
    setValue('receiverFirstName', response?.data?.receiverdetailModel?.firstName)
    setValue('receiverMiddleName', response?.data?.receiverdetailModel?.middleName)
    setValue('receiverLastName', response?.data?.receiverdetailModel?.lastName)
    setValue(
      'receiverGender',
      response?.data?.receiverdetailModel?.gender === 'M' ? 'Male' : 'Female',
    )
    setValue('receiverMobileNo', response?.data?.receiverdetailModel?.mobileNo)
    setValue('receiverEmail', response?.data?.receiverdetailModel?.eMail)
    setValue(
      'receiverDateOfBirth',
      response?.data?.receiverdetailModel?.dateOfBirth !== undefined &&
        response?.data?.receiverdetailModel?.dateOfBirth !== null &&
        response?.data?.receiverdetailModel?.dateOfBirth !== ''
        ? dayjs(
            response?.data?.receiverdetailModel?.dateOfBirth.substring(
              0,
              response?.data?.receiverdetailModel?.dateOfBirth.indexOf(' '),
            ),
          ).$d
        : undefined,
    )
    setValue('receiverNationality', response?.data?.receiverdetailModel?.nationality)
    setValue('receiverOccupation', response?.data?.receiverdetailModel?.occupation)
    setValue(
      'receiverOccupationOther',
      response?.data?.receiverdetailModel?.occupationOther,
    )
    setValue(
      'receiverPurposeofremittance',
      response?.data?.receiverdetailModel?.purposeofremittance,
    )
    setValue(
      'receiverPurposeofremittanceother',
      response?.data?.receiverdetailModel?.purposeofremittanceother,
    )
    setValue('receiverRelationship', response?.data?.receiverdetailModel?.relationship)
    setValue(
      'receiverRelationshipother',
      response?.data?.receiverdetailModel?.relationshipother,
    )

    // Receiver Address
    setValue('receiverAddressCountry', response?.data?.receiverdetailModel?.country)
    setValue('receiverAddressZipCode', response?.data?.receiverdetailModel?.zipCode)
    setValue('receiverAddressState', response?.data?.receiverdetailModel?.prefecture)
    setValue('receiverAddressCity', response?.data?.receiverdetailModel?.city)
    setValue('receiverAddressDistrict', response?.data?.receiverdetailModel?.district)
    // setValue('receiverAddressAddressStreet', ???)

    // Receiver Payment Information
    setValue(
      'receiverPaymentCountryofshipment',
      response?.data?.receiverdetailModel?.countryofshipment,
    )
    setValue(
      'receiverPaymentModeofremittance',
      response?.data?.receiverdetailModel?.modeofremittance,
    )
    setValue('receiverPaymentBankList', response?.data?.receiverdetailModel?.bankList)
    setValue(
      'receiverPaymentReceiverAccounttype',
      response?.data?.receiverdetailModel?.receiverAccounttype,
    )
    setValue('receiverPaymentBranchname', response?.data?.receiverdetailModel?.branchname)
    setValue('receiverPaymentReceiverAc', response?.data?.receiverdetailModel?.receiverAc)

    // Receiver Alternative
    setValue(
      'receiverAlternativeALlternativeName',
      response?.data?.receiverdetailModel?.aLlternativeName,
    )
    setValue(
      'receiverAlternativeAlternateMidName',
      response?.data?.receiverdetailModel?.alternateMidName,
    )
    setValue(
      'receiverAlternativeAlternateLastName',
      response?.data?.receiverdetailModel?.alternateLastName,
    )
    // setValue('receiverAlternativeAlternativeEmail', ???)
    // setValue('receiverAlternativeAlternativeAddress', ???)

    // Receiver Additional
    setValue('receiverAdditionalIdType', response?.data?.receiverdetailModel?.idType)
    // setValue('receiverAdditionalPrimaryIdTypeOther', ???)
    setValue('receiverAdditionalIdNumber', response?.data?.receiverdetailModel?.idNumber)
    setValue(
      'receiverAdditionalPrimaryIdPlaceOfIssue',
      response?.data?.receiverdetailModel?.primaryIdPlaceOfIssue,
    )
    setValue(
      'receiverAdditionalIdissuestate',
      response?.data?.receiverdetailModel?.idissuestate,
    )
    setValue(
      'receiverAdditionalIdIssueDate',
      response?.data?.receiverdetailModel?.idIssueDate !== undefined &&
        response?.data?.receiverdetailModel?.idIssueDate !== null &&
        response?.data?.receiverdetailModel?.idIssueDate !== ''
        ? dayjs(
            response?.data?.receiverdetailModel?.idIssueDate.substring(
              0,
              response?.data?.receiverdetailModel?.idIssueDate.indexOf(' '),
            ),
          ).$d
        : undefined,
    )
    setValue(
      'receiverAdditionalIdExpirydate',
      response?.data?.receiverdetailModel?.idExpirydate !== undefined &&
        response?.data?.receiverdetailModel?.idExpirydate !== null &&
        response?.data?.receiverdetailModel?.idExpirydate !== ''
        ? dayjs(
            response?.data?.receiverdetailModel?.idExpirydate.substring(
              0,
              response?.data?.receiverdetailModel?.idExpirydate.indexOf(' '),
            ),
          ).$d
        : undefined,
    )
    setValue(
      'receiverAdditionalTestquestion',
      response?.data?.receiverdetailModel?.testquestion,
    )
    setValue(
      'receiverAdditionalTestanswer',
      response?.data?.receiverdetailModel?.testanswer,
    )
    setValue('receiverAdditionalMessage1', response?.data?.receiverdetailModel?.message1)
    setValue('receiverAdditionalMessage2', response?.data?.receiverdetailModel?.message2)
    setValue('receiverAdditionalRemarks', response?.data?.receiverdetailModel?.remark)

    // Receiver Bank
    setValue('receiverBankAbbbankname', response?.data?.receiverdetailModel?.abbbankname)
    setValue('receiverBankReceiverBIc', response?.data?.receiverdetailModel?.receiverBIc)
    setValue(
      'receiverBankReceiveriBan',
      response?.data?.receiverdetailModel?.receiveriBan,
    )
    setValue(
      'receiverBankReceiverswift',
      response?.data?.receiverdetailModel?.receiverswift,
    )
    setValue(
      'receiverBankReceiverbanknationalID',
      response?.data?.receiverdetailModel?.receiverbanknationalID,
    )
    setValue(
      'receiverBankAccountsuffix',
      response?.data?.receiverdetailModel?.receiversuffix,
    )
    setValue('receiverBankSubranch', response?.data?.receiverdetailModel?.subranch)
    setOpenBackdrop(false)
  }

  useEffect(() => {
    fetchGetReceiverDetails()
  }, [])

  return (
    <>
      <Container>
        <React.Fragment>
          <Paper sx={{ paddingLeft: 6 }}>
            <Grid item sm={1} key="Back">
              <Button
                onClick={() => {
                  navigate(
                    `/customer/details?routeEdit=${location.state.customerRouteEditURL}`,
                    {
                      replace: false,
                    },
                  )
                }}
                startIcon={<ArrowBackIcon />}
                text="Back"
                variant="outlined"
                size="small"
              />
            </Grid>
          </Paper>
          <Paper sx={{ paddingLeft: 6, paddingTop: 6 }}>
            <Typography
              sx={{
                fontFamily: 'Overpass',
                'font-size': '30px',
                fontWeight: 600,
                lineHeight: '20px',
                letterSpacing: '0.0015em',
                color: '#FFFFFF',
              }}
            >
              CUSTOMER :{receiverInfo?.customerdetailModel?.senderfullname}
            </Typography>
          </Paper>
          <Paper sx={{ paddingTop: 3 }}>
            <form control={control} onSubmit={handleSubmit(onSubmit)}>
              <Box
                sx={{
                  display: 'grid',
                  '@media (min-width:400px)': {
                    gridTemplateColumns: 'repeat(auto-fill, minmax(1fr 3fr))',
                  },
                  '@media (min-width:800px)': {
                    gridTemplateColumns: '1fr 3fr',
                  },
                  borderBottom: 0,
                  borderColor: theme.palette.background.basebg,
                }}
              >
                <Box>
                  <Tabs
                    value={tabValue}
                    onChange={onTabChange}
                    orientation="vertical"
                    centered
                    sx={{
                      '& .MuiTabs-flexContainer': {
                        display: 'flex',
                        justifyContent: 'flex-start',
                        padding: '18px 38px',
                        '& .Mui-selected': {
                          color: '#FFFFFF',
                          bgcolor: theme.palette.background.cardbg,
                          borderRadius: '4px',
                        },
                      },
                      '& .MuiTabs-indicator': {
                        height: 0,
                        width: 0,
                      },
                    }}
                  >
                    {tabList.map((e, i) => {
                      return (
                        <Tab
                          key={`tab-${i}`}
                          label={e.label}
                          icon={e.icon}
                          {...tabProps(i)}
                          sx={{
                            '@media (min-width:600px)': {
                              alignItems: 'start',
                            },
                            width: '240px',
                            height: '48px',
                            padding: '22px',
                            borderRadius: 2,
                            border: '1px solid #8655D1',
                            margin: '8px 0',
                            '&.Mui-selected': {
                              backgroundColor: '#8655D1',
                            },
                          }}
                        />
                      )
                    })}
                  </Tabs>
                </Box>
                <Box>
                  {tabList.map((e, i) => {
                    return (
                      <TabPanel key={i} value={tabValue} index={i}>
                        {e.tab}
                      </TabPanel>
                    )
                  })}
                </Box>
              </Box>
              <Backdrop openBackdrop={openBackdrop} />
              <AlertBox notify={notify} setNotify={setNotify} />
              <ConfirmDialog
                confirmDialog={confirmDialog}
                setConfirmDialog={setConfirmDialog}
              />
              <div className={`${classes.stepBtn}`}>
                <AlertBar type="error" message={errorMessage} />
                <Button type="submit" variant="contained" text="SAVE" />
              </div>
            </form>
          </Paper>
        </React.Fragment>
      </Container>
    </>
  )
}
