import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'
import { Stack, AppBar, Toolbar } from '@mui/material'
import Logo from '../../components/logo/Logo'
import Search from './Search'
import Navigator from './navigator'
import AccountPopover from './AccountPopover'
import LanguagePopover from './LanguagePopover'
import { bgBlur } from '../../utils/stylecss'
import useResponsive from '../../hooks/useResponsive'

// const NAV_WIDTH = 280;

const HEADER_MOBILE = 64

const HEADER_DESKTOP = 92

const StyledRoot = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  ...bgBlur({
    blur: 20,
    color: theme.palette.background.primary,
  }),
  [theme.breakpoints.up('lg')]: {
    width: '100%', //`calc(100% - ${NAV_WIDTH + 1}px)`,
  },
}))

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: HEADER_MOBILE,
  justifyContent: 'center',
  [theme.breakpoints.up('lg')]: {
    minHeight: HEADER_DESKTOP,
    padding: theme.spacing(3, 0),
  },
}))

Header.propTypes = {
  onOpenNav: PropTypes.func,
}

export default function Header() {
  const isMobile = useResponsive('between', 'xs', 'md')
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [value, setValue] = useState(0)

  return (
    <StyledRoot>
      <StyledToolbar>
        <Stack
          direction="row"
          alignItems="center"
          spacing={{
            xs: 0.5,
            sm: 1,
          }}
          gap={isMobile ? 1 : 5}
        >
          <Logo />
          <Navigator
            value={value}
            setValue={setValue}
            selectedIndex={selectedIndex}
            setSelectedIndex={setSelectedIndex}
          />
          <Search />
          <Stack direction="row" gap={2}>
            <LanguagePopover icon />
            <AccountPopover />
          </Stack>
        </Stack>
      </StyledToolbar>
    </StyledRoot>
  )
}
