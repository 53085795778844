import React from 'react'
import { makeStyles } from 'tss-react/mui'
import { Grid, Box, Typography } from '@mui/material'
import Button from '../../../components/common/Button'
import { isEmptyObject } from '../../../utils/app'

export const Stepper = ({
  errors,
  btnText,
  stepLabel,
  stepLength,
  activeStep,
  prevStep,
  nextStep,
  handleSubmit,
  handleClose,
  stepContainer,
  handleUploadFile,
  checkToStep = [],
  checkBackStep = [],
  btnHiden,
}) => {
  const { classes } = useStyles()

  const isLastStep = activeStep === stepLabel?.length - 1
  return (
    <React.Fragment>
      {stepLabel.map((step, index) => {
        return (
          index === activeStep && (
            <Grid item sm={8} key={index}>
              <Typography
                sx={{ mb: '20px' }}
                component={'div'}
                variant="Paragraph_lg_Bold"
              >
                {step.label}
              </Typography>
            </Grid>
          )
        )
      })}
      <div className={classes.root}>
        {stepLabel.map((step, index) => {
          return (
            <div
              key={index}
              className={`${classes.stepBarWrapper} ${
                index === activeStep
                  ? classes.active
                  : index < activeStep
                    ? classes.complete
                    : classes.inactive
              }`}
            ></div>
          )
        })}
      </div>
      {stepContainer.map((content, index) => {
        return (
          index < stepLabel?.length && (
            <div className={`${classes.stepContainer}`} key={index}>
              {content}
            </div>
          )
        )
      })}
      {!isEmptyObject(errors) && (
        <Typography
          color="text.error"
          component={'div'}
          variant="Paragraph_lg_Bold"
          sx={{ mt: '20px', textAlign: 'end' }}
        >
          Please check your information before going to the next step.
        </Typography>
      )}
      <div className={`${classes.stepBtn}`}>
        {activeStep === 0 ? (
          <></>
        ) : (
          <Button
            variant="outlined"
            color="inherit"
            disabled={checkBackStep[activeStep]}
            onClick={prevStep}
            text="Back"
            // sx={{ display: isLastStep && !btnHiden && 'none' }}
          />
        )}
        <Box sx={{ flex: '1 1 auto' }} />

        <Button
          // loading={true}
          onClick={handleUploadFile}
          variant="outlined"
          color="primary"
          text="upload document"
          size="large"
          sx={{ display: (!isLastStep || btnHiden) && 'none' }}
        />
        <Button
          loading={btnHiden}
          variant="contained"
          onClick={isLastStep ? handleSubmit(handleClose) : handleSubmit(nextStep)}
          text={isLastStep ? btnText : 'Next'}
          sx={{ display: isLastStep && btnHiden && 'none' }}
        />
      </div>
    </React.Fragment>
  )
}

const useStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex',
    gap: 20,
    [theme.breakpoints.down('md')]: {
      gap: 5,
    },
  },
  stepContainer: {
    marginTop: 40,
    '& .MuiGrid-root': {
      maxWidth: '100%',
    },
  },
  stepBarWrapper: {
    flex: 1,
    borderRadius: 10,
    display: 'flex',
    justifyContent: 'center',

    [theme.breakpoints.up('md')]: {
      height: 13,
    },
    [theme.breakpoints.down('md')]: {
      height: 5,
    },
  },
  active: {
    background: `linear-gradient(to right, ${theme.palette.gradientcolor.lightyellow} 0%, ${theme.palette.gradientcolor.lightyellow} 100%, ${theme.palette.background.popup} 50%,  ${theme.palette.background.popup} 100%)`,
  },
  inactive: {
    backgroundColor: theme.palette.background.popup,
  },
  complete: {
    backgroundColor: theme.palette.gradientcolor.lightyellow,
  },
  circleCheckIcon: {
    color: theme.palette.gradientcolor.lightyellow,
    fontSize: 18,
    marginTop: -25,
  },
  stepLabel: {
    color: theme.palette.gradientcolor.lightyellow,
    position: 'absolute',
    fontWeight: 'bold',

    [theme.breakpoints.up('md')]: {
      fontSize: 18,
      marginTop: 20,
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
      marginTop: 10,
    },
  },
  stepBtn: {
    marginTop: '30px',
    marginBottom: '0px',
    display: 'flex',
    justifyContent: 'end',

    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
}))
