import React from 'react'
import { Grid } from '@mui/material'
import SvgColor from '../../../components/svg-color/svg-color'
import { ConvertDateTimeFormat } from '../../../utils/app'

export default function SenderKYCStatusDetails(props) {
  const { customerInfo } = props

  return (
    <Grid container spacing={{ xs: 1, sm: 2 }} columns={{ xs: 1, sm: 8, md: 8 }}>
      <Grid
        item
        xs={2}
        sm={2}
        md={2}
        sx={{
          padding: '0 0 20px 0',
        }}
      >
        KYC STATUS
      </Grid>
      <Grid
        item
        xs={2}
        sm={2}
        md={2}
        sx={{
          padding: '0 0 10px 0',
          marginBottom: '10px',
          borderRadius: '30px',
          gap: '10px',
          backgroundColor: '#35E1A91A',
          color: '#35E1A9',
        }}
      >
        {customerInfo?.customerdetailModel?.kycstatus === 'p'
          ? 'KYC Processing'
          : customerInfo?.customerdetailModel?.kycstatus === 'r'
          ? 'KYC Rejected'
          : customerInfo?.customerdetailModel?.kycstatus === 'd'
          ? 'KYC Delivery'
          : customerInfo?.customerdetailModel?.kycstatus === 'y'
          ? 'KYC Completed'
          : 'KYC Not Completed'}
      </Grid>
      <Grid item xs={2} sm={4} md={4}>
        &nbsp;
      </Grid>
      <Grid item xs={2} sm={3} md={3}>
        <Grid
          container
          spacing={{ xs: 1, sm: 2 }}
          columns={{ xs: 1, sm: 8, md: 8 }}
          sx={{
            padding: `15px 30px 30px 30px`,
            borderRadius: 4,
            background: '#444A81',
            // fontFamily: 'Overpass',
            // fontSize: '20px',
            // fontWeight: '700',
            // lineHeight: '20px',
            // letterSpacing: '0.0015em',
            // textAlign: 'left',
          }}
        >
          <Grid item xs={2} sm={8} md={8}>
            {customerInfo?.customerdetailModel?.allowwebonline === 'y' ? (
              <SvgColor
                src={`/assets/icons/icon_checked.svg`}
                sx={{ px: 2, color: '#35E1A9' }}
              />
            ) : (
              <SvgColor
                src={`/assets/icons/icon_unchecked.svg`}
                sx={{ px: 2, color: '#35E1A9' }}
              />
            )}
            Customer Login
          </Grid>
          <Grid item xs={2} sm={8} md={8}>
            {customerInfo?.customerdetailModel?.isEnable === 'y' ? (
              <SvgColor
                src={`/assets/icons/icon_checked.svg`}
                sx={{ px: 2, color: '#35E1A9' }}
              />
            ) : (
              <SvgColor
                src={`/assets/icons/icon_unchecked.svg`}
                sx={{ px: 2, color: '#35E1A9' }}
              />
            )}
            Remittanced Allowed
          </Grid>
          <Grid item xs={2} sm={8} md={8}>
            {customerInfo?.customerdetailModel?.depositEnabled === 'y' ? (
              <SvgColor
                src={`/assets/icons/icon_checked.svg`}
                sx={{ px: 2, color: '#35E1A9' }}
              />
            ) : (
              <SvgColor
                src={`/assets/icons/icon_unchecked.svg`}
                sx={{ px: 2, color: '#35E1A9' }}
              />
            )}
            Deposit Enabled
          </Grid>
          <Grid item xs={2} sm={8} md={8}>
            {customerInfo?.customerdetailModel?.withdrawalEnabled === 'y' ? (
              <SvgColor
                src={`/assets/icons/icon_checked.svg`}
                sx={{ px: 2, color: '#35E1A9' }}
              />
            ) : (
              <SvgColor
                src={`/assets/icons/icon_unchecked.svg`}
                sx={{ px: 2, color: '#35E1A9' }}
              />
            )}
            Withdrawal Enabled
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={2} sm={5} md={5}>
        <Grid
          container
          spacing={{ xs: 1, sm: 2 }}
          columns={{ xs: 1, sm: 8, md: 8 }}
          sx={{
            padding: `0 18px 18px 18px`,
          }}
        >
          <Grid item xs={2} sm={3} md={3}>
            Registered Date :
          </Grid>
          <Grid item xs={2} sm={5} md={5} sx={{ color: '#FFFFFF' }}>
            {ConvertDateTimeFormat(customerInfo?.customerdetailModel?.createts)}
          </Grid>
          <Grid item xs={2} sm={3} md={3}>
            Approved Date :
          </Grid>
          <Grid item xs={2} sm={5} md={5} sx={{ color: '#FFFFFF' }}>
            {ConvertDateTimeFormat(customerInfo?.customerdetailModel?.approvets)}
          </Grid>
          <Grid item xs={2} sm={3} md={3}>
            First TXN Date :
          </Grid>
          <Grid item xs={2} sm={5} md={5} sx={{ color: '#FFFFFF' }}>
            {ConvertDateTimeFormat(
              customerInfo?.customerdetailModel?.firstTansactiondate,
            )}
          </Grid>
          <Grid item xs={2} sm={3} md={3}>
            Last TXN Date :
          </Grid>
          <Grid item xs={2} sm={5} md={5} sx={{ color: '#FFFFFF' }}>
            {ConvertDateTimeFormat(customerInfo?.customerdetailModel?.tranDate)}
          </Grid>
          <Grid item xs={2} sm={3} md={3}>
            KYC Mode :
          </Grid>
          <Grid item xs={2} sm={5} md={5} sx={{ color: '#FFFFFF' }}>
            {customerInfo?.customerdetailModel?.kycmode}
          </Grid>
          <Grid item xs={2} sm={3} md={3}>
            KYC Date :
          </Grid>
          <Grid item xs={2} sm={5} md={5} sx={{ color: '#FFFFFF' }}>
            {ConvertDateTimeFormat(customerInfo?.customerdetailModel?.kycdate)}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
