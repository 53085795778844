import { makeStyles } from 'tss-react/mui'
import typography from '../../../theme/typography'

const typo = typography

const useStyles = makeStyles()((theme) => ({
  tabContainer: {
    marginLeft: 'auto',
    borderRadius: 30,
    border: `2px solid ${theme.palette.background.popup}`,

    '& .MuiTabs-indicator': {
      backgroundColor: 'transparent',
    },
  },
  tab: {
    ...typo['Paragraph_md_Light'],
    color: theme.palette.text.secondary,
    // minWidth: 10,
    // marginLeft: "25px",
    '&.Mui-selected': {
      borderRadius: 30,
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.button.default,
    },
  },

  toolbarMargin: {
    ...theme.mixins.toolbar,
    marginBottom: '3em',
    [theme.breakpoints.down('md')]: {
      marginBottom: '2em',
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: '1.25em',
    },
  },
  logo: {
    height: '8em',
    [theme.breakpoints.down('md')]: {
      height: '7em',
    },
    [theme.breakpoints.down('xs')]: {
      height: '5.5em',
    },
  },
  logoContainer: {
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },

  menu: {
    backgroundColor: theme.palette.common.blue,
    color: 'white',
    borderRadius: '0px',
  },
  menuItem: {
    ...theme.typography.tab,
    opacity: 0.7,
    '&:hover': {
      opacity: 1,
    },
  },
  drawerIcon: {
    height: '50px',
    width: '50px',
  },
  drawerIconContainer: {
    marginLeft: 'auto',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  drawer: {
    backgroundColor: theme.palette.common.blue,
  },
  drawerItem: {
    ...theme.typography.tab,
    color: 'white',
    opacity: 0.7,
  },
  drawerItemSelected: {
    '& .MuiListItemText-root': {
      opacity: 1,
    },
  },
  appbar: {
    zIndex: theme.zIndex.modal + 1,
  },
}))

export default useStyles
