import numeral from 'numeral'
import getSymbolFromCurrency from 'currency-symbol-map'

export const fNumber = (number) => {
  return numeral(number).format('0,0[.][00000000]')
}

export function fCurrency(number, currency) {
  const crrSymbol = getSymbolFromCurrency(currency)
  //const decimals = number?.toString()?.match(/^-?\d+(?:\.\d{0,8})?/)[0]
  const decimals = numeral(number).format('0,0.00[000000]')
  const format = crrSymbol + decimals
  return format
}

export function fNumberCurrency(number, currency) {
  const decimals = numeral(number).format('0,0.00[000000]')
  const format = decimals + ' ' + currency
  return format
}
