import React, { useState, useEffect } from 'react'
import { Grid } from '@mui/material'
import { isNil } from 'lodash'
import AutocompleteField from '../../../components/common/AutocompleteField'
import InputField from '../../../components/common/InputField'
import DateField from '../../../components/common/DateField'
import { apiGetIDTypeList, apiMasterCountryList } from '../../../api/master'
import { apiGetTestQuestionList } from '../../../api/customer'

export default function ReceiverAdditionalInformation(props) {
  const { control, setNotify, useWatch } = props

  const [idTypeRecords, setIDTypeRecords] = useState([])
  const [countryRecords, setCountryRecords] = useState([])
  const [testQuestionRecords, setTestQuestionRecords] = useState([])

  const fetchIDTypeList = async () => {
    const response = await apiGetIDTypeList()
    if (response?.code !== 0 || !isNil(response?.error)) {
      setNotify({
        open: true,
        message: response?.message,
        type: 'error',
      })
      return
    }
    const optionIDType = response?.data?.map((e) => {
      return { label: e?.text, value: e?.value }
    })
    setIDTypeRecords(optionIDType)
  }

  const fetchCountryList = async () => {
    const response = await apiMasterCountryList()
    if (response?.code !== 0 || !isNil(response?.error)) {
      setNotify({
        open: true,
        message: response?.message,
        type: 'error',
      })
      return
    }
    const optionCountry = response?.data?.map((e) => {
      return { label: e?.text, value: e?.value }
    })
    setCountryRecords(optionCountry)
  }

  const fetchTestQuestionList = async () => {
    const response = await apiGetTestQuestionList()
    if (response?.code !== 0 || !isNil(response?.error)) {
      setNotify({
        open: true,
        message: response?.message,
        type: 'error',
      })
      return
    }
    const optionTestQuestion = response?.data?.map((e) => {
      return { label: e?.text, value: e?.value }
    })
    setTestQuestionRecords(optionTestQuestion)
  }

  useEffect(() => {
    fetchIDTypeList()
    fetchCountryList()
    fetchTestQuestionList()
  }, [])

  return (
    <Grid container spacing={{ xs: 1, sm: 2 }} columns={{ xs: 1, sm: 8, md: 8 }}>
      <Grid item xs={2} sm={4} md={4}>
        <AutocompleteField
          control={control}
          name="receiverAdditionalIdType"
          label="ID Type"
          placeholder="Select ID Type"
          options={idTypeRecords}
        />
      </Grid>
      {useWatch({ control, name: 'primaryIdType' }) === '99' && (
        <Grid item xs={2} sm={4} md={4}>
          <InputField
            control={control}
            name="receiverAdditionalPrimaryIdTypeOther"
            label="ID Type Other"
            placeholder="ID Type Other"
            required
          />
        </Grid>
      )}

      <Grid item xs={2} sm={4} md={4}>
        <InputField
          control={control}
          name="receiverAdditionalIdNumber"
          label="ID Number"
          placeholder="ID Number"
        />
      </Grid>
      <Grid item xs={2} sm={4} md={4}>
        <AutocompleteField
          control={control}
          name="receiverAdditionalPrimaryIdPlaceOfIssue"
          label="ID Issue Country"
          placeholder="Select ID Issue Country"
          options={countryRecords}
        />
      </Grid>
      <Grid item xs={2} sm={4} md={4}>
        <InputField
          control={control}
          name="receiverAdditionalIdissuestate"
          label="ID Issue State"
          placeholder="ID Issue State"
        />
      </Grid>
      <Grid item xs={2} sm={4} md={4}>
        <DateField
          control={control}
          name="receiverAdditionalIdIssueDate"
          label="ID Issue Date"
          placeholder="ID Issue Date"
        />
      </Grid>
      <Grid item xs={2} sm={4} md={4}>
        <DateField
          control={control}
          name="receiverAdditionalIdExpirydate"
          label="ID Expire Date"
          placeholder="ID Expire Date"
        />
      </Grid>
      <Grid item xs={2} sm={4} md={4}>
        <AutocompleteField
          control={control}
          name="receiverAdditionalTestquestion"
          label="Test Question"
          placeholder="Test Question"
          options={testQuestionRecords}
        />
      </Grid>
      <Grid item xs={2} sm={4} md={4}>
        <InputField
          control={control}
          name="receiverAdditionalTestanswer"
          label="Test Answer"
          placeholder="Test Answer"
        />
      </Grid>
      <Grid item xs={2} sm={4} md={4}>
        <InputField
          control={control}
          name="receiverAdditionalMessage1"
          label="Message 1"
          placeholder="Message 1"
        />
      </Grid>
      <Grid item xs={2} sm={4} md={4}>
        <InputField
          control={control}
          name="receiverAdditionalMessage2"
          label="Message 2"
          placeholder="Message 2"
        />
      </Grid>
      <Grid item xs={4} sm={8} md={8}>
        <InputField
          control={control}
          name="receiverAdditionalRemarks"
          label="Remarks"
          placeholder="Remarks"
          multiline
          rows={4}
        />
      </Grid>
    </Grid>
  )
}
