import {
  Grid,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  Collapse,
  Box,
  Container,
  Stack,
  Button as MuiButton,
  InputAdornment,
  Popover,
} from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import moment from 'moment'
import StyledTableRow from '../../theme/styledTableRow'
import Loading from '../../components/loading/loading'
import Button from '../../components/common/Button'
import IconBtn from '../../components/common/IconBtn'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import InputSearchFilter from '../../components/common/InputSearchFilter'
import SvgColor from '../../components/svg-color/svg-color'
import CheckBox from '../../components/common/Checkbox'
import { useForm } from 'react-hook-form'
import { CalendarRangePicker } from '../../components/common/CalendarPicker'
import { startOfDay, endOfDay } from 'date-fns'
import {
  apiTransactionApprove,
  apiTransactionApproveAll,
  apiTransactionBranchList,
  apiTransactionHistory,
  apiTransactionReceiverCountriesList,
  apiTransactionUserIDList,
} from '../../api/transaction'
import {
  apiMasterDateTypeSMList,
  apiMasterPaymentTypeList,
  apiMasterTransactionStatusList,
} from '../../api/master'
import AutocompleteField from '../../components/common/AutocompleteField'
import useTableCallback from '../../components/useTableCallback'
import ConfirmDialog from '../../components/useDialog'
import AlertBox from '../../components/alertBox/alertBox'
import { isEmpty } from 'lodash'
import Label from '../../components/label/Label'
import { transactionStatusColorMap } from '../../utils/mapstatus'
import { fNumberCurrency } from '../../utils/formatNumer'
import { useNavigate, useParams } from 'react-router-dom'
import { useHistoryStyles } from '../../containers/history/historyStyles'
import {
  ConvertDateTimeFormat,
  ConvertDateYYYYMMDD,
  ConvertTime,
  CustomerType,
  FirstDayOfMonth,
  FirstDayOfWeek,
} from '../../utils/app'

const initState = {
  records: [],
  errorMessage: null,
  loading: false,
  notify: {
    open: false,
    message: '',
    type: '',
  },
  confirmDialog: {
    isOpen: false,
    title: '',
    subTitle: '',
  },
  expanded: [],
  selected: [],
  dateTypeList: [],
  transactionStatusList: [],
  totalRecords: [],
  checked: false,
  printReceipt: [],
}

export default function TransactionHistory() {
  const { classes } = useHistoryStyles()
  const { control, setValue, getValues } = useForm()
  const navigate = useNavigate()
  const params = useParams()
  const [records, setRecords] = useState(initState.records)
  const [loading, setLoading] = useState(initState.loading)
  const [searchText, setSearchText] = useState('')
  const [expanded, setExpanded] = useState(initState.expanded)
  const [selected, setSelected] = useState(initState.selected)
  const [checked, setChecked] = useState(initState.checked)
  const [dateTypeList, setDateTypeList] = useState([])
  const [transactionStatusList, setTransactionStatusList] = useState([])
  const [paymentTypeList, setPaymentTypeList] = useState([])
  const [branchList, setBranchList] = useState([])
  const [receiverCountryList, setReceiverCountryList] = useState([])
  const [userIDList, setUserIDList] = useState([])
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const [notify, setNotify] = useState(initState.notify)
  const [totalRecords, setTotalRecords] = useState(initState.totalRecords)
  const [confirmDialog, setConfirmDialog] = useState(initState.confirmDialog)
  const [printReceipt, setPrintReceipt] = useState(initState.printReceipt)
  const [dateRange, setDateRange] = useState([
    {
      startDate: startOfDay(new Date()),
      endDate: endOfDay(new Date()),
      key: 'selection',
    },
  ])

  const handlePageChange = (event, newPage) => {
    setPage(newPage)
    setChecked(false)
    setSelected([])
    fetchtransactionHistoryList(searchText, newPage + 1, rowsPerPage, dateRange)
  }

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
    fetchtransactionHistoryList(
      searchText,
      1,
      parseInt(event.target.value, 10),
      dateRange,
    )
  }

  const handleSelectAllCheck = (event) => {
    setChecked(event.target.value)
    if (event.target.value) {
      const recordsHold = records.filter((item) =>
        String(item.transstatus).toLowerCase().includes('hold'),
      )

      const newSelected = recordsHold.map((x) => x.tranno)
      setSelected(newSelected)
      return
    }
    setSelected([])
  }

  const onChangePrintReceipt = (event) => {
    setChecked(event.target.value)
  }

  const handleCheckChange = (event, value) => {
    const selectedIndex = selected.indexOf(value)
    let newSelected = []
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, value)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      )
    }
    setSelected(newSelected)
  }

  const headCells = [
    {
      id: 'isCheckAllApprove',
      label: 'Check All',
      checked: { status: true, value: checked, onchange: handleSelectAllCheck },
    },
    {
      id: 'tranno',
      label: 'TXN ID',
      disableSorting: true,
    },
    {
      id: 'sendername',
      label: 'Sender',
      disableSorting: true,
    },
    {
      id: 'receivername',
      label: 'Receiver',
      disableSorting: true,
    },
    {
      id: 'local_dot',
      label: 'Transaction Date',
      disableSorting: true,
    },
    {
      id: 'paymenttype',
      label: 'Payment Type',
      disableSorting: true,
    },
    {
      id: 'paidamt',
      label: 'Transfer Amount',
      disableSorting: true,
    },
    {
      id: 'transstatus',
      label: '',
      disableSorting: true,
    },
    {
      id: 'btnAppove',
      label: '',
      disableSorting: true,
    },
    {
      id: 'expand',
      label: '',
      disableSorting: true,
    },
  ]
  const onClickApproveAll = async () => {
    let stringApproveAll = String(selected)
    const response = await apiTransactionApproveAll({ tranNos: stringApproveAll })
    if (response?.code !== 0) {
      errorMessageDialog(response)
      return
    }

    successMessageDialog(response)
  }

  const confirmApproveAll = async () => {
    return (
      <Grid container spacing={{ xs: 1, sm: 2 }} columns={{ xs: 1, sm: 8, md: 8 }}>
        <Grid item xs={4} sm={8} md={8} sx={{ textAlign: 'center' }}>
          <img
            src={`/assets/icons/icon_confirm_approve.svg`}
            alt="confirm_approve_all"
            style={{
              display: 'block',
              marginLeft: 'auto',
              marginRight: 'auto',
              width: '100%',
            }}
          />
        </Grid>
        <Grid item xs={4} sm={8} md={8} sx={{ textAlign: 'center' }}>
          <Typography
            variant="H3_Bold"
            sx={{
              fontFamily: 'Overpass',
              fontsize: '30px',
              fontWeight: 700,
              lineHeight: '30px',
              letterSpacing: '0.0015em',
              color: '#FFFFFF',
              textTransform: 'uppercase',
            }}
          >
            CONFIRM TO APPROVE
          </Typography>
        </Grid>
        <Grid item xs={4} sm={8} md={8} sx={{ textAlign: 'center' }}>
          Are you sure to approve all transactions?
        </Grid>
        <Grid item xs={2} sm={8} md={8}>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            spacing={{ xs: 2, sm: 8, md: 8 }}
          >
            <Button
              type="button"
              text="cancel"
              color="inherit"
              size="medium"
              variant="outlined"
              onClick={() => {
                setConfirmDialog({ ...confirmDialog, isOpen: false })
              }}
            />
            <Button
              text="Approve"
              size="medium"
              color="success"
              onClick={() => {
                setConfirmDialog({
                  ...confirmDialog,
                  isOpen: false,
                })
                onClickApproveAll()
              }}
            />
          </Stack>
        </Grid>
      </Grid>
    )
  }

  const confirmApproveRecord = async (data) => {
    return (
      <Grid container spacing={{ xs: 1, sm: 2 }} columns={{ xs: 1, sm: 8, md: 8 }}>
        <Grid item xs={4} sm={8} md={8} sx={{ textAlign: 'center' }}>
          <img
            src={`/assets/icons/icon_confirm_approve.svg`}
            alt="confirm_approve_all"
            style={{
              display: 'block',
              marginLeft: 'auto',
              marginRight: 'auto',
              width: '100%',
            }}
          />
        </Grid>
        <Grid item xs={4} sm={8} md={8} sx={{ textAlign: 'center' }}>
          <Typography
            variant="H3_Bold"
            sx={{
              fontFamily: 'Overpass',
              fontsize: '30px',
              fontWeight: 700,
              lineHeight: '30px',
              letterSpacing: '0.0015em',
              color: '#FFFFFF',
              textTransform: 'uppercase',
            }}
          >
            CONFIRM TO APPROVE
          </Typography>
        </Grid>
        <Grid item xs={4} sm={8} md={8} sx={{ textAlign: 'center' }}>
          You are about to authorized transaction {data}
        </Grid>
        {/* <Grid item xs={4} sm={8} md={8} sx={{ textAlign: 'center' }}>
            <CheckBox
              label={'Print Receipt'}
              onChange={(event) => onChangePrintReceipt()}
            ></CheckBox>
          </Grid> */}
        <Grid item xs={2} sm={8} md={8}>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            spacing={{ xs: 2, sm: 8, md: 8 }}
          >
            <Button
              type="button"
              text="cancel"
              color="inherit"
              size="medium"
              variant="outlined"
              onClick={() => {
                setConfirmDialog({ ...confirmDialog, isOpen: false })
              }}
            />
            <Button
              text="Approve"
              size="medium"
              color="success"
              onClick={() => {
                setConfirmDialog({
                  ...confirmDialog,
                  isOpen: false,
                })
                onClickApprove(data)
              }}
            />
          </Stack>
        </Grid>
      </Grid>
    )
  }

  const onClickApprove = async (value, isPrint) => {
    const response = await apiTransactionApprove({ tranNo: value, printFlag: isPrint })
    if (response?.code !== 0) {
      errorMessageDialog(response)
      return
    }

    successMessageDialog(response)
  }

  const successMessageDialog = async (result) => {
    setConfirmDialog({
      successOpen: true,
      title: `${result?.message}`,
      type: 'successDialog',
      subTitle: '',
      handlerRefresh: () => {
        // setChecked(false)
        // setSelected([])
        fetchtransactionHistoryList(searchText, 1, rowsPerPage, dateRange)
      },
    })
  }

  const errorMessageDialog = async (result) => {
    setConfirmDialog({
      isOpen: true,
      title: 'Error',
      type: 'errorDialog',
      subTitle: `${result?.message}`,
      onConfirm: () => {
        setConfirmDialog({
          ...confirmDialog,
          isOpen: false,
        })
      },
    })
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    fetchtransactionHistoryList(searchText, 1, rowsPerPage, dateRange)
    setAnchorEl(null)
  }

  const {
    TblContainer,
    TblHead,
    TblPagination,
    recordsAfterPaginationAndSorting,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
  } = useTableCallback(
    records,
    totalRecords,
    headCells,
    handlePageChange,
    handleRowsPerPageChange,
  )

  const handleSearch = (e) => {
    setPage(0)
    setSearchText(e.target.value)
    fetchtransactionHistoryList(e.target.value, 1, rowsPerPage, dateRange)
  }

  const handleChangeDate = (item) => {
    setDateRange([item.selection])
  }

  const handleClearAll = (e) => {
    setDateRange([
      {
        startDate: startOfDay(new Date()),
        endDate: endOfDay(new Date()),
        key: 'selection',
      },
    ])
    //$('.rdrDateRangePickerWrapper').addClass('rdrDateRangeUnselect')
    setValue('dateType', 'Local_dot')
    setValue('tranStatus', '')
    setValue('paymentType', '')
    setValue('branch', '')
    setValue('receiverCountry', '')
    setValue('userID', '')

    setChecked(false)
    setSelected([])
    setTotalRecords([])
  }

  const fetchDateTypeList = useCallback(async () => {
    const response = await apiMasterDateTypeSMList()
    if (response?.code !== 0) {
      setDateTypeList([])
      return
    }

    const option = response?.data?.map((e) => {
      return {
        value: e?.value,
        label: e?.text,
      }
    })

    setDateTypeList(option)
    setValue('dateType', 'Local_dot')
  }, [])

  const fecthTransactionStatusList = useCallback(async () => {
    const response = await apiMasterTransactionStatusList()
    if (response?.code !== 0) {
      setTransactionStatusList([])
      return
    }

    const option = response?.data?.map((e) => {
      return {
        value: e?.value,
        label: e?.text,
      }
    })

    setTransactionStatusList(option)
    setValue('tranStatus', '')
  }, [])

  const fetchPaymentTypeList = useCallback(async () => {
    const response = await apiMasterPaymentTypeList()
    if (response?.code !== 0) {
      setPaymentTypeList([])
      return
    }

    const option = response?.data?.map((e) => {
      return {
        value: e?.value,
        label: e?.text,
      }
    })

    setPaymentTypeList(option)
    setValue('paymentType', '')
  }, [])

  const fetchBranchList = useCallback(async () => {
    const response = await apiTransactionBranchList()
    if (response?.code !== 0) {
      setBranchList([])
      return
    }

    const option = response?.data?.map((e) => {
      return {
        value: e?.value,
        label: e?.text,
      }
    })

    setBranchList(option)
    setValue('branch', '')
  }, [])

  const fetchReceiverCountryList = useCallback(async () => {
    const response = await apiTransactionReceiverCountriesList()
    if (response?.code !== 0) {
      setReceiverCountryList([])
      return
    }

    const option = response?.data?.map((e) => {
      return {
        value: e?.value,
        label: e?.text,
      }
    })

    setReceiverCountryList(option)
    setValue('receiverCountry', '')
  }, [])

  const fetchUserIDList = useCallback(async () => {
    const response = await apiTransactionUserIDList()
    if (response?.code !== 0) {
      setUserIDList([])
      return
    }

    const option = response?.data?.map((e) => {
      return {
        value: e?.value,
        label: e?.text,
      }
    })

    setUserIDList(option)
    setValue('userID', '')
  }, [])

  const fetchtransactionHistoryList = useCallback(
    async (searchText, cPage, rPerPage, dateRange) => {
      setLoading(true)

      const response = await apiTransactionHistory({
        tranStatus: getValues('tranStatus') !== undefined ? getValues('tranStatus') : '',
        paymentType:
          getValues('paymentType') !== undefined ? getValues('paymentType') : '',
        branch: getValues('branch') !== undefined ? getValues('branch') : '',
        receiverCountry:
          getValues('receiverCountry') !== undefined ? getValues('receiverCountry') : '',
        userID: getValues('userID') !== undefined ? getValues('userID') : '',
        dateType:
          getValues('dateType') !== undefined ? getValues('dateType') : 'Local_dot',
        fromDate: moment(dateRange[0].startDate).format('YYYY-MM-DD'),
        toDate: moment(dateRange[0].endDate).format('YYYY-MM-DD'),
        currentPage: cPage,
        pageSize: rPerPage,
        searchTxt: searchText,
      })

      if (response?.code !== 0) {
        setNotify({
          open: true,
          message: response?.message,
          type: 'error',
        })

        setChecked(false)
        setSelected([])
        setRecords([])
        setLoading(false)
        return
      }

      setChecked(false)
      setSelected([])
      setRecords(response?.data)
      setTotalRecords(response?.totalRecord)
      setExpanded([...Array(response?.data?.length)].map((val) => false))
      setLoading(false)
    },
    [],
  )

  useEffect(() => {
    fetchDateTypeList()
    fecthTransactionStatusList()
    fetchPaymentTypeList()
    fetchBranchList()
    fetchReceiverCountryList()
    fetchUserIDList()
    fetchtransactionHistoryList(searchText, 1, rowsPerPage, dateRange)
  }, [
    fetchDateTypeList,
    fecthTransactionStatusList,
    fetchPaymentTypeList,
    fetchBranchList,
    fetchReceiverCountryList,
    fetchUserIDList,
    fetchtransactionHistoryList,
  ])

  const handleExpand = (item) => {
    setExpanded(
      expanded?.map((boolean_value, i) => {
        if (item === i) {
          return !boolean_value
        } else {
          return false
        }
      }),
    )
  }
  const isSelected = (tranno) => selected.indexOf(tranno) !== -1

  return (
    <React.Fragment>
      <Container>
        <Grid container mt={3} spacing={3}>
          <Grid item sm={1} key="Back">
            <Button
              onClick={() => {
                navigate(`/history`, {
                  replace: false,
                })
              }}
              startIcon={<ArrowBackIcon />}
              text="Back"
              variant="outlined"
              size="small"
            />
          </Grid>
          <Grid item sm={12} key="Topic">
            <Typography sx={{ mb: '30px' }} component={'div'} variant="H3_Bold">
              Transaction History
            </Typography>
          </Grid>
          <Grid item sm={9} key="Filter" className={classes.search}>
            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              spacing={{ xs: 1, sm: 2, md: 4 }}
            >
              <InputSearchFilter
                placeholder="Search"
                id="iptSearch"
                name="search"
                //value={valueFilters?.search}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgColor
                        src={`/assets/icons/icon_search.svg`}
                        className={classes.icon}
                      />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <MuiButton
                        variant="contained"
                        startIcon={
                          <SvgColor
                            src={`/assets/icons/icon_searchfilter.svg`}
                            className={classes.icon}
                          />
                        }
                        onClick={handleClick}
                        className={classes.buttonHeight}
                      >
                        FILTER
                      </MuiButton>
                    </InputAdornment>
                  ),
                }}
                onChange={handleSearch}
              />
              <Button
                // loading={true}
                onClick={async () => {
                  setConfirmDialog({
                    isOpen: true,
                    type: 'confirmApprove',
                    title: '',
                    subTitle: '',
                    container: await confirmApproveAll(),
                  })
                }}
                text="APPROVE ALL"
                size="large"
                variant="outlined"
                disabled={isEmpty(selected)}
              />
            </Stack>
          </Grid>
          <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            PaperProps={{
              className: classes.paper,
            }}
          >
            <Grid container spacing={2} columns={{ xs: 1, sm: 1, md: 8 }} padding={1}>
              <Grid item sm={8} key="Filter">
                <Typography
                  sx={{ mb: '18px' }}
                  component={'div'}
                  variant="Paragraph_lg_Bold"
                >
                  Filter
                </Typography>
              </Grid>
              <Grid item xs={4} sm={4} md={4}>
                <AutocompleteField
                  control={control}
                  name="tranStatus"
                  label="Status"
                  placeholder="Status"
                  options={transactionStatusList}
                />
              </Grid>
              <Grid item xs={4} sm={4} md={4}>
                <AutocompleteField
                  control={control}
                  name="paymentType"
                  label="Payment Type"
                  placeholder="Payment Type"
                  options={paymentTypeList}
                />
              </Grid>
              <Grid item xs={4} sm={4} md={4}>
                <AutocompleteField
                  control={control}
                  name="branch"
                  label="Branch"
                  placeholder="Branch"
                  options={branchList}
                />
              </Grid>
              <Grid item xs={4} sm={4} md={4}>
                <AutocompleteField
                  control={control}
                  name="receiverCountry"
                  label="Receiver Country"
                  placeholder="Receiver Country"
                  options={receiverCountryList}
                />
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <AutocompleteField
                  control={control}
                  name="userID"
                  label="User ID"
                  placeholder="User ID"
                  options={userIDList}
                />
              </Grid>
              <Grid item xs={2} sm={4} md={4}></Grid>
              <Grid item xs={1} sm={3} md={4} mt={4}>
                <Typography variant="Paragraph_lg_Bold">Date</Typography>
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <AutocompleteField
                  control={control}
                  name="dateType"
                  //label="Date Type"
                  placeholder="Date Type"
                  options={dateTypeList}
                />
              </Grid>
              <Grid item xs={8} sm={8} md={8}>
                <CalendarRangePicker
                  className={`rdrDateRangeUnselect`}
                  onChange={handleChangeDate}
                  ranges={dateRange}
                />
              </Grid>
              <Grid item xs={8} sm={8} md={8}>
                <Stack direction="row" className={classes.btnFilter}>
                  <Button
                    text="Clear all"
                    size="medium"
                    variant="outlined"
                    onClick={handleClearAll}
                  />
                  <Button type="button" text="Done" size="medium" onClick={handleClose} />
                </Stack>
              </Grid>
            </Grid>
          </Popover>
          <Grid item sm={12} key="Container">
            <TblContainer>
              <TblHead />
              {!loading && (
                <TableBody>
                  {recordsAfterPaginationAndSorting().map((item, index) => {
                    const isItemSelected = isSelected(item.tranno)
                    return (
                      <React.Fragment key={`tableTransactionHistory-${index}`}>
                        <StyledTableRow key={index} hover>
                          <TableCell>
                            {String(item.transstatus).toLowerCase() === 'hold' ? (
                              <CheckBox
                                onChange={(event) =>
                                  handleCheckChange(event, item.tranno)
                                }
                                checked={isItemSelected}
                              />
                            ) : (
                              <CheckBox
                                onChange={(event) =>
                                  handleCheckChange(event, item.tranno)
                                }
                                checked={isItemSelected}
                                icon={
                                  <img
                                    alt="disablecheck"
                                    src={`/assets/icons/icon_disabled_checkbox.svg`}
                                  />
                                }
                                disabled
                              />
                            )}
                          </TableCell>
                          <TableCell head-table="TXN ID">
                            <Typography variant="Paragraph_sm_Light">
                              {item?.tranno}
                            </Typography>
                          </TableCell>
                          <TableCell head-table="Sender">
                            <Typography variant="Paragraph_sm_Light">
                              {item?.sendername?.substring(0, 10).concat('...')}
                              {/* {item.sendername} */}
                            </Typography>
                          </TableCell>
                          <TableCell head-table="Receiver">
                            <Typography variant="Paragraph_sm_Light">
                              {item?.receivername?.substring(0, 10).concat('...')}
                              {/* {item.receivername} */}
                            </Typography>
                          </TableCell>
                          <TableCell head-table="Transaction Date">
                            <Typography variant="Paragraph_sm_Light">
                              {ConvertDateTimeFormat(item?.local_dot)}
                            </Typography>
                          </TableCell>
                          <TableCell head-table="Payment Type">
                            <Typography variant="Paragraph_sm_Light">
                              {item?.paymenttype}
                            </Typography>
                          </TableCell>
                          <TableCell head-table="Transfer Amount">
                            <Typography variant="Paragraph_sm_Light">
                              {fNumberCurrency(
                                item?.paidamt - item?.scharge,
                                item?.paidcType,
                              )}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            {item?.transstatus ? (
                              <Label
                                color={
                                  transactionStatusColorMap[item.transstatus]?.status
                                }
                                variant="soft"
                              >
                                {item.transstatus}
                              </Label>
                            ) : (
                              ''
                            )}
                          </TableCell>
                          <TableCell>
                            {String(item.transstatus).toLowerCase() === 'hold' ? (
                              <IconBtn
                                alt="btnApprove"
                                src={`/assets/icons/icon_approve.svg`}
                                onClick={async () => {
                                  setConfirmDialog({
                                    isOpen: true,
                                    type: 'confirmApprove',
                                    title: '',
                                    subTitle: '',
                                    container: await confirmApproveRecord(item.tranno),
                                  })
                                }}
                              />
                            ) : null}
                          </TableCell>
                          <TableCell>
                            <IconBtn
                              sx={{}}
                              onClick={() => {
                                handleExpand(index)
                              }}
                              alt="expand"
                              src={
                                expanded[index]
                                  ? `/assets/icons/icon_arrow_up.svg`
                                  : `/assets/icons/icon_arrow_down.svg`
                              }
                            />
                          </TableCell>
                        </StyledTableRow>
                        <TableRow key={`collapse${index}`}>
                          <TableCell
                            style={{ padding: 0, display: !expanded[index] && 'none' }}
                            colSpan={10}
                          >
                            <Collapse
                              key={`child-${index}`}
                              in={expanded[index]}
                              timeout="auto"
                              unmountOnExit
                              // className={classes.layoutCollapse}
                            >
                              <Box
                                sx={{ flexGrow: 1 }}
                                // className={classes.fields}
                              >
                                <Grid
                                  container
                                  columns={{ xs: 1, sm: 6, md: 6 }}
                                  sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                  }}
                                  key={`grid${index}`}
                                  className={classes.copytext}
                                >
                                  <Grid item xs={1} sm={1} md={1}>
                                    <Typography variant="Paragraph_xs_Light" gutterBottom>
                                      Transaction ID
                                    </Typography>
                                    <Typography
                                      variant="subtitle2"
                                      color={'text.primary'}
                                      gutterBottom
                                    >
                                      {item?.tranno}
                                    </Typography>
                                  </Grid>

                                  <Grid item xs={1} sm={1} md={1}>
                                    <Typography variant="Paragraph_xs_Light" gutterBottom>
                                      Confirmation ID
                                    </Typography>
                                    <Typography
                                      variant="subtitle2"
                                      color={'text.primary'}
                                      gutterBottom
                                    >
                                      {item?.txn_token_id}
                                    </Typography>
                                  </Grid>

                                  <Grid item xs={1} sm={1} md={1}>
                                    <Typography variant="Paragraph_xs_Light" gutterBottom>
                                      TXN Reference (Pin No)
                                    </Typography>
                                    <Typography
                                      variant="subtitle2"
                                      color={'text.primary'}
                                      gutterBottom
                                    >
                                      {item?.refno}
                                    </Typography>
                                  </Grid>

                                  <Grid item xs={1} sm={1} md={1}>
                                    <Typography variant="Paragraph_xs_Light" gutterBottom>
                                      Sender Detail
                                    </Typography>
                                    <Typography
                                      variant="subtitle2"
                                      color={'text.primary'}
                                      gutterBottom
                                    >
                                      {item?.sendername}
                                    </Typography>
                                  </Grid>

                                  <Grid item xs={1} sm={1} md={1}>
                                    <Typography variant="Paragraph_xs_Light" gutterBottom>
                                      Sender Type
                                    </Typography>
                                    <Typography
                                      variant="subtitle2"
                                      color={'text.primary'}
                                      gutterBottom
                                    >
                                      {CustomerType(item?.customers_Types)}
                                    </Typography>
                                  </Grid>

                                  <Grid item xs={1} sm={1} md={1}>
                                    <Typography variant="Paragraph_xs_Light" gutterBottom>
                                      Receiver
                                    </Typography>
                                    <Typography
                                      variant="subtitle2"
                                      color={'text.primary'}
                                      gutterBottom
                                    >
                                      {item?.receivername}
                                    </Typography>
                                  </Grid>

                                  <Grid item xs={1} sm={1} md={1}>
                                    <Typography variant="Paragraph_xs_Light" gutterBottom>
                                      Receiver Type
                                    </Typography>
                                    <Typography
                                      variant="subtitle2"
                                      color={'text.primary'}
                                      gutterBottom
                                    >
                                      {CustomerType(item?.beneficiarY_CUSTOMER_TYPE)}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={1} sm={1} md={1}>
                                    <Typography variant="Paragraph_xs_Light" gutterBottom>
                                      Transaction Date
                                    </Typography>
                                    <Typography
                                      variant="subtitle2"
                                      color={'text.primary'}
                                    >
                                      {ConvertDateYYYYMMDD(item?.local_dot)}
                                    </Typography>
                                    <Typography
                                      color={'text.primary'}
                                      variant="subtitle2"
                                    >
                                      {ConvertTime(item?.local_dot)}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={1} sm={1} md={1}>
                                    <Typography variant="Paragraph_xs_Light" gutterBottom>
                                      Status
                                    </Typography>
                                    <Typography
                                      variant="subtitle2"
                                      color={'text.primary'}
                                      gutterBottom
                                    >
                                      {item?.status}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={1} sm={1} md={1}>
                                    <Typography variant="Paragraph_xs_Light" gutterBottom>
                                      Payment Type
                                    </Typography>
                                    <Typography
                                      variant="subtitle2"
                                      color={'text.primary'}
                                      gutterBottom
                                    >
                                      {item?.paymenttype}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={1} sm={1} md={1}>
                                    <Typography variant="Paragraph_xs_Light" gutterBottom>
                                      Collected Amount
                                    </Typography>
                                    <Typography
                                      variant="subtitle2"
                                      color={'text.primary'}
                                      gutterBottom
                                    >
                                      {fNumberCurrency(item?.paidamt, item?.paidcType)}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={1} sm={1} md={1}>
                                    <Typography variant="Paragraph_xs_Light" gutterBottom>
                                      Service Charge
                                    </Typography>
                                    <Typography
                                      variant="subtitle2"
                                      color={'text.primary'}
                                      gutterBottom
                                    >
                                      {fNumberCurrency(item.serviceFee, item?.paidcType)}
                                    </Typography>
                                  </Grid>

                                  <Grid item xs={1} sm={1} md={1}>
                                    <Typography variant="Paragraph_xs_Light" gutterBottom>
                                      Additional Fee
                                    </Typography>
                                    <Typography
                                      variant="subtitle2"
                                      color={'text.primary'}
                                      gutterBottom
                                    >
                                      {fNumberCurrency(
                                        item?.additional_fee,
                                        item?.paidcType,
                                      )}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={1} sm={1} md={1}>
                                    <Typography variant="Paragraph_xs_Light" gutterBottom>
                                      Payout Amount
                                    </Typography>
                                    <Typography
                                      variant="subtitle2"
                                      color={'text.primary'}
                                      gutterBottom
                                    >
                                      {fNumberCurrency(
                                        item?.payoutRoundAmt,
                                        item?.receiveCType,
                                      )}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={1} sm={1} md={1}>
                                    <Typography variant="Paragraph_xs_Light" gutterBottom>
                                      Customer Rate
                                    </Typography>
                                    <Typography
                                      variant="subtitle2"
                                      color={'text.primary'}
                                      gutterBottom
                                    >
                                      {fNumberCurrency(
                                        item?.customerRate,
                                        item?.receiveCType,
                                      )}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={1} sm={1} md={1}>
                                    <Typography variant="Paragraph_xs_Light" gutterBottom>
                                      User ID
                                    </Typography>
                                    <Typography
                                      variant="subtitle2"
                                      color={'text.primary'}
                                      gutterBottom
                                    >
                                      {item?.customerId}
                                    </Typography>
                                    <Typography
                                      variant="subtitle2"
                                      color={'text.primary'}
                                      gutterBottom
                                    >
                                      {item?.branch}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={2} sm={2} md={2}>
                                    <Button
                                      sx={{ width: '90%' }}
                                      variant="outlined"
                                      text="view transaction details"
                                      onClick={() => {
                                        navigate(`/transaction-details/${item?.tranno}`, {
                                          replace: false,
                                        })
                                      }}
                                    />
                                  </Grid>
                                </Grid>
                              </Box>
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      </React.Fragment>
                    )
                  })}
                </TableBody>
              )}
            </TblContainer>
            <Loading loading={loading} />
            <TblPagination />
          </Grid>
        </Grid>
      </Container>
      <AlertBox notify={notify} setNotify={setNotify} />
      <ConfirmDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
    </React.Fragment>
  )
}
