import { restApi } from '../utils/rest'

export const apiMasterCountryList = async (callback = {}) => {
  try {
    return await restApi(
      {
        url: `${process.env.REACT_APP_SERVICE_API}/api/v1/Master/country`,
        method: 'GET',
      },
      callback,
    )
  } catch (error) {
    return error
  }
}

export const apiGetCustomerTypeList = async (callback = {}) => {
  try {
    return await restApi(
      {
        url: `${process.env.REACT_APP_SERVICE_API}/api/v1/Master/customer-type`,
        method: 'GET',
      },
      callback,
    )
  } catch (error) {
    return error
  }
}

export const apiGetGenderList = async (callback = {}) => {
  try {
    return await restApi(
      {
        url: `${process.env.REACT_APP_SERVICE_API}/api/v1/Master/gender`,
        method: 'GET',
      },
      callback,
    )
  } catch (error) {
    return error
  }
}

export const apiGetIDTypeList = async (callback = {}) => {
  try {
    return await restApi(
      {
        url: `${process.env.REACT_APP_SERVICE_API}/api/v1/Master/id-type`,
        method: 'GET',
      },
      callback,
    )
  } catch (error) {
    return error
  }
}

export const apiMasterGender = async (callback = {}) => {
  try {
    return await restApi(
      {
        url: `${process.env.REACT_APP_SERVICE_API}/api/v1/Master/gender`,
        method: 'GET',
      },
      callback,
    )
  } catch (error) {
    return error
  }
}

export const apiMasterDateTypeList = async (callback = {}) => {
  try {
    return await restApi(
      {
        url: `${process.env.REACT_APP_SERVICE_API}/api/v1/Master/date-type`,
        method: 'GET',
      },
      callback,
    )
  } catch (error) {
    return error
  }
}

export const apiMasterSenderTypeList = async (callback = {}) => {
  try {
    return await restApi(
      {
        url: `${process.env.REACT_APP_SERVICE_API}/api/v1/Master/customer-type`,
        method: 'GET',
      },
      callback,
    )
  } catch (error) {
    return error
  }
}

export const apiMasterTransactionStatusList = async (callback = {}) => {
  try {
    return await restApi(
      {
        url: `${process.env.REACT_APP_SERVICE_API}/api/v1/Master/transaction-status`,
        method: 'GET',
      },
      callback,
    )
  } catch (error) {
    return error
  }
}

export const apiMasterAccountTypeList = async (callback = {}) => {
  try {
    return await restApi(
      {
        url: `${process.env.REACT_APP_SERVICE_API}/api/v1/Master/account-type`,
        method: 'GET',
      },
      callback,
    )
  } catch (error) {
    return error
  }
}

export const apiMasterCalculateMode = async (callback = {}) => {
  try {
    return await restApi(
      {
        url: `${process.env.REACT_APP_SERVICE_API}/api/v1/Master/calculation-mode`,

        method: 'GET',
      },
      callback,
    )
  } catch (error) {
    return error
  }
}

export const apiMasterPaymentTypeList = async (callback = {}) => {
  try {
    return await restApi(
      {
        url: `${process.env.REACT_APP_SERVICE_API}/api/v1/Master/payment-type`,
        method: 'GET',
      },
      callback,
    )
  } catch (error) {
    return error
  }
}

export const apiGetProcessSessionID = async (body = {}, callback = {}) => {
  try {
    return await restApi(
      {
        url: `${process.env.REACT_APP_SERVICE_API}/api/v1/customer/set-process-session-id`,
        method: 'POST',
        body,
      },
      callback,
    )
  } catch (error) {
    return error
  }
}

export const apiMasterSuspiciousTypeList = async (callback = {}) => {
  try {
    return await restApi(
      {
        url: `${process.env.REACT_APP_SERVICE_API}/api/v1/Master/suspicious-type`,
        method: 'GET',
      },
      callback,
    )
  } catch (error) {
    return error
  }
}

export const apiMasterSuspiciousModeList = async (callback = {}) => {
  try {
    return await restApi(
      {
        url: `${process.env.REACT_APP_SERVICE_API}/api/v1/Master/suspicious-mode`,
        method: 'GET',
      },
      callback,
    )
  } catch (error) {
    return error
  }
}

export const apiMasterDateTypeSMList = async (callback = {}) => {
  try {
    return await restApi(
      {
        url: `${process.env.REACT_APP_SERVICE_API}/api/v1/Master/datetype-sm`,
        method: 'GET',
      },
      callback,
    )
  } catch (error) {
    return error
  }
}

export const apiMasterPurposeOfRemittanceList = async (callback = {}) => {
  try {
    return await restApi(
      {
        url: `${process.env.REACT_APP_SERVICE_API}/api/v1/Master/purpose-of-remittance`,
        method: 'GET',
      },
      callback,
    )
  } catch (error) {
    return error
  }
}

export const apiMasterPaymentModeList = async (callback = {}) => {
  try {
    return await restApi(
      {
        url: `${process.env.REACT_APP_SERVICE_API}/api/v1/Master/payment-mode`,
        method: 'GET',
      },
      callback,
    )
  } catch (error) {
    return error
  }
}

export const apiMasterDocumentType = async (callback = {}) => {
  try {
    return await restApi(
      {
        url: `${process.env.REACT_APP_SERVICE_API}/api/v1/Master/document-type`,
        method: 'GET',
      },
      callback,
    )
  } catch (error) {
    return error
  }
}

export const apiMasterTransactionDocumentType = async (callback = {}) => {
  try {
    return await restApi(
      {
        url: `${process.env.REACT_APP_SERVICE_API}/api/v1/Master/transaction-document-type`,
        method: 'GET',
      },
      callback,
    )
  } catch (error) {
    return error
  }
}

export const apiGetStateListByCountryCode = async (countryCode, callback = {}) => {
  try {
    return await restApi(
      {
        url: `${process.env.REACT_APP_SERVICE_API}/api/v1/Master/prefecture?country_name=${countryCode}`,
        method: 'GET',
      },
      callback,
    )
  } catch (error) {
    return error
  }
}

export const apiGetCityListByState = async (state, callback = {}) => {
  try {
    return await restApi(
      {
        url: `${process.env.REACT_APP_SERVICE_API}/api/v1/Master/city?state=${state}`,
        method: 'GET',
      },
      callback,
    )
  } catch (error) {
    return error
  }
}

export const apiGetDistrictListByCity = async (city, callback = {}) => {
  try {
    return await restApi(
      {
        url: `${process.env.REACT_APP_SERVICE_API}/api/v1/Master/district/china?city=${city}`,
        method: 'GET',
      },
      callback,
    )
  } catch (error) {
    return error
  }
}
