export default function Button(theme) {
  return {
    MuiAlert: {
      styleOverrides: {
        root: {
          borderRadius: 4,
          alignItems: 'center',
          '& .MuiSvgIcon-root': {
            color: theme.palette.common.white,
          },

          '& .MuiAlert-message': {
            [theme.breakpoints.up('md')]: {
              fontSize: '18px',
            },
            textAlign: 'left',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: '600',
            lineHeight: 'normal',
            letterSpacing: '0.036px',
            display: 'flex',
            alignItems: 'flex-start',
            color: theme.palette.common.white,
            padding: '0 15px 0 0',
          },
        },

        filledSuccess: {
          backgroundColor: theme.palette.status.success,
        },

        standardError: {
          backgroundColor: 'rgba(255, 88, 88, 0.10)',
        },
      },
    },
  }
}
