import {
  Container,
  Grid,
  Stack,
  Typography,
  InputAdornment,
  Popover,
  TableBody,
  TableCell,
  TableRow,
  Collapse,
  Box,
  Button as MuiButton,
} from '@mui/material'
import React, { useState, useCallback, useEffect } from 'react'
import Button from '../../components/common/Button'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { useNavigate } from 'react-router-dom'
import InputSearchFilter from '../../components/common/InputSearchFilter'
import { startOfDay, endOfDay } from 'date-fns'
import SvgColor from '../../components/svg-color/svg-color'
import useTableCallback from '../../components/useTableCallback'
import { useForm } from 'react-hook-form'
import AutocompleteField from '../../components/common/AutocompleteField'
import StyledTableRow from '../../theme/styledTableRow'
import { fNumberCurrency } from '../../utils/formatNumer'
import moment from 'moment'
import IconBtn from '../../components/common/IconBtn'
import Loading from '../../components/loading/loading'
import { useHistoryStyles } from '../../containers/history/historyStyles'
import RadioField from '../../components/common/RadioField'
import { CalendarRangePicker } from '../../components/common/CalendarPicker'
import { apiSuspiciousTransaction } from '../../api/transaction'
import {
  apiMasterSuspiciousTypeList,
  apiMasterSuspiciousModeList,
  apiMasterDateTypeList,
} from '../../api/master'
import {
  ConvertDateTimeFormat,
  ConvertDateYYYYMMDD,
  ConvertTime,
  CountryNameByCode,
} from '../../utils/app'

//#region Initial
const initState = {
  valueFilters: {
    searchText: '',
    suspiciousType: '',
    suspiciousMode: '',
    dateType: 'confirmdate',
  },
  dateRange: {
    startDate: startOfDay(new Date()),
    endDate: endOfDay(new Date()),
    key: 'selectDateRange',
  },
  loading: false,
  records: [],
  expanded: [],
  suspiciousTypeList: [],
  suspiciousModeList: [],
  dateTypeList: [],
  searchText: '',
}

const headCells = [
  {
    id: 'tranNo',
    label: 'TXN ID',
    disableSorting: true,
  },
  {
    id: 'senderName',
    label: 'Sender',
    disableSorting: true,
  },
  {
    id: 'receiverName',
    label: 'Receiver',
    disableSorting: true,
  },
  {
    id: 'confirmDate',
    label: 'Transaction Date',
    disableSorting: true,
  },
  {
    id: 'totalRoundAmt',
    label: 'Total Round Amount',
    disableSorting: true,
  },
  {
    id: 'comments',
    label: 'Reason',
    disableSorting: true,
  },
  {
    id: 'expand',
    label: '',
    disableSorting: true,
  },
]

//#endregion

export default function SuspiciousReportPage() {
  const navigate = useNavigate()
  const { classes } = useHistoryStyles()
  const { control, getValues, setValue } = useForm()
  const [searchText, setSearchText] = useState(initState.searchText)
  const [dateRange, setDateRange] = useState([initState?.dateRange])
  const [anchorEl, setAnchorEl] = useState(null)
  // const [suspiciousTypeList, setSuspiciousTypeList] = useState(
  //   initState?.suspiciousTypeList,
  // )
  const [suspiciousModeList, setSuspiciousModeList] = useState(
    initState?.suspiciousModeList,
  )
  const [dateTypeList, setDateTypeList] = useState(initState?.dateTypeList)
  const [records, setRecords] = useState(initState?.records)
  const [expanded, setExpanded] = useState(initState.expanded)
  const [totalRecords, setTotalRecords] = useState([])
  const [loading, setLoading] = useState(initState?.loading)

  const open = Boolean(anchorEl)

  //#region Event Search
  const handleClickFilter = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleInputSearch = (e) => {
    setPage(0)
    setSearchText(e.target.value)
    fecthSuspiciousTransaction(e.target.value, 1, rowsPerPage, dateRange)
  }
  const handleCloseFilter = () => {
    fecthSuspiciousTransaction(searchText, 1, rowsPerPage, dateRange)
    setAnchorEl(null)
  }

  const handleChangeDate = (item) => {
    setDateRange([item.selectDateRange])
  }

  const handleClearAll = (e) => {
    setDateRange([
      {
        startDate: startOfDay(new Date()),
        endDate: endOfDay(new Date()),
        key: 'selectDateRange',
      },
    ])
    setValue('suspiciousMode', '')
    setValue('dateType', 'confirmdate')
  }

  //#endregion

  //#region Event Table
  const handlePageChange = (event, newPage) => {
    setPage(newPage)
    fecthSuspiciousTransaction(searchText, newPage + 1, rowsPerPage, dateRange)
  }

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
    fecthSuspiciousTransaction(searchText, 1, parseInt(event.target.value, 10), dateRange)
  }

  const {
    TblContainer,
    TblHead,
    TblPagination,
    recordsAfterPaginationAndSorting,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
  } = useTableCallback(
    records,
    totalRecords,
    headCells,
    handlePageChange,
    handleRowsPerPageChange,
  )

  const handleExpand = (item) => {
    setExpanded(
      expanded?.map((boolean_value, i) => {
        if (item === i) {
          return !boolean_value
        } else {
          return false
        }
      }),
    )
  }
  //#endregion

  //#region Fecth data
  // const fecthSuspiciousTypeList = useCallback(async () => {
  //   const response = await apiMasterSuspiciousTypeList()
  //   if (response?.code !== 0) {
  //     setSuspiciousTypeList(initState?.suspiciousTypeList)
  //     return
  //   }

  //   const option = response?.data?.map((e) => {
  //     return {
  //       value: e?.value,
  //       label: e?.text,
  //     }
  //   })

  //   setSuspiciousTypeList(option)
  // }, initState?.suspiciousTypeList)

  const fecthSuspiciousModeList = useCallback(async () => {
    const response = await apiMasterSuspiciousModeList()
    if (response?.code !== 0) {
      setSuspiciousModeList(initState?.suspiciousModeList)
      return
    }

    const option = response?.data?.map((e) => {
      return {
        value: e?.value,
        label: e?.text,
      }
    })

    setSuspiciousModeList(option)
    setValue('suspiciousMode', '')
  }, initState?.suspiciousModeList)

  const fecthDateTypeList = useCallback(async () => {
    const response = await apiMasterDateTypeList()
    if (response?.code !== 0) {
      setDateTypeList(initState?.dateTypeList)
      return
    }

    const option = response?.data?.map((e) => {
      return {
        value: e?.value,
        label: e?.text,
      }
    })

    setDateTypeList(option)
    setValue('dateType', 'confirmdate')
  }, initState?.dateTypeList)

  const fecthSuspiciousTransaction = useCallback(
    async (searchText, cPage, rPerPage, dateRange) => {
      setLoading(true)
      const response = await apiSuspiciousTransaction({
        suspiciousType:
          getValues('suspiciousType') !== undefined ? getValues('suspiciousType') : '',
        suspiciousMode:
          getValues('suspiciousMode') !== undefined ? getValues('suspiciousMode') : '',
        dateType:
          getValues('dateType') !== undefined ? getValues('dateType') : 'confirmdate',
        fromDate: moment(dateRange[0].startDate).format('YYYY-MM-DD'),
        toDate: moment(dateRange[0].endDate).format('YYYY-MM-DD'),
        currentPage: cPage,
        pageSize: rPerPage,
        searchTxt: searchText,
      })

      if (response?.code !== 0) {
        setRecords([])
        setLoading(false)
        setTotalRecords([])
        return
      }

      setRecords(response?.data)
      setTotalRecords(response?.totalRecord)
      setExpanded([...Array(response?.data?.length)].map((val) => false))
      setLoading(false)
    },
    [],
  )
  //#endregion

  //#region UseEffect
  useEffect(() => {
    //fecthSuspiciousTypeList()
    fecthSuspiciousModeList()
    fecthDateTypeList()
    fecthSuspiciousTransaction(searchText, 1, rowsPerPage, dateRange)
  }, [])
  //#endregion

  return (
    <React.Fragment>
      <Container>
        <Grid container mt={3} spacing={3}>
          <Grid item sm={1} key="Back">
            <Button
              onClick={() => {
                navigate(`/history`, {
                  replace: false,
                })
              }}
              startIcon={<ArrowBackIcon />}
              text="Back"
              variant="outlined"
              size="small"
            />
          </Grid>
          <Grid item sm={12} key="Topic">
            <Typography sx={{ mb: '30px' }} component={'div'} variant="H3_Bold">
              Suspicious Report
            </Typography>
          </Grid>
          <Grid item sm={9} key="Filter" className={classes.search}>
            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              spacing={{ xs: 1, sm: 2, md: 4 }}
            >
              <InputSearchFilter
                id="iptSearch"
                name="iptSearch"
                placeholder="Search"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgColor
                        src={`/assets/icons/icon_search.svg`}
                        className={classes.icon}
                      />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <MuiButton
                        variant="contained"
                        startIcon={
                          <SvgColor
                            src={`/assets/icons/icon_searchfilter.svg`}
                            className={classes.icon}
                          />
                        }
                        onClick={handleClickFilter}
                        buttonFilter
                        className={classes.buttonHeight}
                      >
                        FILTER
                      </MuiButton>
                    </InputAdornment>
                  ),
                }}
                onChange={handleInputSearch}
              ></InputSearchFilter>
              <Button
                startIcon={
                  <SvgColor
                    src={`/assets/icons/icon_document.svg`}
                    className={classes.icon}
                  />
                }
                onClick={() => {
                  navigate(`/history/batch-file-transaction`, {
                    replace: false,
                  })
                }}
                text="Excel"
                size="large"
                variant="outlined"
              />
            </Stack>
          </Grid>
          <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleCloseFilter}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            PaperProps={{
              className: classes.paper,
            }}
          >
            <Grid container spacing={2} columns={{ xs: 1, sm: 1, md: 8 }} padding={1}>
              <Grid item sm={8} key="Filter">
                <Typography variant="Paragraph_lg_Bold">Filter</Typography>
              </Grid>
              {/* <Grid item xs={2} sm={5} md={5}>
                <SelectField
                  control={control}
                  label="Suspicious Type"
                  id="suspiciousType"
                  name="suspiciousType"
                  size="small"
                  options={suspiciousTypeList}
                />
              </Grid> */}
              <Grid item xs={2} sm={8} md={8}>
                <RadioField
                  control={control}
                  name="suspiciousMode"
                  variant={'Paragraph_lg_Bold'}
                  label="Suspicious Mode"
                  options={suspiciousModeList}
                />
              </Grid>
              <Grid item xs={1} sm={3} md={3} mt={3}>
                <Typography variant="Paragraph_lg_Bold">Date</Typography>
              </Grid>
              <Grid item xs={2} sm={5} md={5}>
                <AutocompleteField
                  control={control}
                  name="dateType"
                  //label="Date Type"
                  placeholder="Date Type"
                  options={dateTypeList}
                />
              </Grid>
              <Grid item xs={8} sm={8} md={8}>
                <CalendarRangePicker onChange={handleChangeDate} ranges={dateRange} />
              </Grid>
              <Grid item xs={8} sm={8} md={8}>
                <Stack direction="row" className={classes.btnFilter}>
                  <Button
                    text="Clear all"
                    size="medium"
                    variant="outlined"
                    onClick={handleClearAll}
                  />
                  <Button
                    type="button"
                    text="Done"
                    size="medium"
                    onClick={handleCloseFilter}
                  />
                </Stack>
              </Grid>
            </Grid>
          </Popover>
          <Grid item sm={12} key="Container">
            <TblContainer>
              <TblHead />
              {!loading && (
                <TableBody>
                  {recordsAfterPaginationAndSorting().map((item, index) => {
                    return (
                      <React.Fragment key={`tableSuspiciousReport-${index}`}>
                        <StyledTableRow key={index} hover>
                          <TableCell head-table="TXN ID">
                            <Typography variant="Paragraph_sm_Light">
                              {item?.tranNo}
                            </Typography>
                          </TableCell>
                          <TableCell head-table="Sender">
                            <Typography variant="Paragraph_sm_Light">
                              {item?.senderName.substring(0, 10).concat('...')}
                            </Typography>
                          </TableCell>
                          <TableCell head-table="Receiver">
                            <Typography variant="Paragraph_sm_Light">
                              {item?.receiverName.substring(0, 10).concat('...')}
                            </Typography>
                          </TableCell>
                          <TableCell head-table="Transaction Date">
                            <Typography variant="Paragraph_sm_Light">
                              {ConvertDateTimeFormat(item?.confirmDate)}
                            </Typography>
                          </TableCell>
                          <TableCell head-table="Total Round Amount">
                            <Typography variant="Paragraph_sm_Light">
                              {fNumberCurrency(item?.totalRoundAmt, item?.receiveCType)}
                            </Typography>
                          </TableCell>
                          <TableCell head-table="Reason">
                            <Typography variant="Paragraph_sm_Light">
                              {item?.comments}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <IconBtn
                              sx={{}}
                              onClick={() => {
                                handleExpand(index)
                              }}
                              alt="expand"
                              src={
                                expanded[index]
                                  ? `/assets/icons/icon_arrow_up.svg`
                                  : `/assets/icons/icon_arrow_down.svg`
                              }
                            />
                          </TableCell>
                        </StyledTableRow>
                        <TableRow key={`collapse${index}`}>
                          <TableCell
                            style={{
                              padding: 0,
                              display: !expanded[index] && 'none',
                            }}
                            colSpan={10}
                          >
                            <Collapse
                              key={`child-${index}`}
                              in={expanded[index]}
                              timeout="auto"
                              unmountOnExit
                              //className={classes.layoutCollapse}
                            >
                              <Box sx={{ flexGrow: 1 }}>
                                <Grid
                                  container
                                  columns={{ xs: 1, sm: 6, md: 6 }}
                                  sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                  }}
                                  key={`grid${index}`}
                                  className={classes.copytext}
                                >
                                  <Grid item xs={1} sm={1} md={1}>
                                    <Typography variant="Paragraph_xs_Light" gutterBottom>
                                      Transaction No
                                    </Typography>
                                    <Typography
                                      variant="subtitle2"
                                      color={'text.primary'}
                                      gutterBottom
                                    >
                                      {item?.tranNo}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={1} sm={1} md={1}>
                                    <Typography variant="Paragraph_xs_Light" gutterBottom>
                                      Sender Name
                                    </Typography>
                                    <Typography
                                      variant="subtitle2"
                                      color={'text.primary'}
                                      gutterBottom
                                    >
                                      {item?.senderName}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={1} sm={1} md={1}>
                                    <Typography variant="Paragraph_xs_Light" gutterBottom>
                                      Receiver Name
                                    </Typography>
                                    <Typography
                                      variant="subtitle2"
                                      color={'text.primary'}
                                      gutterBottom
                                    >
                                      {item?.receiverName}
                                    </Typography>
                                  </Grid>

                                  <Grid item xs={1} sm={1} md={1}>
                                    <Typography variant="Paragraph_xs_Light" gutterBottom>
                                      Sender Partner
                                    </Typography>
                                    <Typography
                                      variant="subtitle2"
                                      color={'text.primary'}
                                      gutterBottom
                                    >
                                      {item?.agentName}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={1} sm={1} md={1}>
                                    <Typography variant="Paragraph_xs_Light" gutterBottom>
                                      Branch
                                    </Typography>
                                    <Typography
                                      variant="subtitle2"
                                      color={'text.primary'}
                                    >
                                      {item?.branch}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={1} sm={1} md={1}>
                                    <Typography variant="Paragraph_xs_Light" gutterBottom>
                                      Teller Name
                                    </Typography>
                                    <Typography
                                      variant="subtitle2"
                                      color={'text.primary'}
                                      gutterBottom
                                    >
                                      {item?.senderAgentUserID}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={1} sm={1} md={1}>
                                    <Typography variant="Paragraph_xs_Light" gutterBottom>
                                      Transaction Date
                                    </Typography>
                                    <Typography
                                      variant="subtitle2"
                                      color={'text.primary'}
                                    >
                                      {ConvertDateYYYYMMDD(item?.confirmDate)}
                                    </Typography>
                                    <Typography
                                      color={'text.primary'}
                                      variant="subtitle2"
                                    >
                                      {ConvertTime(item?.confirmDate)}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={1} sm={1} md={1}>
                                    <Typography variant="Paragraph_xs_Light" gutterBottom>
                                      Paid Amount
                                    </Typography>
                                    <Typography
                                      variant="subtitle2"
                                      color={'text.primary'}
                                      gutterBottom
                                    >
                                      {fNumberCurrency(item?.paidAmt, item?.paidCType)}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={1} sm={1} md={1}>
                                    <Typography variant="Paragraph_xs_Light" gutterBottom>
                                      TotalRoundAmount
                                    </Typography>
                                    <Typography
                                      variant="subtitle2"
                                      color={'text.primary'}
                                      gutterBottom
                                    >
                                      {fNumberCurrency(
                                        item.totalRoundAmt,
                                        item?.receiveCType,
                                      )}
                                    </Typography>
                                  </Grid>

                                  <Grid item xs={1} sm={1} md={1}>
                                    <Typography variant="Paragraph_xs_Light" gutterBottom>
                                      Payout Country
                                    </Typography>
                                    <Typography
                                      variant="subtitle2"
                                      color={'text.primary'}
                                      gutterBottom
                                    >
                                      {CountryNameByCode(item?.receiverCountry)}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={1} sm={1} md={1}>
                                    <Typography variant="Paragraph_xs_Light" gutterBottom>
                                      Approve By
                                    </Typography>
                                    <Typography
                                      variant="subtitle2"
                                      color={'text.primary'}
                                      gutterBottom
                                    >
                                      {item?.ofacAppBy}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={1} sm={1} md={1}>
                                    <Typography variant="Paragraph_xs_Light" gutterBottom>
                                      Approved Date
                                    </Typography>
                                    <Typography
                                      variant="subtitle2"
                                      color={'text.primary'}
                                    >
                                      {ConvertDateYYYYMMDD(item?.ofacAppTs)}
                                    </Typography>
                                    <Typography
                                      color={'text.primary'}
                                      variant="subtitle2"
                                    >
                                      {ConvertTime(item?.ofacAppTs)}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={2} sm={3} md={3}>
                                    <Typography variant="Paragraph_xs_Light" gutterBottom>
                                      Reason
                                    </Typography>
                                    <Typography
                                      variant="subtitle2"
                                      color={'text.primary'}
                                      gutterBottom
                                    >
                                      {item?.comments}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Box>
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      </React.Fragment>
                    )
                  })}
                </TableBody>
              )}
            </TblContainer>
            <Loading loading={loading} />
            <TblPagination />
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  )
}
