import React, { createContext } from 'react'
import i18n from 'i18n-js'
import { getLanguage, saveDataLocalStorage, getLangNameByLangCode } from '../i18n/helper'

export const LocalizationContext = createContext(undefined)

// eslint-disable-next-line
export default function ({ children }) {
  const changeLanguage = (lang = 'en') => {
    i18n.locale = lang
    saveDataLocalStorage('language', lang)
    // window.location.reload();
  }

  const currentLanguage = () => i18n.currentLocale()
  const current = getLanguage()

  return (
    <LocalizationContext.Provider
      value={[getLangNameByLangCode(current), { changeLanguage, currentLanguage }]}
    >
      {children}
    </LocalizationContext.Provider>
  )
}
