import React from 'react'
import { Grid, Stack } from '@mui/material'
import CardExpand from '../../../components/common/CardExpand'
import SenderAdditionalInformationContainer from './sender-additional-information'

export default function SenderAdditionalInformationTab(props) {
  return (
    <div>
      <Stack spacing={3}>
        <Grid item sm={6}>
          <CardExpand title="Additional Infomation" defaultOpen="true">
            <SenderAdditionalInformationContainer {...props} />
          </CardExpand>
        </Grid>
      </Stack>
    </div>
  )
}
