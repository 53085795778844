export default function Button(theme) {
  return {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 30,
          boxShadow: 'none',
          background: theme.palette.button.default,
          border: `2px solid ${theme.palette.button.default}`,

          '&:hover': {
            background: theme.palette.button.hover,
            border: `2px solid ${theme.palette.button.hover}`,
          },

          '&:active': {
            background: theme.palette.button.active,
            border: `2px solid ${theme.palette.button.active}`,
          },

          '&.Mui-disabled': {
            color: theme.palette.text.primary,
            background: theme.palette.button.disable,
            border: `2px solid ${theme.palette.outline.disabled}`,
          },

          // span: {
          //   "&.MuiTouchRipple-root": {
          //     visibility: "hidden",
          //   },
          // },
        },

        sizeLarge: { height: 48, width: 240 },

        sizeMedium: { height: 40, width: 180 },

        sizeSmall: { height: 36, width: 140 },

        containedInherit: {
          color: theme.palette.text.disabled,
          boxShadow: theme.customShadows.z8,
          '&:hover': {
            backgroundColor: theme.palette.button.cancelhover,
          },
        },
        containedPrimary: {
          boxShadow: theme.customShadows.primary,
        },
        containedSecondary: {
          boxShadow: theme.customShadows.secondary,
        },

        outlinedInherit: {
          backgroundColor: 'transparent',
          color: theme.palette.text.inherit,
          border: `2px solid ${theme.palette.outline.disabled}`,
          '&:hover': {
            backgroundColor: 'transparent',
            color: theme.palette.text.disabled,
            border: `2px solid ${theme.palette.outline.secondary}`,
          },
        },

        outlinedPrimary: {
          backgroundColor: 'transparent',
          color: theme.palette.text.primary,
          border: `2px solid ${theme.palette.button.default}`,
          '&:hover': {
            backgroundColor: 'transparent',
            color: theme.palette.text.primary,
            border: `2px solid ${theme.palette.button.hover}`,
          },
        },

        textInherit: {
          border: `transparent`,
          backgroundColor: 'transparent',
          color: theme.palette.text.primary,
          '&:hover': {
            border: `transparent`,
            backgroundColor: 'transparent',
            color: theme.palette.text.secondary,
          },
        },

        containedSuccess: {
          backgroundColor: theme.palette.button.confirm,
          '&:hover': {
            backgroundColor: theme.palette.button.confirmhover,
          },
        },
        containedError: {
          backgroundColor: theme.palette.button.error,
          '&:hover': {
            backgroundColor: theme.palette.button.errorhover,
          },
        },
      },
    },

    MuiLoadingButton: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          borderRadius: 30,
          background: theme.palette.button.default,

          '&.Mui-disabled': {
            color: theme.palette.text.primary,
            background: theme.palette.button.disable,
            border: `2px solid ${theme.palette.outline.disabled}`,
          },

          '&:hover': {
            boxShadow: 'none',
            background: theme.palette.button.hover,
          },
        },
      },
    },
  }
}
