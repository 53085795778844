import React, { useState } from 'react'
import {
  Table,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TableContainer,
  Paper,
  Stack,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import CheckBox from './common/Checkbox'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: '#8685AE',
}))

export default function useTableCallback(
  records,
  totalRecords,
  headCells,
  handlePageChange,
  handleRowsPerPageChange,
) {
  const pages = [5, 10, 25, 50, 100]
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(pages[page])
  const [order, setOrder] = useState()
  const [orderBy, setOrderBy] = useState()

  const TblContainer = (props) => {
    return (
      <TableContainer
        component={Paper}
        sx={{
          paddingLeft: '24px',
          paddingRight: '24px',
          paddingTop: '24px',
        }}
      >
        <Table size="small">{props.children}</Table>
      </TableContainer>
    )
  }

  const TblHead = (props) => {
    const handleSortRequest = (cellId) => {
      setPage(0)
      const isAsc = orderBy === cellId && order === 'asc'
      setOrder(isAsc ? 'desc' : 'asc')
      setOrderBy(cellId)
    }

    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => {
            return (
              <StyledTableCell
                key={headCell.id}
                sortDirection={orderBy === headCell.id ? order : false}
              >
                {headCell.disableSorting ? (
                  headCell.label
                ) : (
                  <>
                    {headCell?.checked?.status ? (
                      <Stack flexDirection="row">
                        <CheckBox
                          name={headCell?.id}
                          value={headCell?.checked?.value}
                          onChange={headCell?.checked?.onchange}
                        />
                        {/* <TableSortLabel
                          active={orderBy === headCell.id}
                          direction={orderBy === headCell.id ? order : 'asc'}
                          onClick={() => handleSortRequest(headCell.id)}
                        >
                          {headCell.label}
                        </TableSortLabel> */}
                      </Stack>
                    ) : (
                      <TableSortLabel
                        active={orderBy === headCell.id}
                        direction={orderBy === headCell.id ? order : 'asc'}
                        onClick={() => handleSortRequest(headCell.id)}
                      >
                        {headCell.label}
                      </TableSortLabel>
                    )}
                  </>
                )}
              </StyledTableCell>
            )
          })}
        </TableRow>
      </TableHead>
    )
  }

  const TblPagination = () => {
    return (
      <TablePagination
        style={{ paddingLeft: 24, paddingRight: 24 }}
        component="div"
        showFirstButton
        showLastButton
        page={page}
        rowsPerPageOptions={pages}
        rowsPerPage={rowsPerPage}
        count={totalRecords}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
      />
    )
  }

  const recordsAfterPaginationAndSorting = () => {
    return records
  }

  return {
    TblContainer,
    TblHead,
    TblPagination,
    recordsAfterPaginationAndSorting,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
  }
}
