import React, { useState, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { alpha } from '@mui/material/styles'
import {
  Box,
  Divider,
  Typography,
  Stack,
  MenuItem,
  Avatar,
  IconButton,
  Popover,
} from '@mui/material'
import SvgColor from '../../components/svg-color/svg-color'
import { logout } from '../../redux/auth/action'
import { removeCookieAll } from '../../utils/cookie'

const icon = (name) => (
  <SvgColor src={`/assets/icons/avatars/${name}.svg`} sx={{ width: 24, height: 24 }} />
)

const account = {
  displayName: 'User',
  // email: "demo@lightremit.io",
  photoURL: '/assets/icons/avatars/avatar_default.svg',
}

const MENU_OPTIONS = [
  {
    label: 'My profile',
    icon: icon('profile-circle'),
  },
  {
    label: 'Change password',
    icon: icon('lock'),
  },
]

export default function AccountPopover() {
  const dispatch = useDispatch()
  const [open, setOpen] = useState(null)

  const handleOpen = (event) => {
    setOpen(event.currentTarget)
  }

  const handleClose = () => {
    setOpen(null)
  }

  const handleLogout = useCallback(() => {
    setOpen(null)
    dispatch(logout())
  }, [dispatch])

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <Avatar sx={{ width: 48, height: 48 }} src={account.photoURL} alt="photoURL" />
      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1.5,
            ml: 0.75,
            width: 230,
            border: 'none',
            '& .MuiMenuItem-root': {
              // px: 1,
              // py: 1,
              mx: 1,
            },
          },
        }}
      >
        <Box sx={{ my: 2, px: 3 }}>
          <Typography variant="H5_Bold">{account.displayName}</Typography>
        </Box>

        <Divider sx={{ borderStyle: 'solid', borderColor: 'outline.default' }} />

        <Stack sx={{ my: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <>
              <MenuItem key={option.label} onClick={handleClose}>
                <Box sx={{ mr: 2 }}>{option.icon}</Box>
                {option.label}
              </MenuItem>
              <Divider
                sx={{
                  borderStyle: 'solid',
                  borderColor: 'outline.default',
                }}
              />
            </>
          ))}
          <MenuItem onClick={handleLogout}>
            <Box sx={{ mr: 2 }}>{icon('logout')}</Box>
            Logout
          </MenuItem>
        </Stack>
      </Popover>
    </>
  )
}
