import React from 'react'
import { useTheme } from '@mui/material/styles'
import { Controller } from 'react-hook-form'
import { DesktopDatePicker } from '@mui/x-date-pickers'
import { Box, InputLabel, Typography } from '@mui/material'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import Input from '../../components/common/Input'
import { hideScrollbarY, hideScrollbarX } from '../../utils/stylecss'

const todayDate = new Date()
const dateFormatSource = 'yyyy-MM-dd'

function calendarIcon() {
  return (
    <img
      src="/assets/icons/icon_calendar.svg"
      alt="Date picker opening icon"
      width={28}
    />
  )
}

const DateField = ({
  sx,
  name,
  label,
  control,
  required,
  disabled,
  placeholder,
  disablePastDate,
  disableFutureDate,
  disableErrorSpace,
  ...restProps
}) => {
  const theme = useTheme()

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Controller
        control={control}
        name={name}
        render={({ field: { ref, ...restField }, fieldState: { error } }) => {
          return (
            <Box sx={{ mb: 2 }}>
              <InputLabel htmlFor={name}>
                {error ? (
                  <Typography
                    variant="Paragraph_sm_Light"
                    color="text.error"
                    component="div"
                    sx={{ mb: 1 }}
                  >
                    {label ? `${label}${required ? '  *' : ''}` : ''}
                  </Typography>
                ) : (
                  <Typography variant="Paragraph_sm_Light" component="div" sx={{ mb: 1 }}>
                    {label ? `${label}${required ? '  *' : ''}` : ''}
                  </Typography>
                )}
              </InputLabel>
              <DesktopDatePicker
                {...restField}
                inputRef={ref}
                format={dateFormatSource}
                inputFormat="dd-MM-yyyy"
                views={['year', 'month', 'day']}
                maxDate={disableFutureDate ? todayDate : undefined}
                minDate={disablePastDate ? todayDate : undefined}
                renderInput={(params) => (
                  <Input
                    {...params}
                    {...restProps}
                    error={!!error}
                    placeholder={placeholder}
                    helperText={error?.message || (disableErrorSpace ? '' : ' ')}
                    fullWidth
                  />
                )}
                slots={{ openPickerIcon: calendarIcon }}
                slotProps={{
                  desktopPaper: {
                    elevation: 0,
                    variant: 'outlined',
                  },
                  layout: {
                    sx: {
                      marginTop: 2,
                      '.MuiPickersCalendarHeader-switchViewButton': {
                        color: theme.palette.text.primary,
                      },
                      '.MuiPickersArrowSwitcher-button': {
                        color: theme.palette.text.primary,
                      },
                      '.MuiDayCalendar-weekDayLabel': {
                        color: theme.palette.gradientcolor.lightyellow,
                      },
                      '.MuiDateCalendar-root': {
                        borderRadius: 4,
                        borderWidth: 1,
                        color: theme.palette.text.primary,
                        border: `1px solid ${theme.palette.outline.default}`,
                        backgroundColor: theme.palette.background.dark,
                      },
                      '.MuiYearCalendar-root': {
                        ...hideScrollbarY,
                        ...hideScrollbarX,
                      },
                    },
                  },
                  inputAdornment: {
                    position: 'start',
                  },
                }}
                DialogProps={{
                  style: { zIndex: 1325 }, //  should be more than 1200
                }}
                sx={{
                  button: {
                    marginLeft: '-8px',
                    marginRight: 2,
                    backgroundColor: '#444A81',
                    '&:hover': {
                      backgroundColor: '#8655D1',
                    },
                    '&:active': {
                      backgroundColor: '#8655D1',
                    },
                  },
                  width: '100%',
                  ...sx,
                }}
                disabled={disabled}
              />
            </Box>
          )
        }}
        rules={{
          required: required ? 'Required' : '',
          // validate: (value) => (value && !isValid(value) ? 'Wrong format' : true),
        }}
      />
    </LocalizationProvider>
  )
}

export default DateField
