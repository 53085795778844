import React from 'react'
import { Grid } from '@mui/material'
import EmailField from '../../../components/common/EmailField'
import InputField from '../../../components/common/InputField'

export default function ReceiverAlternativeDetails(props) {
  const { control } = props

  return (
    <Grid container spacing={{ xs: 1, sm: 2 }} columns={{ xs: 1, sm: 8, md: 8 }}>
      <Grid item xs={2} sm={4} md={4}>
        <InputField
          control={control}
          name="receiverAlternativeALlternativeName"
          label="Alternative First Name"
          placeholder="Alternative First Name"
        />
      </Grid>
      <Grid item xs={2} sm={4} md={4}>
        <InputField
          control={control}
          name="receiverAlternativeAlternateMidName"
          label="Alternative Middle Name"
          placeholder="Alternative Middle Name"
        />
      </Grid>
      <Grid item xs={2} sm={4} md={4}>
        <InputField
          control={control}
          name="receiverAlternativeAlternateLastName"
          label="Alternative Last Name"
          placeholder="Alternative Last Name"
        />
      </Grid>
      <Grid item xs={2} sm={4} md={4}>
        <EmailField
          control={control}
          name="receiverAlternativeAlternativeEmail"
          label="Email"
          placeholder="Email"
        />
      </Grid>
      <Grid item xs={2} sm={4} md={4}>
        <InputField
          control={control}
          name="receiverAlternativeAlternativeAddress"
          label="Alternative Address"
          placeholder="Alternative address"
        />
      </Grid>
    </Grid>
  )
}
