import React from 'react'
import { Grid, Alert } from '@mui/material'

export default function AlertBar(props) {
  const { type, message, variant = 'filled' } = props

  const severity = {
    error: 'error',
    info: 'info',
    success: 'success',
    warning: 'warning',
  }

  return (
    <>
      {message && (
        <Grid item xs={12} sx={{ mb: 3 }}>
          <Alert style={{ marginTop: 8 }} variant={variant} severity={severity[type]}>
            {message}
          </Alert>
        </Grid>
      )}
    </>
  )
}
