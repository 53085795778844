import React from 'react'
import { styled } from '@mui/material/styles'
import { useTheme } from '@mui/material/styles'
import useResponsive from '../../hooks/useResponsive'
import {
  Box,
  AppBar,
  Toolbar,
  // Link,
  Typography,
  // Divider,
  Stack,
  // Button,
  useMediaQuery,
} from '@mui/material'
import LoginForm from './auth-forms/login-form'
import LanguagePopover from '../../layouts/header/LanguagePopover'

const APP_BAR_MOBILE = 64
const APP_BAR_DESKTOP = 42

const StyledRoot = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
})

const AuthBackground = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  // paddingTop: APP_BAR_MOBILE + 24,
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  backgroundColor: theme.palette.background.secondary,
  // backgroundImage: `url(/background/BackgroundMobile.svg)`,
  backgroundSize: 'cover',
  backgroundAttachment: 'fixed',
  // paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('sm')]: {
    // paddingTop: APP_BAR_DESKTOP + 24,
    // paddingLeft: theme.spacing(2),
    // paddingRight: theme.spacing(2),
    backgroundImage: `url(/assets/auth/Background.svg)`,
  },
}))

export default function DashboardLayout() {
  const theme = useTheme()
  const mdUp = useResponsive('up', 'md')
  const mdDown = useMediaQuery(theme.breakpoints.down('md'))
  const blur = 9

  const StyledSection = styled('div')(() => ({
    width: '100%',
    height: '85%',
    maxWidth: 725,
    display: 'flex',
    marginLeft: 'auto',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: mdDown && 'center',
  }))

  const StyledContent = styled('div')(({ theme }) => ({
    gap: 15,
    maxWidth: 480,
    borderRadius: 30,
    // margin: "20px 0px",
    // marginLeft: "auto",
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    backdropFilter: `blur(${blur}px)`,
    minHeight: mdUp ? '75vh' : '50vh',
    backgroundColor: 'rgba(39,44,83,.5)',
    border: `2px solid ${theme.palette.background.popup}`,
    padding: mdUp ? theme.spacing(4, 5) : theme.spacing(2, 1),
  }))

  const StyledToolbar = styled(Toolbar)(({ theme }) => ({
    boxShadow: 'none',
    justifyContent: 'flex-end',
    background: 'transparent',
    padding: theme.spacing(2, 5),
    // [theme.breakpoints.up("lg")]: {
    // width: `calc(100% - ${NAV_WIDTH + 1}px)`,
    // },
  }))

  return (
    <StyledRoot>
      <AuthBackground>
        <StyledToolbar>
          <LanguagePopover />
        </StyledToolbar>
        <StyledSection>
          <StyledContent>
            <Box>
              <img
                src={'/logo/logo.svg'}
                alt="logo"
                width={mdDown ? 80 : 100}
                height={mdDown ? 60 : 92}
              />
            </Box>
            <Stack alignItems="center" gap="20px">
              <Typography variant="H3_Bold">agent panel log in </Typography>
              <Typography variant="Paragraph_lg_Light" color="text.secondary">
                You connected from 183.89.246.116
              </Typography>
            </Stack>
            <LoginForm />
          </StyledContent>
        </StyledSection>
      </AuthBackground>
    </StyledRoot>
  )
}
