import React from 'react'
import { Controller } from 'react-hook-form'
import { NumericFormat } from 'react-number-format'
import { InputLabel, Typography } from '@mui/material'
import { numberFieldValidator } from './validators'
import Input from '../../components/common/Input'
import NumericInput from '../../components/common/NumericInputProps'

const NumericField = ({
  control,
  disableErrorSpace,
  currencytype,
  name,
  required,
  label,
  sx,
  ...restProps
}) => (
  <Controller
    render={({ field: { ref, ...restField }, fieldState: { error } }) => {
      return (
        <>
          <InputLabel htmlFor={name}>
            <Typography
              color={error ? 'text.error' : 'text.primary'}
              variant="Paragraph_sm_Light"
              component="div"
              sx={{ mb: 1 }}
            >{`${label ? `${label}${required ? ' *' : ''}` : ''}`}</Typography>
          </InputLabel>
          <NumericFormat
            {...restField}
            {...restProps}
            inputRef={ref}
            error={!!error}
            decimalScale={2}
            thousandSeparator={currencytype ? true : false}
            customInput={currencytype ? NumericInput : Input}
            //" " Space reserves space under for error message
            helperText={error?.message || (disableErrorSpace ? '' : ' ')}
            // format="##-###"
            mask="_"
            InputProps={{
              endAdornment: (
                <div
                  style={{
                    display: !currencytype && 'none',
                    position: 'absolute',
                    right: currencytype && '0px',
                    padding: currencytype && 20,
                    textAlign: 'center',
                    width: 86,
                    height: 56,
                    borderRadius: 20,
                    border: `2px solid #444A81`,
                    backgroundColor: '#444A81',
                  }}
                >
                  {currencytype}
                </div>
              ),
            }}
            sx={{
              width: '100%',
              ...sx,
            }}
          />
        </>
      )
    }}
    name={name}
    control={control}
    rules={{
      validate: numberFieldValidator(required),
      required: required ? 'Required' : '',
    }}
  />
)

export default NumericField
