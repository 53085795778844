import React, { useEffect, useCallback, useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import { isNil } from 'lodash'
import { Box, Typography, Grid } from '@mui/material'
import FileUpload from '../../../../components/common/UploadFiles'
import AutocompleteField from '../../../../components/common/AutocompleteField'
import { apiMasterDocumentType } from '../../../../api/master'

const UploadDocumentTypeFiles = ({ control }) => {
  const { classes } = useStyles()
  const [doctypeList, setDoctypeList] = useState([])

  const fetchDocumentTypeList = useCallback(async () => {
    const response = await apiMasterDocumentType()
    if (response?.code !== 0 || !isNil(response?.error)) {
      return
    }
    const options = response?.data?.map((e) => {
      return { label: e?.text, value: e?.value }
    })

    setDoctypeList(options)
  }, [])

  useEffect(() => {
    fetchDocumentTypeList()
  }, [fetchDocumentTypeList])

  return (
    <div>
      <Grid container justifyContent="flex-start" className={classes['& .MuiGrid-root']}>
        <Grid item sm={8}>
          <AutocompleteField
            control={control}
            name="type_id"
            label="Document Type"
            placeholder="Select Document Type"
            options={doctypeList}
            disableClearable
          />
        </Grid>
      </Grid>
      <Box className={classes.fileUpload}>
        <Typography variant="Paragraph_md_Bold">Front side</Typography>
        <FileUpload limit={1} multiple={false} name="front_side_img" />
        <Typography variant="Paragraph_md_Bold">Back side</Typography>
        <FileUpload limit={1} multiple={false} name="back_side_img" />
      </Box>
    </div>
  )
}

export default UploadDocumentTypeFiles

const useStyles = makeStyles()((theme) => ({
  '& .MuiGrid-root': {
    padding: '16px 20px',
    margin: 'initial',
    [theme.breakpoints.up('md')]: {
      padding: '0px 80px',
    },
  },

  fileUpload: {
    [theme.breakpoints.up('md')]: {
      width: 640,
      margin: 'auto',
    },

    span: {
      padding: '16px 20px 0px',
      margin: 'initial',
      [theme.breakpoints.up('md')]: {
        padding: '0px 80px',
      },
    },
  },
}))
