import { Container, Grid, Typography } from '@mui/material'
import React from 'react'
import { Helmet } from 'react-helmet-async'
import QuickFilter from './all-transaction/quickFilter'
import Hold from './all-transaction/hold'
import { makeStyles } from 'tss-react/mui'

export default function History() {
  const classes = useStyles()

  return (
    <React.Fragment>
      <Helmet>
        <title> History </title>
      </Helmet>
      <Container>
        <Grid container spacing={{ xs: 1, sm: 2 }} columns={{ xs: 1, sm: 8, md: 8 }}>
          <Grid item xs={6} spacing={3}>
            <Hold></Hold>
          </Grid>
          <Grid item xs={2} spacing={3}>
            <QuickFilter />
          </Grid>
          <Grid item xs={8} spacing={3}></Grid>
          <Grid item xs={8} spacing={3}></Grid>
          <Grid item xs={8} spacing={3}></Grid>
          <Grid item xs={8} spacing={3}></Grid>
        </Grid>
      </Container>
    </React.Fragment>
  )
}

const useStyles = makeStyles()((theme) => ({
  paper: {
    borderRadius: 25,
    backgroundColor: theme.palette.background.dark,
    border: `1px solid ${theme.palette.background.light}`,
    padding: '22px',
  },
}))
