import { types } from './action'

const INITIAL_STATE = {
  banks: [],
  sender_type: [],
  account_type: [],
  genders: [],
  id_types: [],
  countries: [],
  visa_types: [],
  fieldvalidate: [],
  occupations: [],
  sourceoffund: [],
  relationships: [],
  test_questions: [],
  receiver_detail: {},
  customer_detail: {},
  transaction_type: [],
  receiver_delivery: {},
  calculate_modes: [],
  kyc_modes: [],
  purpose_of_remittances: [],
  selected_transaction_type: [],
  selected_modeofremittance: [],
  display_servicecharge: {},
  process_sessionID: '',
  modeofremittance: [],
  beneficountries: [],
  doctype: [],
  receiver_fieldvalidate: [],
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case types.FIELD_RULES:
      return { ...state, fieldvalidate: payload }
    case types.OPTION_BANK:
      return { ...state, banks: payload }
    case types.OPTION_GENDER:
      return { ...state, genders: payload }
    case types.OPTION_VISA_TYPE:
      return { ...state, visa_types: payload }
    case types.OPTION_ID_TYPE:
      return { ...state, id_types: payload }
    case types.OPTION_COUNTRY:
      return { ...state, countries: payload }
    case types.RECEIVER_DETAIL:
      return { ...state, receiver_detail: payload }
    case types.OPTION_SENDER_TYPE:
      return { ...state, sender_type: payload }
    case types.OPTION_ACCOUNT_TYPE:
      return { ...state, account_type: payload }
    case types.OPTION_RELATIONSHIP:
      return { ...state, relationships: payload }
    case types.OPTION_SOF:
      return { ...state, sourceoffund: payload }
    case types.OPTION_TEST_QUESTION:
      return { ...state, test_questions: payload }
    case types.OPTION_OCCUPATION:
      return { ...state, occupations: payload }
    case types.OPTION_TRANSACTION_TYPE:
      return { ...state, transaction_type: payload }
    case types.CUSTOMER_DETAIL:
      return { ...state, customer_detail: payload }
    case types.SELECTED_TRANSACTION_TYPE:
      return { ...state, selected_transaction_type: payload }
    case types.RECEIVER_DELIVERY:
      return { ...state, receiver_delivery: payload }
    case types.OPTION_CALCULATE_MODE:
      return { ...state, calculate_modes: payload }
    case types.OPTION_KYC_MODE:
      return { ...state, kyc_modes: payload }
    case types.DISPLAY_SERVICE_CHARGE:
      return { ...state, display_servicecharge: payload }
    case types.OPTION_PURPOSE_OF_REMITTANCE:
      return { ...state, purpose_of_remittances: payload }
    case types.PROCESS_SESSION_ID:
      return { ...state, process_sessionID: payload }
    case types.OPTION_DOCTYPE:
      return { ...state, doctype: payload }
    case types.SELECTED_MODEOFREMITTANCE:
      return { ...state, selected_modeofremittance: payload }
    case types.OPTION_MODEOFREMITTANCE:
      return { ...state, modeofremittance: payload }
    case types.OPTION_BENEFICOUNTRY:
      return { ...state, beneficountries: payload }
    case types.RECEIVER_FIELD_RULES:
      return { ...state, receiver_fieldvalidate: payload }

    default:
      return state
  }
}
