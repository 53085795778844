import React from 'react'
import { makeStyles } from 'tss-react/mui'
import { Controller } from 'react-hook-form'
import {
  Checkbox,
  FormControlLabel,
  FormControl,
  FormHelperText,
  Typography,
} from '@mui/material'

const useStyles = makeStyles()((theme) => ({
  labelName: {
    marginRight: 1,
    justifyContent: 'center',
    '& .MuiCheckbox-root': {
      padding: 0,
      width: 24,
      height: 24,
    },
  },
}))

const LabeledCheckbox = ({ control, checked, name, label, required, ...restProps }) => {
  const { classes } = useStyles()

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { ref, value, ...restField }, fieldState: { error } }) => {
        return (
          <FormControl required={required} error={!!error} component="fieldset">
            <FormControlLabel
              className={classes.labelName}
              control={
                <Checkbox
                  //Focus on error is not visible
                  {...restField}
                  {...restProps}
                  checked={checked}
                  inputRef={ref}
                  icon={<img alt="unckeck" src={`/assets/icons/icon_uncheckbox.svg`} />}
                  checkedIcon={
                    <img alt="ckeck" src={`/assets/icons/icon_checkbox.svg`} />
                  }
                />
              }
              label={
                <Typography ml={1} variant="Paragraph_lg_Light">
                  {label ? `${label}${required ? '*' : ''}` : ''}
                </Typography>
              }
            />
            <FormHelperText sx={{ mx: 0 }}>{error?.message}</FormHelperText>
          </FormControl>
        )
      }}
      rules={{
        required: required ? 'Required' : '',
      }}
    />
  )
}
export default LabeledCheckbox
