const config = [
  { name: 'Dashboard', link: '/dashboard', activeIndex: 0 },
  {
    name: 'Send Money',
    link: '/send-money',
    activeIndex: 1,
  },
  { name: 'Import', link: '/import', activeIndex: 2 },
  { name: 'History', link: '/history', activeIndex: 3 },
  { name: 'Customer', link: '/customer', activeIndex: 4 },
]

export default config
