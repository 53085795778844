import Router from './routes/routes'
import ThemeProvider from './theme'
import ScrollToTop from './components/scroll-to-top/scroll-to-top'
import ReactLocalization from './hooks/useLocalize'

export default function App() {
  return (
    <ThemeProvider>
      <ScrollToTop />
      <ReactLocalization>
        <Router />
      </ReactLocalization>
    </ThemeProvider>
  )
}
