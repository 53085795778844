import { Box, FormControl, InputAdornment, OutlinedInput } from '@mui/material'
import SvgColor from '../../components/svg-color/svg-color'
import { styled } from '@mui/material/styles'

const StyledSearchbar = styled('div')(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    padding: '1px 22px',
    borderRadius: '30px',
    background: 'transparent',
    border: `1px solid ${theme.palette.outline.default}`,
    '& .MuiOutlinedInput-notchedOutline': {
      border: `none`,
    },
  },
}))

const Search = (props) => {
  return (
    <Box sx={{ ml: { xs: 0, md: 1 } }}>
      <FormControl sx={{ width: { xs: '100%', md: 224 } }}>
        <StyledSearchbar>
          <OutlinedInput
            size="small"
            id="header-search"
            startAdornment={
              <InputAdornment position="start">
                <SvgColor
                  src={`/assets/icons/icon_search.svg`}
                  sx={{ color: 'outline.secondary' }}
                />
              </InputAdornment>
            }
            aria-describedby="header-search-text"
            inputProps={{
              'aria-label': 'weight',
            }}
            placeholder="Search"
            {...props}
          />
        </StyledSearchbar>
      </FormControl>
    </Box>
  )
}

export default Search
