import React from 'react'
import { Grid, Stack } from '@mui/material'
import CardExpand from '../../../components/common/CardExpand'
import ReceiverTypeContainer from './receiver-type'
import ReceiverDetailsContainer from './receiver-details'
import ReceiverAddressDetailsContainer from './receiver-address-details'
import ReceiverPaymentDetailsContainer from './receiver-payment-details'

export default function ReceiverDetailsTab(props) {
  return (
    <div>
      <Stack spacing={3}>
        {props.receiverInfo === undefined ? (
          <Grid item sm={6}>
            <CardExpand
              title="Select receiver type to register new receiver"
              defaultOpen={true}
            >
              <ReceiverTypeContainer {...props} />
            </CardExpand>
          </Grid>
        ) : (
          <></>
        )}
        <Grid item sm={6}>
          <CardExpand
            title={
              'Receiver details : ' +
              (props.receiverInfo?.receiverdetailModel?.receiversno !== undefined
                ? props.receiverInfo?.receiverdetailModel?.receiversno
                : '')
            }
            defaultOpen={true}
          >
            <ReceiverDetailsContainer {...props} />
          </CardExpand>
        </Grid>
        <Grid item sm={6}>
          <CardExpand
            title="Receiver Address details"
            defaultOpen={props.receiverInfo !== undefined ? false : true}
          >
            <ReceiverAddressDetailsContainer {...props} />
          </CardExpand>
        </Grid>
        <Grid item sm={6}>
          <CardExpand
            title="Receiver Payment Information"
            defaultOpen={props.receiverInfo !== undefined ? false : true}
          >
            <ReceiverPaymentDetailsContainer {...props} />
          </CardExpand>
        </Grid>
      </Stack>
    </div>
  )
}
