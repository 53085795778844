import React, { useState, useEffect } from 'react'
import { Grid } from '@mui/material'
import { isNil } from 'lodash'
import RadioField from '../../../components/common/RadioField'
import { apiGetCustomerTypeList } from '../../../api/master'

export default function ReceiverType(props) {
  const { control, setNotify } = props

  const [receiverTypeRecords, setReceiverTypeRecords] = useState([])

  const fetchReceiverTypeList = async () => {
    const response = await apiGetCustomerTypeList()
    if (response?.code !== 0 || !isNil(response?.error)) {
      setNotify({
        open: true,
        message: response?.message,
        type: 'error',
      })
      return
    }
    const optionReceiverType = response?.data?.map((e) => {
      return { label: e?.text, value: e?.value }
    })
    setReceiverTypeRecords(optionReceiverType)
  }

  useEffect(() => {
    fetchReceiverTypeList()
  }, [])

  return (
    <Grid container spacing={{ xs: 1, sm: 2 }} columns={{ xs: 1, sm: 8, md: 8 }}>
      <Grid item xs={2} sm={4} md={4}>
        <RadioField
          control={control}
          name="receiverType"
          label="Select Receiver Type"
          options={receiverTypeRecords}
          required
        />
      </Grid>
    </Grid>
  )
}
