import React from 'react'
import { Backdrop, CircularProgress } from '@mui/material'

export default function useBackdrop(props) {
  const { openBackdrop } = props

  return (
    <>
      <Backdrop
        // sx={{
        //   color: '#fff',
        //   position: 'absolute',
        //   zIndex: 99999,
        // // }}
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  )
}
