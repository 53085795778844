import Menu from './Menu'
import Card from './Card'
import Alert from './Alert'
import Paper from './Paper'
import Input from './Input'
import Table from './Table'
import Select from './Select'
import Button from './Button'
import Tooltip from './Tooltip'
import Popover from './Popover'
import Backdrop from './Backdrop'
import Typography from './Typography'
import TablePagin from './TablePagin'
import Autocomplete from './Autocomplete'
export default function ComponentsOverrides(theme) {
  return Object.assign(
    Menu(theme),
    Card(theme),
    Alert(theme),
    Paper(theme),
    Table(theme),
    Select(theme),
    Input(theme),
    Button(theme),
    Tooltip(theme),
    Popover(theme),
    Backdrop(theme),
    Typography(theme),
    TablePagin(theme),
    Autocomplete(theme),
  )
}
