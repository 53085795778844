export const transactionStatusColorMap = {
  Hold: {
    status: 'hold',
    text: 'Hold',
  },
  Paid: {
    status: 'paid',
    text: 'Paid',
  },
  Post: {
    status: 'post',
    text: 'Post',
  },
  Cancel: {
    status: 'cancel',
    text: 'Cancel',
  },
  CancelHold: {
    status: 'cancel',
    text: 'Cancel',
  },
}
