import React from 'react'
import { TextField } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()((theme) => ({
  searchInput: {
    '& .MuiOutlinedInput-root': {
      paddingRight: '1px',
      paddingLeft: '22px',
      borderRadius: '30px',
      background: 'transparent',
      border: `1px solid ${theme.palette.outline.default}`,
      '& .MuiOutlinedInput-notchedOutline': {
        border: `none`,
      },
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0px 8px',
    },
  },
}))

export default function InputSearchFilter(props) {
  const { id, name, label, value, error = null, onChange, ...other } = props
  const { classes } = useStyles()

  return (
    <TextField
      id={id}
      name={name}
      value={value}
      onChange={onChange}
      className={classes.searchInput}
      fullWidth
      inputProps={{
        autoComplete: 'off',
      }}
      {...other}
      {...(error && { error: true, helperText: error })}
    />
  )
}
