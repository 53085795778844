export const types = {
  PREVIEW_TRANSACTION: 'PREVIEW_TRANSACTION',
}

export const fetchPreviewSendTransaction = (data) => {
  return {
    type: types.PREVIEW_TRANSACTION,
    payload: data,
  }
}
