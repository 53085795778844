import React, { useState, useEffect } from 'react'
import { Grid } from '@mui/material'
import { isNil } from 'lodash'
import AutocompleteField from '../../../components/common/AutocompleteField'
import EmailField from '../../../components/common/EmailField'
import InputField from '../../../components/common/InputField'
import RadioField from '../../../components/common/RadioField'
import DateField from '../../../components/common/DateField'
import { apiMasterCountryList, apiGetGenderList } from '../../../api/master'
import {
  apiGetOccupationList,
  apiGetPurposeOfRemittanceList,
  apiGetRelationshipList,
} from '../../../api/customer'

export default function ReceiverDetails(props) {
  const { control, setNotify, useWatch } = props

  const [countryRecords, setCountryRecords] = useState([])
  const [genderRecords, setGenderRecords] = useState([])
  const [occupationRecords, setOccupationRecords] = useState([])
  const [purposeOfRemittanceRecords, setPurposeOfRemittanceRecords] = useState([])
  const [relationshipRecords, setRelationshipRecords] = useState([])

  const fetchCountryList = async () => {
    const response = await apiMasterCountryList()
    if (response?.code !== 0 || !isNil(response?.error)) {
      setNotify({
        open: true,
        message: response?.message,
        type: 'error',
      })
      return
    }
    const optionCountry = response?.data?.map((e) => {
      return { label: e?.text, value: e?.value }
    })
    setCountryRecords(optionCountry)
  }

  const fetchGenderList = async () => {
    const response = await apiGetGenderList()
    if (response?.code !== 0 || !isNil(response?.error)) {
      setNotify({
        open: true,
        message: response?.message,
        type: 'error',
      })
      return
    }
    const optionGender = response?.data?.map((e) => {
      return { label: e?.text, value: e?.value }
    })
    setGenderRecords(optionGender)
  }

  const fetchOccupationList = async () => {
    const response = await apiGetOccupationList()
    if (response?.code !== 0 || !isNil(response?.error)) {
      setNotify({
        open: true,
        message: response?.message,
        type: 'error',
      })
      return
    }
    const optionOccupation = response?.data?.map((e) => {
      return { label: e?.text, value: e?.value }
    })
    setOccupationRecords(optionOccupation)
  }

  const fetchPurposeOfRemittanceList = async () => {
    const response = await apiGetPurposeOfRemittanceList()
    if (response?.code !== 0 || !isNil(response?.error)) {
      setNotify({
        open: true,
        message: response?.message,
        type: 'error',
      })
      return
    }
    const optionPurposeOfRemittance = response?.data?.map((e) => {
      return { label: e?.text, value: e?.value }
    })
    setPurposeOfRemittanceRecords(optionPurposeOfRemittance)
  }

  const fetchRelationshipList = async () => {
    const response = await apiGetRelationshipList()
    if (response?.code !== 0 || !isNil(response?.error)) {
      setNotify({
        open: true,
        message: response?.message,
        type: 'error',
      })
      return
    }
    const optionRelationship = response?.data?.map((e) => {
      return { label: e?.text, value: e?.value }
    })
    setRelationshipRecords(optionRelationship)
  }

  useEffect(() => {
    fetchCountryList()
    fetchGenderList()
    fetchOccupationList()
    fetchPurposeOfRemittanceList()
    fetchRelationshipList()
  }, [])

  return (
    <Grid container spacing={{ xs: 1, sm: 2 }} columns={{ xs: 1, sm: 8, md: 8 }}>
      <Grid item xs={2} sm={4} md={4}>
        <InputField
          control={control}
          name="receiverFirstName"
          label="First Name"
          placeholder="First Name"
          required
        />
      </Grid>
      <Grid item xs={2} sm={4} md={4}>
        <InputField
          control={control}
          name="receiverMiddleName"
          label="Middle Name"
          placeholder="Middle Name"
        />
      </Grid>
      <Grid item xs={2} sm={4} md={4}>
        <InputField
          control={control}
          name="receiverLastName"
          label="Last Name"
          placeholder="Last Name"
          required
        />
      </Grid>
      <Grid item xs={2} sm={4} md={4}>
        <RadioField
          control={control}
          name="receiverGender"
          label="Gender"
          options={genderRecords}
          required
        />
      </Grid>
      <Grid item xs={2} sm={4} md={4}>
        <InputField
          control={control}
          name="receiverMobileNo"
          label="Mobile No"
          placeholder="Mobile No"
        />
      </Grid>
      <Grid item xs={2} sm={4} md={4}>
        <EmailField
          control={control}
          name="receiverEmail"
          label="Email"
          placeholder="Email"
        />
      </Grid>
      <Grid item xs={2} sm={4} md={4}>
        <DateField
          control={control}
          name="receiverDateOfBirth"
          label="Date of Birth"
          placeholder="Date of Birth"
        />
      </Grid>
      <Grid item xs={2} sm={4} md={4}>
        <AutocompleteField
          control={control}
          name="receiverNationality"
          label="Nationality"
          placeholder="Select Nationality"
          options={countryRecords}
          required
        />
      </Grid>
      <Grid item xs={2} sm={4} md={4}>
        <AutocompleteField
          control={control}
          name="receiverOccupation"
          label="Occupation"
          placeholder="Select Occupation"
          options={occupationRecords}
        />
      </Grid>
      {useWatch({ control, name: 'receiverOccupation' }) === '99' && (
        <Grid item xs={2} sm={4} md={4}>
          <InputField
            control={control}
            name="receiverOccupationOther"
            label="Other Occupation"
            placeholder="Other Occupation"
            required
          />
        </Grid>
      )}
      <Grid item xs={2} sm={4} md={4}>
        <AutocompleteField
          control={control}
          name="receiverPurposeofremittance"
          label="Purpose of Remittance"
          placeholder="Select Purpose of Remittance"
          options={purposeOfRemittanceRecords}
        />
      </Grid>
      {useWatch({ control, name: 'receiverPurposeofremittance' }) === '99' && (
        <Grid item xs={2} sm={4} md={4}>
          <InputField
            control={control}
            name="receiverPurposeofremittanceother"
            label="Other Purpose of Remittance"
            placeholder="Other Purpose of Remittance"
            required
          />
        </Grid>
      )}
      <Grid item xs={2} sm={4} md={4}>
        <AutocompleteField
          control={control}
          name="receiverRelationship"
          label="Relationship"
          placeholder="Select Relationship"
          options={relationshipRecords}
        />
      </Grid>
      {useWatch({ control, name: 'receiverRelationship' }) === '99' && (
        <Grid item xs={2} sm={4} md={4}>
          <InputField
            control={control}
            name="receiverRelationshipother"
            label="Other Relationship"
            placeholder="Other Relationship"
            required
          />
        </Grid>
      )}
    </Grid>
  )
}
