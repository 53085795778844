import React from 'react'
import { Outlet } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import Header from './header'
import HelmetTitle from '../components/helmet-title/helmet-title'

const APP_BAR_MOBILE = 64
const APP_BAR_DESKTOP = 124

export default function MainLayout() {
  const StyledRoot = styled('div')({
    display: 'flex',
    minHeight: '100%',
    overflow: 'hidden',
  })

  const Main = styled('div')(({ theme }) => ({
    flexGrow: 1,
    overflow: 'auto',
    minHeight: '100%',
    paddingTop: APP_BAR_MOBILE + 24,
    paddingBottom: theme.spacing(10),
    [theme.breakpoints.up('lg')]: {
      paddingTop: APP_BAR_DESKTOP + 24,
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
  }))

  return (
    <StyledRoot>
      <Header />
      <HelmetTitle />
      <Main>
        <Outlet />
      </Main>
    </StyledRoot>
  )
}
