import React, { useState, useEffect } from 'react'
import { Grid } from '@mui/material'
import { isNil } from 'lodash'
import AutocompleteField from '../../../components/common/AutocompleteField'
import InputField from '../../../components/common/InputField'
import { apiMasterAccountTypeList } from '../../../api/master'
import {
  apiGetBeneficiaryCountryList,
  apiGetModeOfRemittanceList,
  apiGetDeliveryOptionList,
  apiGetBankList,
} from '../../../api/customer'

export default function ReceiverPaymentDetails(props) {
  const { control, setNotify, useWatch } = props

  var vCountryOfShipment = useWatch({ control, name: 'receiverPaymentCountryofshipment' })
  var vModeOfRemittance = useWatch({ control, name: 'receiverPaymentModeofremittance' })

  const [beneficiaryCountryRecords, setBeneficiaryCountryRecords] = useState([])
  const [modeOfRemittanceRecords, setModeOfRemittanceRecords] = useState([])
  const [bankRecords, setBankRecords] = useState([])
  const [accountTypeRecords, setAccountTypeRecords] = useState([])

  const fetchBeneficiaryCountryList = async () => {
    const response = await apiGetBeneficiaryCountryList()
    if (response?.code !== 0 || !isNil(response?.error)) {
      setNotify({
        open: true,
        message: response?.message,
        type: 'error',
      })
      return
    }
    const optionBeneficiaryCountry = response?.data?.map((e) => {
      return { label: e?.rec_country, value: e?.country_code }
    })
    setBeneficiaryCountryRecords(optionBeneficiaryCountry)
  }

  const fetchModeOfRemittanceList = async () => {
    if (vCountryOfShipment !== undefined && vCountryOfShipment !== '') {
      const response = await apiGetModeOfRemittanceList(vCountryOfShipment)
      if (response?.code !== 0 || !isNil(response?.error)) {
        setNotify({
          open: true,
          message: response?.message,
          type: 'error',
        })
        return
      }
      const optionModeOfRemittance = response?.data?.map((e) => {
        return { label: e?.deleiveryoption, value: e?.deleiveryoption }
      })
      setModeOfRemittanceRecords(optionModeOfRemittance)
    }
  }

  const fetchBankList = async () => {
    if (
      vCountryOfShipment !== undefined &&
      vCountryOfShipment !== '' &&
      vModeOfRemittance !== undefined &&
      vModeOfRemittance !== ''
    ) {
      const responseDeliveryOption = await apiGetDeliveryOptionList(
        vCountryOfShipment,
        vModeOfRemittance,
      )
      if (responseDeliveryOption?.code !== 0 || !isNil(responseDeliveryOption?.error)) {
        setNotify({
          open: true,
          message: responseDeliveryOption?.message,
          type: 'error',
        })
        return
      }
      if (responseDeliveryOption?.data.length < 0) {
        setNotify({
          open: true,
          message: 'Delivery Option not found',
          type: 'error',
        })
        return
      }
      const response = await apiGetBankList(
        vModeOfRemittance,
        responseDeliveryOption?.data[0]?.receiveragentid,
        vCountryOfShipment,
      )
      if (response?.code !== 0 || !isNil(response?.error)) {
        setNotify({
          open: true,
          message: response?.message,
          type: 'error',
        })
        return
      }
      const optionBank = response?.data?.map((e) => {
        return { label: e?.bankname, value: e?.commercialbank }
      })
      setBankRecords(optionBank)
    }
  }

  const fetchAccountTypeList = async () => {
    const response = await apiMasterAccountTypeList()
    if (response?.code !== 0 || !isNil(response?.error)) {
      setNotify({
        open: true,
        message: response?.message,
        type: 'error',
      })
      return
    }
    const optionAccountType = response?.data?.map((e) => {
      return { label: e?.text, value: e?.value }
    })
    setAccountTypeRecords(optionAccountType)
  }

  useEffect(() => {
    fetchBeneficiaryCountryList()
    fetchAccountTypeList()
  }, [])

  useEffect(() => {
    fetchBankList()
  }, [vCountryOfShipment, vModeOfRemittance])

  useEffect(() => {
    fetchModeOfRemittanceList()
  }, [vCountryOfShipment])

  return (
    <Grid container spacing={{ xs: 1, sm: 2 }} columns={{ xs: 1, sm: 8, md: 8 }}>
      <Grid item xs={2} sm={4} md={4}>
        <AutocompleteField
          control={control}
          name="receiverPaymentCountryofshipment"
          label="Receiver Country"
          placeholder="Select receiver country"
          options={beneficiaryCountryRecords}
          required
        />
      </Grid>
      <Grid item xs={2} sm={4} md={4}>
        <AutocompleteField
          control={control}
          name="receiverPaymentModeofremittance"
          label="Mode of remittance"
          placeholder="Select mode of remittance"
          options={modeOfRemittanceRecords}
          required
        />
      </Grid>
      <Grid item xs={2} sm={4} md={4}>
        <AutocompleteField
          control={control}
          name="receiverPaymentBankList"
          label="Bank"
          placeholder="Select bank"
          options={bankRecords}
        />
      </Grid>
      <Grid item xs={2} sm={4} md={4}>
        <AutocompleteField
          control={control}
          name="receiverPaymentReceiverAccounttype"
          label="Account Type"
          placeholder="Select account type"
          options={accountTypeRecords}
        />
      </Grid>
      <Grid item xs={2} sm={4} md={4}>
        <InputField
          control={control}
          name="receiverPaymentBranchname"
          label="Branch"
          placeholder="Branch"
        />
      </Grid>
      <Grid item xs={2} sm={4} md={4}>
        <InputField
          control={control}
          name="receiverPaymentReceiverAc"
          label="Account Number"
          placeholder="Account Number"
        />
      </Grid>
    </Grid>
  )
}
