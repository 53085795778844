import React from 'react'
import { Stack, TextField } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()((theme) => ({
  NumericInputStyle: {
    '& .MuiOutlinedInput-root': {
      '&.Mui-disabled': {
        boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.25)',
      },
      '& .MuiOutlinedInput-input': {
        padding: '12px 17px',
        fontWeight: 700,
        fontSize: '24px',
        lineHeight: 'normal',
        letterSpacing: '0.045px',
        textAlign: 'center',
        color: theme.palette.text.primary,
        [theme.breakpoints.up('sm')]: {
          marginRight: 64,
          textAlign: 'end',
        },
        '&:hover': {
          color: theme.palette.text.secondary,
        },
      },
    },
  },
}))

export default function NumericInput(props) {
  const { classes } = useStyles()
  const { id, name, label, value, error = null, onChange, ...other } = props

  return (
    <Stack className={classes?.NumericInputStyle}>
      <TextField
        fullWidth
        id={id}
        name={name}
        value={value}
        onChange={onChange}
        inputProps={{
          autoComplete: 'new-password',
        }}
        {...other}
        {...(error && { error: true, helperText: error })}
      />
    </Stack>
  )
}
