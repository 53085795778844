import { Calendar, DateRangePicker } from 'react-date-range'
import {
  addDays,
  endOfDay,
  startOfDay,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  addMonths,
  startOfYear,
  endOfYear,
  addYears,
  isSameDay,
  differenceInCalendarDays,
} from 'date-fns'
import { Box } from '@mui/material'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import { useHistoryStyles } from '../../containers/history/historyStyles'

export default function CalendarPicker(propsPagin) {
  return (
    <Calendar
      showMonthAndYearPickers={true}
      months={1}
      direction="horizontal"
      weekdayDisplayFormat={`EEEEEE`}
      {...propsPagin}
    />
  )
}

export function CalendarRangePicker(propsPagin) {
  const { classes } = useHistoryStyles()
  const defineds = {
    startOfToday: startOfDay(new Date()),
    endOfToday: endOfDay(new Date()),
    startOfWeek: startOfWeek(new Date()),
    endOfWeek: endOfWeek(new Date()),
    startOfMonth: startOfMonth(new Date()),
    endOfMonth: endOfMonth(new Date()),
    startOf3Month: startOfMonth(addMonths(new Date(), -3)),
    endOf3Month: endOfDay(new Date()),
    startOf6Month: startOfMonth(addMonths(new Date(), -6)),
    endOf6Month: endOfDay(new Date()),
    startOfYear: startOfYear(new Date()),
    endOfYear: endOfDay(new Date()),
  }

  const staticRangeHandler = {
    range: {},
    isSelected(range) {
      const definedRange = this.range()
      return (
        isSameDay(range.startDate, definedRange.startDate) &&
        isSameDay(range.endDate, definedRange.endDate)
      )
    },
  }

  function createStaticRanges(ranges) {
    return ranges.map((range) => ({ ...staticRangeHandler, ...range }))
  }

  const defaultStaticRanges = createStaticRanges([
    {
      label: 'Today',
      range: () => ({
        startDate: defineds.startOfToday,
        endDate: defineds.endOfToday,
      }),
    },
    {
      label: 'This Week',
      range: () => ({
        startDate: defineds.startOfWeek,
        endDate: defineds.endOfWeek,
      }),
    },
    {
      label: 'This Month',
      range: () => ({
        startDate: defineds.startOfMonth,
        endDate: defineds.endOfMonth,
      }),
    },
    {
      label: '3 Months',
      range: () => ({
        startDate: defineds.startOf3Month,
        endDate: defineds.endOf3Month,
      }),
    },
    {
      label: '6 Months',
      range: () => ({
        startDate: defineds.startOf6Month,
        endDate: defineds.endOf6Month,
      }),
    },
    {
      label: 'This Year',
      range: () => ({
        startDate: defineds.startOfYear,
        endDate: defineds.endOfYear,
      }),
    },
  ])
  return (
    <Box sx={classes.calendarPaper}>
      <DateRangePicker
        showSelectionPreview={true}
        moveRangeOnFirstSelection={false}
        showMonthAndYearPickers={true}
        months={1}
        minDate={startOfYear(addYears(new Date(), -5))}
        maxDate={endOfDay(new Date())}
        direction="horizontal"
        staticRanges={defaultStaticRanges}
        //rangeColors={['#444A81']}
        rangeColors={['#F2AF7B']}
        inputRanges={[]}
        showDateDisplay={false}
        weekdayDisplayFormat={`EEEEEE`}
        {...propsPagin}
      />
    </Box>
  )
}
