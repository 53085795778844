export default function Autocomplete(theme) {
  return {
    MuiSelect: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
  }
}
