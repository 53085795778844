import React, { useState, useEffect } from 'react'
import { Card, CardHeader, CardContent } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import Expand from 'react-expand-animated'
import IconBtn from '../common/IconBtn'

export default function CardExpand(props) {
  const { classes } = useStyles()
  const { title, children, defaultOpen } = props
  const [open, setOpen] = useState(null)
  const transitions = ['height', 'opacity', 'background']

  const toggle = () => {
    setOpen(!open)
  }

  useEffect(() => {
    if (defaultOpen !== undefined) {
      setOpen(defaultOpen)
    }
  }, [defaultOpen])

  return (
    <Card className={classes.root} elevation={0}>
      <CardHeader
        action={
          <IconBtn
            onClick={toggle}
            alt="expand"
            src={
              open
                ? `/assets/icons/icon_minusexpands.svg`
                : `/assets/icons/icon_addexpands.svg`
            }
          />
        }
        title={title}
      />
      <Expand open={open} duration={500} transitions={transitions}>
        <CardContent>{children}</CardContent>
      </Expand>
    </Card>
  )
}

const useStyles = makeStyles()((theme) => ({
  root: {
    '& .MuiCardHeader-root': {
      padding: '30px 36px 30px',
    },

    '& .MuiCardContent-root': {
      justifyContent: 'space-between',
      padding: '0px 36px 40px 36px',
      [theme.breakpoints.up('sm')]: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
      },
      '& .MuiGrid-root': {
        // marginLeft: -4,
        justifyContent: 'space-between',
        '& .MuiTableRow-root.Mui-selected': {
          backgroundColor: theme.palette.outline.default,
        },

        '& .MuiTableRow-root td': {
          [theme.breakpoints.up('sm')]: {
            borderTop: ` 2px solid ${theme.palette.outline.default}`,
            borderBottom: ` 2px solid ${theme.palette.outline.default}`,
          },
        },

        '& .MuiTableRow-root td:first-of-type': {
          borderTopLeftRadius: 30,
          borderBottomLeftRadius: 30,

          [theme.breakpoints.up('sm')]: {
            padding: '20px 0px 20px 20px',
            borderTop: ` 2px solid ${theme.palette.outline.default}`,
            borderBottom: ` 2px solid ${theme.palette.outline.default}`,
            borderLeft: ` 2px solid ${theme.palette.outline.default}`,
          },

          [theme.breakpoints.down('sm')]: {
            paddingTop: 30,
          },
        },

        '& .MuiTableRow-root td:last-child': {
          [theme.breakpoints.up('sm')]: {
            borderRight: ` 2px solid ${theme.palette.outline.default}`,
            borderTopRightRadius: 30,
            borderBottomRightRadius: 30,
          },
          [theme.breakpoints.down('sm')]: {
            paddingBottom: 30,
          },
        },
      },
    },

    '& .MuiContainer-root': {
      padding: 0,
    },
  },
}))
