import React from 'react'
import { Box } from '@mui/material'
import { motion } from 'framer-motion'

const Loading = ({ loading }) => {
  const variants = {
    initial: { x: 0 },

    final: {
      rotate: '360deg',

      transition: {
        duration: 2,
        repeat: Infinity,
        ease: 'linear',
      },
    },
  }
  return (
    loading && (
      <Box
        sx={{
          my: 6,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <motion.div variants={variants} initial="initial" animate="final">
          <img
            alt="loading icon"
            src={`/assets/icons/icon_refresh.svg`}
            style={{ width: 28, height: 28, color: 'outline.primary' }}
          />
        </motion.div>
      </Box>
    )
  )
}

export default Loading
