import React from 'react'
import { makeStyles } from 'tss-react/mui'
import { Grid, Paper, Typography, TextField } from '@mui/material'
import { NumericFormat } from 'react-number-format'
import { experimentalStyled as styled } from '@mui/material/styles'
import { fNumber } from '../../../utils/formatNumer'

const DisplayServiceCharge = ({ data, cType, paidCType }) => {
  return (
    <Grid
      container
      columns={{ xs: 2, sm: 8, md: 8 }}
      sx={{
        my: 4,
        borderRadius: 5,
        backgroundColor: (theme) => theme.palette.background.popup,
        border: (theme) => `2px solid ${theme.palette.background.popup}`,
      }}
    >
      <Grid item xs={8}>
        <ItemBox>
          <Typography variant="Paragraph_lg_Light" component={'p'}>
            Collected Amount :
          </Typography>
          <Typography variant="Paragraph_lg_Bold" component={'div'}>
            {fNumber(data?.collect_Amount) + ' ' + cType}
          </Typography>
        </ItemBox>
        <ItemBox>
          <Typography variant="Paragraph_lg_Light" component={'p'}>
            Transfer Amount :
          </Typography>
          <Typography variant="Paragraph_lg_Bold" component={'div'}>
            {fNumber(data?.payinAmount) + ' ' + cType}
          </Typography>
        </ItemBox>
        <ItemBox>
          <Typography variant="Paragraph_lg_Light" component={'p'}>
            Service Fee :
          </Typography>
          <NumericFormat
            value={`${data?.serviceFee}`}
            decimalScale={2}
            thousandSeparator
            customInput={NumericInput}
            InputProps={{
              endAdornment: <div>{cType}</div>,
            }}
          />
        </ItemBox>
        <ItemBox>
          <Typography variant="Paragraph_lg_Light" component={'p'}>
            Customer Rate :
          </Typography>
          <Typography variant="Paragraph_lg_Bold" component={'div'}>
            {fNumber(data?.customer_Rate) + ' ' + paidCType}
          </Typography>
        </ItemBox>
        <ItemBox>
          <Typography variant="Paragraph_lg_Light" component={'p'}>
            Payout Amount :
          </Typography>
          <Typography variant="H3_Bold" component={'div'}>
            {fNumber(data?.payoutAmount) + ' ' + paidCType}
          </Typography>
        </ItemBox>
      </Grid>
    </Grid>
  )
}

export default DisplayServiceCharge

const ItemBox = styled(Paper)(({ theme }) => ({
  display: 'flex',
  margin: '26px 20px',
  flexDirection: 'column',

  [theme.breakpoints.up('md')]: {
    margin: '26px 80px',
    flexDirection: 'row',
  },
  div: {
    width: '100%',
    marginTop: 8,
    [theme.breakpoints.up('md')]: {
      width: '100%',
      marginTop: 0,
      textAlign: 'right',
      alignItems: 'flex-end',
    },
  },
  p: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      width: '50%',
    },
  },
}))

function NumericInput(props) {
  const { classes } = useStyles()
  const { id, name, label, value, error = null, onChange, ...other } = props

  return (
    <TextField
      fullWidth
      id={id}
      name={name}
      value={value}
      onChange={onChange}
      inputProps={{
        readOnly: true,
      }}
      {...other}
      {...(error && { error: true, helperText: error })}
      className={classes?.NumericStyle}
    />
  )
}

const useStyles = makeStyles()((theme) => ({
  NumericStyle: {
    '& .MuiOutlinedInput-root': {
      width: '222px',
      borderRadius: 30,
      background: theme.palette.outline.primary,
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },
      '&.Mui-disabled': {
        boxShadow: 'none',
      },
      '& .MuiOutlinedInput-input': {
        padding: '12px 17px',
        fontWeight: 700,
        lineHeight: 'normal',
        letterSpacing: '0.045px',
        textAlign: 'center',
        color: theme.palette.text.secondary,
        [theme.breakpoints.up('sm')]: {
          marginRight: 64,
          textAlign: 'end',
        },
      },
      div: {
        margin: 0,
        position: 'absolute',
        right: '-1px',
        padding: '15px',
        textAlign: 'center',
        width: '90px',
        borderRadius: 30,
        border: `1px solid ${theme.palette.text.secondary}`,
        background: theme.palette.text.secondary,
      },
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
  },
}))
