import typography from '../typography'

const typo = typography

export default function Menu(theme) {
  return {
    MuiMenu: {
      styleOverrides: {
        paper: {
          boxShadow: 'none',
          marginTop: 8,
        },

        list: {
          '&[role="listbox"]': {
            backgroundColor: theme.palette.background.cardbg1,
            padding: theme.spacing(1),
            li: {
              marginTop: 8,
            },
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: theme.palette.text.primary,
          ...typo['Paragraph_md_Light'],
          borderRadius: 10,
          '&.Mui-selected': {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.background.popup,
          },
          '&:hover': {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.background.popup,
          },
        },
      },
    },
  }
}
