import React, { useEffect } from 'react'
import {
  Dialog,
  DialogContentText,
  DialogActions,
  DialogTitle,
  Typography,
  Grid,
} from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { useTheme } from '@mui/material/styles'
import Button from '../components/common/Button'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import Spinner from '../components/common/Spinner'
import useResponsive from '../hooks/useResponsive'
import typography from '../theme/typography'

const typo = typography

export default function ConfirmDialog(props) {
  const { confirmDialog, setConfirmDialog } = props
  const { classes } = useStyles()
  const theme = useTheme()
  const isMobile = useResponsive('between', 'xs', 'md')

  const handleClose = () => {
    setConfirmDialog({ ...confirmDialog, successOpen: false })
    confirmDialog?.handlerRefresh()
  }

  useEffect(() => {
    if (confirmDialog?.successOpen === true) {
      setTimeout(() => {
        handleClose()
      }, 3000)
    }
  }, [confirmDialog?.successOpen])

  const DialogSubmitResultMessage = () => {
    return (
      <Dialog
        open={confirmDialog?.isOpen}
        keepMounted
        classes={{
          paper: classes?.dialog,
        }}
      >
        <DialogTitle className={classes?.title}>{confirmDialog?.title}</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => {
            setConfirmDialog({ ...confirmDialog, isOpen: false })
          }}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContentText className={classes?.container}>
          {confirmDialog?.container}
        </DialogContentText>
        {/* <DialogActions className={classes?.action}>
          <Button
            type="button"
            text="cancel"
            color="inherit"
            size="medium"
            variant="outlined"
            onClick={() => {
              setConfirmDialog({ ...confirmDialog, isOpen: false })
            }}
          />
          <Button
            text="confirm"
            size="medium"
            color="success"
            onClick={confirmDialog?.onConfirm}
          />
        </DialogActions> */}
      </Dialog>
    )
  }

  const DialogWithContainer = () => {
    return (
      <Dialog
        open={confirmDialog?.isOpen}
        keepMounted
        classes={{
          paper: classes?.dialog,
        }}
      >
        <DialogTitle className={classes?.title}>{confirmDialog?.title}</DialogTitle>
        <DialogContentText className={classes?.container}>
          {confirmDialog?.container}
        </DialogContentText>
        {/* <DialogActions className={classes?.action}>
          <Button
            type="button"
            text="cancel"
            color="inherit"
            size="medium"
            variant="outlined"
            onClick={() => {
              setConfirmDialog({ ...confirmDialog, isOpen: false })
            }}
          />
          <Button
            text="confirm"
            size="medium"
            color="success"
            onClick={confirmDialog?.onConfirm}
          />
        </DialogActions> */}
      </Dialog>
    )
  }

  const DialogSuccessResultMessage = () => {
    return (
      <Dialog
        open={confirmDialog?.successOpen}
        keepMounted
        classes={{
          paper: classes?.dialog,
        }}
        onClose={() => handleClose()}
        // onClose={setTimeout(() => {
        //   if (confirmDialog?.isOpen === false) return
        //   setConfirmDialog({ ...confirmDialog, isOpen: false })
        // }, 5000)}
        // onClose={(event, reason) => {
        //   if (reason === 'escapeKeyDown' || reason === 'backdropClick') {
        //     setConfirmDialog({ ...confirmDialog, isOpen: false })
        //     //confirmDialog?.handlerRefresh()
        //   }
        // }}
      >
        {/* <IconButton
          aria-label="close"
          onClick={() => {
            setConfirmDialog({ ...confirmDialog, isOpen: false })
          }}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton> */}
        <img
          src={`/assets/icons/create_successfully.svg`}
          alt="successfully"
          style={{
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
            //width: '50%',
          }}
        />
        <DialogTitle className={classes?.titleMessage}>
          {confirmDialog?.title}
        </DialogTitle>
        <DialogContentText className={classes?.subtitleMessage}>
          {confirmDialog?.subTitle}
        </DialogContentText>
        <DialogContentText className={classes?.container}>
          {confirmDialog?.container}
        </DialogContentText>
      </Dialog>
    )
  }

  const DialogSuccessMessage = () => {
    return (
      <Dialog
        open={confirmDialog?.isOpen}
        keepMounted
        classes={{
          paper: classes?.dialog,
        }}
      >
        <img
          src={`/assets/icons/create_successfully.svg`}
          alt="error"
          style={{
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
            width: '50%',
          }}
        />
        <DialogTitle className={classes?.titleMessage}>
          {confirmDialog?.title}
        </DialogTitle>
        <DialogContentText className={classes?.subtitleMessage}>
          {confirmDialog?.subTitle}
        </DialogContentText>
        <DialogActions className={classes?.dialogAction}>
          <Button
            //className={classes?.button}
            type="button"
            text="OK"
            size="medium"
            onClick={() => {
              setConfirmDialog({ ...confirmDialog, isOpen: false })
              confirmDialog?.handlerRefresh()
            }}
          />
        </DialogActions>
      </Dialog>
    )
  }

  const DialogErrorResultMessage = () => {
    return (
      <Dialog
        open={confirmDialog?.isOpen}
        keepMounted
        classes={{
          paper: classes?.dialog,
        }}
      >
        <img
          src={`/assets/icons/icon_error.svg`}
          alt="error"
          style={{
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
            //width: '50%',
          }}
        />
        <DialogTitle className={classes?.titleMessage}>
          {confirmDialog?.title}
        </DialogTitle>
        <DialogContentText className={classes?.subtitleMessage}>
          {confirmDialog?.subTitle}
        </DialogContentText>
        <DialogActions className={classes?.dialogAction}>
          <Button
            //className={classes?.button}
            type="button"
            text="OK"
            size="medium"
            onClick={() => {
              setConfirmDialog({ ...confirmDialog, isOpen: false })
            }}
          />
        </DialogActions>
      </Dialog>
    )
  }

  const DialogDeleteMessage = () => {
    return (
      <Dialog
        open={confirmDialog?.isOpen}
        keepMounted
        classes={{
          paper: classes?.dialog,
        }}
      >
        <img
          className={classes?.alertDelete}
          src={`/assets/icons/icon_delete.svg`}
          alt="error"
        />
        <DialogTitle className={classes?.titleMessage}>
          {confirmDialog?.title}
        </DialogTitle>
        <DialogContentText className={classes?.subtitleMessage}>
          {confirmDialog?.subTitle}
        </DialogContentText>
        <DialogActions className={classes?.dialogAction}>
          <Button
            type="button"
            text="cancel"
            color="inherit"
            size="medium"
            variant="outlined"
            onClick={() => {
              setConfirmDialog({ ...confirmDialog, isOpen: false })
            }}
          />
          <Button
            text="Delete"
            size="medium"
            type="button"
            onClick={confirmDialog?.onConfirm}
          />
        </DialogActions>
      </Dialog>
    )
  }

  const DialogMessage = () => {
    return (
      <Dialog
        open={confirmDialog?.isOpen}
        keepMounted
        classes={{
          paper: classes?.dialog,
        }}
      >
        <DialogTitle className={classes?.title}>{confirmDialog?.title}</DialogTitle>
        <DialogContentText className={classes?.subtitle}>
          {confirmDialog?.subTitle}
        </DialogContentText>
        <DialogActions className={classes?.action}>
          {/* <Button
            type="button"
            text="cancel"
            color="inherit"
            size="medium"
            variant="outlined"
            onClick={() => {
              setConfirmDialog({ ...confirmDialog, isOpen: false })
            }}
          /> */}
          {/* <Button
            text="confirm"
            size="medium"
            color="success"
            onClick={confirmDialog?.onConfirm}
          /> */}
          <Button
            text="OK"
            size="medium"
            color="success"
            onClick={() => {
              setConfirmDialog({ ...confirmDialog, isOpen: false })
            }}
          />
        </DialogActions>
      </Dialog>
    )
  }

  const DialogApproveMessage = () => {
    return (
      <Dialog
        open={confirmDialog?.isOpen}
        keepMounted
        classes={{
          paper: classes?.dialog,
        }}
      >
        <DialogTitle className={classes?.title}>{confirmDialog?.title}</DialogTitle>
        <DialogContentText className={classes?.subtitle}>
          {confirmDialog?.subTitle}
        </DialogContentText>
        <DialogContentText className={classes?.container}>
          {confirmDialog?.container}
        </DialogContentText>
      </Dialog>
    )
  }

  const DialogAlertSuccessful = () => {
    return (
      <Dialog
        open={confirmDialog?.isOpen}
        keepMounted
        classes={{
          paper: classes?.dialog,
        }}
      >
        <DialogTitle className={classes?.title}>
          <Typography component={'div'} variant="h4" color="success">
            {confirmDialog?.title}
          </Typography>
        </DialogTitle>
        <DialogContentText className={classes?.container}>
          <Grid container spacing={{ xs: 1, sm: 2 }} columns={{ xs: 1, sm: 8, md: 8 }}>
            <Grid item xs={4} sm={8} md={8} sx={{ textAlign: 'center' }}>
              <img
                src={`/assets/icons/create_successfully.svg`}
                alt="successfully"
                style={{
                  display: 'block',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  width: '50%',
                }}
              />
            </Grid>
            <Grid item xs={4} sm={8} md={8} sx={{ textAlign: 'center' }}>
              <Typography
                variant="H5_Bold"
                sx={{
                  fontFamily: 'Overpass',
                  fontsize: '24px',
                  fontWeight: 700,
                  lineHeight: '24px',
                  letterSpacing: '0.0015em',
                  color: '#FFFFFF',
                }}
              >
                {confirmDialog?.subTitle}
              </Typography>
            </Grid>
            <Grid item xs={4} sm={8} md={8} sx={{ textAlign: 'center' }}>
              <Button
                text="OK"
                size="medium"
                color="error"
                onClick={() => {
                  setConfirmDialog({ ...confirmDialog, isOpen: false })
                  if (confirmDialog?.onAction !== undefined) {
                    confirmDialog?.onAction()
                  }
                }}
                sx={{ width: '60%' }}
              />
            </Grid>
          </Grid>
        </DialogContentText>
      </Dialog>
    )
  }

  const DialogAlertWarning = () => {
    return (
      <Dialog
        open={confirmDialog?.isOpen}
        keepMounted
        classes={{
          paper: classes?.dialog,
        }}
      >
        <DialogTitle className={classes?.title}>
          <Typography component={'div'} variant="h4" color="warning">
            {confirmDialog?.title}
          </Typography>
        </DialogTitle>
        <DialogContentText className={classes?.container}>
          <Grid container spacing={{ xs: 1, sm: 2 }} columns={{ xs: 1, sm: 8, md: 8 }}>
            <Grid item xs={4} sm={8} md={8} sx={{ textAlign: 'center' }}>
              <img
                src={`/assets/icons/icon_alert_error.svg`}
                alt="error"
                style={{
                  display: 'block',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  width: '50%',
                }}
              />
            </Grid>
            <Grid item xs={4} sm={8} md={8} sx={{ textAlign: 'center' }}>
              <Typography
                sx={{
                  fontFamily: 'Overpass',
                  fontsize: '24px',
                  fontWeight: 700,
                  lineHeight: '24px',
                  letterSpacing: '0.0015em',
                  color: '#FFFFFF',
                }}
              >
                {confirmDialog?.subTitle}
              </Typography>
            </Grid>
            <Grid item xs={4} sm={8} md={8} sx={{ textAlign: 'center' }}>
              <Button
                text="OK"
                size="medium"
                color="error"
                onClick={() => {
                  setConfirmDialog({ ...confirmDialog, isOpen: false })
                }}
                sx={{ width: '60%' }}
              />
            </Grid>
          </Grid>
        </DialogContentText>
      </Dialog>
    )
  }

  const DialogAlertError = () => {
    return (
      <Dialog
        open={confirmDialog?.isOpen}
        keepMounted
        classes={{
          paper: classes?.dialog,
        }}
      >
        <DialogTitle className={classes?.title}>
          <Typography component={'div'} variant="h4" color="error">
            {confirmDialog?.title}
          </Typography>
        </DialogTitle>
        <DialogContentText className={classes?.container}>
          <Grid container spacing={{ xs: 1, sm: 2 }} columns={{ xs: 1, sm: 8, md: 8 }}>
            <Grid item xs={4} sm={8} md={8} sx={{ textAlign: 'center' }}>
              <img
                src={`/assets/icons/icon_alert_error.svg`}
                alt="error"
                style={{
                  display: 'block',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  width: '50%',
                }}
              />
            </Grid>
            <Grid item xs={4} sm={8} md={8} sx={{ textAlign: 'center' }}>
              <Typography
                variant="H5_Bold"
                sx={{
                  fontFamily: 'Overpass',
                  fontsize: '24px',
                  fontWeight: 700,
                  lineHeight: '24px',
                  letterSpacing: '0.0015em',
                  color: '#FFFFFF',
                }}
              >
                ERROR
              </Typography>
            </Grid>
            <Grid item xs={4} sm={8} md={8} sx={{ textAlign: 'center' }}>
              <Typography
                sx={{
                  fontFamily: 'Overpass',
                  fontsize: '18px',
                  fontWeight: 300,
                  lineHeight: '18px',
                  letterSpacing: '0.0015em',
                }}
              >
                {confirmDialog?.subTitle}
              </Typography>
            </Grid>
            <Grid item xs={4} sm={8} md={8} sx={{ textAlign: 'center' }}>
              <Button
                text="OK"
                size="medium"
                color="error"
                onClick={() => {
                  setConfirmDialog({ ...confirmDialog, isOpen: false })
                }}
                sx={{ width: '60%' }}
              />
            </Grid>
          </Grid>
        </DialogContentText>
      </Dialog>
    )
  }

  const DialogAlertMsgError = () => {
    return (
      <Dialog
        open={confirmDialog?.isOpen}
        keepMounted
        classes={{
          paper: classes?.dialog,
        }}
      >
        <img src="/assets/images/svg/alert-error.svg" alt="alert-error" />
        <DialogTitle className={classes?.alert_error_title}>
          {confirmDialog?.title}
        </DialogTitle>
        <DialogContentText className={classes?.alert_error_subTitle}>
          {confirmDialog?.subTitle}
          {confirmDialog?.container}
        </DialogContentText>
        <DialogActions className={classes?.action}>
          <Button
            text="ok"
            size="medium"
            color="success"
            onClick={() => {
              setConfirmDialog({ ...confirmDialog, isOpen: false })
            }}
          />
        </DialogActions>
      </Dialog>
    )
  }

  const DialogConfirmSendTransaction = () => {
    return (
      <Dialog
        open={confirmDialog?.isOpen}
        keepMounted
        classes={{
          paper: classes?.dialog,
        }}
      >
        <img src="/assets/images/svg/confirmTransaction.svg" alt="confirmTransaction" />
        <DialogTitle className={classes?.confirm_send_title}>
          {confirmDialog?.title}
        </DialogTitle>
        <DialogContentText className={classes?.confirm_send_subTitle}>
          {confirmDialog?.subTitle}
          {confirmDialog?.container}
        </DialogContentText>
        <DialogActions className={classes?.action}>
          <Button
            type="button"
            text="cancel"
            // color="inherit"
            // size="medium"
            variant="outlined"
            onClick={() => {
              setConfirmDialog({ ...confirmDialog, isOpen: false })
            }}
          />
          <Button
            text="confirm"
            // size="medium"
            color="success"
            onClick={confirmDialog?.onConfirm}
          />
        </DialogActions>
      </Dialog>
    )
  }

  const DialogTransactionSubmitted = () => {
    return (
      <Dialog
        open={confirmDialog?.isOpen}
        keepMounted
        classes={{
          paper: classes?.dialog,
        }}
      >
        <img src="/assets/images/svg/sendSuccessfully.svg" alt="sendSuccessfully" />
        <DialogTitle className={classes?.transaction_submited_title}>
          {confirmDialog?.title}
        </DialogTitle>
        <DialogContentText className={classes?.transaction_submited_subTitle}>
          {confirmDialog?.subTitle}
        </DialogContentText>
        <DialogContentText className={classes?.transaction_submited_subTitle}>
          {confirmDialog?.subTitle2}
        </DialogContentText>
        <Grid
          container
          spacing={{ xs: 0 }}
          columns={{ xs: 1, sm: 8, md: 8 }}
          className={classes.transaction_submited_action}
        >
          <Grid item xs={2} sm={4} md={4}>
            <Button
              text="VIEW TRANSACTION HISTORY"
              color="success"
              onClick={confirmDialog?.onView}
            />
          </Grid>
          <Grid item xs={2} sm={4} md={4}>
            <Button
              text="DOWNLOAD RECIPIENT"
              variant="outlined"
              onClick={confirmDialog?.onDownLoad}
            />
          </Grid>
          <Grid item xs={2} sm={4} md={4}>
            <Button
              sx={{
                height: 42,
                textTransform: 'lowercase',
              }}
              text="back to send money page"
              color="inherit"
              size="large"
              variant="text"
              onClick={confirmDialog?.onBack}
            />
          </Grid>
        </Grid>
      </Dialog>
    )
  }

  const DialogWaitTransaction = () => {
    return (
      <Dialog
        open={confirmDialog?.isOpen}
        keepMounted
        classes={{
          paper: classes?.dialog,
        }}
        onClose={() => {
          setTimeout(() => {
            if (confirmDialog?.isOpen === false) return
            if (confirmDialog?.loading === false) {
              setConfirmDialog({ ...confirmDialog, isOpen: false })
              return
            }
          }, 10000)
        }}
      >
        <Spinner
          sx={{
            p: isMobile ? '30px ' : '0px 92px 60px',
          }}
          color={theme.palette.status.waiting}
          size={isMobile ? 100 : 264}
        />
        <Typography
          variant="Paragraph_lg_Bold"
          color="text.secondary"
          component="div"
          sx={{ mx: 1, my: 1 }}
        >
          The system is processing
        </Typography>
        <Typography variant="H4_Bold" component="div" sx={{ mx: 1, my: 1 }}>
          Please wait a moment
        </Typography>
      </Dialog>
    )
  }

  // type : PropTypes.oneOf(["enable", "disable", "submit", "disable all", "alertError"]),
  switch (confirmDialog?.type) {
    case 'alertSuccessful':
      return <DialogAlertSuccessful />
    case 'alertWarning':
      return <DialogAlertWarning />
    case 'alertError':
      return <DialogAlertError />
    case 'submitResult':
      return <DialogSubmitResultMessage />
    case 'confirmApprove':
      return <DialogApproveMessage />
    case 'successDialog':
      return <DialogSuccessResultMessage />
    case 'successResult':
      return <DialogSuccessMessage />
    case 'errorDialog':
      return <DialogErrorResultMessage />
    case 'deleteDialog':
      return <DialogDeleteMessage />
    case 'customDialogWithContainer':
      return <DialogWithContainer />
    case 'alertMsgError':
      return <DialogAlertMsgError />
    case 'confirmToSendTransaction':
      return <DialogConfirmSendTransaction />
    case 'transactionSubmitted':
      return <DialogTransactionSubmitted />
    case 'DialogWaitingTransaction':
      return <DialogWaitTransaction />
    default:
      return <DialogMessage />
  }
}

const useStyles = makeStyles()((theme) => ({
  title: {
    // fontSize: 24,
    // fontWeight: 600,
    // textAlign: 'center',
    // fontStyle: 'normal',
    // lineHeight: 'normal',
    // letterSpacing: '0.036px',
  },
  titleMessage: {
    fontFamily: 'Overpass',
    fontsize: '30px',
    fontWeight: 700,
    lineHeight: '30px',
    letterSpacing: '0.0015em',
    color: theme.palette.text.primary,
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  container: {
    padding: '16px 24px',
  },
  warningTitle: {
    color: '#F1B805',
    fontSize: 24,
    fontWeight: 600,
    textAlign: 'center',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '0.036px',
  },
  subtitle: {
    fontWeight: 600,
    fontStyle: 'normal',
    textAlign: 'center',
    fontSize: '14px !important',
    letterSpacing: '0.027px',
    color: theme.palette.status.error,
  },
  subtitleWarning: {
    color: '#FFFFFF',
    fontWeight: 300,
    fontStyle: 'normal',
    textAlign: 'center',
    fontSize: '18px !important',
    letterSpacing: '0.027px',
  },
  subtitleError: {
    fontWeight: 600,
    fontStyle: 'normal',
    textAlign: 'center',
    fontSize: '14px !important',
    letterSpacing: '0.027px',
    // color: theme.palette.status.error,
  },
  subtitleMessage: {
    fontFamily: 'Overpass',
    fontsize: '18px',
    fontWeight: 300,
    lineHeight: '18px',
    letterSpacing: '0.0015em',
    color: theme.palette.text.secondary,
    textAlign: 'center',
  },
  alert: {
    width: 72,
    height: 72,
  },
  alertError: {
    width: 72,
    height: 72,
    color: theme.palette.status.error,
  },
  alertDelete: {
    padding: '20px 20px',
    // width: 206.5,
    height: 286.25,
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '50%',
  },
  dialog: {
    maxWidth: 540,
    borderRadius: 30,
    boxShadow: 'none',
    border: `2px solid ${theme.palette.outline.popup}`,
    background:
      'linear-gradient(0deg, #272C53, #272C53), linear-gradient(0deg, #444A81, #444A81)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '40px 10px',
    margin: 0,
    [theme.breakpoints.down('md')]: {
      width: '100%',
      padding: '30px 0px',
      margin: 10,
    },
    img: {
      height: 320,
      maxWidth: '100%',
      objectFit: 'none',
      objectPosition: 'top',
    },
  },
  action: {
    padding: '20px 0px 0px',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
    button: {
      width: '108px',
      height: '40px',
      [theme.breakpoints.up('md')]: {
        width: '148px',
        height: '46px',
      },
    },
  },
  alert_error_title: {
    ...typo['H3_Bold'],
    position: 'sticky',
    bottom: 142,
    [theme.breakpoints.down('md')]: {
      ...typo['Paragraph_lg_Bold'],
    },
  },
  alert_error_subTitle: {
    ...typo['Paragraph_lg_Light'],
    padding: '0px 24px',
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      padding: '0px 10px',
    },
  },
  confirm_send_title: {
    ...typo['H3_Bold'],
    position: 'absolute',
    bottom: 142,
    [theme.breakpoints.down('md')]: {
      ...typo['Paragraph_lg_Bold'],
    },
  },
  confirm_send_subTitle: {
    ...typo['Paragraph_lg_Light'],
    padding: '0px 24px',
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      padding: '0px 60px',
    },
  },
  transaction_submited_title: {
    ...typo['H3_Bold'],
    position: 'absolute',
    bottom: 325,
    [theme.breakpoints.down('md')]: {
      ...typo['H4_Bold'],
      position: 'initial',
    },
  },
  transaction_submited_subTitle: {
    ...typo['Paragraph_lg_Light'],
    padding: '20px 75px 0px',
    textAlign: 'center',

    [theme.breakpoints.down('md')]: {
      padding: '10px 40px',
    },
  },
  transaction_submited_action: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginTop: 10,
    [theme.breakpoints.up('md')]: {
      marginTop: 20,
    },

    button: {
      height: '40px',
      [theme.breakpoints.up('md')]: {
        ...typo['Paragraph_lg_Light'],
        width: '276px',
        height: '46px',
        padding: 8,
      },
    },
  },
  dialogAction: {
    padding: '20px 20px',
    justifyContent: 'center',
  },
}))
