import { hideScrollbarX } from '../../utils/stylecss'

export default function Table(theme) {
  return {
    MuiTable: {
      styleOverrides: {
        root: {
          borderCollapse: 'separate', // 'collapse',
          // margin: '20px 0',
          padding: '0',
          width: '100%',
          borderSpacing: '0px 16px',

          [theme.breakpoints.down('sm')]: {
            '& .MuiTableRow-root': {
              display: 'block',
              marginBottom: 14,
              borderRadius: 30,
              border: ` 2px solid ${theme.palette.background.popup}`,
              background: theme.palette.background.dark,
            },
          },

          '& .MuiTableRow-root td': {
            [theme.breakpoints.up('sm')]: {
              borderTop: ` 2px solid ${theme.palette.background.dark}`,
              borderBottom: ` 2px solid ${theme.palette.background.dark}`,
            },
          },

          '& .MuiTableRow-root td:first-of-type': {
            borderTopLeftRadius: 30,
            borderBottomLeftRadius: 30,

            [theme.breakpoints.up('sm')]: {
              borderTop: ` 2px solid ${theme.palette.background.dark}`,
              borderBottom: ` 2px solid ${theme.palette.background.dark}`,
              borderLeft: ` 2px solid ${theme.palette.background.dark}`,
            },

            [theme.breakpoints.down('sm')]: {
              paddingTop: 30,
            },
          },

          '& .MuiTableRow-root td:last-child': {
            [theme.breakpoints.up('sm')]: {
              borderRight: ` 2px solid ${theme.palette.background.dark}`,
              borderTopRightRadius: 30,
              borderBottomRightRadius: 30,
            },
            [theme.breakpoints.down('sm')]: {
              paddingBottom: 30,
            },
          },
        },
      },
    },

    MuiTableHead: {
      styleOverrides: {
        root: {
          display: 'table-header-group',
          verticalAlign: 'middle',
          borderColor: 'inherit',
          [theme.breakpoints.down('sm')]: {
            border: 'none',
            clip: 'rect(0 0 0 0)',
            height: '1px',
            margin: '-1px',
            overflow: 'hidden',
            padding: '0',
            position: 'absolute',
            width: '1px',
          },
        },
      },
    },

    MuiTableRow: {
      styleOverrides: {
        root: {
          // padding: '5px',
          // [theme.breakpoints.down('sm')]: {
          // tableLayout: "fixed",
          // },
          // "table-layout": "fixed",
        },
      },
    },

    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: 0,
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: 300,
          letterSpacing: '0.027px,',

          [theme.breakpoints.down('sm')]: {
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 600,
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
            flexDirection: 'column',

            '&:before': {
              content: 'attr(head-table)',
              float: 'left',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: 300,
              letterSpacing: '0.021px,',
            },

            '&:last-child': {
              borderRadius: '0px 0px 4px 4px',
              display: 'flex',
              // alignItems: 'center',
              // justifyContent: 'center',
            },

            '&:last-child td, &:last-child th': {
              border: 0,
            },
          },
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          ...hideScrollbarX,
        },
      },
    },
  }
}
