import React, { useState, useContext } from 'react'
import { alpha } from '@mui/material/styles'
import { styled } from '@mui/material/styles'
import {
  Box,
  Stack,
  Select,
  Popover,
  MenuItem,
  IconButton,
  Typography,
} from '@mui/material'
import SvgColor from '../../components/svg-color/svg-color'
import { LocalizationContext } from '../../hooks/useLocalize'
import { getLanguage } from '../../i18n/helper'
// import $t from "../../i18n";

const LANGS = [
  {
    value: 'en',
    label: 'English',
  },
  {
    value: 'jpn',
    label: 'Japanese',
  },
  {
    value: 'th',
    label: 'Thai',
  },
]

export default function LanguagePopover({ icon }) {
  const [language, { changeLanguage }] = useContext(LocalizationContext)
  const lang = getLanguage() || 'en'
  // const inputRef = useRef(null);
  const [open, setOpen] = useState(null)

  const handleOpen = (event) => {
    setOpen(event.currentTarget)
  }

  const handleClose = () => {
    // console.log(inputRef.current);
    setOpen(null)
  }

  return (
    <>
      {/* {$t("setting.logout")} */}

      {!!icon ? (
        <IconButton
          onClick={handleOpen}
          sx={{
            padding: 0,
            ...(open && {
              bgcolor: (theme) =>
                alpha(theme.palette.primary.main, theme.palette.action.focusOpacity),
            }),
          }}
        >
          <SvgColor
            sx={{
              width: 48,
              height: 48,
              position: 'relative',
              color: 'outline.default',
            }}
            src={`/assets/icons/icon_global_ellipse.svg`}
          />
          <SvgColor
            sx={{
              width: 30,
              height: 30,
              position: 'absolute',
              color: 'outline.secondary',
            }}
            src={`/assets/icons/icon_global.svg`}
          />
        </IconButton>
      ) : (
        <StyledSelectLang>
          <Select
            sx={{ width: 130 }}
            // displayEmpty
            name="lang"
            defaultValue={language}
            onChange={(val) => {
              const current = LANGS.find((item) => item.label === val.target.value)?.value
              changeLanguage(current)
            }}
            renderValue={(value) => {
              return (
                <Box alignItems="center" sx={{ display: 'flex', gap: 1 }}>
                  <SvgColor
                    sx={{ width: 30, height: 30 }}
                    src={`/assets/icons/icon_global.svg`}
                  />
                  <Typography variant="Langs">{value}</Typography>
                </Box>
              )
            }}
          >
            {LANGS.map((option) => (
              <MenuItem key={option.value} value={option.label}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </StyledSelectLang>
      )}

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            mt: 1.5,
            ml: 0.75,
            width: 160,
            '& .MuiMenuItem-root': {
              px: 2,
              py: 2,
            },
          },
        }}
      >
        <Stack spacing={0.75}>
          {LANGS.map((option) => (
            <MenuItem
              name="lang"
              key={option.label}
              selected={option.value === lang}
              onClick={(e) => {
                const current = LANGS.find(
                  (item) => item.label === e.target.outerText,
                )?.value
                changeLanguage(current)
                handleClose()
              }}
            >
              {option.label}
            </MenuItem>
          ))}
        </Stack>
      </Popover>
    </>
  )
}

const StyledSelectLang = styled('div')(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    padding: 0,
    borderRadius: '30px',
    background: 'transparent',

    '& .MuiOutlinedInput-input': {
      padding: 10,
    },

    '& .MuiOutlinedInput-notchedOutline': {
      border: `2px solid ${theme.palette.outline.default}`,
    },
  },
}))
