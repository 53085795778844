import React from 'react'
import { Controller } from 'react-hook-form'
import { TextField, InputLabel, Typography } from '@mui/material'
import { emailValidator } from './validators'

const EmailField = ({
  disableErrorSpace,
  placeholder,
  required,
  control,
  label,
  name,
  sx,
  inputProps,
  ...restProps
}) => (
  <Controller
    render={({ field: { ref, ...restField }, fieldState: { error } }) => (
      <>
        <InputLabel htmlFor={name}>
          <Typography
            color={error ? 'text.error' : 'text.primary'}
            variant="Paragraph_sm_Light"
            component="div"
            sx={{ mb: 1 }}
          >
            {`${label ? `${label}${required ? ' *' : ''}` : ''}`}
          </Typography>
        </InputLabel>
        <TextField
          {...restField}
          {...restProps}
          inputRef={ref}
          error={!!error}
          placeholder={placeholder}
          inputProps={{ autoComplete: 'off', ...inputProps }}
          helperText={error?.message || (disableErrorSpace ? '' : ' ')}
          fullWidth
          sx={{
            width: '100%',
            ...sx,
          }}
        />
      </>
    )}
    name={name}
    control={control}
    rules={{
      validate: emailValidator(required),
      required: required ? 'Required' : '',
    }}
  />
)

export default EmailField
