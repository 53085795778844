import has from 'lodash/has'
import isObject from 'lodash/isObject'
import stringify from 'query-string'
import { parseJWT } from '../utils/jwt'
import { getCookie, removeCookieAll } from '../utils/cookie'

export const restApi = async (options, callback = {}) => {
  let token = getCookie('authorization')?.token
  let expiredToken = false

  if (!!token) {
    const payload = parseJWT(token)
    const currentTime = Date.now() / 1000
    if (payload.exp < currentTime) {
      expiredToken = true
    }
  }

  // const currentTime = new Date()
  // if (!!token) {
  //   // const decodeToken = decode(token)
  //   // if (currentTime.getTime() / 1000 > decodeToken?.exp || 0) token = renewToken()

  //   options.headers = {
  //     Authorization: `Bearer ${token}`,
  //     ...options.headers,
  //   }
  // } else {
  //   window.location.href = '/login'
  // }

  const defaultCallback = {
    network_error: (error) => {
      if (process.env.NODE_ENV === 'development') console.log(error.message)
      return error
    },
    condition: (response) => {
      return has(response, 'code') && (response.code === 0 || response.code === '0')
    },
    success: (response) => response,
    fail: (response) => {
      // expiredToken
      if (response.code === 403 || (response.code === 401 && expiredToken)) {
        removeCookieAll()
        window.location.href = '/login'
      }
      if (process.env.NODE_ENV === 'development') {
        console.log('==== 😂 callback fail ====')
        console.log(response)
        console.log('===========================')
      }

      // removeCookieAll()
      // window.location.href = '/login'

      return response
    },
    before: () => {},
    after: (response) => response,
    verifyUnauthorized: (response) => {
      if (
        (!has(options, 'notLoggedIn') && response.code === 401 && expiredToken) ||
        response.message === 'Unauthorized'
      ) {
        removeCookieAll()
        window.location.href = '/login'
        return false
      }
      return true
    },
    ...callback,
  }

  const response = await restAPI(options)
  defaultCallback.before(response)
  if (response.error) handleError(defaultCallback, response.error)

  handleSuccess(defaultCallback, response)
  defaultCallback.after(response)
  // defaultCallback.verifyUnauthorized(response);
  return response
}

const handleSuccess = (callback, response) => {
  if (callback.condition(response) === true) {
    callback.success(response)
    return
  }
  callback.fail(response)
}

const handleError = (callback, error) => {
  callback.network_error(error)
}

const restAPI = async (options) => {
  let token = getCookie('authorization')?.token

  try {
    if (
      (options.method === 'POST' ||
        options.method === 'PUT' ||
        options.method === 'DELETE') &&
      !!options.form === false
    )
      options.headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...options.headers,
      }

    if (!!token) {
      options.headers = {
        Authorization: `Bearer ${token}`,
        ...options.headers,
      }
    }
    // else {
    //   window.location.href = '/login'
    // }

    /**
       * ==========
       * Example params convert object to url
       * http://localhost/test?fieldTestA=valueTestA&fieldTestA=valueTestB
       * ==========
       *
        restApi({
          ...
          params: {
            fieldTestA: valueTestA,
            fieldTestB: valueTestB
          }
          ...
        });
       *
       */
    if (has(options, 'params') && isObject(options.params))
      options.url = `${options.url}?${stringify(options.params)}`

    /**
       * ==========
       * Example raw json data
       * ==========
       *
        restApi({
          ...
          body: {
            fieldTestA: valueTestA,
            fieldTestB: valueTestB
          }
          ...
        })
       *
       */
    if (has(options, 'body') && isObject(options.body))
      options.body = JSON.stringify(options.body)

    /**
       * ==========
       * Example form data
       * ==========
       *
       * const datas = new FormData();
       * datas.append('fieldTestA', 'valueTestA');
       * datas.append('fieldTestB', 'valueTestB');
       *
        restApi({
          ...
          form: datas
          ...
        })
       *
       */
    if (has(options, 'form') && isObject(options.form)) options.body = options.form

    const res = await timeoutPromise(
      1000 * 60 * 60,
      fetch(options.url, {
        method: options.method,
        ...options,
      }),
    )
      .then(function (response) {
        return response
      })
      .catch(function (error) {
        return error
      })

    return await res.json()
  } catch (error) {
    return {
      error,
    }
  }
}

function timeoutPromise(ms, promise) {
  return new Promise((resolve, reject) => {
    const timeout = setTimeout(() => {
      reject(new Error('promise timeout'))
    }, ms)
    promise.then(
      (res) => {
        clearTimeout(timeout)
        resolve(res)
      },
      (err) => {
        clearTimeout(timeout)
        reject(err)
      },
    )
  })
}
