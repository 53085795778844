export const textFieldValidator = (isRequired) => (value) => {
  if (!isRequired && !value) {
    return true
  }
  //Check if not only spaces are entered
  return !!value.trim() || 'Required'
}

export const postalCodeValidator = (isRequired) => (value) => {
  if (!isRequired && !value) {
    return true
  }
  return /^[0-9]{2}-[0-9]{3}$/.test(value) || 'Wrong postal code'
}

const emailRegexp =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const emailValidator = (required) => (value) => {
  if (!required && !value) {
    return true
  }
  return emailRegexp.test(String(value).toLowerCase()) || 'Wrong e-mail'
}

export const phoneNumberValidator = (isRequired) => (value) => {
  if (!isRequired && !value) {
    return true
  }
  //min 9, max 11 digits
  return (
    /^[0-9]{3}?[\s+]?[0-9]{3}?[\s+]?[0-9]{2}\d+/.test(value) ||
    'Mobile should have 9-11 digits'
  )
}

export const numberFieldValidator = (isRequired) => (value) => {
  if (!isRequired && !value) {
    return true
  }
  return /^[0-9]/.test(value) || 'Required'
}
