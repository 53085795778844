import React, { useState, useCallback, useMemo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, Navigate } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'
import {
  Link,
  Alert,
  Stack,
  IconButton,
  InputAdornment,
  Typography,
  // Checkbox,
} from '@mui/material'
import { isNull } from 'lodash'
import { makeStyles } from 'tss-react/mui'
import Input from '../../../components/common/Input'
import Button from '../../../components/common/Button'
import Iconify from '../../../components/iconify/Iconify'
import AnimateButton from '../../../components/animate-button/animate-button'
import { usernameSignIn } from '../../../redux/auth/action'
import { getCookie, setCookie } from '../../../utils/cookie'
import { apiGetProcessSessionID } from '../../../api/master'
import { apiUserInfo } from '../../../api/auth'

const initialState = {
  username: '',
  password: '',
  accessCode: '',
}

const LoginForm = () => {
  const { classes } = useStyles()
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const [message, setMessage] = useState(null)
  const [showPassword, setShowPassword] = useState(false)

  const navigate = useNavigate()

  const IS_LOGIN = getCookie('authorization')
  const loginSelector = useSelector((state) => state.login)

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors, isDirty },
    // watch
  } = useForm({
    initialState,
  })

  const renderRedirect = useCallback(() => {
    if (IS_LOGIN) {
      return <Navigate to="/dashboard" replace={true} />
    }
    return null
  }, [IS_LOGIN])

  const onSubmit = async (params) => {
    const { userName, password, accessCode } = params

    setIsLoading(true)

    dispatch(usernameSignIn(userName, password, accessCode))
      .then((msg) => {
        setMessage(msg)
        setIsLoading(true)
      })
      .catch(() => {})

    reset()
    setIsLoading(true)
  }

  const fetchSetProcessSessionID = useCallback(async () => {
    if (loginSelector?.auth?.token) {
      const response = await apiGetProcessSessionID()
      sessionStorage.setItem('PROCESS_SESSION_ID', response?.data?.processID)
    }
  }, [loginSelector])

  useMemo(() => {
    fetchSetProcessSessionID()
  }, [fetchSetProcessSessionID])

  const fetchGetUserInfo = useCallback(async () => {
    if (loginSelector?.auth?.token) {
      const response = await apiUserInfo()
      setCookie('USER_INFO', response?.data)
    }
  }, [loginSelector])

  useMemo(() => {
    fetchGetUserInfo()
  }, [fetchGetUserInfo])

  if (loginSelector?.isAuth && isLoading) {
    setTimeout(() => {
      navigate(`/dashboard`)
    }, 3000)

    return
  }

  return (
    <Stack spacing={1} sx={{ width: '100%', my: 2, mx: 2, gap: 2 }}>
      {renderRedirect()}
      <Controller
        name="userName"
        control={control}
        rules={{ required: 'Please fill out this field.' }}
        defaultValue=""
        render={({ field }) => (
          <Input
            {...field}
            label="Username"
            name="userName"
            margin="dense"
            error={!!errors.userName}
            helperText={errors.userName?.message}
          />
        )}
      />

      <Controller
        name="password"
        control={control}
        rules={{ required: 'Please fill out this field.' }}
        defaultValue=""
        render={({ field }) => (
          <Input
            {...field}
            label="Password"
            name="password"
            margin="dense"
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={!!errors.password}
            helperText={errors.password?.message}
          />
        )}
      />

      <Controller
        name="accessCode"
        control={control}
        rules={{ required: 'Please fill out this field.' }}
        defaultValue=""
        render={({ field }) => (
          <Input
            {...field}
            label="Access code"
            name="accessCode"
            margin="dense"
            error={!!errors.accessCode}
            helperText={errors.accessCode?.message}
          />
        )}
      />

      <Alert
        severity="error"
        className={classes.alertAuth}
        onClick={() => {
          setMessage(null)
          setIsLoading(false)
        }}
        sx={{ display: (message === 'IS_AUTH' || isNull(message)) && 'none' }}
      >
        <Typography variant="AlertMsg" component="div" sx={{ mx: 1, my: 1 }}>
          {message?.includes('Authentication Failed')
            ? 'Authentication Failed.'
            : 'Login Failed'}
        </Typography>
        <Typography variant="Paragraph_lg_Light" component="div" sx={{ mx: 1, my: 1 }}>
          {message?.includes('Verify your userid and password')
            ? 'Please verify your userid and password.'
            : message}
        </Typography>
      </Alert>

      <AnimateButton>
        <Stack direction="column" alignItems="stretch">
          <Button
            loading={Boolean(isLoading)}
            disabled={!isDirty}
            className={classes.loadingBtn}
            onClick={handleSubmit(onSubmit)}
            text="LOG IN"
            size="large"
            type="submit"
          />
        </Stack>
      </AnimateButton>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        //   sx={{ my: 2 }}
      >
        {/* <Checkbox name="remember" label="Remember me" /> */}
        <Link variant="Forgot_Password" color="text.primary" underline="hover">
          Forgot Password ?
        </Link>
      </Stack>
    </Stack>
  )
}

export default LoginForm

const useStyles = makeStyles()((theme) => ({
  alertAuth: {
    marginTop: 8,
    borderRadius: 30,

    '& .MuiAlert-icon': {
      display: 'none',
    },

    '& .MuiAlert-message': {
      padding: 10,
      flexDirection: 'column',
      color: theme.palette.text.error,
    },
  },
  loadingBtn: {
    width: '100%',
    span: {
      left: '35%',

      color: theme.palette.text.primary,
      [theme.breakpoints.up('md')]: {
        left: '40%',
      },

      '&.MuiTouchRipple-root': {
        visibility: 'hidden',
      },
    },
  },
}))
