import * as React from 'react'
import PropTypes from 'prop-types'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'

/**
 * type {string} [error, warning, info, success]
 */

const AlertBox = ({ notify, setNotify }) => {
  const severity = {
    error: 'error',
    info: 'info',
    success: 'success',
    warning: 'warning',
  }

  return (
    <>
      {notify?.type && notify?.message !== 'jwt expired' && (
        <Snackbar
          style={{ top: '100px' }}
          autoHideDuration={3000}
          open={notify?.open}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          onClose={() => setNotify({ ...notify, open: false })}
        >
          <Alert
            elevation={6}
            severity={severity[notify?.type]}
            variant="filled"
            onClose={() => setNotify({ ...notify, open: false })}
          >
            {notify?.message}
          </Alert>
        </Snackbar>
      )}
    </>
  )
}

AlertBox.propTypes = {
  message: PropTypes.string,
  type: PropTypes.string,
  open: PropTypes.bool,
  onclose: PropTypes.func,
}

export default AlertBox
