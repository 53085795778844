import { restApi } from '../utils/rest'

export const apiCustomerList = async (searchText, currentPage, pageSize, callback) => {
  try {
    var url = ''
    if (!(searchText === undefined || searchText === '')) {
      url = `/api/v1/customer/customer-lists?searchTxt=${searchText}&currentPage=${currentPage}&pageSize=${pageSize}`
    } else {
      url = `/api/v1/customer/customer-lists?currentPage=${currentPage}&pageSize=${pageSize}`
    }
    return await restApi(
      {
        url: `${process.env.REACT_APP_SERVICE_API}${url}`,
        method: 'GET',
      },
      callback,
    )
  } catch (error) {
    console.log('ERROR', error)
    return error
  }
}

export const apiGetOccupationList = async (callback = {}) => {
  try {
    return await restApi(
      {
        url: `${process.env.REACT_APP_SERVICE_API}/api/v1/customer/occupation`,
        method: 'GET',
      },
      callback,
    )
  } catch (error) {
    return error
  }
}

export const apiGetVisaTypeList = async (callback = {}) => {
  try {
    return await restApi(
      {
        url: `${process.env.REACT_APP_SERVICE_API}/api/v1/customer/sender-visa-type`,
        method: 'GET',
      },
      callback,
    )
  } catch (error) {
    return error
  }
}

export const apiGetSourceOfFundList = async (callback = {}) => {
  try {
    return await restApi(
      {
        url: `${process.env.REACT_APP_SERVICE_API}/api/v1/customer/sourceoffund`,
        method: 'GET',
      },
      callback,
    )
  } catch (error) {
    return error
  }
}

export const apiCreateCustomer = async (body = {}, callback = {}) => {
  try {
    return await restApi(
      {
        url: `${process.env.REACT_APP_SERVICE_API}/api/v1/customer/customer/new`,
        method: 'POST',
        body,
      },
      callback,
    )
  } catch (error) {
    return error
  }
}

export const apiUpdateCustomer = async (body = {}, callback = {}) => {
  try {
    return await restApi(
      {
        url: `${process.env.REACT_APP_SERVICE_API}/api/v1/customer/customer/edit`,
        method: 'PUT',
        body,
      },
      callback,
    )
  } catch (error) {
    return error
  }
}

export const apiCheckPermissionEdit = async (route, callback = {}) => {
  try {
    return await restApi(
      {
        url: `${process.env.REACT_APP_SERVICE_API}/api/v1/customer/check-permission-edit?route=${route}`,
        method: 'GET',
      },
      callback,
    )
  } catch (error) {
    return error
  }
}

export const apiGetCustomerDetails = async (route, callback = {}) => {
  try {
    return await restApi(
      {
        url: `${process.env.REACT_APP_SERVICE_API}/api/v1/customer/customer-detail?route=${route}`,
        method: 'GET',
      },
      callback,
    )
  } catch (error) {
    return error
  }
}

export const apiGetAgentCountry = async (callback = {}) => {
  try {
    return await restApi(
      {
        url: `${process.env.REACT_APP_SERVICE_API}/api/v1/customer/agent-country`,
        method: 'GET',
      },
      callback,
    )
  } catch (error) {
    return error
  }
}

export const apiGetReceiverDetails = async (route, callback = {}) => {
  try {
    return await restApi(
      {
        url: `${process.env.REACT_APP_SERVICE_API}/api/v1/customer/receiver-detail?route=${route}`,
        method: 'GET',
      },
      callback,
    )
  } catch (error) {
    return error
  }
}

export const apiGetBeneficiaryCountryList = async (callback = {}) => {
  try {
    return await restApi(
      {
        url: `${process.env.REACT_APP_SERVICE_API}/api/v1/customer/beneficiary-country`,
        method: 'GET',
      },
      callback,
    )
  } catch (error) {
    return error
  }
}

export const apiGetPurposeOfRemittanceList = async (callback = {}) => {
  try {
    return await restApi(
      {
        url: `${process.env.REACT_APP_SERVICE_API}/api/v1/customer/purpose-of-remittance`,
        method: 'GET',
      },
      callback,
    )
  } catch (error) {
    return error
  }
}

export const apiGetRelationshipList = async (callback = {}) => {
  try {
    return await restApi(
      {
        url: `${process.env.REACT_APP_SERVICE_API}/api/v1/customer/relationship`,
        method: 'GET',
      },
      callback,
    )
  } catch (error) {
    return error
  }
}

export const apiGetPayoutCountryList = async (callback = {}) => {
  try {
    return await restApi(
      {
        url: `${process.env.REACT_APP_SERVICE_API}/api/v1/customer/payout-country`,
        method: 'GET',
      },
      callback,
    )
  } catch (error) {
    return error
  }
}

export const apiGetModeOfRemittanceList = async (country, callback = {}) => {
  try {
    return await restApi(
      {
        url: `${process.env.REACT_APP_SERVICE_API}/api/v1/customer/transaction-type/${country}`,
        method: 'GET',
      },
      callback,
    )
  } catch (error) {
    return error
  }
}

export const apiGetDeliveryOptionList = async (country, paymentMode, callback = {}) => {
  try {
    return await restApi(
      {
        url: `${process.env.REACT_APP_SERVICE_API}/api/v1/customer/receiver-currency/delivery-option?Country=${country}&PaymentMode=${paymentMode}`,
        method: 'GET',
      },
      callback,
    )
  } catch (error) {
    return error
  }
}

export const apiGetBankList = async (
  mode,
  receiveAgentID,
  payoutCountry,
  callback = {},
) => {
  try {
    return await restApi(
      {
        url: `${process.env.REACT_APP_SERVICE_API}/api/v1/customer/banklist?mode=${mode}&receiveAgentID=${receiveAgentID}&payoutCountry=${payoutCountry}`,
        method: 'GET',
      },
      callback,
    )
  } catch (error) {
    return error
  }
}

export const apiGetTestQuestionList = async (callback = {}) => {
  try {
    return await restApi(
      {
        url: `${process.env.REACT_APP_SERVICE_API}/api/v1/customer/test-question`,
        method: 'GET',
      },
      callback,
    )
  } catch (error) {
    return error
  }
}

export const apiCreateReceiver = async (body = {}, callback = {}) => {
  try {
    return await restApi(
      {
        url: `${process.env.REACT_APP_SERVICE_API}/api/v1/customer/receiver/new`,
        method: 'POST',
        body,
      },
      callback,
    )
  } catch (error) {
    return error
  }
}

export const apiUpdateReceiver = async (body = {}, callback = {}) => {
  try {
    return await restApi(
      {
        url: `${process.env.REACT_APP_SERVICE_API}/api/v1/customer/receiver/edit`,
        method: 'PUT',
        body,
      },
      callback,
    )
  } catch (error) {
    return error
  }
}

export const apiGetFieldRuleList = async (
  deliveryOptionId,
  customerBeneficiaryFg,
  customerType,
  receiverType,
  callback = {},
) => {
  try {
    return await restApi(
      {
        url: `${process.env.REACT_APP_SERVICE_API}/api/v1/customer/field-rule?delivery_option_id=${deliveryOptionId}&customer_beneficiary_fg=${customerBeneficiaryFg}&customertype=${customerType}&receivertype=${receiverType}`,
        method: 'GET',
      },
      callback,
    )
  } catch (error) {
    return error
  }
}
