import React, { useState, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, Stack, TableBody, TableCell, TableRow } from '@mui/material'
import CardExpand from '../../../components/common/CardExpand'
import HEADERTABLE from '../../../_mock/header-table/sender-money'
import useTable from '../../../components/useTable'
import useResponsive from '../../../hooks/useResponsive'
import Loading from '../../../components/loading/loading'
import { selectReceiverDetail, fetchReceiverDelivery } from '../../../redux/master/action'
import Button from '../../../components/common/Button'
import CheckBoxField from '../../../components/common/CheckBoxField'
import isEmpty from 'lodash/isEmpty'
import {
  apiGetReceiverListBysno,
  apiGetReceiverDetailBysno,
} from '../../../api/send-money'

const initState = {
  records: [],
  checked: [],
  receiverList: [],
  loading: false,
  isChecking: false,
}

const SelectReceiver = ({ control, sno, values, next, reset }) => {
  const dispatch = useDispatch()
  const isMobile = useResponsive('between', 'xs', 'md')
  const [loading, setLoading] = useState(initState.loading)
  const [isChecking, setIsChecking] = useState(initState.isChecking)
  const [checked, setChecked] = React.useState(initState.checked)
  const [receiverList, setReceiverList] = useState(initState.receiverList)
  const RECEIVER_DETAIL = useSelector((state) => state?.master?.receiver_detail)

  const fetchReceiverListBysno = useCallback(async () => {
    setLoading(true)

    if (!sno) return

    const response = await apiGetReceiverListBysno(sno)
    if (response?.code !== 0) {
      return
    }

    setReceiverList(response?.data)
    setLoading(false)
  }, [sno])

  useEffect(() => {
    fetchReceiverListBysno()
  }, [fetchReceiverListBysno])

  const handleSelected = async (_, sno) => {
    setChecked(sno)

    const response = await apiGetReceiverDetailBysno(sno)
    if (response?.code !== 0) {
      return
    }

    dispatch(selectReceiverDetail(response?.data))
  }

  const handleNewReceiver = () => {
    setIsChecking(true)
    dispatch(selectReceiverDetail({}))
    dispatch(fetchReceiverDelivery([]))

    setTimeout(() => {
      reset({
        ...values,
        r_firstName: '', // ###### 3 RECEIVER DETAILS ######
        r_middleName: '',
        r_lastName: '',
        r_gender: '',
        r_phoneNumber: '',
        r_dateOfBirth: null,
        r_email: '',
        r_nationality: null,
        r_occupation: null,
        r_occupation_other: '',
        r_purposeofremittance: null,
        r_purposeofremittance_other: '',
        r_relationship: null,
        r_relationship_other: '',
        r_country: null, // ###### RECEIVER ADDRESS DETAILS ######
        r_postalCode: '',
        r_state: '',
        r_city: '',
        r_district: '',
        r_street: '',
        r_modeofremittance: null,
        // r_bank: null,
        r_account_type: null,
        r_branchname: '',
        r_account_number: '',
        // ###### ADDITIONAL INFORMATION ######
        r_add_alternative_name: '',
        r_add_alternate_midName: '',
        r_add_alternate_lastName: '',
        r_add_alternative_address: '',
        // ###### ADDITIONAL DOCUMENT INFORMATION ######
        r_add_id_type: null,
        r_add_id_type_other: '',
        r_add_id_number: '',
        r_add_issue_date: null,
        r_add_expire_date: null,
        r_add_id_issue_country: null,
        r_add_id_issue_state: '',
        r_add_testquestion: null,
        r_add_testanswer: '',
        r_add_message1: '',
        r_add_message2: '',
        r_add_remark: '',
        // ###### ADDITIONAL BANK INFORMATION ######
        r_add_abbbankname: '',
        r_add_BICB: '',
        r_add_IBAN: '',
        r_add_SWIFTBIC: '',
        r_add_NID: '',
        r_add_SUFFIX: '',
        r_add_subranch: '',
        fileSTR: '',
        remittance_bank: null,
        calcMode: '',
        remittance_amount: '',
        transfer_amount: '',
        kycMode: '',
      })
      next()
    }, 2000)
    setIsChecking(false)
  }

  const isSelect = (id) => checked.indexOf(id) !== -1

  const {
    TblContainer,
    TblHead,
    TblPagination,
    recordsAfterPaginationAndSorting,
    // setPage,
  } = useTable(receiverList, HEADERTABLE.ReceiverHeader)

  return (
    <div>
      <Stack spacing={3}>
        <Grid item sm={6}>
          <CardExpand title="receiver" defaultOpen>
            <Grid container spacing={{ xs: 1, sm: 2 }} columns={{ xs: 1, sm: 8, md: 8 }}>
              <Grid item xs={8}>
                <TblContainer>
                  <TblHead />
                  {!loading && (
                    <TableBody>
                      {recordsAfterPaginationAndSorting().map((item, index) => {
                        const isSelected = isEmpty(RECEIVER_DETAIL)
                          ? isSelect(item?.receiverId)
                          : RECEIVER_DETAIL?.receiversno === item?.receiverId
                        return (
                          <React.Fragment key={`receiver-${index}`}>
                            <TableRow
                              key={index}
                              tabIndex={-1}
                              role="checkbox"
                              selected={isSelected}
                              aria-checked={isSelected}
                              onClick={(event) => handleSelected(event, item?.receiverId)}
                            >
                              <TableCell head-table="Action">
                                <CheckBoxField
                                  control={control}
                                  name="is_selected"
                                  checked={isSelected}
                                  // required
                                />
                              </TableCell>
                              <TableCell head-table="Receiver ID">
                                {item?.receiverId}
                              </TableCell>
                              <TableCell head-table="Receiver Name">
                                {item?.receiverName}
                              </TableCell>
                              <TableCell head-table="Address">{item?.rAddress}</TableCell>
                              <TableCell head-table="Country">{item?.rCountry}</TableCell>
                              <TableCell head-table="Relationship">
                                {item?.realtion}
                              </TableCell>
                              <TableCell head-table="Payout Partner">
                                {item?.payout}
                              </TableCell>
                            </TableRow>
                          </React.Fragment>
                        )
                      })}
                    </TableBody>
                  )}
                </TblContainer>
                <Loading loading={loading} />
                <TblPagination />
                <Stack
                  sx={{ mt: 5 }}
                  flexDirection="row"
                  justifyContent={isMobile ? 'center' : 'flex-start'}
                >
                  <Button
                    onClick={handleNewReceiver}
                    disabled={isChecking}
                    variant="outlined"
                    color="primary"
                    text="Create new receiver"
                    size="large"
                  />
                </Stack>
              </Grid>
            </Grid>
          </CardExpand>
        </Grid>
      </Stack>
    </div>
  )
}

export default SelectReceiver
