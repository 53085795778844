import { Controller } from 'react-hook-form'
import { makeStyles } from 'tss-react/mui'
import { TextField, InputLabel, Typography } from '@mui/material'
import { textFieldValidator } from './validators'

const InputField = ({
  sx,
  name,
  label,
  control,
  required,
  placeholder,
  disableErrorSpace,
  maxLength = 50,
  inputProps,
  ...restProps
}) => {
  const { classes } = useStyles()

  return (
    <Controller
      render={({ field: { ref, ...restField }, fieldState: { error } }) => (
        <>
          <InputLabel className={classes?.labelStyle} htmlFor={name}>
            <Typography
              color={error ? 'text.error' : 'text.primary'}
              variant="Paragraph_sm_Light"
              component="div"
              sx={{ mb: 1 }}
            >{`${label ? `${label}${required ? ' *' : ''}` : ''}`}</Typography>
          </InputLabel>
          <TextField
            {...restField}
            {...restProps}
            inputRef={ref}
            error={!!error}
            inputProps={{ autoComplete: 'off', ...inputProps }}
            helperText={error?.message || (disableErrorSpace ? '' : ' ')}
            placeholder={placeholder}
            fullWidth
            sx={{
              width: '100%',
              ...sx,
            }}
          />
        </>
      )}
      name={name}
      control={control}
      rules={{
        validate: textFieldValidator(required),
        required: required ? 'Required' : '',
      }}
    />
  )
}

export default InputField

const useStyles = makeStyles()(() => ({
  labelStyle: {
    whiteSpace: 'wrap',
  },
}))
