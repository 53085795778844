import React, { useEffect, useState } from 'react'
import { useTheme } from '@mui/material/styles'
import { experimentalStyled as styled } from '@mui/material/styles'
import { Grid, Stack, Card, CardContent, Typography, Divider, Paper } from '@mui/material'
import CheckBoxField from '../../../components/common/CheckBoxField'
import CardExpand from '../../../components/common/CardExpand'
import Spinner from '../../../components/common/Spinner'
import useResponsive from '../../../hooks/useResponsive'
import { fNumberCurrency } from '../../../utils/formatNumer'
import isEmpty from 'lodash/isEmpty'
import moment from 'moment'
import { CountryNameByCode } from '../../../utils/app'
import { getCookie } from '../../../utils/cookie'

const ConfirmSendTransaction = ({ control, data, isWaiting, EnterNote }) => {
  const theme = useTheme()
  const isMobile = useResponsive('between', 'xs', 'md')
  const custDetail = data?.customerDetail
  const receivDetail = data?.receiver
  const deposit = data?.deposit ?? []
  const [currencyCode, setCurrencyCode] = useState('-')

  useEffect(() => {
    setCurrencyCode(getCookie('USER_INFO')?.currencyCode || '-')
  }, [])

  return (
    <div>
      <Stack spacing={3}>
        <Grid item sm={8}>
          {!isWaiting || !isEmpty(data) ? (
            <>
              <CardExpand title="Confirm send Transaction" defaultOpen>
                <Grid
                  container
                  spacing={{ xs: 0 }}
                  columns={{ xs: 1, sm: 12, md: 12 }}
                  sx={{
                    borderRadius: 5,
                    p: '24px 22px 30px 22px;',
                    border: (theme) => `2px solid ${theme.palette.background.popup}`,
                  }}
                >
                  <Grid item xs={12}>
                    <Typography variant="H5_Bold">Sender details</Typography>
                  </Grid>

                  <Grid item xs={2} sm={4} md={4}>
                    <Item>
                      <Typography variant="Paragraph_xs_Light" component={'div'}>
                        Full Name
                      </Typography>
                      <Typography variant="Paragraph_sm_Light">
                        {custDetail?.senderfullname}
                      </Typography>
                    </Item>
                  </Grid>

                  <Grid item xs={2} sm={4} md={4}>
                    <Item>
                      <Typography variant="Paragraph_xs_Light" component={'div'}>
                        Date of Birth
                      </Typography>
                      <Typography variant="Paragraph_sm_Light">
                        {custDetail?.dob}
                      </Typography>
                    </Item>
                  </Grid>

                  <Grid item xs={2} sm={4} md={4}>
                    <Item>
                      <Typography variant="Paragraph_xs_Light" component={'div'}>
                        Phone Number
                      </Typography>
                      <Typography variant="Paragraph_sm_Light">
                        {custDetail?.mobileNum}
                      </Typography>
                    </Item>
                  </Grid>

                  <Grid item xs={2} sm={4} md={4}>
                    <Item>
                      <Typography variant="Paragraph_xs_Light" component={'div'}>
                        Nationality
                      </Typography>
                      <Typography variant="Paragraph_sm_Light">
                        {CountryNameByCode(custDetail?.nationality)}
                      </Typography>
                    </Item>
                  </Grid>

                  <Grid item xs={2} sm={4} md={4}>
                    <Item>
                      <Typography variant="Paragraph_xs_Light" component={'div'}>
                        Address
                      </Typography>
                      <Typography variant="Paragraph_sm_Light">
                        {custDetail?.senderFullAddress}
                      </Typography>
                    </Item>
                  </Grid>

                  <Grid item xs={2} sm={4} md={4}>
                    <Item>
                      <Typography variant="Paragraph_xs_Light" component={'div'}>
                        ID Type
                      </Typography>
                      <Typography variant="Paragraph_sm_Light">
                        {custDetail?.idTypeName}
                      </Typography>
                    </Item>
                  </Grid>
                </Grid>

                <Grid
                  container
                  spacing={{ xs: 0 }}
                  columns={{ xs: 1, sm: 12, md: 12 }}
                  sx={{
                    my: 4,
                    borderRadius: 5,
                    p: '24px 22px 30px 22px;',
                    border: (theme) => `2px solid ${theme.palette.background.popup}`,
                  }}
                >
                  <Grid item xs={12}>
                    <Typography variant="H5_Bold">receiver detail</Typography>
                  </Grid>

                  <Grid item xs={2} sm={4} md={4}>
                    <Item>
                      <Typography variant="Paragraph_xs_Light" component={'div'}>
                        Full Name
                      </Typography>
                      <Typography variant="Paragraph_sm_Light">
                        {receivDetail?.receiverFullName}
                      </Typography>
                    </Item>
                  </Grid>

                  <Grid item xs={2} sm={4} md={4}>
                    <Item>
                      <Typography variant="Paragraph_xs_Light" component={'div'}>
                        Phone Number
                      </Typography>
                      <Typography variant="Paragraph_sm_Light">
                        {receivDetail?.mobileNo}
                      </Typography>
                    </Item>
                  </Grid>

                  <Grid item xs={2} sm={4} md={4}>
                    <Item>
                      <Typography variant="Paragraph_xs_Light" component={'div'}>
                        Address
                      </Typography>
                      <Typography variant="Paragraph_sm_Light">
                        {receivDetail?.receiverFullAddress}
                      </Typography>
                    </Item>
                  </Grid>
                </Grid>

                <Grid
                  container
                  spacing={{ xs: 0 }}
                  columns={{ xs: 1, sm: 12, md: 12 }}
                  sx={{
                    mb: 4,
                    borderRadius: 5,
                    p: '24px 22px 30px 22px;',
                    border: (theme) => `2px solid ${theme.palette.background.popup}`,
                  }}
                >
                  <Grid item xs={12}>
                    <Typography variant="H5_Bold">Payout Partner Details</Typography>
                  </Grid>

                  <Grid item xs={2} sm={4} md={4}>
                    <Item>
                      <Typography variant="Paragraph_xs_Light" component={'div'}>
                        Country
                      </Typography>
                      <Typography variant="Paragraph_sm_Light">
                        {CountryNameByCode(receivDetail?.receiverCountry)}
                      </Typography>
                    </Item>
                  </Grid>

                  <Grid item xs={2} sm={4} md={4}>
                    <Item>
                      <Typography variant="Paragraph_xs_Light" component={'div'}>
                        Payout Partner
                      </Typography>
                      <Typography variant="Paragraph_sm_Light">
                        {receivDetail?.partnerName}
                      </Typography>
                    </Item>
                  </Grid>

                  <Grid item xs={2} sm={4} md={4}>
                    <Item>
                      <Typography variant="Paragraph_xs_Light" component={'div'}>
                        Bank Name / Bank Branch
                      </Typography>
                      <Typography variant="Paragraph_sm_Light">
                        {receivDetail?.bankName}
                      </Typography>
                    </Item>
                  </Grid>

                  <Grid item xs={2} sm={4} md={4}>
                    <Item>
                      <Typography variant="Paragraph_xs_Light" component={'div'}>
                        Account Number
                      </Typography>
                      <Typography variant="Paragraph_sm_Light">
                        {receivDetail?.receiverAc}
                      </Typography>
                    </Item>
                  </Grid>

                  <Grid item xs={12}>
                    <Divider
                      sx={{
                        mt: 4,
                        borderBottom: `2px solid ${theme.palette.background.popup}`,
                      }}
                    />
                  </Grid>

                  <Grid item xs={2} sm={3} md={3}>
                    <Item>
                      <Typography variant="Paragraph_xs_Light" component={'div'}>
                        Deposit Type
                      </Typography>
                      <Typography variant="Paragraph_sm_Light">
                        {deposit[0]?.bankName}
                      </Typography>
                    </Item>
                  </Grid>

                  <Grid item xs={2} sm={3} md={3}>
                    <Item>
                      <Typography variant="Paragraph_xs_Light" component={'div'}>
                        Ref 1
                      </Typography>
                      <Typography variant="Paragraph_sm_Light">
                        {deposit[0]?.deposit_detail1}
                      </Typography>
                    </Item>
                  </Grid>

                  <Grid item xs={2} sm={3} md={3}>
                    <Item>
                      <Typography variant="Paragraph_xs_Light" component={'div'}>
                        Ref 2
                      </Typography>
                      <Typography variant="Paragraph_sm_Light">
                        {deposit[0]?.deposit_detail2}
                      </Typography>
                    </Item>
                  </Grid>

                  <Grid item xs={2} sm={3} md={3}>
                    <Item>
                      <Typography variant="Paragraph_xs_Light" component={'div'}>
                        Deposit Date
                      </Typography>
                      <Typography variant="Paragraph_sm_Light">
                        {moment(deposit[0]?.depositDOT).format('YYYY-MM-DD')}
                      </Typography>
                    </Item>
                  </Grid>
                </Grid>

                <Grid
                  container
                  columns={{ xs: 2, sm: 8, md: 8 }}
                  sx={{
                    mb: 4,
                    borderRadius: 5,
                    border: (theme) => `2px solid ${theme.palette.background.popup}`,
                  }}
                >
                  <Grid item xs={12}>
                    <ItemBox>
                      <Typography variant="H5_Bold">Remittance details</Typography>
                    </ItemBox>
                  </Grid>

                  <Grid item xs={8}>
                    <ItemBox>
                      <Typography variant="Paragraph_lg_Light" component={'p'}>
                        Collected Amount :
                      </Typography>
                      <Typography variant="H4_Bold" component={'div'}>
                        {fNumberCurrency(receivDetail?.collectedAmount, currencyCode)}
                      </Typography>
                    </ItemBox>
                    <ItemBox>
                      <Typography variant="Paragraph_lg_Light" component={'p'}>
                        Transfer Amount :
                      </Typography>
                      <Typography variant="H4_Bold" component={'div'}>
                        {fNumberCurrency(receivDetail?.transferAmount, currencyCode)}
                      </Typography>
                    </ItemBox>

                    <ItemBox>
                      <Typography variant="Paragraph_lg_Light" component={'p'}>
                        Service Fee :
                      </Typography>
                      <Typography variant="H4_Bold" component={'div'}>
                        {fNumberCurrency(receivDetail?.serviceCharge, currencyCode)}
                      </Typography>
                    </ItemBox>

                    <ItemBox>
                      <Typography variant="Paragraph_lg_Light" component={'p'}>
                        Customer Rate :
                      </Typography>
                      <Typography variant="H4_Bold" component={'div'}>
                        {fNumberCurrency(
                          receivDetail?.customerRate,
                          receivDetail?.currency,
                        )}
                      </Typography>
                    </ItemBox>
                    <ItemBox>
                      <Typography variant="Paragraph_lg_Light" component={'p'}>
                        Payout Amount :
                      </Typography>
                      <Typography variant="H4_Bold" component={'div'}>
                        {fNumberCurrency(
                          receivDetail?.payoutAmount,
                          receivDetail?.currency,
                        )}
                      </Typography>
                    </ItemBox>
                  </Grid>
                </Grid>
              </CardExpand>
              <CardContent>
                <CheckBoxField
                  control={control}
                  name="fileSTR"
                  label="File a Suspicious Transaction Report (STR)"
                  onChange={() => EnterNote()}
                  //EnterNote
                />
              </CardContent>
            </>
          ) : (
            <Card>
              <CardContent>
                <Grid
                  container
                  spacing={{ xs: 1, sm: 2 }}
                  columns={{ xs: 1, sm: 8, md: 8 }}
                >
                  <Grid item xs={8}>
                    <Stack
                      sx={{
                        py: isMobile ? 4 : 6,
                        borderRadius: 5,
                        backgroundColor: (theme) => theme.palette.background.dark,
                      }}
                      alignItems="center"
                      gap="16px"
                    >
                      <Spinner
                        sx={{ p: '0px 50px 60px' }}
                        color={theme.palette.status.waiting}
                        size={200}
                      />
                      <Typography
                        variant="Paragraph_lg_Bold"
                        color="text.secondary"
                        component="div"
                        sx={{ mx: 1, my: 1 }}
                      >
                        The system is processing
                      </Typography>
                      <Typography variant="H4_Bold" component="div" sx={{ mx: 1, my: 1 }}>
                        Please wait a moment
                      </Typography>
                    </Stack>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          )}
        </Grid>
      </Stack>
    </div>
  )
}

export default ConfirmSendTransaction

const Item = styled('div')(({ theme }) => ({
  padding: theme.spacing(4, 0, 0),
  display: 'flex',
  flexDirection: 'column',
  div: {
    color: theme.palette.text.secondary,
    padding: theme.spacing(1, 0),
  },
}))

const ItemBox = styled(Paper)(({ theme }) => ({
  display: 'flex',
  margin: '26px 20px',
  flexDirection: 'column',

  [theme.breakpoints.up('md')]: {
    margin: '26px 80px',
    flexDirection: 'row',
  },
  div: {
    width: '100%',
    marginTop: 8,
    [theme.breakpoints.up('md')]: {
      width: '50%',
      marginTop: 0,
      textAlign: 'right',
      alignItems: 'flex-end',
    },
  },
  p: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    color: theme.palette.text.secondary,
    [theme.breakpoints.up('md')]: {
      width: '50%',
    },
  },
}))
