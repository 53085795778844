import { restApi } from '../utils/rest'

export const apiTransactionHistory = async (
  {
    tranStatus,
    paymentType,
    branch,
    receiverCountry,
    userID,
    dateType,
    fromDate,
    toDate,
    currentPage,
    pageSize,
    searchTxt,
  },
  callback = {},
) => {
  try {
    return await restApi(
      {
        url: `${process.env.REACT_APP_SERVICE_API}/api/v1/transaction/transaction-history?trn_Status=${tranStatus}&paymentType=${paymentType}&branch_Code=${branch}&receiverCountry=${receiverCountry}&userLoginID=${userID}&ddDate=${dateType}&fromDate=${fromDate}&toDate=${toDate}&currentPage=${currentPage}&pageSize=${pageSize}&searchTxt=${searchTxt}`,
        method: 'GET',
      },
      callback,
    )
  } catch (error) {
    return error
  }
}

export const apiTransactionBranchList = async (callback = {}) => {
  try {
    return await restApi(
      {
        url: `${process.env.REACT_APP_SERVICE_API}/api/v1/transaction/branch-list`,
        method: 'GET',
      },
      callback,
    )
  } catch (error) {
    return error
  }
}

export const apiTransactionUserIDList = async (callback = {}) => {
  try {
    return await restApi(
      {
        url: `${process.env.REACT_APP_SERVICE_API}/api/v1/transaction/user-list`,
        method: 'GET',
      },
      callback,
    )
  } catch (error) {
    return error
  }
}

export const apiTransactionReceiverCountriesList = async (callback = {}) => {
  try {
    return await restApi(
      {
        url: `${process.env.REACT_APP_SERVICE_API}/api/v1/transaction/receiver-countries-list`,
        method: 'GET',
      },
      callback,
    )
  } catch (error) {
    return error
  }
}

export const apiTransactionApproveAll = async (body = {}, callback = {}) => {
  try {
    return await restApi(
      {
        url: `${process.env.REACT_APP_SERVICE_API}/api/v1/transaction/approvealltransaction`,
        method: 'POST',
        body,
      },
      callback,
    )
  } catch (error) {
    return error
  }
}

export const apiTransactionApprove = async (body = {}, callback = {}) => {
  try {
    return await restApi(
      {
        url: `${process.env.REACT_APP_SERVICE_API}/api/v1/transaction/approve-transaction`,
        method: 'POST',
        body,
      },
      callback,
    )
  } catch (error) {
    return error
  }
}

export const apiSuspiciousTransaction = async (
  {
    suspiciousType,
    suspiciousMode,
    dateType,
    fromDate,
    toDate,
    currentPage,
    pageSize,
    searchTxt,
  },
  callback = {},
) => {
  try {
    return await restApi(
      {
        url: `${process.env.REACT_APP_SERVICE_API}/api/v1/transaction/suspicious-transaction?cmbDateType=${dateType}&suspiciousType=${suspiciousType}&suspiciousMode=${suspiciousMode}&fromDate=${fromDate}&toDate=${toDate}&currentPage=${currentPage}&pageSize=${pageSize}&searchTxt=${searchTxt}`,
        method: 'GET',
      },
      callback,
    )
  } catch (error) {
    return error
  }
}

export const apiTransactionPendingList = async (
  { currentPage, pageSize },
  callback = {},
) => {
  try {
    return await restApi(
      {
        url: `${process.env.REACT_APP_SERVICE_API}/api/v1/transaction/transaction-pending?currentPage=${currentPage}&pageSize=${pageSize}`,
        method: 'GET',
      },
      callback,
    )
  } catch (error) {
    return error
  }
}

export const apiEditPendingTransaction = async ({ route }, callback = {}) => {
  try {
    return await restApi(
      {
        url: `${process.env.REACT_APP_SERVICE_API}/api/v1/Transaction/edit-pending-transaction?route=${route}`,
        method: 'GET',
      },
      callback,
    )
  } catch (error) {
    return error
  }
}

export const apiUpdatePendingTransaction = async (body = {}, callback = {}) => {
  try {
    return await restApi(
      {
        url: `${process.env.REACT_APP_SERVICE_API}/api/v1/Transaction/update-pending-transaction`,
        method: 'PUT',
        body,
      },
      callback,
    )
  } catch (error) {
    return error
  }
}

export const apiTransactionReprocess = async (body = {}, callback = {}) => {
  try {
    return await restApi(
      {
        url: `${process.env.REACT_APP_SERVICE_API}/api/v1/transaction/reprocess-transaction`,
        method: 'POST',
        body,
      },
      callback,
    )
  } catch (error) {
    return error
  }
}

export const apiTransactionPendingDelete = async (body = {}, callback = {}) => {
  try {
    return await restApi(
      {
        url: `${process.env.REACT_APP_SERVICE_API}/api/v1/transaction/delete-pending-transaction`,
        method: 'DELETE',
        body,
      },
      callback,
    )
  } catch (error) {
    return error
  }
}

export const apiGetTransactionDetails = async (TransactionNo, callback = {}) => {
  try {
    return await restApi(
      {
        url: `${process.env.REACT_APP_SERVICE_API}/api/v1/transaction/transaction-detail?TransactionNo=${TransactionNo}`,
        method: 'GET',
      },
      callback,
    )
  } catch (error) {
    return error
  }
}

export const apiGetDocuments = async (tableName, imageID, imagePath, callback = {}) => {
  try {
    return await restApi(
      {
        url: `${process.env.REACT_APP_SERVICE_API}/api/v1/transaction/get-documents?tableName=${tableName}&imageID=${imageID}&imagePath=${imagePath}`,
        method: 'GET',
      },
      callback,
    )
  } catch (error) {
    return error
  }
}
