import React, { useState, useMemo } from 'react'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import useScrollTrigger from '@mui/material/useScrollTrigger'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Button from '@mui/material/Button'
import { Link } from 'react-router-dom'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import IconButton from '@mui/material/IconButton'
// import MenuIcon from "@material-ui/icons/Menu";
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import routes from './config'
import useStyles from './styles'

export default function Navigator(props) {
  const { classes } = useStyles()
  const theme = useTheme()
  // const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);
  const matches = useMediaQuery(theme.breakpoints.down('sm'))

  const [openDrawer, setOpenDrawer] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const [openMenu, setOpenMenu] = useState(false)

  const handleChange = (event, newValue) => {
    props.setValue(newValue)
  }

  // const handleClick = (e) => {
  //   setAnchorEl(e.currentTarget);
  //   setOpenMenu(true);
  // };

  // const handleMenuItemClick = (e, i) => {
  //   setAnchorEl(null);
  //   setOpenMenu(false);
  //   props.setSelectedIndex(i);
  // };

  const handleClose = (e) => {
    setAnchorEl(null)
    setOpenMenu(false)
  }

  // const menuOptions = [
  //   { name: "Services", link: "/services", activeIndex: 1, selectedIndex: 0 },
  //   {
  //     name: "Custom Software Development",
  //     link: "/customsoftware",
  //     activeIndex: 1,
  //     selectedIndex: 1,
  //   },
  //   {
  //     name: "iOS/Android App Development",
  //     link: "/mobileapps",
  //     activeIndex: 1,
  //     selectedIndex: 2,
  //   },
  //   {
  //     name: "Website Development",
  //     link: "/websites",
  //     activeIndex: 1,
  //     selectedIndex: 3,
  //   },
  // ];

  // const routes = [
  //   { name: "Home", link: "/", activeIndex: 0 },
  //   {
  //     name: "Services",
  //     link: "/services",
  //     activeIndex: 1,
  //     ariaOwns: anchorEl ? "simple-menu" : undefined,
  //     ariaPopup: anchorEl ? "true" : undefined,
  //     mouseOver: (event) => handleClick(event),
  //   },
  //   {
  //     name: "The Revolution",
  //     link: "/revolution",
  //     activeIndex: 2,
  //   },
  //   { name: "About Us", link: "/about", activeIndex: 3 },
  //   { name: "Contact Us", link: "/contact", activeIndex: 4 },
  // ];

  useMemo(() => {
    // [...menuOptions, ...routes].forEach((route) => {
    ;[...routes].forEach((route) => {
      switch (window.location.pathname) {
        case `${route.link}`:
          if (props.value !== route.activeIndex) {
            props.setValue(route.activeIndex)
            if (route.selectedIndex && route.selectedIndex !== props.selectedIndex) {
              props.setSelectedIndex(route.selectedIndex)
            }
          }
          break

        // case "/":
        //   return;
        default:
          break
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const tabs = (
    <React.Fragment>
      <Tabs
        value={props.value}
        onChange={handleChange}
        className={classes.tabContainer}
        indicatorColor="primary"
      >
        {routes.map((route, index) => (
          <Tab
            key={`${route}${index}`}
            className={classes.tab}
            component={Link}
            to={route.link}
            label={route.name}
            aria-owns={route.ariaOwns}
            aria-haspopup={route.ariaPopup}
            onMouseOver={route.mouseOver}
          />
        ))}
      </Tabs>
      {/* <Button
        component={Link}
        to="/estimate"
        variant="contained"
        color="secondary"
        className={classes.button}
        onClick={() => props.setValue(5)}
      >
        Free Estimate
      </Button> */}
      {/* <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleClose}
        classes={{ paper: classes.menu }}
        MenuListProps={{
          onMouseLeave: handleClose,
        }}
        elevation={0}
        style={{ zIndex: 1302 }}
        keepMounted
      >
        {menuOptions.map((option, i) => (
          <MenuItem
            key={`${option}${i}`}
            component={Link}
            to={option.link}
            classes={{ root: classes.menuItem }}
            onClick={(event) => {
              handleMenuItemClick(event, i);
              props.setValue(1);
              handleClose();
            }}
            selected={i === props.selectedIndex && props.value === 1}
          >
            {option.name}
          </MenuItem>
        ))}
      </Menu> */}
    </React.Fragment>
  )

  const drawer = (
    <React.Fragment>
      <SwipeableDrawer
        // disableBackdropTransition={!iOS}
        // disableDiscovery={iOS}
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        onOpen={() => setOpenDrawer(true)}
        classes={{ paper: classes.drawer }}
      >
        <div className={classes.toolbarMargin} />
        <List disablePadding>
          {routes.map((route) => (
            <ListItem
              divider
              key={`${route}${route.activeIndex}`}
              button
              component={Link}
              to={route.link}
              selected={props.value === route.activeIndex}
              classes={{ selected: classes.drawerItemSelected }}
              onClick={() => {
                setOpenDrawer(false)
                props.setValue(route.activeIndex)
              }}
            >
              <ListItemText className={classes.drawerItem} disableTypography>
                {route.name}
              </ListItemText>
            </ListItem>
          ))}
        </List>
      </SwipeableDrawer>
      <IconButton
        className={classes.drawerIconContainer}
        onClick={() => setOpenDrawer(!openDrawer)}
        disableRipple
      >
        menu
        {/* <MenuIcon className={classes.drawerIcon} /> */}
      </IconButton>
    </React.Fragment>
  )

  return (
    <React.Fragment>
      <ElevationScroll>
        {/* <AppBar position="fixed" className={classes.appbar}> */}
        {/* <Toolbar disableGutters> */}
        {/* <Button
              component={Link}
              to="/"
              disableRipple
              onClick={() => props.setValue(0)}
              className={classes.logoContainer}
            >
              <img
                alt="company logo"
                className={classes.logo}
                src="https://via.placeholder.com/300"
              />
            </Button> */}
        {matches ? drawer : tabs}
        {/* </Toolbar> */}
        {/* </AppBar> */}
      </ElevationScroll>
      <div className={classes.toolbarMargin} />
    </React.Fragment>
  )
}

function ElevationScroll(props) {
  const { children } = props

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  })

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  })
}
