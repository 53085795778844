import React from 'react'
import { useSelector } from 'react-redux'
import { Grid, Stack } from '@mui/material'
import PatternNumberField from '../../../components/common/PatternNumberField'
import AutocompleteField from '../../../components/common/AutocompleteField'
import PhoneNumberField from '../../../components/common/PhoneNumberField'
import NumberField from '../../../components/common/NumberField'
import EmailField from '../../../components/common/EmailField'
import CardExpand from '../../../components/common/CardExpand'
import InputField from '../../../components/common/InputField'
import RadioField from '../../../components/common/RadioField'
import DateField from '../../../components/common/DateField'
import { ValidateModel } from '../../../utils/app'
import isEmpty from 'lodash/isEmpty'

const SenderDetails = ({ control, watch, currency }) => {
  const GENDERLIST = useSelector((state) => state?.master?.genders)
  const COUNTRYLIST = useSelector((state) => state?.master?.countries)
  const SENDERTYPE = useSelector((state) => state?.master?.sender_type)
  const OPTION_ID_TYPE = useSelector((state) => state?.master?.id_types)
  const FIELD_RULES = useSelector((state) => state?.master?.fieldvalidate)
  const OPTION_VISA_TYPE = useSelector((state) => state?.master?.visa_types)
  const OPTION_OCCUPATION = useSelector((state) => state?.master?.occupations)
  const OPTION_SOF = useSelector((state) => state?.master?.sourceoffund)
  const OPTION_TRANSACTION_TYPE = useSelector((state) => state?.master?.transaction_type)
  const CUSTOMER_DETAIL = useSelector((state) => state?.master?.customer_detail)

  return (
    <div>
      <Stack spacing={3}>
        <Grid item sm={6}>
          <CardExpand title="select country" defaultOpen>
            <Grid container spacing={{ xs: 1, sm: 2 }} columns={{ xs: 1, sm: 8, md: 8 }}>
              <Grid item xs={2} sm={4} md={4}>
                <AutocompleteField
                  control={control}
                  name="country_code"
                  label="Country"
                  placeholder="Select country"
                  options={COUNTRYLIST}
                  required
                />
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <AutocompleteField
                  control={control}
                  name="transaction_type"
                  label="Transaction Type"
                  placeholder="Select transaction type"
                  options={OPTION_TRANSACTION_TYPE}
                  InputProp={currency || '-'}
                  required
                />
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <AutocompleteField
                  control={control}
                  name="sender_type"
                  label="Sender Type"
                  placeholder="Select sender type"
                  options={SENDERTYPE}
                  required
                  disabled={!isEmpty(CUSTOMER_DETAIL)}
                />
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <AutocompleteField
                  control={control}
                  name="receiver_type"
                  label="Receiver Type"
                  placeholder="Select receiver type"
                  options={SENDERTYPE}
                  required
                />
              </Grid>
            </Grid>
          </CardExpand>
        </Grid>

        <Grid item sm={6}>
          <CardExpand title="Sender details" defaultOpen>
            <Grid container spacing={{ xs: 1, sm: 2 }} columns={{ xs: 1, sm: 8, md: 8 }}>
              {!isEmpty(CUSTOMER_DETAIL) && (
                <Grid item xs={2} sm={4} md={4}>
                  <InputField
                    control={control}
                    name="customerid"
                    label="Customer ID"
                    placeholder="Customer ID"
                    // inputProps={{ readOnly: true }}
                    disabled
                  />
                </Grid>
              )}
              <Grid item xs={2} sm={4} md={4}>
                <InputField
                  control={control}
                  name="s_firstName"
                  label="First Name"
                  placeholder="First Name"
                  required={ValidateModel(FIELD_RULES, 'sender_first_name')}
                  disabled={!isEmpty(CUSTOMER_DETAIL)}
                />
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <InputField
                  control={control}
                  name="s_middleName"
                  label="Middle Name"
                  placeholder="-"
                  required={ValidateModel(FIELD_RULES, 'sender_middle_name')}
                  disabled={!isEmpty(CUSTOMER_DETAIL)}
                />
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <InputField
                  control={control}
                  name="s_lastName"
                  label="Last Name"
                  placeholder="Last Name"
                  required={ValidateModel(FIELD_RULES, 'sender_last_name')}
                  disabled={!isEmpty(CUSTOMER_DETAIL)}
                />
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <RadioField
                  control={control}
                  label="Gender"
                  name="s_gender"
                  options={GENDERLIST}
                  // required
                  disabled={!isEmpty(CUSTOMER_DETAIL)}
                />
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <PhoneNumberField
                  control={control}
                  name="s_phoneNumber"
                  label="Phone Number"
                  placeholder="Phone Number"
                  required={ValidateModel(FIELD_RULES, 'sender_mobile')}
                  disabled={!isEmpty(CUSTOMER_DETAIL)}
                />
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <AutocompleteField
                  control={control}
                  name="sender_type"
                  label="Sender Type"
                  placeholder="Select Sender Type"
                  options={SENDERTYPE}
                  required
                  disabled={!isEmpty(CUSTOMER_DETAIL)}
                />
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <EmailField
                  control={control}
                  name="s_email"
                  label="Email"
                  placeholder="Email"
                  required={ValidateModel(FIELD_RULES, 'sender_emailId')}
                  disabled={!isEmpty(CUSTOMER_DETAIL)}
                />
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <DateField
                  control={control}
                  name="s_dateOfBirth"
                  label="Date of Birth"
                  placeholder="Date of Birth"
                  required //={ValidateModel(FIELD_RULES, 'sender_date_of_birth')}
                  disabled={!isEmpty(CUSTOMER_DETAIL)}
                />
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <AutocompleteField
                  control={control}
                  name="s_nationality"
                  label="Nationality"
                  placeholder="Select Nationality"
                  options={COUNTRYLIST}
                  required={ValidateModel(FIELD_RULES, 'sender_nationality_code')}
                  disabled={!isEmpty(CUSTOMER_DETAIL)}
                />
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <AutocompleteField
                  control={control}
                  name="s_occupation"
                  label="Occupation"
                  placeholder="Select Occupation"
                  options={OPTION_OCCUPATION}
                  required={ValidateModel(FIELD_RULES, 'sender_occupation')}
                />
              </Grid>
              {watch('s_occupation') === '99' && (
                <Grid item xs={2} sm={4} md={4}>
                  <InputField
                    control={control}
                    name="s_occupation_other"
                    label="Other Occupation"
                    placeholder="Select Other Occupation"
                  />
                </Grid>
              )}
            </Grid>
          </CardExpand>
        </Grid>

        <Grid item sm={6}>
          <CardExpand
            title="Sender Address details"
            defaultOpen={!!isEmpty(CUSTOMER_DETAIL)}
          >
            <Grid container spacing={{ xs: 1, sm: 2 }} columns={{ xs: 1, sm: 8, md: 8 }}>
              <Grid item xs={2} sm={4} md={4}>
                <AutocompleteField
                  control={control}
                  name="s_country"
                  label="Country"
                  placeholder="Select country"
                  options={COUNTRYLIST}
                  required={ValidateModel(FIELD_RULES, 'sender_country')}
                />
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <NumberField
                  control={control}
                  name="s_postalCode"
                  label="Postal code"
                  placeholder="Postal Code"
                  required={ValidateModel(FIELD_RULES, 'sender_zip_code')}
                />
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <InputField
                  control={control}
                  name="s_state"
                  label="State"
                  placeholder="State"
                  required={ValidateModel(FIELD_RULES, 'sender_state')}
                />
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <InputField
                  control={control}
                  name="s_city"
                  label="City"
                  placeholder="City"
                  required={ValidateModel(FIELD_RULES, 'sender_city')}
                />
              </Grid>
              {/* <Grid item xs={2} sm={4} md={4}>
                <InputField
                  control={control}
                  name="district"  no use
                  label="District"
                  placeholder="-"
                />
              </Grid> */}
              <Grid item xs={2} sm={4} md={4}>
                <InputField
                  control={control}
                  name="s_street"
                  label="Street"
                  placeholder="-"
                  required={ValidateModel(FIELD_RULES, 'sender_address')}
                />
              </Grid>
            </Grid>
          </CardExpand>
        </Grid>

        <Grid item sm={6}>
          <CardExpand
            title="Sender document details"
            defaultOpen={!!isEmpty(CUSTOMER_DETAIL)}
          >
            <Grid container spacing={{ xs: 1, sm: 2 }} columns={{ xs: 1, sm: 8, md: 8 }}>
              <Grid item xs={2} sm={4} md={4}>
                <AutocompleteField
                  control={control}
                  name="s_visa_type"
                  label="Visa Type"
                  placeholder="Select Visa Type"
                  options={OPTION_VISA_TYPE}
                  getOptionLabel={(option) => `${option?.label}`}
                  required={ValidateModel(FIELD_RULES, 'sender_visa_type')}
                  disabled={!isEmpty(CUSTOMER_DETAIL)}
                />
              </Grid>
              {watch('s_visa_type') === '99' && (
                <Grid item xs={2} sm={4} md={4}>
                  <InputField
                    control={control}
                    name="s_visa_type_other"
                    label="Other Visa Type"
                    placeholder="Select Other Visa Type"
                    disabled={!isEmpty(CUSTOMER_DETAIL)}
                  />
                </Grid>
              )}
              <Grid item xs={2} sm={4} md={4}>
                <AutocompleteField
                  control={control}
                  name="s_id_type"
                  label="ID Type"
                  placeholder="ID Type"
                  options={OPTION_ID_TYPE}
                  getOptionLabel={(option) => `${option?.label}`}
                  required={ValidateModel(FIELD_RULES, 'sender_primary_id_type')}
                  disabled={!isEmpty(CUSTOMER_DETAIL)}
                />
              </Grid>
              {watch('s_id_type') === '99' && (
                <Grid item xs={2} sm={4} md={4}>
                  <InputField
                    control={control}
                    name="s_id_type_other"
                    label="Other ID Type"
                    placeholder="Select Other ID Type"
                    disabled={!isEmpty(CUSTOMER_DETAIL)}
                  />
                </Grid>
              )}
              <Grid item xs={2} sm={4} md={4}>
                <PatternNumberField
                  control={control}
                  name="s_id_number"
                  label="ID Number"
                  placeholder="ID Number"
                  required //={ValidateModel(FIELD_RULES, 'sender_primary_id_number')}
                  disabled={!isEmpty(CUSTOMER_DETAIL)}
                />
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <AutocompleteField
                  control={control}
                  name="s_id_issue_country"
                  label="ID Issue Country"
                  placeholder="Select ID Issue Country"
                  options={COUNTRYLIST}
                  getOptionLabel={(option) => `${option?.label}`}
                  required={ValidateModel(
                    FIELD_RULES,
                    'sender_primary_id_place_of_issue_code',
                  )}
                  disabled={!isEmpty(CUSTOMER_DETAIL)}
                />
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <DateField
                  control={control}
                  name="s_issue_date"
                  label="ID Issue Date"
                  placeholder="ID Issue Date"
                  required={ValidateModel(FIELD_RULES, 'sender_id_issue_date')}
                  disabled={!isEmpty(CUSTOMER_DETAIL)}
                />
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <DateField
                  control={control}
                  name="s_expire_date"
                  label="ID Expire Date"
                  placeholder="ID Expire Date"
                  required={ValidateModel(FIELD_RULES, 'sender_id_expiry_date')}
                  disabled={!isEmpty(CUSTOMER_DETAIL)}
                />
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <AutocompleteField
                  control={control}
                  name="s_source_of_fund"
                  label="Source of Fund"
                  placeholder="Select Source of Funds"
                  options={OPTION_SOF}
                  getOptionLabel={(option) => `${option?.label}`}
                  required={ValidateModel(FIELD_RULES, 'source_of_income')}
                />
              </Grid>
              {watch('s_source_of_fund') === '99' && (
                <Grid item xs={2} sm={4} md={4}>
                  <InputField
                    control={control}
                    name="s_source_of_fund_other"
                    label="Other Source of Funds"
                    placeholder="Select Other Source of Funds"
                  />
                </Grid>
              )}
            </Grid>
          </CardExpand>
        </Grid>
      </Stack>
    </div>
  )
}

export default SenderDetails
