import React from 'react'
import { DialogContent, DialogTitle, Dialog, Typography, Box } from '@mui/material'
import Slide from '@mui/material/Slide'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import { makeStyles } from 'tss-react/mui'
import { hideScrollbarY, hideScrollbarX } from '../utils/stylecss'

const Transition = React.forwardRef((props, ref) => {
  return <Slide direction="up" ref={ref} {...props} />
})

export default function usePopup(props) {
  const { title, children, openPopup, setOpenPopup } = props
  const { classes } = useStyles()

  return (
    <React.Fragment>
      {title ? (
        <Dialog
          maxWidth="xl"
          open={openPopup}
          className={classes.root}
          TransitionComponent={Transition}
        >
          <DialogTitle className={classes.title}>
            <Typography noWrap variant="H4_Bold" component="div">
              {title}
            </Typography>
            <IconButton color="secondary" onClick={() => setOpenPopup(false)}>
              <CloseIcon sx={{ color: 'text.primary' }} />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers className={classes.wrapper}>
            {children}
          </DialogContent>
        </Dialog>
      ) : (
        <Dialog maxWidth="xl" open={openPopup} TransitionComponent={Transition}>
          <Box spacing={{ xs: 2, md: 3 }} className={classes.wrap}>
            <IconButton color="secondary" onClick={() => setOpenPopup(false)}>
              <CloseIcon />
            </IconButton>
          </Box>
          <DialogContent>{children}</DialogContent>
        </Dialog>
      )}
    </React.Fragment>
  )
}

const useStyles = makeStyles()((theme) => ({
  root: {
    '& .MuiDialog-paper': {
      maxWidth: 660,
      borderRadius: 30,
      boxShadow: 'none',
      padding: '30px 0px',
      margin: 20,
      [theme.breakpoints.down('md')]: {
        width: '100%',
        margin: 10,
      },
      border: `2px solid ${theme.palette.outline.popup}`,
      background:
        'linear-gradient(0deg, #272C53, #272C53), linear-gradient(0deg, #444A81, #444A81)',
    },
    '& .MuiDialog-container': {
      alignItems: 'flex-start',
    },
  },
  wrapper: {
    width: '100%',
    boxShadow: 24,
    border: 'none',
    padding: 0,
    ...hideScrollbarY,
    ...hideScrollbarX,
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0px 30px',
  },
  wrap: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
}))
