import React from 'react'
import { Grid } from '@mui/material'
import InputField from '../../../components/common/InputField'

export default function ReceiverBankDetails(props) {
  const { control } = props

  return (
    <Grid container spacing={{ xs: 1, sm: 2 }} columns={{ xs: 1, sm: 8, md: 8 }}>
      <Grid item xs={2} sm={4} md={4}>
        <InputField
          control={control}
          name="receiverBankAbbbankname"
          label="Abbreviated Bank Name"
          placeholder="Abbreviated Bank Name"
        />
      </Grid>
      <Grid item xs={2} sm={4} md={4}>
        <InputField
          control={control}
          name="receiverBankReceiverBIc"
          label="Receiver Bank BICB/Bank Code"
          placeholder="Receiver Bank BICB/Bank Code"
        />
      </Grid>
      <Grid item xs={2} sm={4} md={4}>
        <InputField
          control={control}
          name="receiverBankReceiveriBan"
          label="Receiver Bank IBAN"
          placeholder="Receiver Bank IBAN"
        />
      </Grid>
      <Grid item xs={2} sm={4} md={4}>
        <InputField
          control={control}
          name="receiverBankReceiverswift"
          label="Receiver SWIFTBIC/Branch Code"
          placeholder="Receiver SWIFTBIC/Branch Code"
        />
      </Grid>
      <Grid item xs={2} sm={4} md={4}>
        <InputField
          control={control}
          name="receiverBankReceiverbanknationalID"
          label="Receiver Bank National ID"
          placeholder="Receiver Bank National ID"
        />
      </Grid>
      <Grid item xs={2} sm={4} md={4}>
        <InputField
          control={control}
          name="receiverBankAccountsuffix"
          label="Receiver Bank Account Suffix"
          placeholder="Receiver Bank Account Suffix"
        />
      </Grid>
      <Grid item xs={2} sm={4} md={4}>
        <InputField
          control={control}
          name="receiverBankSubranch"
          label="Sub Branch Name"
          placeholder="Sub Branch Name"
        />
      </Grid>
    </Grid>
  )
}
