import React, { useState, useEffect } from 'react'
import { Grid } from '@mui/material'
import { isNil } from 'lodash'
import AutocompleteField from '../../../components/common/AutocompleteField'
import InputField from '../../../components/common/InputField'
import DateField from '../../../components/common/DateField'
import { apiGetIDTypeList, apiMasterCountryList } from '../../../api/master'
import { apiGetVisaTypeList, apiGetSourceOfFundList } from '../../../api/customer'

export default function SenderAdditionalInformation(props) {
  const { control, setNotify, useWatch } = props

  const [visaTypeRecords, setVisaTypeRecords] = useState([])
  const [sourceOfFundRecords, setSourceOfFundRecords] = useState([])
  const [idTypeRecords, setIDTypeRecords] = useState([])
  const [countryRecords, setCountryRecords] = useState([])

  const fetchVisaTypeList = async () => {
    const response = await apiGetVisaTypeList()
    if (response?.code !== 0 || !isNil(response?.error)) {
      setNotify({
        open: true,
        message: response?.message,
        type: 'error',
      })
      return
    }
    const optionVisaType = response?.data?.map((e) => {
      return { label: e?.text, value: e?.value }
    })
    setVisaTypeRecords(optionVisaType)
  }

  const fetchSourceOfFundList = async () => {
    const response = await apiGetSourceOfFundList()
    if (response?.code !== 0 || !isNil(response?.error)) {
      setNotify({
        open: true,
        message: response?.message,
        type: 'error',
      })
      return
    }
    const optionSourceOfFund = response?.data?.map((e) => {
      return { label: e?.text, value: e?.value }
    })
    setSourceOfFundRecords(optionSourceOfFund)
  }

  const fetchIDTypeList = async () => {
    const response = await apiGetIDTypeList()
    if (response?.code !== 0 || !isNil(response?.error)) {
      setNotify({
        open: true,
        message: response?.message,
        type: 'error',
      })
      return
    }
    const optionIDType = response?.data?.map((e) => {
      return { label: e?.text, value: e?.value }
    })
    setIDTypeRecords(optionIDType)
  }

  const fetchCountryList = async () => {
    const response = await apiMasterCountryList()
    if (response?.code !== 0 || !isNil(response?.error)) {
      setNotify({
        open: true,
        message: response?.message,
        type: 'error',
      })
      return
    }
    const optionCountry = response?.data?.map((e) => {
      return { label: e?.text, value: e?.value }
    })
    setCountryRecords(optionCountry)
  }

  useEffect(() => {
    fetchVisaTypeList()
    fetchSourceOfFundList()
    fetchIDTypeList()
    fetchCountryList()
  }, [])

  return (
    <Grid container spacing={{ xs: 1, sm: 2 }} columns={{ xs: 1, sm: 8, md: 8 }}>
      <Grid item xs={2} sm={4} md={4}>
        <AutocompleteField
          control={control}
          name="senderAdditionalTypeVisa"
          label="Visa Type"
          placeholder="Select Visa Type"
          options={visaTypeRecords}
        />
      </Grid>
      <Grid item xs={2} sm={4} md={4}>
        <AutocompleteField
          control={control}
          name="senderAdditionalSourceOfIncome"
          label="Source of fund"
          placeholder="Source of fund"
          options={sourceOfFundRecords}
          required
        />
      </Grid>
      {useWatch({ control, name: 'senderAdditionalSourceOfIncome' }) === '99' && (
        <Grid item xs={2} sm={4} md={4}>
          <InputField
            control={control}
            name="senderAdditionalSourceOfIncomeOther"
            label="Other Source Of Fund"
            placeholder="Other Source Of Fund"
            required
          />
        </Grid>
      )}
      <Grid item xs={2} sm={4} md={4}>
        <InputField
          control={control}
          name="senderAdditionalCompanyName"
          label="Company Name"
          placeholder="Company Name"
        />
      </Grid>
      <Grid item xs={2} sm={4} md={4}>
        <InputField
          control={control}
          name="senderAdditionalCompanyAddress"
          label="Company Address"
          placeholder="Company Address"
        />
      </Grid>
      <Grid item xs={2} sm={4} md={4}>
        <InputField
          control={control}
          name="senderAdditionalCompanyContact"
          label="Company Contact"
          placeholder="Company Contact"
        />
      </Grid>
      <Grid item xs={2} sm={4} md={4}>
        <AutocompleteField
          control={control}
          name="senderAdditionalPrimaryIdType"
          label="ID Type"
          placeholder="Select ID Type"
          options={idTypeRecords}
        />
      </Grid>
      {useWatch({ control, name: 'senderAdditionalPrimaryIdType' }) === '99' && (
        <Grid item xs={2} sm={4} md={4}>
          <InputField
            control={control}
            name="senderAdditionalPrimaryIdTypeOther"
            label="ID Type Other"
            placeholder="ID Type Other"
            required
          />
        </Grid>
      )}
      <Grid item xs={2} sm={4} md={4}>
        <InputField
          control={control}
          name="senderAdditionalPrimaryIdNumber"
          label="ID Number"
          placeholder="ID Number"
        />
      </Grid>
      <Grid item xs={2} sm={4} md={4}>
        <AutocompleteField
          control={control}
          name="senderAdditionalPrimaryIdPlaceOfIssue"
          label="ID Issue Country"
          placeholder="Select ID Issue Country"
          options={countryRecords}
        />
      </Grid>
      <Grid item xs={2} sm={4} md={4}>
        <DateField
          control={control}
          name="senderAdditionalPrimaryIdIssueDate"
          label="ID Issue Date"
          placeholder="ID Issue Date"
        />
      </Grid>
      <Grid item xs={2} sm={4} md={4}>
        <DateField
          control={control}
          name="senderAdditionalPrimaryIdExpirydate"
          label="ID Expire Date"
          placeholder="ID Expire Date"
        />
      </Grid>
      <Grid item xs={2} sm={4} md={4}>
        <InputField
          control={control}
          name="senderAdditionalKycinputby"
          label="KYC Input By"
          placeholder="KYC Input By"
        />
      </Grid>
      <Grid item xs={4} sm={8} md={8}>
        <InputField
          control={control}
          name="senderAdditionalCustomerRemarks"
          label="Remarks"
          placeholder="Remarks"
          multiline
          rows={4}
        />
      </Grid>
    </Grid>
  )
}
