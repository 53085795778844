export default function Card(theme) {
  return {
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          backgroundColor: theme.palette.background.dark,
          borderRadius: 30,
          position: 'relative',
          zIndex: 0, // Fix Safari overflow: hidden with border radius
        },
      },
    },
    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: { variant: 'H5_Bold' },
        subheaderTypographyProps: { variant: 'Paragraph_lg_Light' },
      },
      styleOverrides: {
        root: {
          padding: theme.spacing(3, 4, 3),
        },
      },
    },

    MuiCardContent: {
      styleOverrides: {
        root: {
          // padding: theme.spacing(0, 4, 3),
        },
      },
    },
  }
}
