import React from 'react'
import { Alert, AlertTitle, Grid } from '@mui/material'
import Button from '../../../components/common/Button'
import SvgColor from '../../../components/svg-color/svg-color'
import { fNumberCurrency } from '../../../utils/formatNumer'
import { makeStyles } from 'tss-react/mui'
import { ConvertDateYYYYMMDD } from '../../../utils/app'

export default function CustomerDetails(props) {
  const { customerInfo } = props
  const { classes } = useStyles()

  return (
    <Grid container spacing={{ xs: 1, sm: 2 }} columns={{ xs: 1, sm: 8, md: 8 }}>
      <Grid item xs={2} sm={3} md={3}>
        <Grid
          container
          spacing={{ xs: 1, sm: 2 }}
          columns={{ xs: 1, sm: 8, md: 8 }}
          sx={{
            paddingLeft: '18px',
          }}
        >
          <Grid item xs={2} sm={8} md={8}>
            <img
              alt=""
              src={`/assets/icons/icon_customer_profile_circle.svg`}
              style={{ width: 200, height: 200, color: 'outline.primary' }}
            />
          </Grid>
          <Grid
            item
            xs={2}
            sm={8}
            md={8}
            sx={{
              margin: '0 0 0 -9px',
            }}
          >
            <Button
              startIcon={
                <SvgColor
                  src={`/assets/icons/icon_upload.svg`}
                  sx={{ width: 20, height: 20, color: '#646671' }}
                />
              }
              text="Upload Document"
              size="small"
              variant="outlined"
              color="inherit"
              sx={{ width: '80%' }}
            />
          </Grid>
          {customerInfo?.pendingMessage !== undefined &&
          customerInfo?.pendingMessage !== null &&
          customerInfo?.pendingMessage !== '' ? (
            <Grid
              item
              xs={2}
              sm={8}
              md={8}
              sx={{
                width: '80%',
                padding: '40px',
              }}
            >
              <Alert severity="error" className={classes.alertMsg}>
                <AlertTitle>{customerInfo?.pendingMessage}</AlertTitle>
              </Alert>
            </Grid>
          ) : null}
        </Grid>
      </Grid>
      <Grid item xs={2} sm={5} md={5}>
        <Grid
          container
          spacing={{ xs: 1, sm: 2 }}
          columns={{ xs: 1, sm: 8, md: 8 }}
          sx={{
            padding: `9px 60px 18px 60px`,
            borderRadius: 30,
            background: '#8655D1',
            fontFamily: 'Overpass',
            fontSize: '20px',
            fontWeight: '700',
            lineHeight: '20px',
            letterSpacing: '0.0015em',
            textAlign: 'left',
          }}
        >
          <Grid item xs={2} sm={8} md={8}>
            BALANCE : &nbsp;
            {customerInfo?.customerdetailModel?.wallet !== undefined
              ? fNumberCurrency(
                  customerInfo?.customerdetailModel?.wallet,
                  customerInfo?.customerdetailModel?.currency,
                )
              : ''}
          </Grid>
        </Grid>
        <Grid
          container
          spacing={{ xs: 1, sm: 2 }}
          columns={{ xs: 1, sm: 8, md: 8 }}
          sx={{
            padding: `0 18px 18px 18px`,
            borderRadius: 10,
            border: '2px solid #444A81',
            marginTop: '30px !important',
          }}
        >
          <Grid item xs={2} sm={3} md={3}>
            Username :
          </Grid>
          <Grid item xs={2} sm={5} md={5} sx={{ color: '#FFFFFF' }}>
            {customerInfo?.customerdetailModel?.userName}
          </Grid>
          <Grid item xs={2} sm={3} md={3}>
            Date of birth :
          </Grid>
          <Grid item xs={2} sm={5} md={5} sx={{ color: '#FFFFFF' }}>
            {customerInfo?.customerdetailModel?.dateOfBirth !== undefined
              ? ConvertDateYYYYMMDD(customerInfo?.customerdetailModel?.dateOfBirth)
              : ''}
          </Grid>
          <Grid item xs={2} sm={3} md={3}>
            Nationality :
          </Grid>
          <Grid item xs={2} sm={5} md={5} sx={{ color: '#FFFFFF' }}>
            {customerInfo?.customerdetailModel?.nationality}
          </Grid>
          <Grid item xs={2} sm={3} md={3}>
            Email :
          </Grid>
          <Grid item xs={2} sm={5} md={5} sx={{ color: '#FFFFFF' }}>
            {customerInfo?.customerdetailModel?.email}
          </Grid>
          <Grid item xs={2} sm={3} md={3}>
            Mobile No :
          </Grid>
          <Grid item xs={2} sm={5} md={5} sx={{ color: '#FFFFFF' }}>
            {customerInfo?.customerdetailModel?.mobileNo}
          </Grid>
          <Grid item xs={2} sm={3} md={3}>
            Address :
          </Grid>
          <Grid item xs={2} sm={5} md={5} sx={{ color: '#FFFFFF' }}>
            {customerInfo?.customerdetailModel?.state}&nbsp;
            {customerInfo?.customerdetailModel?.city}&nbsp;
            {customerInfo?.customerdetailModel?.district}&nbsp;
            {customerInfo?.customerdetailModel?.addressStreet}&nbsp;
            {customerInfo?.customerdetailModel?.country}&nbsp;
            {customerInfo?.customerdetailModel?.zipCode}
          </Grid>
          <Grid item xs={2} sm={3} md={3}>
            Visa Type :
          </Grid>
          <Grid item xs={2} sm={5} md={5} sx={{ color: '#FFFFFF' }}>
            {customerInfo?.customerdetailModel?.visaTypeValue}
          </Grid>
          <Grid item xs={2} sm={3} md={3}>
            ID Type :
          </Grid>
          <Grid item xs={2} sm={5} md={5} sx={{ color: '#FFFFFF' }}>
            {customerInfo?.customerdetailModel?.senderIdTypeValue}
          </Grid>
          <Grid item xs={2} sm={3} md={3}>
            ID Number :
          </Grid>
          <Grid item xs={2} sm={5} md={5} sx={{ color: '#FFFFFF' }}>
            {customerInfo?.customerdetailModel?.primaryIdNumber}
          </Grid>
          <Grid item xs={2} sm={3} md={3}>
            ID Expiry Date :
          </Grid>
          <Grid item xs={2} sm={5} md={5} sx={{ color: '#FFFFFF' }}>
            {customerInfo?.customerdetailModel?.primaryIdExpirydate !== undefined
              ? ConvertDateYYYYMMDD(
                  customerInfo?.customerdetailModel?.primaryIdExpirydate,
                )
              : ''}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

const useStyles = makeStyles()((theme) => ({
  alertMsg: {
    marginTop: 8,
    borderRadius: 30,

    '& .MuiAlert-icon': {
      display: 'none',
    },

    '& .MuiAlert-message': {
      padding: 10,
      flexDirection: 'column',
      color: theme.palette.text.error,
    },
  },
}))
