import React from 'react'
import { Controller } from 'react-hook-form'
import { NumberFormatBase } from 'react-number-format'
import { InputLabel, Typography } from '@mui/material'
import Input from '../../components/common/Input'

const PatternNumberField = ({
  control,
  disableErrorSpace,
  name,
  placeholder,
  required,
  label,
  sx,
  disabled,
  ...restProps
}) => {
  const format = (value) =>
    value
      .replace(/\s+/g, '')
      .replace(/([a-z0-9]{4})/gi, '$1 ')
      .trim()
      .toLocaleUpperCase()
  const isAllowed = ({ value }) => value?.length <= 20

  return (
    <Controller
      render={({ field: { ref, ...restField }, fieldState: { error } }) => (
        <>
          <InputLabel htmlFor={name}>
            <Typography
              color={error ? 'text.error' : 'text.primary'}
              variant="Paragraph_sm_Light"
              component="div"
              sx={{ mb: 1 }}
            >
              {`${label ? `${label}${required ? ' *' : ''}` : ''}`}
            </Typography>
          </InputLabel>
          <NumberFormatBase
            {...restField}
            {...restProps}
            inputRef={ref}
            error={!!error}
            customInput={Input}
            allowNegative={false}
            format={format}
            isAllowed={isAllowed}
            placeholder={placeholder}
            helperText={error?.message || (disableErrorSpace ? '' : ' ')}
            fullWidth
            sx={{
              width: '100%',
              ...sx,
            }}
            disabled={disabled}
          />
        </>
      )}
      name={name}
      control={control}
      rules={{
        required: required ? 'Required' : '',
      }}
    />
  )
}

export default PatternNumberField
