import moment from 'moment'
import { byIso } from 'country-code-lookup'

export const clearObject = (object = {}, setDefault = {}) => {
  return Object.keys(object).reduce(function (p, c) {
    return p + decodeURIComponent(object[c].value)
  }, setDefault)
}

export const isEmptyObject = (obj = {}) => {
  return JSON.stringify(obj) === '{}'
}

export const Dropdown = (option = [], selected) => {
  if (!selected) return { label: '', value: '' }
  return option?.find((e) => e?.value === `${selected}`)
}

export const DDLabel = (option = [], value) => {
  if (!value) return
  return option?.find((e) => e?.value === `${value}`)?.label
}

export const FormatDDMMYYYY = (format_date = '') => {
  if (format_date === '') return
  return format_date?.substring(0, format_date.indexOf(' '))
}

export const SleepTimeout = (delay = 0) => {
  return new Promise((resolve) => {
    setTimeout(resolve, delay)
  })
}

export const ConvertDateYYYYMMDD = (date) => {
  if (!date) return
  return moment(date).format('YYYY-MM-DD')
}

export const ConvertDateGMT = (date) => {
  if (!date) return
  return moment(date, 'YYYY-MM-DD HH:mm:ssZ').format('YYYY-MM-DD | hh:mm A Z')
}

export const ConvertDateTimeFormat = (date) => {
  if (!date) return
  return moment(date).format('YYYY-MM-DD | hh:mm A')
}

export const ValidateModel = (field, name) => {
  if (!field) return false
  return field.find((e) => e?.field_application_name === name)?.field_visibility === 'REQ'
}

export const FirstDayOfWeek = () => {
  const today = new Date()
  const firstDayOfWeek = new Date(today.setDate(today.getDate() - today.getDay() + 1))
  return moment(firstDayOfWeek).format('YYYY-MM-DD')
}

export const FirstDayOfMonth = () => {
  const today = new Date()
  const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1)
  return moment(firstDayOfMonth).format('YYYY-MM-DD')
}

export const ConvertTime = (date) => {
  return moment(date).format('hh:mm A')
}

export const CountryNameByCode = (countryCode) => {
  if (!countryCode) return
  return byIso(countryCode).country
}

export const CustomerType = (customerType) => {
  if (customerType === 'I') {
    return 'Individual'
  } else if (customerType === 'B') {
    return 'Business'
  }
}
