import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import { isNil } from 'lodash'
import {
  Grid,
  Container,
  Typography,
  Stack,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import Search from '../../layouts/header/Search'
import Button from '../../components/common/Button'
import useTableCallback from '../../components/useTableCallback'
import Loading from '../../components/loading/loading'
import AlertBox from '../../components/alertBox/alertBox'
import SvgColor from '../../components/svg-color/svg-color'
import { apiCustomerList } from '../../api/customer'
import { ConvertDateYYYYMMDD } from '../../utils/app'

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: '#272C53',
  borderRadius: 25,
}))

const headCells = [
  {
    id: 'customer_id',
    label: 'Customer ID',
    disableSorting: true,
  },
  {
    id: 'name',
    label: 'Name',
    disableSorting: true,
  },
  {
    id: 'address',
    label: 'Address',
    disableSorting: true,
  },
  {
    id: 'date_of_birth',
    label: 'Date of birth',
    disableSorting: true,
  },
  {
    id: 'mobile_no',
    label: 'Mobile No.',
    disableSorting: true,
  },
  {
    id: 'kyc_status',
    label: 'KYC Status',
    disableSorting: true,
  },
  {
    id: 'actions',
    label: '',
    disableSorting: true,
  },
]

export default function Customer() {
  const navigate = useNavigate()

  const [records, setRecords] = useState([])
  const [loading, setLoading] = useState(false)
  const [notify, setNotify] = useState({
    open: false,
    message: '',
    type: '',
  })
  const [searchText, setSearchText] = useState('')
  const [totalRecords, setTotalRecords] = useState(0)

  const handlePageChange = (event, newPage) => {
    setPage(newPage)
    fetchCustomerList(searchText, newPage + 1, rowsPerPage)
  }

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
    fetchCustomerList(searchText, 1, parseInt(event.target.value, 10))
  }

  const {
    TblContainer,
    TblHead,
    TblPagination,
    recordsAfterPaginationAndSorting,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
  } = useTableCallback(
    records,
    totalRecords,
    headCells,
    handlePageChange,
    handleRowsPerPageChange,
  )

  const handleSearch = (e) => {
    if (e.key === 'Enter') {
      setPage(0)
      setSearchText(e.target.value)
      fetchCustomerList(e.target.value, 1, rowsPerPage)
    }
  }

  const fetchCustomerList = async (pSearchText, pPage, pRowsPerPage) => {
    setLoading(true)
    const response = await apiCustomerList(pSearchText, pPage, pRowsPerPage)
    if (response?.code !== 0 || !isNil(response?.error)) {
      setNotify({
        open: true,
        message: response?.message,
        type: 'error',
      })
      return
    }
    setRecords(response?.data)
    setTotalRecords(response?.totalRecord)
    setLoading(false)
  }

  useEffect(() => {
    fetchCustomerList('', 1, 5)
  }, [])

  return (
    <>
      <Helmet>
        <title> Customer </title>
      </Helmet>
      <Container>
        <Grid container mt={3} justifyContent="center" spacing={3}>
          <Grid item sm={12} key="Topic">
            <Typography sx={{ mb: '20px' }} component={'div'} variant="Paragraph_lg_Bold">
              CUSTOMER LISTS
            </Typography>
          </Grid>
          <Grid item sm={12} key="Filter">
            <Stack
              direction="row"
              alignItems="center"
              spacing={{
                xs: 0.5,
                sm: 1,
              }}
              gap={1}
            >
              <Search onKeyDown={handleSearch} />
              <Button
                onClick={() => {
                  navigate(`/customer/new`, {
                    replace: false,
                  })
                }}
                text="ADD NEW CUSTOMER"
                size="large"
              />
            </Stack>
          </Grid>
          <Grid item sm={12} key="Container">
            <TblContainer>
              <TblHead />
              {!loading && (
                <TableBody>
                  {recordsAfterPaginationAndSorting().map((item, index) => (
                    <React.Fragment key={`tablecustomerlist-${index}`}>
                      <StyledTableRow key={index} hover>
                        <TableCell head-table="Customer ID">{item.customerId}</TableCell>
                        <TableCell head-table="Name">
                          {item.senderName.substring(0, 10).concat('...')}
                        </TableCell>
                        <TableCell head-table="Address">
                          {item.address.substring(0, 10).concat('...')}
                        </TableCell>
                        <TableCell head-table="Date of birth">
                          {item.dob ? ConvertDateYYYYMMDD(item.dob) : ''}
                        </TableCell>
                        <TableCell head-table="Mobile No">{item.mobile}</TableCell>
                        <TableCell head-table="KYC Status">{item.kycStatus}</TableCell>
                        <TableCell head-table="">
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={{
                              xs: 0.5,
                              sm: 1,
                            }}
                            gap={1}
                          >
                            <Button
                              onClick={() => {
                                navigate(
                                  `/customer/details?routeEdit=${item.routeEdit}`,
                                  {
                                    replace: false,
                                  },
                                )
                              }}
                              startIcon={
                                <SvgColor
                                  src={`/assets/icons/icon_edit.svg`}
                                  sx={{ width: 20, height: 20, color: 'outline.primary' }}
                                />
                              }
                              text="Edit"
                              size="small"
                            />
                            <Button
                              startIcon={
                                <SvgColor
                                  src={`/assets/icons/icon_printer.svg`}
                                  sx={{ width: 20, height: 20, color: '#646671' }}
                                />
                              }
                              text="Print"
                              size="small"
                              variant="outlined"
                              color="inherit"
                            />
                          </Stack>
                        </TableCell>
                      </StyledTableRow>
                    </React.Fragment>
                  ))}
                </TableBody>
              )}
            </TblContainer>
            <Loading loading={loading} />
            <TblPagination />
          </Grid>
        </Grid>
      </Container>
      <AlertBox notify={notify} setNotify={setNotify} />
    </>
  )
}
