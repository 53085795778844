export default function Tooltip(theme) {
  return {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          color: theme.palette.background.paper,
          backgroundColor: theme.palette.background.cardbg,
          border: `solid 1px ${theme.palette.background.cardbg1}`,
        },
        arrow: {
          color: theme.palette.background.cardbg,
        },
      },
    },
  }
}
