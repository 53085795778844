import React, { useEffect, useState } from 'react'
import { Grid, Stack, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { isUndefined, isEmpty } from 'lodash'
import AutocompleteField from '../../../components/common/AutocompleteField'
import NumberField from '../../../components/common/NumberField'
import CardExpand from '../../../components/common/CardExpand'
import InputField from '../../../components/common/InputField'
import AlertBox from '../../../components/alertBox/alertBox'
import Button from '../../../components/common/Button'
import { SleepTimeout, DDLabel } from '../../../utils/app'
import useResponsive from '../../../hooks/useResponsive'
import Loading from '../../../components/loading/loading'
import { fetchServiceCharge, fetchProcessSessionID } from '../../../redux/master/action'
import { apiGetProcessSessionID } from '../../../api/master'
import {
  apiCheckEditServiceFee,
  apiGetCustomerServiceCharge,
} from '../../../api/send-money'
import DisplayServiceCharge from '../../../containers/send-money/form-sender/display-service-charge'
import { getCookie } from '../../../utils/cookie'

const initState = {
  isCalculating: undefined,
  notify: {
    open: false,
    message: '',
    type: '',
  },
}

const RemittanceDetails = ({ control, data }) => {
  const dispatch = useDispatch()
  const isMobile = useResponsive('between', 'xs', 'md')
  const [notify, setNotify] = useState(initState.notify)
  const [isCalculating, setIsCalculating] = useState(initState.isCalculating)
  const [currencyCode, setCurrencyCode] = useState('-')
  const OPTION_BANK = useSelector((state) => state?.master?.banks)
  const OPTION_KYC_MODE = useSelector((state) => state?.master?.kyc_modes)
  const CALCULATE_MODE = useSelector((state) => state?.master?.calculate_modes)
  const OPTION_TRANSACTION_TYPE = useSelector((state) => state?.master?.transaction_type)
  const DISPLAY_SERVICE_CHARGE = useSelector(
    (state) => state?.master?.display_servicecharge,
  )
  const RECEIVER_DELIVERY = useSelector((state) => state?.master?.receiver_delivery)

  const handleClear = () => setIsCalculating(undefined)

  const handleCalculate = async () => {
    const check = await apiCheckEditServiceFee()
    const response = await apiGetProcessSessionID()
    if (response?.code !== 0) return

    dispatch(fetchProcessSessionID(response?.data?.processID))
    sessionStorage.setItem('PROCESS_SESSION_ID', response?.data?.processID)

    if (check) {
      setIsCalculating(true)
      const payload = {
        Country: data?.r_country || '',
        CalcMode: data?.calcMode || '',
        Currency: RECEIVER_DELIVERY?.currency || '',
        ReceiveAgentID: RECEIVER_DELIVERY?.receiveragentid || '',
        Modeofremittance:
          DDLabel(OPTION_TRANSACTION_TYPE, data?.r_modeofremittance) || '',
        RemittanceAmt: data?.remittance_amount || '',
        EditServiceCharge: 'n', //check?.data?.isEditServiceFeeRight === 'true' ? 't' : 'n',
        ServiceCharge: null,
        BankList: data?.remittance_bank || '', //'25543',
      }
      await SleepTimeout(1e3)
      const response = await apiGetCustomerServiceCharge(payload)

      if (response?.code !== 0 || response?.status === 400) {
        setNotify({
          open: true,
          message: response?.Message || response?.title,
          type: 'error',
        })
        setIsCalculating(undefined)
        return
      }

      setIsCalculating(false)
      dispatch(fetchServiceCharge(response?.data))
    }
  }

  useEffect(() => {
    setCurrencyCode(getCookie('USER_INFO')?.currencyCode || '-')
  }, [])

  return (
    <div>
      <Stack spacing={3}>
        <Grid item sm={6}>
          <CardExpand title="Remittance details" defaultOpen>
            <Grid container spacing={{ xs: 1, sm: 2 }} columns={{ xs: 1, sm: 8, md: 8 }}>
              <Grid item xs={2} sm={4} md={4}>
                <AutocompleteField
                  control={control}
                  name="remittance_bank" //"remittance_bank"
                  label="Location/Bank"
                  placeholder="Select Location/Bank"
                  options={OPTION_BANK}
                  // required
                />
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <AutocompleteField
                  control={control}
                  name="r_modeofremittance"
                  label="Transaction Type"
                  placeholder="Select transaction type"
                  InputProp={RECEIVER_DELIVERY?.currency || '-'}
                  options={OPTION_TRANSACTION_TYPE}
                  required
                />
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <InputField
                  control={control}
                  name="remittance_pinNo"
                  label="TXN Reference (Pin No)"
                  placeholder="Pin No"
                />
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <InputField
                  control={control}
                  name="remittance_promoCode"
                  label="Do you have promotion code ?"
                  placeholder="Promotion Code"
                />
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <AutocompleteField
                  control={control}
                  name="calcMode"
                  label="Calculation Mode"
                  placeholder="Calculation Mode"
                  options={CALCULATE_MODE}
                  required
                />
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <NumberField
                  control={control}
                  name="remittance_amount"
                  label="Remittance Amount"
                  placeholder="0.0"
                  currencytype={currencyCode || '-'}
                  required
                />
              </Grid>
              <Grid item xs={8}>
                <Stack
                  flexDirection={isMobile ? 'column-reverse' : 'row'}
                  alignItems={isMobile && 'center'}
                  justifyContent="flex-end"
                >
                  <Button
                    sx={{ display: isUndefined(isCalculating) && 'none' }}
                    onClick={handleClear}
                    text="clear all"
                    size="small"
                    variant="text"
                    color="inherit"
                  />
                  <Button
                    text="CALCULATE"
                    size="small"
                    loading={isCalculating}
                    onClick={handleCalculate}
                    disabled={isEmpty(data?.remittance_amount)}
                  />
                </Stack>
                {!isUndefined(isCalculating) ? (
                  isCalculating ? (
                    <Loading loading={isCalculating} />
                  ) : (
                    <DisplayServiceCharge
                      data={DISPLAY_SERVICE_CHARGE}
                      cType={currencyCode || '-'}
                      paidCType={RECEIVER_DELIVERY?.currency || '-'}
                    />
                  )
                ) : null}
                <Stack sx={{ my: 4 }} flexDirection="row" justifyContent="flex-start">
                  <Typography variant="H4_Bold">KYC CONFIRMATION</Typography>
                </Stack>
              </Grid>
              <Grid item xs={4}>
                <AutocompleteField
                  control={control}
                  name="kycmode"
                  label="Did Sender visited counter to remit transaction ?"
                  placeholder="Please Select"
                  options={OPTION_KYC_MODE}
                  // required
                />
              </Grid>
            </Grid>
          </CardExpand>
        </Grid>
      </Stack>
      {notify?.message && <AlertBox notify={notify} setNotify={setNotify} />}
    </div>
  )
}

export default RemittanceDetails
