import React, { useState, useCallback, useEffect } from 'react'
import { Grid, Stack, TableBody, TableCell, Typography } from '@mui/material'
import { ArrowForward } from '@mui/icons-material'
import moment from 'moment'
import { apiTransactionHistory } from '../../../api/transaction'
import useTable from '../../../components/useTable'
import Button from '../../../components/common/Button'
import { useNavigate } from 'react-router'
import StyledTableRow from '../../../theme/styledTableRow'
import Label from '../../../components/label/Label'
import IconBtn from '../../../components/common/IconBtn'
import { fCurrency } from '../../../utils/formatNumer'
import { transactionStatusColorMap } from '../../../utils/mapstatus'
// import ApproveTransaction from '../../../containers/history/approveTransaction'

const headCells = [
  {
    id: 'tranno',
    label: 'TXN ID',
    disableSorting: true,
  },
  {
    id: 'sendername',
    label: 'Sender',
    disableSorting: true,
  },
  {
    id: 'receivername',
    label: 'Receiver',
    disableSorting: true,
  },
  {
    id: 'paidamt',
    label: 'Transfer Amount',
    disableSorting: true,
  },
  {
    id: 'transstatus',
    label: '',
    disableSorting: true,
  },
  {
    id: 'btnAppove',
    label: '',
    disableSorting: true,
  },
]

export default function Hold() {
  const navigate = useNavigate()
  const [records, setRecords] = useState([])
  const [loading, setLoading] = useState([])
  const [filterFn, setFilterFn] = useState({})

  const { TblContainer, TblHead, recordsAfterPaginationAndSorting } = useTable(
    records,
    headCells,
    filterFn,
  )

  const fetchtransactionHistoryList = useCallback(async () => {
    setLoading(true)

    const today = new Date()
    const lastWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7)

    const response = await apiTransactionHistory({
      tranStatus: 'Hold',
      paymentType: '',
      branch: '',
      receiverCountry: '',
      userID: '',
      dateType: 'Local_dot',
      fromDate: moment(lastWeek, 'YYYY-MM-DDTHH:mm:ssZ').format('YYYY-MM-DD'),
      toDate: moment(today, 'YYYY-MM-DDTHH:mm:ssZ').format('YYYY-MM-DD'),
      currentPage: 1,
      pageSize: 4,
      searchTxt: '',
    })

    if (response?.code !== 0) {
      // setNotify({
      //   open: true,
      //   message: response?.message,
      //   type: 'error',
      // })
      // return
      setRecords([])
      setLoading(false)
      return
    }

    setRecords(response?.data)
    setLoading(false)
  }, [])

  useEffect(() => {
    fetchtransactionHistoryList()
  }, [])

  return (
    <React.Fragment>
      <Grid item sm={7} key="Topic">
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          spacing={{ xs: 1, sm: 2, md: 2 }}
          justifyContent={'space-between'}
        >
          <Typography variant="H5_Bold">Hold Transaction</Typography>
          <Button
            onClick={() => {
              navigate(`/history/transaction-history`, {
                replace: false,
              })
            }}
            endIcon={<ArrowForward />}
            text="View More"
            size="medium"
          />
        </Stack>
      </Grid>
      <Grid item sm={12} key="Container">
        <TblContainer>
          <TblHead />
          {!loading && (
            <TableBody>
              {recordsAfterPaginationAndSorting().map((item, index) => {
                return (
                  <React.Fragment key={`tableTransactionHold-${index}`}>
                    <StyledTableRow key={index} hover>
                      <TableCell head-table="TXN ID">
                        <Typography variant="Paragraph_sm_Light">
                          {item?.tranno}
                        </Typography>
                      </TableCell>
                      <TableCell head-table="Sender">
                        <Typography variant="Paragraph_sm_Light">
                          {item?.sendername?.substring(0, 10).concat('...')}
                          {/* {item.sendername} */}
                        </Typography>
                      </TableCell>
                      <TableCell head-table="Receiver">
                        <Typography variant="Paragraph_sm_Light">
                          {item?.receivername?.substring(0, 10).concat('...')}
                          {/* {item.receivername} */}
                        </Typography>
                      </TableCell>
                      <TableCell head-table="Transfer Amount">
                        <Typography variant="Paragraph_sm_Light">
                          {fCurrency(item?.paidamt - item?.scharge, item?.paidcType)}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        {item?.transstatus ? (
                          <Label
                            color={transactionStatusColorMap[item.transstatus]?.status}
                            variant="soft"
                          >
                            {item.transstatus}
                          </Label>
                        ) : (
                          ''
                        )}
                      </TableCell>
                      <TableCell>
                        {String(item.transstatus).toLowerCase() === 'hold' ? (
                          <IconBtn
                            alt="btnApprove"
                            src={`/assets/icons/icon_approve.svg`}
                            onClick={() => {
                              // ApproveTransaction({
                              //   tranno: item.tranno,
                              //   handlerRefresh: fetchtransactionHistoryList(),
                              // })
                            }}
                          />
                        ) : null}
                      </TableCell>
                    </StyledTableRow>
                  </React.Fragment>
                )
              })}
            </TableBody>
          )}
        </TblContainer>
      </Grid>
    </React.Fragment>
  )
}
