import React from 'react'
import { makeStyles } from 'tss-react/mui'
import { Stack } from '@mui/material'
import InputField from '../../../components/common/InputField'
import Button from '../../../components/common/Button'

const FormDialogSuspiciousNote = ({ control, onclose }) => {
  const { classes } = useStyles()
  const handleSubmit = () => onclose()

  return (
    <div>
      <Stack px={4} className={classes.note}>
        <InputField control={control} multiline rows={4} name="notes" />
      </Stack>
      <Stack alignItems="center">
        <Button
          type="submit"
          text="SUBMIT"
          variant="contained"
          onClick={handleSubmit}
          fullWidth
        />
      </Stack>
    </div>
  )
}
export default FormDialogSuspiciousNote

const useStyles = makeStyles()((theme) => ({
  note: {
    gap: 20,
    [theme.breakpoints.up('md')]: {
      width: 640,
      margin: 'auto',
    },

    button: {
      display: 'flex',
      justifyContent: 'center',
    },

    '& .MuiDivider-root': {
      backgroundPosition: 'top',
      backgroundSize: '30px 1px',
      backgroundRepeat: 'repeat-x',
      backgroundImage: `linear-gradient(to right, transparent 50%, #444A81 50%)`,
    },
  },
}))
