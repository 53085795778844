import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useForm, useWatch } from 'react-hook-form'
import { makeStyles } from 'tss-react/mui'
import dayjs from 'dayjs'
import { Container, Tabs, Tab, Box, Stack, Paper, Typography, Grid } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { useTheme } from '@mui/material/styles'
import { isNil } from 'lodash'
import Button from '../../components/common/Button'
import Backdrop from '../../components/useBackdrop'
import AlertBar from '../../components/common/AlertBar'
import ConfirmDialog from '../../components/useDialog'
import AlertBox from '../../components/alertBox/alertBox'
import SenderDetailsTab from '../../containers/customers/sender/sender-details-tab'
import SenderAdditionalInformationTab from '../../containers/customers/sender/sender-additional-information-tab'
import ReceiverDetailsTab from '../receiver/index'
import {
  apiCheckPermissionEdit,
  apiGetCustomerDetails,
  apiUpdateCustomer,
} from '../../api/customer'
import moment from 'moment'

const useStyles = makeStyles()((theme) => ({
  stepBtn: {
    marginTop: '40px',
    marginBottom: '0px',
    display: 'flex',
    justifyContent: 'end',
  },
}))

export default function SenderDetailsPage() {
  const navigate = useNavigate()

  const location = useLocation()

  const theme = useTheme()

  const { classes } = useStyles()

  const { control, setValue, handleSubmit } = useForm()

  const [tabValue, setTabValue] = useState(0)
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    type: '',
    title: '',
    subTitle: '',
    container: '',
  })
  const [notify, setNotify] = useState({
    open: false,
    message: '',
    type: '',
  })
  const [openBackdrop, setOpenBackdrop] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const [customerInfo, setCustomerInfo] = useState(null)

  const TabPanel = (props) => {
    const { children, value, index, ...other } = props
    return (
      <Stack
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Container
            style={{ maxWidth: '-webkit-fill-available' }}
            sx={{
              '@media (min-width:600px)': {
                padding: '0px 0px',
              },
            }}
          >
            <Box
              sx={{
                p: 1,
                '& .MuiContainer-root': {
                  maxWidth: '100%',
                },
              }}
            >
              <Typography component={'div'}>{children}</Typography>
            </Box>
          </Container>
        )}
      </Stack>
    )
  }

  const onSubmit = async (data) => {
    const invalidRequiredFieldList = await validateRequiredField(data)
    if (invalidRequiredFieldList !== '') {
      setConfirmDialog({
        isOpen: true,
        type: 'alertWarning',
        title: 'Validation failed',
        subTitle: invalidRequiredFieldList + ' is required',
      })
      return
    }
    addOrEdit(data)
  }

  const validateRequiredField = async (data) => {
    let temp = ''
    temp += data.senderFirstName ? '' : '[First name] '
    temp += data.senderGender ? '' : '[Gender] '
    temp += data.senderNationality ? '' : '[Nationality] '
    temp += data.senderMobileNo ? '' : '[Mobile no] '
    temp += data.senderOccupation ? '' : '[Occupation] '
    temp += data.senderAddressCountry ? '' : '[Country] '
    temp += data.senderAddressState ? '' : '[State] '
    temp += data.senderAddressCity ? '' : '[City] '
    temp += data.senderAdditionalSourceOfIncome ? '' : '[Source of fund] '
    return temp
  }

  const addOrEdit = async (data) => {
    setOpenBackdrop(true)
    const payload = {
      customersno: customerInfo.customerdetailModel.customersno,
      customerId: customerInfo.customerdetailModel.customerId,
      customertype: customerInfo.customerdetailModel.customertype,
      // Sender Details
      firstName: data.senderFirstName ? data.senderFirstName : '',
      middleName: data.senderMiddleName ? data.senderMiddleName : '',
      lastName: data.senderLastName ? data.senderLastName : '',
      gender: data.senderGender ? (data.senderGender === 'Male' ? 'M' : 'F') : '',
      nationality: data.senderNationality ? data.senderNationality : '',
      dateOfBirth: data.senderDateOfBirth
        ? moment(data.senderDateOfBirth).format('YYYY-MM-DD')
        : '',
      email: data.senderEmail ? data.senderEmail : '',
      mobileNo: data.senderMobileNo ? data.senderMobileNo : '',
      occupation: data.senderOccupation ? data.senderOccupation : '',
      occupationOther: data.senderOccupationOther ? data.senderOccupationOther : '',
      // Sender Address
      country: data.senderAddressCountry ? data.senderAddressCountry : '',
      zipCode: data.senderAddressZipCode ? data.senderAddressZipCode : '',
      state: data.senderAddressState ? data.senderAddressState : '',
      city: data.senderAddressCity ? data.senderAddressCity : '',
      addressStreet: data.senderAddressAddressStreet
        ? data.senderAddressAddressStreet
        : '',
      district: data.senderAddressDistrict ? data.senderAddressDistrict : '',
      // Sender Additional Details
      typeVisa: data.senderAdditionalTypeVisa ? data.senderAdditionalTypeVisa : '',
      sourceOfIncome: data.senderAdditionalSourceOfIncome
        ? data.senderAdditionalSourceOfIncome
        : '',
      sourceOfIncomeOther: data.senderAdditionalSourceOfIncomeOther
        ? data.senderAdditionalSourceOfIncomeOther
        : '',
      companyName: data.senderAdditionalCompanyName
        ? data.senderAdditionalCompanyName
        : '',
      companyAddress: data.senderAdditionalCompanyAddress
        ? data.senderAdditionalCompanyAddress
        : '',
      companyContact: data.senderAdditionalCompanyContact
        ? data.senderAdditionalCompanyContact
        : '',
      primaryIdType: data.senderAdditionalPrimaryIdType
        ? data.senderAdditionalPrimaryIdType
        : '',
      primaryIdTypeOther: data.senderAdditionalPrimaryIdTypeOther
        ? data.senderAdditionalPrimaryIdTypeOther
        : '',
      primaryIdNumber: data.senderAdditionalPrimaryIdNumber
        ? data.senderAdditionalPrimaryIdNumber
        : '',
      primaryIdPlaceOfIssue: data.senderAdditionalPrimaryIdPlaceOfIssue
        ? data.senderAdditionalPrimaryIdPlaceOfIssue
        : '',
      primaryIdIssueDate: data.senderAdditionalPrimaryIdIssueDate
        ? moment(data.senderAdditionalPrimaryIdIssueDate).format('YYYY-MM-DD')
        : '',
      primaryIdExpirydate: data.senderAdditionalPrimaryIdExpirydate
        ? moment(data.senderAdditionalPrimaryIdExpirydate).format('YYYY-MM-DD')
        : '',
      kycinputby: data.senderAdditionalKycinputby ? data.senderAdditionalKycinputby : '',
      customerRemarks: data.senderAdditionalCustomerRemarks
        ? data.senderAdditionalCustomerRemarks
        : '',
    }
    const response = await apiUpdateCustomer(payload)
    setOpenBackdrop(false)
    if (response?.code !== 0) {
      setErrorMessage(response?.message)
      return
    }
    const params = new URLSearchParams(location.search)
    fetchGetCustomerDetails(params.get('routeEdit'))
    setConfirmDialog({
      isOpen: true,
      title: '',
      type: 'alertSuccessful',
      subTitle: 'EDIT COMPLETE',
    })
  }

  const tabProps = (index) => {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    }
  }
  const onTabChange = (e, value) => {
    e.preventDefault()
    setTabValue(value)
  }

  const tabList = [
    {
      key: 'personaldetails',
      label: 'Personal Details',
      tab: (
        <SenderDetailsTab
          control={control}
          useWatch={useWatch}
          setNotify={setNotify}
          customerInfo={customerInfo}
        />
      ),
    },
    {
      key: 'additionalinformation',
      label: 'Additional Information',
      tab: (
        <SenderAdditionalInformationTab
          control={control}
          useWatch={useWatch}
          setNotify={setNotify}
        />
      ),
    },
    {
      key: ' receiverdetails',
      label: 'Receiver Details',
      tab: <ReceiverDetailsTab />,
    },
  ]

  const fetchCheckPermissionEdit = async () => {
    setOpenBackdrop(true)
    const params = new URLSearchParams(location.search)

    if (params.get('routeEdit') === null) {
      setOpenBackdrop(false)
      navigate(`/customer`, {
        replace: false,
      })
      return
    }
    const response = await apiCheckPermissionEdit(params.get('routeEdit'))
    if (response?.code !== 0 || !isNil(response?.error)) {
      setOpenBackdrop(false)
      setConfirmDialog({
        isOpen: true,
        title: '',
        type: 'customDialogWithContainer',
        subTitle: '',
        container: await containerCheckPermissionData(response.message),
      })
      return
    }
    fetchGetCustomerDetails(params.get('routeEdit'))
  }

  const fetchGetCustomerDetails = async (routeCustomer) => {
    const response = await apiGetCustomerDetails(routeCustomer)
    if (response?.code !== 0 || !isNil(response?.error)) {
      setOpenBackdrop(false)
      setConfirmDialog({
        isOpen: true,
        title: '',
        type: 'alertError',
        subTitle: response?.message,
      })
      return
    }
    setCustomerInfo(response?.data)

    // Sender Details
    setValue('senderFirstName', response?.data?.customerdetailModel?.firstName)
    setValue('senderMiddleName', response?.data?.customerdetailModel?.middleName)
    setValue('senderLastName', response?.data?.customerdetailModel?.lastName)
    setValue(
      'senderGender',
      response?.data?.customerdetailModel?.gender === 'M' ? 'Male' : 'Female',
    )
    setValue('senderMobileNo', response?.data?.customerdetailModel?.mobileNo)
    setValue('senderEmail', response?.data?.customerdetailModel?.email)
    setValue(
      'senderDateOfBirth',
      response?.data?.customerdetailModel?.dateOfBirth !== undefined &&
        response?.data?.customerdetailModel?.dateOfBirth !== null &&
        response?.data?.customerdetailModel?.dateOfBirth !== ''
        ? dayjs(
            response?.data?.customerdetailModel?.dateOfBirth.substring(
              0,
              response?.data?.customerdetailModel?.dateOfBirth.indexOf(' '),
            ),
          ).$d
        : undefined,
    )
    setValue('senderNationality', response?.data?.customerdetailModel?.nationality)
    setValue('senderOccupation', response?.data?.customerdetailModel?.occupation)
    setValue(
      'senderOccupationOther',
      response?.data?.customerdetailModel?.occupationOther,
    )

    // Sender Address
    setValue('senderAddressCountry', response?.data?.customerdetailModel?.country)
    setValue('senderAddressZipCode', response?.data?.customerdetailModel?.zipCode)
    setValue('senderAddressState', response?.data?.customerdetailModel?.prefecture)
    setValue('senderAddressCity', response?.data?.customerdetailModel?.city)
    setValue('senderAddressDistrict', response?.data?.customerdetailModel?.district)
    setValue(
      'senderAddressAddressStreet',
      response?.data?.customerdetailModel?.addressStreet,
    )

    // Sender Additional Details
    setValue('senderAdditionalTypeVisa', response?.data?.customerdetailModel?.typeVisa)
    setValue(
      'senderAdditionalSourceOfIncome',
      response?.data?.customerdetailModel?.sourceOfIncome,
    )
    setValue(
      'senderAdditionalSourceOfIncomeOther',
      response?.data?.customerdetailModel?.sourceOfIncomeOther,
    )
    setValue(
      'senderAdditionalCompanyName',
      response?.data?.customerdetailModel?.companyName,
    )
    setValue(
      'senderAdditionalCompanyAddress',
      response?.data?.customerdetailModel?.companyAddress,
    )
    setValue(
      'senderAdditionalCompanyContact',
      response?.data?.customerdetailModel?.companyContact,
    )
    setValue(
      'senderAdditionalPrimaryIdType',
      response?.data?.customerdetailModel?.primaryIdType,
    )
    setValue(
      'senderAdditionalPrimaryIdTypeOther',
      response?.data?.customerdetailModel?.primaryIdTypeOther,
    )
    setValue(
      'senderAdditionalPrimaryIdNumber',
      response?.data?.customerdetailModel?.primaryIdNumber,
    )
    setValue(
      'senderAdditionalPrimaryIdPlaceOfIssue',
      response?.data?.customerdetailModel?.primaryIdPlaceOfIssue,
    )
    setValue(
      'senderAdditionalPrimaryIdIssueDate',
      response?.data?.customerdetailModel?.primaryIdIssueDate !== undefined &&
        response?.data?.customerdetailModel?.primaryIdIssueDate !== null &&
        response?.data?.customerdetailModel?.primaryIdIssueDate !== ''
        ? dayjs(
            response?.data?.customerdetailModel?.primaryIdIssueDate.substring(
              0,
              response?.data?.customerdetailModel?.primaryIdIssueDate.indexOf(' '),
            ),
          ).$d
        : undefined,
    )
    setValue(
      'senderAdditionalPrimaryIdExpirydate',
      response?.data?.customerdetailModel?.primaryIdExpirydate !== undefined &&
        response?.data?.customerdetailModel?.primaryIdExpirydate !== null &&
        response?.data?.customerdetailModel?.primaryIdExpirydate !== ''
        ? dayjs(
            response?.data?.customerdetailModel?.primaryIdExpirydate.substring(
              0,
              response?.data?.customerdetailModel?.primaryIdExpirydate.indexOf(' '),
            ),
          ).$d
        : undefined,
    )
    setValue(
      'senderAdditionalKycinputby',
      response?.data?.customerdetailModel?.kycinputby,
    )
    setValue(
      'senderAdditionalCustomerRemarks',
      response?.data?.customerdetailModel?.customerRemarks,
    )
    setOpenBackdrop(false)
  }

  const containerCheckPermissionData = async (data) => {
    return (
      <Grid container spacing={{ xs: 1, sm: 2 }} columns={{ xs: 1, sm: 8, md: 8 }}>
        <Grid item xs={4} sm={8} md={8} sx={{ textAlign: 'center' }}>
          <img
            src={`/assets/icons/icon_alert_error.svg`}
            alt="error"
            style={{
              display: 'block',
              marginLeft: 'auto',
              marginRight: 'auto',
              width: '50%',
            }}
          />
        </Grid>
        <Grid item xs={4} sm={8} md={8} sx={{ textAlign: 'center' }}>
          <Typography
            sx={{
              fontFamily: 'Overpass',
              fontsize: '24px',
              fontWeight: 700,
              lineHeight: '24px',
              letterSpacing: '0.0015em',
              color: '#FFFFFF',
            }}
          >
            {data}
          </Typography>
        </Grid>
        <Grid item xs={4} sm={8} md={8} sx={{ textAlign: 'center' }}>
          <Button
            text="GO TO CUSTOMER LIST PAGE"
            size="medium"
            color="success"
            onClick={() => {
              setConfirmDialog({
                ...confirmDialog,
                isOpen: false,
              })
              navigate(`/customer`, {
                replace: false,
              })
            }}
            sx={{ width: '60%' }}
          />
        </Grid>
      </Grid>
    )
  }

  useEffect(() => {
    fetchCheckPermissionEdit()
  }, [])

  return (
    <>
      <Container>
        <React.Fragment>
          <Paper sx={{ paddingLeft: 6 }}>
            <Grid item sm={1} key="Back">
              <Button
                onClick={() => {
                  navigate(`/customer`, {
                    replace: false,
                  })
                }}
                startIcon={<ArrowBackIcon />}
                text="Back"
                variant="outlined"
                size="small"
              />
            </Grid>
          </Paper>
          <Paper sx={{ paddingLeft: 6, paddingTop: 6 }}>
            <Typography
              sx={{
                fontFamily: 'Overpass',
                'font-size': '30px',
                fontWeight: 600,
                lineHeight: '20px',
                letterSpacing: '0.0015em',
                color: '#FFFFFF',
              }}
            >
              CUSTOMER :{' '}
              {customerInfo?.customerdetailModel?.firstName +
                ' ' +
                customerInfo?.customerdetailModel?.middleName +
                ' ' +
                customerInfo?.customerdetailModel?.lastName}
            </Typography>
          </Paper>
          <Paper sx={{ paddingTop: 3 }}>
            <form control={control} onSubmit={handleSubmit(onSubmit)}>
              <Box
                sx={{
                  display: 'grid',
                  '@media (min-width:400px)': {
                    gridTemplateColumns: 'repeat(auto-fill, minmax(1fr 3fr))',
                  },
                  '@media (min-width:800px)': {
                    gridTemplateColumns: '1fr 3fr',
                  },
                  borderBottom: 0,
                  borderColor: theme.palette.background.basebg,
                }}
              >
                <Box>
                  <Tabs
                    value={tabValue}
                    onChange={onTabChange}
                    orientation="vertical"
                    centered
                    sx={{
                      '& .MuiTabs-flexContainer': {
                        display: 'flex',
                        justifyContent: 'flex-start',
                        padding: '18px 38px',
                        '& .Mui-selected': {
                          color: '#FFFFFF',
                          bgcolor: theme.palette.background.cardbg,
                          borderRadius: '4px',
                        },
                      },
                      '& .MuiTabs-indicator': {
                        height: 0,
                        width: 0,
                      },
                    }}
                  >
                    {tabList.map((e, i) => {
                      return (
                        <Tab
                          key={`tab-${i}`}
                          label={e.label}
                          icon={e.icon}
                          {...tabProps(i)}
                          sx={{
                            '@media (min-width:600px)': {
                              alignItems: 'start',
                            },
                            width: '240px',
                            height: '48px',
                            padding: '22px',
                            borderRadius: 2,
                            border: '1px solid #8655D1',
                            margin: '8px 0',
                            '&.Mui-selected': {
                              backgroundColor: '#8655D1',
                            },
                          }}
                        />
                      )
                    })}
                  </Tabs>
                </Box>
                <Box>
                  {tabList.map((e, i) => {
                    return (
                      <TabPanel key={i} value={tabValue} index={i}>
                        {e.tab}
                      </TabPanel>
                    )
                  })}
                </Box>
              </Box>
              <Backdrop openBackdrop={openBackdrop} />
              <AlertBox notify={notify} setNotify={setNotify} />
              <ConfirmDialog
                confirmDialog={confirmDialog}
                setConfirmDialog={setConfirmDialog}
              />
              {tabValue !== 2 && (
                <div className={`${classes.stepBtn}`}>
                  <AlertBar type="error" message={errorMessage} />
                  <Button type="submit" variant="contained" text="SAVE" />
                </div>
              )}
            </form>
          </Paper>
        </React.Fragment>
      </Container>
    </>
  )
}
