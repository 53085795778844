import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import * as serviceWorker from './serviceWorker'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async'
import store from './redux/store'
import App from './App'

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <Provider store={store}>
    <HelmetProvider>
      <BrowserRouter>
        {/* <LocalizationProvider dateAdapter={AdapterDayjs}> */}
        <App />
        {/* <OnIdle /> */}
        {/* </LocalizationProvider> */}
      </BrowserRouter>
    </HelmetProvider>
  </Provider>,
)

serviceWorker.unregister()

reportWebVitals()
