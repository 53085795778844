export default function Popover(theme) {
  return {
    MuiPopover: {
      // defaultProps: {
      //   elevation: 0,
      // },
      styleOverrides: {
        root: {},
        paper: {
          border: `2px solid ${theme.palette.outline.default}`,
          background: theme.palette.background.dark,
          boxShadow: 'none',
          borderRadius: 20,

          // list: {
          //   '&[role="menuitem"]': {
          //     backgroundColor: theme.palette.background.popup,
          //     // marginTop: theme.spacing(1),
          //     "&.Mui-selected": {
          //       background: theme.palette.background.popup,
          //     },
          //   },
          //   // borderBottom: `1px solid "#30363d"`,
          // },
        },
      },
    },
  }
}
