export const types = {
  OPTION_SOF: 'OPTION_SOF',
  FIELD_RULES: 'FIELD_RULES',
  OPTION_BANK: 'OPTION_BANK',
  OPTION_GENDER: 'OPTION_GENDER',
  OPTION_ID_TYPE: 'OPTION_ID_TYPE',
  OPTION_COUNTRY: 'OPTION_COUNTRY',
  RECEIVER_DETAIL: 'RECEIVER_DETAIL',
  CUSTOMER_DETAIL: 'CUSTOMER_DETAIL',
  OPTION_KYC_MODE: 'OPTION_KYC_MODE',
  OPTION_VISA_TYPE: 'OPTION_VISA_TYPE',
  OPTION_OCCUPATION: 'OPTION_OCCUPATION',
  OPTION_SENDER_TYPE: 'OPTION_SENDER_TYPE',
  OPTION_ACCOUNT_TYPE: 'OPTION_ACCOUNT_TYPE',
  OPTION_RELATIONSHIP: 'OPTION_RELATIONSHIP',
  OPTION_TEST_QUESTION: 'OPTION_TEST_QUESTION',
  OPTION_CALCULATE_MODE: 'OPTION_CALCULATE_MODE',
  DISPLAY_SERVICE_CHARGE: 'DISPLAY_SERVICE_CHARGE',
  OPTION_TRANSACTION_TYPE: 'OPTION_TRANSACTION_TYPE',
  RECEIVER_DELIVERY: 'RECEIVER_DELIVERY',
  SELECTED_TRANSACTION_TYPE: 'SELECTED_TRANSACTION_TYPE',
  SELECTED_MODEOFREMITTANCE: 'SELECTED_MODEOFREMITTANCE',
  OPTION_PURPOSE_OF_REMITTANCE: 'OPTION_PURPOSE_OF_REMITTANCE',
  OPTION_MODEOFREMITTANCE: 'OPTION_MODEOFREMITTANCE',
  PROCESS_SESSION_ID: 'PROCESS_SESSION_ID',
  OPTION_BENEFICOUNTRY: 'OPTION_BENEFICOUNTRY',
  OPTION_DOCTYPE: 'OPTION_DOCTYPE',
  RECEIVER_FIELD_RULES: 'RECEIVER_FIELD_RULES',
}

export const fetchGender = (data) => {
  return {
    type: types.OPTION_GENDER,
    payload: data,
  }
}

export const fetchBankList = (data) => {
  return {
    type: types.OPTION_BANK,
    payload: data,
  }
}

export const fetchCountry = (data) => {
  return {
    type: types.OPTION_COUNTRY,
    payload: data,
  }
}

export const fetchBeneficiaryCountry = (data) => {
  return {
    type: types.OPTION_BENEFICOUNTRY,
    payload: data,
  }
}

export const fetchSenderType = (data) => {
  return {
    type: types.OPTION_SENDER_TYPE,
    payload: data,
  }
}

export const fetchVisaType = (data) => {
  return {
    type: types.OPTION_VISA_TYPE,
    payload: data,
  }
}

export const fetchTransactiontype = (data) => {
  return {
    type: types.OPTION_TRANSACTION_TYPE,
    payload: data,
  }
}

export const selectedTransactiontype = (data) => {
  return {
    type: types.SELECTED_TRANSACTION_TYPE,
    payload: data,
  }
}

export const fetchFieldRules = (data) => {
  return {
    type: types.FIELD_RULES,
    payload: data,
  }
}

export const fetchReceivFieldRule = (data) => {
  return {
    type: types.RECEIVER_FIELD_RULES,
    payload: data,
  }
}

export const selectReceiverDetail = (data) => {
  return {
    type: types.RECEIVER_DETAIL,
    payload: data,
  }
}

export const fetchPurposeOfRemittance = (data) => {
  return {
    type: types.OPTION_PURPOSE_OF_REMITTANCE,
    payload: data,
  }
}

export const fetchOccupation = (data) => {
  return {
    type: types.OPTION_OCCUPATION,
    payload: data,
  }
}

export const fetchRelationship = (data) => {
  return {
    type: types.OPTION_RELATIONSHIP,
    payload: data,
  }
}

export const fetchAccountType = (data) => {
  return {
    type: types.OPTION_ACCOUNT_TYPE,
    payload: data,
  }
}

export const fetchIDType = (data) => {
  return {
    type: types.OPTION_ID_TYPE,
    payload: data,
  }
}

export const fetchTestQuestion = (data) => {
  return {
    type: types.OPTION_TEST_QUESTION,
    payload: data,
  }
}

export const selectCustomerDetail = (data) => {
  return {
    type: types.CUSTOMER_DETAIL,
    payload: data,
  }
}

export const fetchSourceOfFund = (data) => {
  return {
    type: types.OPTION_SOF,
    payload: data,
  }
}

export const fetchReceiverDelivery = (data) => {
  return {
    type: types.RECEIVER_DELIVERY,
    payload: data,
  }
}

export const fetchCalculateMode = (data) => {
  return {
    type: types.OPTION_CALCULATE_MODE,
    payload: data,
  }
}

export const fetchKYCMode = (data) => {
  return {
    type: types.OPTION_KYC_MODE,
    payload: data,
  }
}

export const fetchServiceCharge = (data) => {
  return {
    type: types.DISPLAY_SERVICE_CHARGE,
    payload: data,
  }
}

export const fetchProcessSessionID = (data) => {
  return {
    type: types.PROCESS_SESSION_ID,
    payload: data,
  }
}

export const fetchDoctype = (data) => {
  return {
    type: types.OPTION_DOCTYPE,
    payload: data,
  }
}

export const selectedModeOfRemittance = (data) => {
  return {
    type: types.SELECTED_MODEOFREMITTANCE,
    payload: data,
  }
}

export const fetchModeOfRemittance = (data) => {
  return {
    type: types.OPTION_MODEOFREMITTANCE,
    payload: data,
  }
}
