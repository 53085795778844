import React, { useState, useMemo } from 'react'
import { Helmet } from 'react-helmet-async'
import { useLocation } from 'react-router-dom'
import config_pathname from '../../layouts/header/navigator/config'

export default function HelmetTitle() {
  // const [title, setTitle] = useState("");
  let location = useLocation()

  // useMemo(() => {
  const current = config_pathname?.find((item) => item.link === location?.pathname)?.name

  // if (current && !!current) {
  //   setTitle(current?.title);
  // } else {
  //   setTitle("");
  // }
  // }, [location]);

  return (
    <Helmet>
      <title> {current || ''} </title>
    </Helmet>
  )
}
