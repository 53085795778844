import typography from '../typography'

const typo = typography

export default function Card(theme) {
  return {
    MuiTablePagination: {
      styleOverrides: {
        root: {
          padding: 0,
        },

        selectLabel: {
          color: theme.palette.text.secondary,
          ...typo['Paragraph_sm_Light'],
        },

        select: {
          padding: 10,
          borderRadius: 30,
          border: `1px solid ${theme.palette.outline.default}`,
        },

        actions: {
          '& .MuiIconButton-root': {
            color: theme.palette.outline.primary,
          },
          '& .MuiIconButton-root.Mui-disabled': {
            color: theme.palette.outline.default,
          },
        },
      },
    },
  }
}
