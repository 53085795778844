import { alpha } from '@mui/material/styles'
import typography from '../typography'

const typo = typography

export default function Input(theme) {
  return {
    MuiInputBase: {
      styleOverrides: {
        root: {
          '& svg': { color: theme.palette.text.secondary },

          '&.Mui-disabled': {
            '& svg': { color: theme.palette.text.disabled },
          },
        },

        input: {
          '&::placeholder': {
            opacity: 1,
            color: theme.palette.text.disabled,
          },
        },
      },
    },

    MuiInput: {
      styleOverrides: {
        underline: {
          // "&:before": {
          //   borderBottomColor: "none", //alpha(theme.palette.grey[500], 0.56),
          // },
        },
      },
    },

    MuiFilledInput: {
      styleOverrides: {
        root: {
          // backgroundColor: theme.palette.background.light,
          // "& .MuiOutlinedInput-notchedOutline": {
          //   border: `2px solid ${theme.palette.outline.focus}`,
          // },
          // "&:hover": {
          //   backgroundColor: theme.palette.background.secondary,
          // },
          // "&.Mui-focused": {
          //   backgroundColor: theme.palette.action.focus,
          // },
          // "&.Mui-disabled": {
          //   backgroundColor: theme.palette.action.disabledBackground,
          // },
        },
        underline: {
          '&:before': {
            borderBottomColor: alpha(theme.palette.grey[500], 0.56),
          },
        },
      },
    },

    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          // padding: "0px 22px",
          borderRadius: '20px',
          ...typo['Paragraph_md_Light'],
          background: theme.palette.background.secondary,

          '& .MuiOutlinedInput-notchedOutline': {
            border: `2px solid ${theme.palette.outline.default}`,
          },

          '&:hover': {
            '& .MuiOutlinedInput-notchedOutline': {
              border: `2px solid ${theme.palette.outline.hover}`,
            },
          },

          '&.Mui-focused': {
            '& .MuiOutlinedInput-notchedOutline': {
              border: `2px solid ${theme.palette.outline.focus}`,
            },
          },

          '&.Mui-error': {
            '& .MuiOutlinedInput-notchedOutline': {
              border: `2px solid ${theme.palette.outline.error}`,
            },
          },

          '&.Mui-disabled': {
            background: theme.palette.background.disabledBackground,
            '& .MuiOutlinedInput-notchedOutline': {
              border: `2px solid ${theme.palette.outline.disabled}`,
            },
          },
        },
        input: {
          color: theme.palette.text.primary,
          ...typo['Paragraph_md_Light'],
        },
      },
    },

    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: theme.palette.text.primary,
          ...typo['Paragraph_md_Bold'],
        },
      },
    },
  }
}
