import React, { useState, useEffect } from 'react'
import { Grid } from '@mui/material'
import { isNil } from 'lodash'
import AutocompleteField from '../../../components/common/AutocompleteField'
import EmailField from '../../../components/common/EmailField'
import InputField from '../../../components/common/InputField'
import RadioField from '../../../components/common/RadioField'
import DateField from '../../../components/common/DateField'
import { apiMasterCountryList, apiGetGenderList } from '../../../api/master'
import { apiGetOccupationList } from '../../../api/customer'

export default function SenderDetails(props) {
  const { control, setNotify, useWatch } = props

  const [countryRecords, setCountryRecords] = useState([])
  const [genderRecords, setGenderRecords] = useState([])
  const [occupationRecords, setOccupationRecords] = useState([])

  const fetchCountryList = async () => {
    const response = await apiMasterCountryList()
    if (response?.code !== 0 || !isNil(response?.error)) {
      setNotify({
        open: true,
        message: response?.message,
        type: 'error',
      })
      return
    }
    const optionCountry = response?.data?.map((e) => {
      return { label: e?.text, value: e?.value }
    })
    setCountryRecords(optionCountry)
  }

  const fetchGenderList = async () => {
    const response = await apiGetGenderList()
    if (response?.code !== 0 || !isNil(response?.error)) {
      setNotify({
        open: true,
        message: response?.message,
        type: 'error',
      })
      return
    }
    const optionGender = response?.data?.map((e) => {
      return { label: e?.text, value: e?.value }
    })
    setGenderRecords(optionGender)
  }

  const fetchOccupationList = async () => {
    const response = await apiGetOccupationList()
    if (response?.code !== 0 || !isNil(response?.error)) {
      setNotify({
        open: true,
        message: response?.message,
        type: 'error',
      })
      return
    }
    const optionOccupation = response?.data?.map((e) => {
      return { label: e?.text, value: e?.value }
    })
    setOccupationRecords(optionOccupation)
  }

  useEffect(() => {
    fetchCountryList()
    fetchGenderList()
    fetchOccupationList()
  }, [])

  return (
    <Grid container spacing={{ xs: 1, sm: 2 }} columns={{ xs: 1, sm: 8, md: 8 }}>
      <Grid item xs={2} sm={4} md={4}>
        <InputField
          control={control}
          name="senderFirstName"
          label="First Name"
          placeholder="First Name"
          required
        />
      </Grid>
      <Grid item xs={2} sm={4} md={4}>
        <InputField
          control={control}
          name="senderMiddleName"
          label="Middle Name"
          placeholder="Middle Name"
        />
      </Grid>
      <Grid item xs={2} sm={4} md={4}>
        <InputField
          control={control}
          name="senderLastName"
          label="Last Name"
          placeholder="Last Name"
        />
      </Grid>
      <Grid item xs={2} sm={4} md={4}>
        <RadioField
          control={control}
          name="senderGender"
          label="Gender"
          options={genderRecords}
          required
        />
      </Grid>
      <Grid item xs={2} sm={4} md={4}>
        <InputField
          control={control}
          name="senderMobileNo"
          label="Mobile"
          placeholder="Mobile"
          required
        />
      </Grid>
      <Grid item xs={2} sm={4} md={4}>
        <EmailField
          control={control}
          name="senderEmail"
          label="Email"
          placeholder="Email"
        />
      </Grid>
      <Grid item xs={2} sm={4} md={4}>
        <DateField
          control={control}
          name="senderDateOfBirth"
          label="Date of Birth"
          placeholder="Date of Birth"
        />
      </Grid>
      <Grid item xs={2} sm={4} md={4}>
        <AutocompleteField
          control={control}
          name="senderNationality"
          label="Nationality"
          placeholder="Select Nationality"
          options={countryRecords}
          required
        />
      </Grid>
      <Grid item xs={2} sm={4} md={4}>
        <AutocompleteField
          control={control}
          name="senderOccupation"
          label="Occupation"
          placeholder="Select Occupation"
          options={occupationRecords}
          required
        />
      </Grid>
      {useWatch({ control, name: 'senderOccupation' }) === '99' && (
        <Grid item xs={2} sm={4} md={4}>
          <InputField
            control={control}
            name="senderOccupationOther"
            label="Other Occupation"
            placeholder="Other Occupation"
            required
          />
        </Grid>
      )}
    </Grid>
  )
}
