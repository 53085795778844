import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { isNil } from 'lodash'
import { Grid, Container, Stack, TableBody, TableCell, TableRow } from '@mui/material'
import { styled } from '@mui/material/styles'
import EditIcon from '@mui/icons-material/Edit'
import Button from '../../components/common/Button'
import useTable from '../../components/useTable'
import Loading from '../../components/loading/loading'
import AlertBox from '../../components/alertBox/alertBox'
import { apiGetCustomerDetails } from '../../api/customer'

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: '#272C53',
  borderRadius: 25,
}))

const headCells = [
  {
    id: 'receiverId',
    label: 'Receiver ID',
  },
  {
    id: 'receiverName',
    label: 'Name',
  },
  {
    id: 'rCountry',
    label: 'Country',
  },
  {
    id: 'mobile',
    label: 'Mobile No',
  },
  {
    id: 'realtion',
    label: 'Relationship',
  },
  {
    id: 'paymentMode',
    label: 'Payment Mode',
  },
  {
    id: '',
    label: '',
    disableSorting: true,
  },
]

export default function Receiver() {
  const navigate = useNavigate()

  const location = useLocation()

  const [loading, setLoading] = useState(false)
  const [records, setRecords] = useState([])
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items
    },
  })
  const [notify, setNotify] = useState({
    open: false,
    message: '',
    type: '',
  })

  const {
    TblContainer,
    TblHead,
    TblPagination,
    recordsAfterPaginationAndSorting,
    setPage,
  } = useTable(records, headCells, filterFn)

  const fetchGetCustomerDetails = async (routeCustomer) => {
    setLoading(true)
    const response = await apiGetCustomerDetails(routeCustomer)
    if (response?.code !== 0 || !isNil(response?.error)) {
      setLoading(false)
      setNotify({
        open: true,
        message: response?.message,
        type: 'error',
      })
      return
    }
    setLoading(false)
    setRecords(response?.data?.receiverlistModel)
  }

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    fetchGetCustomerDetails(params.get('routeEdit'))
  }, [])

  return (
    <>
      <Container>
        <Grid container justifyContent="center" spacing={3}>
          <Grid item sm={12} key="Container">
            <TblContainer>
              <TblHead />
              {!loading && (
                <TableBody>
                  {recordsAfterPaginationAndSorting()?.map((item, index) => (
                    <React.Fragment key={`tablereceiver-${index}`}>
                      <StyledTableRow key={index} hover>
                        <TableCell head-table="Receiver ID">{item?.receiverId}</TableCell>
                        <TableCell head-table="Name">
                          {item?.receiverName.substring(0, 10).concat('...')}
                        </TableCell>
                        <TableCell head-table="Country">{item?.rCountry}</TableCell>
                        <TableCell head-table="Mobile No">{item?.mobile}</TableCell>
                        <TableCell head-table="Relationship">{item?.realtion}</TableCell>
                        <TableCell head-table="Payment Mode">
                          {item?.paymentMode}
                        </TableCell>
                        <TableCell head-table="">
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={{
                              xs: 0.5,
                              sm: 1,
                            }}
                            gap={1}
                          >
                            <Button
                              onClick={() => {
                                const params = new URLSearchParams(location.search)
                                navigate(
                                  `/receiver/details?routeEdit=${item.routeEdit}`,
                                  {
                                    replace: false,
                                    state: {
                                      customerRouteEditURL: params.get('routeEdit'),
                                    },
                                  },
                                )
                              }}
                              startIcon={<EditIcon />}
                              text="Edit"
                              size="small"
                            />
                          </Stack>
                        </TableCell>
                      </StyledTableRow>
                    </React.Fragment>
                  ))}
                </TableBody>
              )}
            </TblContainer>
            <Loading loading={loading} />
            <TblPagination />
          </Grid>
          <Grid item sm={12} key="Filter">
            <Stack
              direction="row"
              alignItems="center"
              spacing={{
                xs: 0.5,
                sm: 1,
              }}
              gap={1}
            >
              <Button
                onClick={() => {
                  const params = new URLSearchParams(location.search)
                  navigate(`/receiver/new`, {
                    replace: false,
                    state: { customerRouteEditURL: params.get('routeEdit') },
                  })
                }}
                text="ADD NEW RECEIVER"
                size="large"
              />
            </Stack>
          </Grid>
        </Grid>
      </Container>
      <AlertBox notify={notify} setNotify={setNotify} />
    </>
  )
}
