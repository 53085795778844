import React from "react";
import { Helmet } from "react-helmet-async";

export default function Import() {
  return (
    <Helmet>
      <title> Import </title>
    </Helmet>
  );
}
