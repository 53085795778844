import { Grid, Typography, Collapse, Box, Container, Popover } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import IconBtn from '../../components/common/IconBtn'
import AlertBox from '../../components/alertBox/alertBox'
import Label from '../../components/label/Label'
import Button from '../../components/common/Button'
import { makeStyles } from 'tss-react/mui'
import SvgColor from '../../components/svg-color/svg-color'
import { apiGetTransactionDetails, apiGetDocuments } from '../../api/transaction'
import { isNil } from 'lodash'
import { transactionStatusColorMap } from '../../utils/mapstatus'
import { CountryNameByCode, ConvertDateGMT, CustomerType } from '../../utils/app'
import { ConvertDateYYYYMMDD } from '../../utils/app'
import { fNumberCurrency } from '../../utils/formatNumer'

const useStyles = makeStyles()((theme) => ({
  gridContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    borderRadius: 30,
    background: '#272C53',
  },
  gridItem: {
    padding: '20px 0 20px 20px',
  },
  gridRowItem: {
    padding: '0 0 0 20px',
  },
  gridItemLabel: {
    color: '#9D9EAF',
  },
  gridItemHighlight: {
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '24px',
    letterSpacing: '0.0015em',
    color: '#F2AF7B',
  },
  btn: {
    marginTop: '40px',
    marginBottom: '0px',
    display: 'flex',
    justifyContent: 'end',
  },
}))

export default function TransactionDetails() {
  const { classes } = useStyles()

  const navigate = useNavigate()

  const params = useParams()

  const [notify, setNotify] = useState({
    open: false,
    message: '',
    type: '',
  })
  const [records, setRecords] = useState(null)
  const [openOptions, setOpenOptions] = useState(null)
  const [expandedTxnReference, setExpandedTxnReference] = useState(false)
  const [expandedTransactionInfo, setExpandedTransactionInfo] = useState(false)
  const [expandedSenderInfo, setExpandedSenderInfo] = useState(false)
  const [expandedReceiverInfo, setExpandedReceiverInfo] = useState(false)

  const handleOpenOptions = (event) => {
    setOpenOptions(event.currentTarget)
  }

  const handleCloseOptions = () => {
    setOpenOptions(null)
  }

  const handleExpandTxnReference = () => {
    setExpandedTxnReference(!expandedTxnReference)
  }

  const handleExpandTransactionInfo = () => {
    setExpandedTransactionInfo(!expandedTransactionInfo)
  }

  const handleExpandSenderInfo = (item) => {
    setExpandedSenderInfo(!expandedSenderInfo)
  }

  const handleExpandReceiverInfo = (item) => {
    setExpandedReceiverInfo(!expandedReceiverInfo)
  }

  const getDocumentFullPath = async (tableName, imageID, imagePath) => {
    if (!params?.id) return
    const response = await apiGetDocuments(tableName, imageID, imagePath)
    if (response?.code !== 0 || !isNil(response?.error)) {
      setNotify({
        open: true,
        message: response?.message,
        type: 'error',
      })
      return
    }
    window.open(response?.data?.imageFullPath, '_blank', 'noreferrer')
  }

  const fetchTransactionDetails = async () => {
    if (!params?.id) return
    const response = await apiGetTransactionDetails(params?.id)
    if (response?.code !== 0 || !isNil(response?.error)) {
      setNotify({
        open: true,
        message: response?.message,
        type: 'error',
      })
      return
    }
    setRecords(response?.data)
  }

  useEffect(() => {
    fetchTransactionDetails()
  }, [])

  return (
    <React.Fragment>
      <Container>
        <Grid container mt={3} spacing={3}>
          <Grid item sm={12} key="Topic">
            <Typography sx={{ mb: '30px' }} component={'div'} variant="H3_Bold">
              Transaction Details
            </Typography>
          </Grid>
          <Grid item sm={12} key="Topic">
            <Box sx={{ flexGrow: 1, mt: 1 }}>
              <Grid container columns={{ xs: 1, sm: 6, md: 6 }}>
                <Grid item xs={1} sm={1} md={1}>
                  <SvgColor
                    src={`/assets/icons/icon_lightnet_company.svg`}
                    sx={{ width: 180, height: 180 }}
                  />
                </Grid>
                <Grid item xs={1} sm={4} md={4} sx={{ paddingLeft: 4, paddingTop: 2 }}>
                  <Grid item xs={1} sm={4} md={4}>
                    Lightnet Pte. Ltd.
                  </Grid>
                  <Grid
                    item
                    xs={1}
                    sm={4}
                    md={4}
                    sx={{ paddingTop: 2, paddingBottom: 2, color: '#9D9EAF' }}
                  >
                    9 Battery Road, #28-01 MYP Centre 049910 Singapore
                  </Grid>
                  <Grid item xs={1} sm={4} md={4}>
                    www.lightnet.io
                  </Grid>
                  <Grid item xs={1} sm={4} md={4}>
                    Support Email : Support@lightnet.io
                  </Grid>
                </Grid>
                <Grid item xs={1} sm={1} md={1}>
                  <Button
                    variant="contained"
                    text="More option"
                    sx={{ width: '95%', marginRight: '20px' }}
                    onClick={handleOpenOptions}
                    endIcon={
                      <SvgColor
                        src={`/assets/icons/icon_arrow_down.svg`}
                        sx={{ width: 20, height: 20 }}
                      />
                    }
                  />
                  <Popover
                    open={Boolean(openOptions)}
                    anchorEl={openOptions}
                    onClose={handleCloseOptions}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                    PaperProps={{
                      sx: {
                        p: 0,
                        mt: 1.5,
                        ml: 0.75,
                        width: 380,
                        border: 'none',
                        '& .MuiMenuItem-root': {
                          mx: 1,
                        },
                      },
                    }}
                  >
                    <Grid
                      container
                      columns={{ xs: 1, sm: 2, md: 2 }}
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Grid item xs={1} sm={1} md={1}>
                        <Grid
                          item
                          xs={1}
                          sm={12}
                          md={12}
                          sx={{
                            margin: '10px 10px',
                            padding: '8px 8px',
                            borderRadius: '20px',
                            border: '1px solid #444A81',
                            cursor: 'pointer',
                          }}
                        >
                          <Grid item xs={1} sm={12} md={12} sx={{ textAlign: 'center' }}>
                            <SvgColor
                              src={`/assets/icons/icon_printer.svg`}
                              sx={{ width: 20, height: 20 }}
                            />
                          </Grid>
                          <Grid item xs={1} sm={12} md={12} sx={{ textAlign: 'center' }}>
                            <Typography>Re-print voucher</Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={1} sm={1} md={1}>
                        <Grid
                          item
                          xs={1}
                          sm={12}
                          md={12}
                          sx={{
                            margin: '10px 10px',
                            padding: '8px 8px',
                            borderRadius: '20px',
                            border: '1px solid #444A81',
                            cursor: 'pointer',
                          }}
                        >
                          <Grid item xs={1} sm={12} md={12} sx={{ textAlign: 'center' }}>
                            <SvgColor
                              src={`/assets/icons/icon_edit.svg`}
                              sx={{ width: 20, height: 20 }}
                            />
                          </Grid>
                          <Grid item xs={1} sm={12} md={12} sx={{ textAlign: 'center' }}>
                            <Typography>CDD Report</Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={1} sm={1} md={1}>
                        <Grid
                          item
                          xs={1}
                          sm={12}
                          md={12}
                          sx={{
                            margin: '10px 10px',
                            padding: '8px 8px',
                            borderRadius: '20px',
                            border: '1px solid #444A81',
                            cursor: 'pointer',
                          }}
                        >
                          <Grid item xs={1} sm={12} md={12} sx={{ textAlign: 'center' }}>
                            <SvgColor
                              src={`/assets/icons/icon_export.svg`}
                              sx={{ width: 20, height: 20 }}
                            />
                          </Grid>
                          <Grid item xs={1} sm={12} md={12} sx={{ textAlign: 'center' }}>
                            <Typography>Export to PDF</Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={1} sm={1} md={1}>
                        <Grid
                          item
                          xs={1}
                          sm={12}
                          md={12}
                          sx={{
                            margin: '10px 10px',
                            padding: '8px 8px',
                            borderRadius: '20px',
                            border: '1px solid #444A81',
                            cursor: 'pointer',
                          }}
                        >
                          <Grid item xs={1} sm={12} md={12} sx={{ textAlign: 'center' }}>
                            <SvgColor
                              src={`/assets/icons/icon_upload.svg`}
                              sx={{ width: 20, height: 20 }}
                            />
                          </Grid>
                          <Grid item xs={1} sm={12} md={12} sx={{ textAlign: 'center' }}>
                            <Typography>Upload document</Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Popover>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item sm={12} key="Container">
            <Box sx={{ flexGrow: 1, mt: 2 }}>
              <Grid
                container
                columns={{ xs: 1, sm: 6, md: 6 }}
                className={`${classes.gridContainer}`}
                key={`grid-txn-ref`}
              >
                <Grid item xs={1} sm={1} md={1} className={`${classes.gridItem}`}>
                  <Typography
                    variant="Paragraph_xs_Light"
                    gutterBottom
                    className={`${classes.gridItemLabel}`}
                  >
                    TXN Reference (Pin No)
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    color={'text.primary'}
                    gutterBottom
                    className={`${classes.gridItemHighlight}`}
                  >
                    {records?.tranDetail?.pinNo}
                  </Typography>
                </Grid>
                <Grid item xs={1} sm={1} md={1} className={`${classes.gridItem}`}>
                  <Typography
                    variant="Paragraph_xs_Light"
                    gutterBottom
                    className={`${classes.gridItemLabel}`}
                  >
                    Transaction ID
                  </Typography>
                  <Typography variant="subtitle2" color={'text.primary'} gutterBottom>
                    {records?.tranDetail?.tranNo}
                  </Typography>
                </Grid>
                <Grid item xs={1} sm={1} md={1} className={`${classes.gridItem}`}>
                  <Typography
                    variant="Paragraph_xs_Light"
                    gutterBottom
                    className={`${classes.gridItemLabel}`}
                  >
                    Transaction Type
                  </Typography>
                  <Typography variant="subtitle2" color={'text.primary'} gutterBottom>
                    {records?.tranDetail?.transactionType}
                  </Typography>
                </Grid>
                <Grid item xs={1} sm={1} md={1} className={`${classes.gridItem}`}>
                  {records?.tranDetail?.statusDetail ? (
                    <Label
                      color={
                        transactionStatusColorMap[records.tranDetail.statusDetail]?.status
                      }
                      variant="soft"
                      sx={{
                        padding: '20px 0 20px 0',
                      }}
                    >
                      {records?.tranDetail?.statusDetail}
                    </Label>
                  ) : (
                    ''
                  )}
                </Grid>
                <Grid item xs={1} sm={1} md={1} className={`${classes.gridItem}`}>
                  <IconBtn
                    sx={{}}
                    onClick={() => {
                      handleExpandTxnReference()
                    }}
                    alt="expand"
                    src={
                      expandedTxnReference
                        ? `/assets/icons/icon_arrow_up.svg`
                        : `/assets/icons/icon_arrow_down.svg`
                    }
                  />
                </Grid>
                <Collapse
                  key={`child-txn-ref`}
                  in={expandedTxnReference}
                  timeout="auto"
                  unmountOnExit
                  sx={{ width: '100%', padding: '20px 20px 20px 20px' }}
                >
                  <Box>
                    <Grid
                      container
                      columns={{ xs: 1, sm: 4, md: 4 }}
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                      key={`grid-txn-ref`}
                    >
                      <Grid item xs={1} sm={1} md={1}>
                        <Typography
                          variant="Paragraph_xs_Light"
                          gutterBottom
                          className={`${classes.gridItemLabel}`}
                        >
                          Agent Name
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          color={'text.primary'}
                          gutterBottom
                        >
                          {records?.tranDetail?.agentName}
                        </Typography>
                      </Grid>

                      <Grid item xs={1} sm={1} md={1}>
                        <Typography
                          variant="Paragraph_xs_Light"
                          gutterBottom
                          className={`${classes.gridItemLabel}`}
                        >
                          Payout Partner
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          color={'text.primary'}
                          gutterBottom
                        >
                          {records?.tranDetail?.payoutPartnerName}
                        </Typography>
                      </Grid>

                      <Grid item xs={1} sm={1} md={1}>
                        <Typography
                          variant="Paragraph_xs_Light"
                          gutterBottom
                          className={`${classes.gridItemLabel}`}
                        >
                          Branch
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          color={'text.primary'}
                          gutterBottom
                        >
                          {records?.tranDetail?.payoutPartnerBranch}
                        </Typography>
                      </Grid>

                      <Grid item xs={1} sm={1} md={1}>
                        <Typography
                          variant="Paragraph_xs_Light"
                          gutterBottom
                          className={`${classes.gridItemLabel}`}
                        >
                          Confirmation ID
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          color={'text.primary'}
                          gutterBottom
                        >
                          {records?.tranDetail?.conformationID}
                        </Typography>
                      </Grid>

                      <Grid item xs={1} sm={1} md={1}>
                        <Typography
                          variant="Paragraph_xs_Light"
                          gutterBottom
                          className={`${classes.gridItemLabel}`}
                        >
                          Sender Country
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          color={'text.primary'}
                          gutterBottom
                        >
                          {CountryNameByCode(records?.tranDetail?.senderCountry)}
                        </Typography>
                      </Grid>

                      <Grid item xs={1} sm={3} md={3}>
                        <Typography
                          variant="Paragraph_xs_Light"
                          gutterBottom
                          className={`${classes.gridItemLabel}`}
                        >
                          Paid by
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          color={'text.primary'}
                          gutterBottom
                        >
                          {records?.tranDetail?.paidBy}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </Collapse>
              </Grid>
            </Box>
          </Grid>

          <Grid item sm={12} key="Container">
            <Box sx={{ flexGrow: 1, mt: 2 }}>
              <Grid
                container
                columns={{ xs: 1, sm: 6, md: 6 }}
                className={`${classes.gridContainer}`}
                key={`grid-tran-info`}
              >
                <Grid item sm={12} key="Topic">
                  <Typography
                    sx={{ mb: '10px', padding: '20px 20px 0 20px' }}
                    component={'div'}
                    variant="H4_Bold"
                  >
                    Transaction Information
                  </Typography>
                </Grid>
                <Grid item xs={1} sm={1} md={1} className={`${classes.gridItem}`}>
                  <Typography
                    variant="Paragraph_xs_Light"
                    gutterBottom
                    className={`${classes.gridItemLabel}`}
                  >
                    Order No
                  </Typography>
                  <Typography variant="subtitle2" color={'text.primary'} gutterBottom>
                    {records?.tranDetail?.txnTokenID}
                  </Typography>
                </Grid>
                <Grid item xs={1} sm={1} md={1} className={`${classes.gridItem}`}>
                  <Typography
                    variant="Paragraph_xs_Light"
                    gutterBottom
                    className={`${classes.gridItemLabel}`}
                  >
                    Operation Name
                  </Typography>
                  <Typography variant="subtitle2" color={'text.primary'} gutterBottom>
                    {records?.tranDetail?.operatorName +
                      ' ' +
                      ConvertDateGMT(records?.tranDetail?.gmtLocalDot)}
                  </Typography>
                </Grid>
                <Grid item xs={1} sm={1} md={1} className={`${classes.gridItem}`}>
                  <Typography
                    variant="Paragraph_xs_Light"
                    gutterBottom
                    className={`${classes.gridItemLabel}`}
                  >
                    Payout Amount
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    color={'text.primary'}
                    gutterBottom
                    className={`${classes.gridItemHighlight}`}
                  >
                    {fNumberCurrency(
                      records?.tranDetail?.payoutAmount,
                      records?.tranDetail?.payoutCCY,
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={1} sm={1} md={1} className={`${classes.gridItem}`}>
                  <IconBtn
                    sx={{}}
                    onClick={() => {
                      handleExpandTransactionInfo()
                    }}
                    alt="expand"
                    src={
                      expandedTransactionInfo
                        ? `/assets/icons/icon_arrow_up.svg`
                        : `/assets/icons/icon_arrow_down.svg`
                    }
                  />
                </Grid>
                <Collapse
                  key={`child-tran-info`}
                  in={expandedTransactionInfo}
                  timeout="auto"
                  unmountOnExit
                  sx={{ width: '100%', padding: '20px 20px 20px 20px' }}
                >
                  <Box>
                    <Grid
                      container
                      columns={{ xs: 1, sm: 5, md: 5 }}
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                      key={`grid-txn-ref`}
                    >
                      <Grid item xs={1} sm={1} md={1}>
                        <Typography
                          variant="Paragraph_xs_Light"
                          gutterBottom
                          className={`${classes.gridItemLabel}`}
                        >
                          Collected Amount
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          color={'text.primary'}
                          gutterBottom
                        >
                          {fNumberCurrency(
                            records?.tranDetail?.collecedtAmount,
                            records?.tranDetail?.paidCType,
                          )}
                        </Typography>
                      </Grid>

                      <Grid item xs={1} sm={1} md={1}>
                        <Typography
                          variant="Paragraph_xs_Light"
                          gutterBottom
                          className={`${classes.gridItemLabel}`}
                        >
                          Service Charge
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          color={'text.primary'}
                          gutterBottom
                        >
                          {fNumberCurrency(
                            records?.tranDetail?.serviceCharge,
                            records?.tranDetail?.paidCType,
                          )}
                        </Typography>
                      </Grid>

                      <Grid item xs={1} sm={1} md={1}>
                        <Typography
                          variant="Paragraph_xs_Light"
                          gutterBottom
                          className={`${classes.gridItemLabel}`}
                        >
                          Transfer Amount
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          color={'text.primary'}
                          gutterBottom
                        >
                          {fNumberCurrency(
                            records?.tranDetail?.transferAmount,
                            records?.tranDetail?.paidCType,
                          )}
                        </Typography>
                      </Grid>

                      <Grid item xs={1} sm={1} md={1}>
                        <Typography
                          variant="Paragraph_xs_Light"
                          gutterBottom
                          className={`${classes.gridItemLabel}`}
                        >
                          Exchange Rate
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          color={'text.primary'}
                          gutterBottom
                        >
                          {'1 ' +
                            records?.tranDetail?.paidCType +
                            ' = ' +
                            fNumberCurrency(
                              records?.tranDetail?.exchangeRate,
                              records?.tranDetail?.payoutCCY,
                            )}
                        </Typography>
                      </Grid>

                      <Grid item xs={1} sm={1} md={1}>
                        <Typography
                          variant="Paragraph_xs_Light"
                          gutterBottom
                          className={`${classes.gridItemLabel}`}
                        >
                          Approve By
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          color={'text.primary'}
                          gutterBottom
                        >
                          {records?.tranDetail?.approveBy}
                        </Typography>
                      </Grid>

                      <Grid item xs={1} sm={1} md={1}>
                        <Typography
                          variant="Paragraph_xs_Light"
                          gutterBottom
                          className={`${classes.gridItemLabel}`}
                        >
                          Serial No.
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          color={'text.primary'}
                          gutterBottom
                        >
                          {records?.tranDetail?.serialNo}
                        </Typography>
                      </Grid>

                      <Grid item xs={1} sm={1} md={1}>
                        <Typography
                          variant="Paragraph_xs_Light"
                          gutterBottom
                          className={`${classes.gridItemLabel}`}
                        >
                          Transaction Source
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          color={'text.primary'}
                          gutterBottom
                        >
                          {records?.tranDetail?.depositType === 'Cash'
                            ? 'Send Page'
                            : records?.tranDetail?.depositType}
                        </Typography>
                      </Grid>

                      <Grid item xs={1} sm={3} md={3}>
                        <Typography
                          variant="Paragraph_xs_Light"
                          gutterBottom
                          className={`${classes.gridItemLabel}`}
                        >
                          API By
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          color={'text.primary'}
                          gutterBottom
                        >
                          {records?.tranDetail?.apiPostBy !== ''
                            ? records?.tranDetail?.apiPostBy +
                              ' on : ' +
                              ConvertDateYYYYMMDD(records?.tranDetail?.apiPostDate)
                            : ''}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </Collapse>
              </Grid>
            </Box>
          </Grid>

          <Grid item sm={12} key="Container">
            <Box sx={{ flexGrow: 1, mt: 2 }}>
              <Grid
                container
                columns={{ xs: 1, sm: 8, md: 8 }}
                className={`${classes.gridContainer}`}
                key={`grid-sender-info`}
              >
                <Grid item sm={12} key="Topic">
                  <Typography
                    sx={{ mb: '10px', padding: '20px 20px 0 20px' }}
                    component={'div'}
                    variant="H4_Bold"
                  >
                    Sender Information
                  </Typography>
                </Grid>
                <Grid item xs={1} sm={1} md={1} className={`${classes.gridItem}`}>
                  <Typography
                    variant="Paragraph_xs_Light"
                    gutterBottom
                    className={`${classes.gridItemLabel}`}
                  >
                    First Name
                  </Typography>
                  <Typography variant="subtitle2" color={'text.primary'} gutterBottom>
                    {records?.tranDetail?.senderFirstName}
                  </Typography>
                </Grid>
                <Grid item xs={1} sm={1} md={1} className={`${classes.gridItem}`}>
                  <Typography
                    variant="Paragraph_xs_Light"
                    gutterBottom
                    className={`${classes.gridItemLabel}`}
                  >
                    Middle Name
                  </Typography>
                  <Typography variant="subtitle2" color={'text.primary'} gutterBottom>
                    {records?.tranDetail?.senderMiddleName}
                  </Typography>
                </Grid>
                <Grid item xs={1} sm={1} md={1} className={`${classes.gridItem}`}>
                  <Typography
                    variant="Paragraph_xs_Light"
                    gutterBottom
                    className={`${classes.gridItemLabel}`}
                  >
                    Last Name
                  </Typography>
                  <Typography variant="subtitle2" color={'text.primary'} gutterBottom>
                    {records?.tranDetail?.senderLastName}
                  </Typography>
                </Grid>
                <Grid item xs={1} sm={1} md={1} className={`${classes.gridItem}`}>
                  <Typography
                    variant="Paragraph_xs_Light"
                    gutterBottom
                    className={`${classes.gridItemLabel}`}
                  >
                    Customer ID
                  </Typography>
                  <Typography variant="subtitle2" color={'text.primary'} gutterBottom>
                    {records?.tranDetail?.customerID}
                  </Typography>
                </Grid>
                <Grid item xs={1} sm={1} md={1} className={`${classes.gridItem}`}>
                  <Typography
                    variant="Paragraph_xs_Light"
                    gutterBottom
                    className={`${classes.gridItemLabel}`}
                  >
                    ID Type
                  </Typography>
                  <Typography variant="subtitle2" color={'text.primary'} gutterBottom>
                    {records?.tranDetail?.idType}
                  </Typography>
                </Grid>
                <Grid item xs={1} sm={1} md={1} className={`${classes.gridItem}`}>
                  <Typography
                    variant="Paragraph_xs_Light"
                    gutterBottom
                    className={`${classes.gridItemLabel}`}
                  >
                    ID No
                  </Typography>
                  <Typography variant="subtitle2" color={'text.primary'} gutterBottom>
                    {records?.tranDetail?.idNo}
                  </Typography>
                </Grid>
                <Grid item xs={1} sm={1} md={1} className={`${classes.gridItem}`}>
                  <IconBtn
                    sx={{}}
                    onClick={() => {
                      handleExpandSenderInfo()
                    }}
                    alt="expand"
                    src={
                      expandedSenderInfo
                        ? `/assets/icons/icon_arrow_up.svg`
                        : `/assets/icons/icon_arrow_down.svg`
                    }
                  />
                </Grid>
                <Collapse
                  key={`child-sender-info`}
                  in={expandedSenderInfo}
                  timeout="auto"
                  unmountOnExit
                  sx={{ width: '100%', padding: '20px 20px 20px 20px' }}
                >
                  <Box>
                    <Grid
                      container
                      columns={{ xs: 1, sm: 6, md: 6 }}
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                      key={`grid-txn-ref`}
                    >
                      <Grid item xs={1} sm={1} md={1}>
                        <Typography
                          variant="Paragraph_xs_Light"
                          gutterBottom
                          className={`${classes.gridItemLabel}`}
                        >
                          ID Expiry Date
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          color={'text.primary'}
                          gutterBottom
                        >
                          {ConvertDateYYYYMMDD(records?.tranDetail?.validDate)}
                        </Typography>
                      </Grid>

                      <Grid item xs={1} sm={1} md={1}>
                        <Typography
                          variant="Paragraph_xs_Light"
                          gutterBottom
                          className={`${classes.gridItemLabel}`}
                        >
                          ID Issue Date
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          color={'text.primary'}
                          gutterBottom
                        >
                          {ConvertDateYYYYMMDD(records?.tranDetail?.issuedDate)}
                        </Typography>
                      </Grid>

                      <Grid item xs={1} sm={1} md={1}>
                        <Typography
                          variant="Paragraph_xs_Light"
                          gutterBottom
                          className={`${classes.gridItemLabel}`}
                        >
                          Address
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          color={'text.primary'}
                          gutterBottom
                        >
                          {records?.tranDetail?.address}
                        </Typography>
                      </Grid>

                      <Grid item xs={1} sm={1} md={1}>
                        <Typography
                          variant="Paragraph_xs_Light"
                          gutterBottom
                          className={`${classes.gridItemLabel}`}
                        >
                          ID Issue Country
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          color={'text.primary'}
                          gutterBottom
                        >
                          {CountryNameByCode(records?.tranDetail?.issuedCountry)}
                        </Typography>
                      </Grid>

                      <Grid item xs={1} sm={1} md={1}>
                        <Typography
                          variant="Paragraph_xs_Light"
                          gutterBottom
                          className={`${classes.gridItemLabel}`}
                        >
                          Email
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          color={'text.primary'}
                          gutterBottom
                        >
                          {records?.tranDetail?.email}
                        </Typography>
                      </Grid>

                      <Grid item xs={1} sm={1} md={1}>
                        <Typography
                          variant="Paragraph_xs_Light"
                          gutterBottom
                          className={`${classes.gridItemLabel}`}
                        >
                          Date of Birth
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          color={'text.primary'}
                          gutterBottom
                        >
                          {ConvertDateYYYYMMDD(records?.tranDetail?.dateOfBirth)}
                        </Typography>
                      </Grid>

                      <Grid item xs={1} sm={1} md={1}>
                        <Typography
                          variant="Paragraph_xs_Light"
                          gutterBottom
                          className={`${classes.gridItemLabel}`}
                        >
                          Occupation
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          color={'text.primary'}
                          gutterBottom
                        >
                          {records?.tranDetail?.occupation}
                        </Typography>
                      </Grid>

                      <Grid item xs={1} sm={1} md={1}>
                        <Typography
                          variant="Paragraph_xs_Light"
                          gutterBottom
                          className={`${classes.gridItemLabel}`}
                        >
                          Mobile no
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          color={'text.primary'}
                          gutterBottom
                        >
                          {records?.tranDetail?.mobileNo}
                        </Typography>
                      </Grid>

                      <Grid item xs={1} sm={1} md={1}>
                        <Typography
                          variant="Paragraph_xs_Light"
                          gutterBottom
                          className={`${classes.gridItemLabel}`}
                        >
                          Phone No
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          color={'text.primary'}
                          gutterBottom
                        >
                          {records?.tranDetail?.phoneNo}
                        </Typography>
                      </Grid>

                      <Grid item xs={1} sm={1} md={1}>
                        <Typography
                          variant="Paragraph_xs_Light"
                          gutterBottom
                          className={`${classes.gridItemLabel}`}
                        >
                          Beneficiary Relationship
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          color={'text.primary'}
                          gutterBottom
                        >
                          {records?.tranDetail?.beneficiaryRelationship}
                        </Typography>
                      </Grid>

                      <Grid item xs={1} sm={1} md={1}>
                        <Typography
                          variant="Paragraph_xs_Light"
                          gutterBottom
                          className={`${classes.gridItemLabel}`}
                        >
                          Employer Name
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          color={'text.primary'}
                          gutterBottom
                        >
                          {records?.tranDetail?.employerName}
                        </Typography>
                      </Grid>

                      <Grid item xs={1} sm={1} md={1}>
                        <Typography
                          variant="Paragraph_xs_Light"
                          gutterBottom
                          className={`${classes.gridItemLabel}`}
                        >
                          Purpose of Remittance
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          color={'text.primary'}
                          gutterBottom
                        >
                          {records?.tranDetail?.purposeOfRemittance}
                        </Typography>
                      </Grid>

                      <Grid item xs={1} sm={1} md={1}>
                        <Typography
                          variant="Paragraph_xs_Light"
                          gutterBottom
                          className={`${classes.gridItemLabel}`}
                        >
                          Gender
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          color={'text.primary'}
                          gutterBottom
                        >
                          {records?.tranDetail?.gender === 'M' ? 'Male' : 'Female'}
                        </Typography>
                      </Grid>

                      <Grid item xs={1} sm={1} md={1}>
                        <Typography
                          variant="Paragraph_xs_Light"
                          gutterBottom
                          className={`${classes.gridItemLabel}`}
                        >
                          Sender Type
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          color={'text.primary'}
                          gutterBottom
                        >
                          {records?.tranDetail?.senderType}
                        </Typography>
                      </Grid>

                      <Grid item xs={1} sm={1} md={1}>
                        <Typography
                          variant="Paragraph_xs_Light"
                          gutterBottom
                          className={`${classes.gridItemLabel}`}
                        >
                          Nationality
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          color={'text.primary'}
                          gutterBottom
                        >
                          {CountryNameByCode(records?.tranDetail?.nationality)}
                        </Typography>
                      </Grid>

                      <Grid item xs={1} sm={1} md={1}>
                        <Typography
                          variant="Paragraph_xs_Light"
                          gutterBottom
                          className={`${classes.gridItemLabel}`}
                        >
                          Source of Funds
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          color={'text.primary'}
                          gutterBottom
                        >
                          {records?.tranDetail?.sourceOfFunds}
                        </Typography>
                      </Grid>

                      <Grid item xs={1} sm={1} md={1}>
                        <Typography
                          variant="Paragraph_xs_Light"
                          gutterBottom
                          className={`${classes.gridItemLabel}`}
                        >
                          KYC
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          color={'text.primary'}
                          gutterBottom
                        >
                          {records?.tranDetail?.kyc}
                        </Typography>
                      </Grid>

                      <Grid item xs={1} sm={1} md={1}>
                        <Typography
                          variant="Paragraph_xs_Light"
                          gutterBottom
                          className={`${classes.gridItemLabel}`}
                        >
                          Visa Type
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          color={'text.primary'}
                          gutterBottom
                        >
                          {records?.tranDetail?.senderType}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </Collapse>
              </Grid>
            </Box>
          </Grid>

          <Grid item sm={12} key="Container">
            <Box sx={{ flexGrow: 1, mt: 2 }}>
              <Grid
                container
                columns={{ xs: 1, sm: 8, md: 8 }}
                className={`${classes.gridContainer}`}
                key={`grid-receiver-info`}
              >
                <Grid item sm={12} key="Topic">
                  <Typography
                    sx={{ mb: '10px', padding: '20px 20px 0 20px' }}
                    component={'div'}
                    variant="H4_Bold"
                  >
                    Receiver Information
                  </Typography>
                </Grid>
                <Grid item xs={1} sm={1} md={1} className={`${classes.gridItem}`}>
                  <Typography
                    variant="Paragraph_xs_Light"
                    gutterBottom
                    className={`${classes.gridItemLabel}`}
                  >
                    Payout Country
                  </Typography>
                  <Typography variant="subtitle2" color={'text.primary'} gutterBottom>
                    {CountryNameByCode(records?.tranDetail?.receiverCountry)}
                  </Typography>
                </Grid>
                <Grid item xs={1} sm={1} md={1} className={`${classes.gridItem}`}>
                  <Typography
                    variant="Paragraph_xs_Light"
                    gutterBottom
                    className={`${classes.gridItemLabel}`}
                  >
                    Receiver Nationality
                  </Typography>
                  <Typography variant="subtitle2" color={'text.primary'} gutterBottom>
                    {records?.tranDetail?.receiverNationality}
                  </Typography>
                </Grid>
                <Grid item xs={1} sm={1} md={1} className={`${classes.gridItem}`}>
                  <Typography
                    variant="Paragraph_xs_Light"
                    gutterBottom
                    className={`${classes.gridItemLabel}`}
                  >
                    First Name
                  </Typography>
                  <Typography variant="subtitle2" color={'text.primary'} gutterBottom>
                    {records?.tranDetail?.receiverFirstName}
                  </Typography>
                </Grid>

                <Grid item xs={1} sm={1} md={1} className={`${classes.gridItem}`}>
                  <Typography
                    variant="Paragraph_xs_Light"
                    gutterBottom
                    className={`${classes.gridItemLabel}`}
                  >
                    Middle Name
                  </Typography>
                  <Typography variant="subtitle2" color={'text.primary'} gutterBottom>
                    {records?.tranDetail?.receiverMiddleName}
                  </Typography>
                </Grid>

                <Grid item xs={1} sm={1} md={1} className={`${classes.gridItem}`}>
                  <Typography
                    variant="Paragraph_xs_Light"
                    gutterBottom
                    className={`${classes.gridItemLabel}`}
                  >
                    Last Name
                  </Typography>
                  <Typography variant="subtitle2" color={'text.primary'} gutterBottom>
                    {records?.tranDetail?.receiverLastName}
                  </Typography>
                </Grid>

                <Grid item xs={1} sm={1} md={1} className={`${classes.gridItem}`}>
                  <Typography
                    variant="Paragraph_xs_Light"
                    gutterBottom
                    className={`${classes.gridItemLabel}`}
                  >
                    Receiver Type
                  </Typography>
                  <Typography variant="subtitle2" color={'text.primary'} gutterBottom>
                    {CustomerType(records?.tranDetail?.beneficiary_Customer_Type)}
                  </Typography>
                </Grid>

                <Grid item xs={1} sm={1} md={1} className={`${classes.gridItem}`}>
                  <IconBtn
                    sx={{}}
                    onClick={() => {
                      handleExpandReceiverInfo()
                    }}
                    alt="expand"
                    src={
                      expandedReceiverInfo
                        ? `/assets/icons/icon_arrow_up.svg`
                        : `/assets/icons/icon_arrow_down.svg`
                    }
                  />
                </Grid>
                <Collapse
                  key={`child-receiver-info`}
                  in={expandedReceiverInfo}
                  timeout="auto"
                  unmountOnExit
                  sx={{ width: '100%', padding: '20px 20px 20px 20px' }}
                >
                  <Box>
                    <Grid
                      container
                      columns={{ xs: 1, sm: 6, md: 6 }}
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                      key={`grid-txn-ref`}
                    >
                      <Grid item xs={1} sm={1} md={1}>
                        <Typography
                          variant="Paragraph_xs_Light"
                          gutterBottom
                          className={`${classes.gridItemLabel}`}
                        >
                          Receiver Gender
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          color={'text.primary'}
                          gutterBottom
                        >
                          {records?.tranDetail?.receiverGender === 'M'
                            ? 'Male'
                            : 'Female'}
                        </Typography>
                      </Grid>

                      <Grid item xs={1} sm={1} md={1}>
                        <Typography
                          variant="Paragraph_xs_Light"
                          gutterBottom
                          className={`${classes.gridItemLabel}`}
                        >
                          Phone Number
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          color={'text.primary'}
                          gutterBottom
                        >
                          {records?.tranDetail?.receiverPhone}
                        </Typography>
                      </Grid>

                      <Grid item xs={1} sm={1} md={1}>
                        <Typography
                          variant="Paragraph_xs_Light"
                          gutterBottom
                          className={`${classes.gridItemLabel}`}
                        >
                          ID Type
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          color={'text.primary'}
                          gutterBottom
                        >
                          {records?.tranDetail?.receiverIDType}
                        </Typography>
                      </Grid>

                      <Grid item xs={1} sm={1} md={1}>
                        <Typography
                          variant="Paragraph_xs_Light"
                          gutterBottom
                          className={`${classes.gridItemLabel}`}
                        >
                          ID Number
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          color={'text.primary'}
                          gutterBottom
                        >
                          {records?.tranDetail?.receiverIDNO}
                        </Typography>
                      </Grid>

                      <Grid item xs={1} sm={1} md={1}>
                        <Typography
                          variant="Paragraph_xs_Light"
                          gutterBottom
                          className={`${classes.gridItemLabel}`}
                        >
                          Receiver ID Issue Date
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          color={'text.primary'}
                          gutterBottom
                        >
                          {ConvertDateYYYYMMDD(records?.tranDetail?.receiverIdIssueDate)}
                        </Typography>
                      </Grid>

                      <Grid item xs={1} sm={1} md={1}>
                        <Typography
                          variant="Paragraph_xs_Light"
                          gutterBottom
                          className={`${classes.gridItemLabel}`}
                        >
                          Receiver ID Expiry Date
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          color={'text.primary'}
                          gutterBottom
                        >
                          {ConvertDateYYYYMMDD(records?.tranDetail?.receiverIdExpireDate)}
                        </Typography>
                      </Grid>

                      <Grid item xs={1} sm={1} md={1}>
                        <Typography
                          variant="Paragraph_xs_Light"
                          gutterBottom
                          className={`${classes.gridItemLabel}`}
                        >
                          Receiver Address
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          color={'text.primary'}
                          gutterBottom
                        >
                          {records?.tranDetail?.receiverAddress}
                        </Typography>
                      </Grid>

                      <Grid item xs={1} sm={1} md={1}>
                        <Typography
                          variant="Paragraph_xs_Light"
                          gutterBottom
                          className={`${classes.gridItemLabel}`}
                        >
                          Receiver DOB
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          color={'text.primary'}
                          gutterBottom
                        >
                          {records?.tranDetail?.receiverDOB}
                        </Typography>
                      </Grid>

                      <Grid item xs={1} sm={1} md={1}>
                        <Typography
                          variant="Paragraph_xs_Light"
                          gutterBottom
                          className={`${classes.gridItemLabel}`}
                        >
                          Location
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          color={'text.primary'}
                          gutterBottom
                        >
                          {records?.tranDetail?.locationName}
                        </Typography>
                      </Grid>

                      <Grid item xs={1} sm={1} md={1}>
                        <Typography
                          variant="Paragraph_xs_Light"
                          gutterBottom
                          className={`${classes.gridItemLabel}`}
                        >
                          Branch
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          color={'text.primary'}
                          gutterBottom
                        >
                          {records?.tranDetail?.bankBranchName}
                        </Typography>
                      </Grid>

                      <Grid item xs={1} sm={1} md={1}>
                        <Typography
                          variant="Paragraph_xs_Light"
                          gutterBottom
                          className={`${classes.gridItemLabel}`}
                        >
                          Bank Account No.
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          color={'text.primary'}
                          gutterBottom
                        >
                          {records?.tranDetail?.bankAccountNo}
                        </Typography>
                      </Grid>

                      <Grid item xs={1} sm={1} md={1}>
                        <Typography
                          variant="Paragraph_xs_Light"
                          gutterBottom
                          className={`${classes.gridItemLabel}`}
                        >
                          Receiver Message
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          color={'text.primary'}
                          gutterBottom
                        >
                          {records?.tranDetail?.beneficiaryMessage}
                        </Typography>
                      </Grid>

                      <Grid item xs={1} sm={1} md={1}>
                        <Typography
                          variant="Paragraph_xs_Light"
                          gutterBottom
                          className={`${classes.gridItemLabel}`}
                        >
                          Invoice No.
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          color={'text.primary'}
                          gutterBottom
                        >
                          {records?.tranDetail?.invoiceNumber}
                        </Typography>
                      </Grid>

                      <Grid item xs={1} sm={1} md={1}>
                        <Typography
                          variant="Paragraph_xs_Light"
                          gutterBottom
                          className={`${classes.gridItemLabel}`}
                        >
                          Occupation
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          color={'text.primary'}
                          gutterBottom
                        >
                          {records?.tranDetail?.beneficiaryOccupation}
                        </Typography>
                      </Grid>

                      <Grid item xs={1} sm={4} md={4}>
                        <Typography
                          variant="Paragraph_xs_Light"
                          gutterBottom
                          className={`${classes.gridItemLabel}`}
                        >
                          Email
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          color={'text.primary'}
                          gutterBottom
                        >
                          {records?.tranDetail?.receiverEmail}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </Collapse>
              </Grid>
            </Box>
          </Grid>

          <Grid item sm={12} key="Container">
            <Box sx={{ flexGrow: 1, mt: 2 }}>
              <Grid
                container
                columns={{ xs: 1, sm: 4, md: 4 }}
                className={`${classes.gridContainer}`}
                key={`grid-txn-ref`}
              >
                <Grid item xs={1} sm={1} md={1} className={`${classes.gridItem}`}>
                  <Typography
                    variant="Paragraph_xs_Light"
                    gutterBottom
                    className={`${classes.gridItemLabel}`}
                  >
                    Deposite Type
                  </Typography>
                </Grid>
                <Grid item xs={1} sm={1} md={1} className={`${classes.gridItem}`}>
                  <Typography
                    variant="Paragraph_xs_Light"
                    gutterBottom
                    className={`${classes.gridItemLabel}`}
                  >
                    Ref 1
                  </Typography>
                </Grid>
                <Grid item xs={1} sm={1} md={1} className={`${classes.gridItem}`}>
                  <Typography
                    variant="Paragraph_xs_Light"
                    gutterBottom
                    className={`${classes.gridItemLabel}`}
                  >
                    Ref 2
                  </Typography>
                </Grid>
                <Grid item xs={1} sm={1} md={1} className={`${classes.gridItem}`}>
                  <Typography
                    variant="Paragraph_xs_Light"
                    gutterBottom
                    className={`${classes.gridItemLabel}`}
                  >
                    Deposite Date
                  </Typography>
                </Grid>
                {records?.depositList?.map((item, index) => (
                  <>
                    <Grid item xs={1} sm={1} md={1} className={`${classes.gridRowItem}`}>
                      <Typography variant="subtitle2" color={'text.primary'} gutterBottom>
                        {item?.depositType}
                      </Typography>
                    </Grid>
                    <Grid item xs={1} sm={1} md={1} className={`${classes.gridRowItem}`}>
                      <Typography variant="subtitle2" color={'text.primary'} gutterBottom>
                        {item?.reF1}
                      </Typography>
                    </Grid>
                    <Grid item xs={1} sm={1} md={1} className={`${classes.gridRowItem}`}>
                      <Typography variant="subtitle2" color={'text.primary'} gutterBottom>
                        {item?.reF2}
                      </Typography>
                    </Grid>
                    <Grid item xs={1} sm={1} md={1} className={`${classes.gridRowItem}`}>
                      <Typography variant="subtitle2" color={'text.primary'} gutterBottom>
                        {ConvertDateYYYYMMDD(item?.depositDate)}
                      </Typography>
                    </Grid>
                  </>
                ))}
              </Grid>
            </Box>
          </Grid>
          {records?.imageList.length > 0 && (
            <>
              <Grid item sm={12} key="Container">
                <Box sx={{ flexGrow: 1, mt: 2 }}>
                  <Grid
                    container
                    columns={{ xs: 1, sm: 5, md: 5 }}
                    className={`${classes.gridContainer}`}
                    key={`grid-txn-ref`}
                  >
                    <Grid item sm={12} key="Topic">
                      <Typography
                        sx={{ mb: '10px', padding: '20px 20px 0 20px' }}
                        component={'div'}
                        variant="H4_Bold"
                      >
                        Document upload
                      </Typography>
                    </Grid>
                    <Grid item xs={1} sm={4} md={4} className={`${classes.gridItem}`}>
                      <Typography
                        variant="Paragraph_xs_Light"
                        gutterBottom
                        className={`${classes.gridItemLabel}`}
                      >
                        Document Name
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={1}
                      sm={1}
                      md={1}
                      className={`${classes.gridItem}`}
                    ></Grid>
                    {records?.imageList?.map((item, index) => (
                      <>
                        <Grid
                          item
                          xs={1}
                          sm={4}
                          md={4}
                          className={`${classes.gridRowItem}`}
                        >
                          <Typography
                            variant="subtitle2"
                            color={'text.primary'}
                            gutterBottom
                          >
                            {item?.imagePath}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={1}
                          sm={1}
                          md={1}
                          className={`${classes.gridRowItem}`}
                        >
                          <Button
                            startIcon={
                              <SvgColor
                                src={`/assets/icons/icon_eyes.svg`}
                                sx={{ width: 20, height: 20 }}
                              />
                            }
                            text="View file"
                            size="small"
                            variant="outlined"
                            color="inherit"
                            onClick={() => {
                              getDocumentFullPath(
                                records?.tranDetail?.tableName,
                                item?.imageID,
                                item?.imagePath,
                              )
                            }}
                          />
                        </Grid>
                      </>
                    ))}
                  </Grid>
                </Box>
              </Grid>
            </>
          )}
        </Grid>
        <div className={`${classes.btn}`}>
          <Button
            text="Back to send money"
            size="small"
            variant="outlined"
            color="inherit"
            sx={{
              width: '95%',
              height: '48px',
              marginRight: '20px',
            }}
            onClick={() => {
              navigate(`/send-money`, {
                replace: false,
              })
            }}
          />
          <Button
            variant="contained"
            text="Go to transaction history"
            sx={{ width: '95%', marginRight: '20px' }}
            onClick={() => {
              navigate(`/history`, {
                replace: false,
              })
            }}
          />
        </div>
      </Container>
      <AlertBox notify={notify} setNotify={setNotify} />
    </React.Fragment>
  )
}
