import React, { useState } from 'react'
import {
  Table,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TableContainer,
  Paper,
  Stack,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import CheckBox from './common/Checkbox'
import { isEmptyObject } from '../utils/app'

const StyledTableCell = styled(TableCell)(() => ({
  color: '#8685AE',
  padding: 0,
  fontWeight: 300,
  fontSize: '12px',
  fontStyle: 'normal',
  textAlign: 'center',
}))

export default function useTable(records, headCells, filterFn = {}) {
  const pages = [5, 10, 25, 50, 100]
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(pages[page])
  const [order, setOrder] = useState()
  const [orderBy, setOrderBy] = useState()

  const TblContainer = (props) => {
    return (
      <TableContainer component={Paper}>
        <Table size="small">{props.children}</Table>
      </TableContainer>
    )
  }

  const TblHead = (props) => {
    const handleSortRequest = (cellId) => {
      setPage(0)
      const isAsc = orderBy === cellId && order === 'asc'
      setOrder(isAsc ? 'desc' : 'asc')
      setOrderBy(cellId)
    }

    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => {
            return (
              <StyledTableCell
                key={headCell.id}
                sortDirection={orderBy === headCell.id ? order : false}
              >
                {headCell.disableSorting ? (
                  headCell.label
                ) : (
                  <>
                    {headCell?.checked?.status ? (
                      <Stack flexDirection="row">
                        <CheckBox
                          name={headCell?.id}
                          value={headCell?.checked?.value}
                          onChange={headCell?.checked?.onchange}
                        />
                        {/* <TableSortLabel
                          active={orderBy === headCell.id}
                          direction={orderBy === headCell.id ? order : 'asc'}
                          onClick={() => handleSortRequest(headCell.id)}
                        >
                          {headCell.label}
                        </TableSortLabel> */}
                      </Stack>
                    ) : (
                      <TableSortLabel
                        active={orderBy === headCell.id}
                        direction={orderBy === headCell.id ? order : 'asc'}
                        onClick={() => handleSortRequest(headCell.id)}
                      >
                        {headCell.label}
                      </TableSortLabel>
                    )}
                  </>
                )}
              </StyledTableCell>
            )
          })}
        </TableRow>
      </TableHead>
    )
  }

  const handlePageChange = (event, newPage) => {
    setPage(newPage)
  }

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const TblPagination = () => {
    return (
      <TablePagination
        component="div"
        page={page}
        rowsPerPageOptions={pages}
        rowsPerPage={rowsPerPage}
        count={records.length}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
      />
    )
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index])
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0])
      if (order !== 0) return order
      return a[1] - b[1]
    })
    return stabilizedThis.map((el) => el[0])
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy)
  }

  function descendingComparator(a, b, orderBy) {
    if ((b[orderBy] ?? '') < (a[orderBy] ?? '')) {
      return -1
    }
    if ((b[orderBy] ?? '') > (a[orderBy] ?? '')) {
      return 1
    }
    return 0
  }

  const recordsAfterPaginationAndSorting = () => {
    if (!isEmptyObject(filterFn)) {
      records = filterFn?.fn(records)
      return stableSort(records, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        (page + 1) * rowsPerPage,
      )
    }

    return stableSort(records, getComparator(order, orderBy)).slice(
      page * rowsPerPage,
      (page + 1) * rowsPerPage,
    )
  }

  return {
    TblContainer,
    TblHead,
    TblPagination,
    recordsAfterPaginationAndSorting,
    setPage,
  }
}
