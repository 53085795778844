import { types } from './action'

const INITIAL_STATE = {
  display_preview_transaction: {},
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case types.PREVIEW_TRANSACTION:
      return { ...state, display_preview_transaction: payload }
    default:
      return state
  }
}
