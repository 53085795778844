import Languages from '../resource/languages.json'

export function getLanguage() {
  let language = getDataLocalStorage('language')
  if (language === null) {
    return 'en'
  } else {
    return language
  }
}

export function getLangNameByLangCode(language_code) {
  return (
    Languages?.languages.filter((language) => {
      return language.value === language_code ?? ''
    })?.[0]?.label ?? ''
  )
}

export function saveDataLocalStorage(key, data) {
  localStorage.setItem(key, JSON.stringify(data))
}

export function getDataLocalStorage(key) {
  return JSON.parse(localStorage.getItem(key))
}
