import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Grid, Stack, Container, Typography } from '@mui/material'
import { useNavigate, useLocation } from 'react-router-dom'
import CardExpand from '../../components/common/CardExpand'
import Button from '../../components/common/Button'
import SearchSender from './search-sender'
import ConfirmDialog from '../../components/useDialog'
import {
  selectCustomerDetail,
  selectReceiverDetail,
  fetchReceiverDelivery,
} from '../../redux/master/action'
import { fetchPreviewSendTransaction } from '../../redux/send-money/action'

export default function SendMoney(props) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const currentPath = location.pathname
  const [confirmDialog, setConfirmDialog] = useState()

  return (
    <Container>
      <Grid container justifyContent="center" spacing={3}>
        <Grid item sm={8}>
          <CardExpand title="SEARCH SENDER" defaultOpen>
            <SearchSender />
            <Stack padding={3} flexDirection="row" justifyContent="center">
              <img src={`/assets/images/svg/line.svg`} alt="line" />
              <Typography px={1} variant="H4_Light" color="text.popup">
                OR
              </Typography>
              <img src={`/assets/images/svg/line.svg`} alt="line" />
            </Stack>
            <Stack flexDirection="row" justifyContent="center">
              <Button
                onClick={() => {
                  dispatch(selectCustomerDetail({}))
                  dispatch(selectReceiverDetail({}))
                  dispatch(fetchReceiverDelivery([]))
                  dispatch(fetchPreviewSendTransaction({}))
                  navigate(`${currentPath}/create-new-sender`)
                }}
                text="CREATE NEW SENDER"
                size="large"
              />
            </Stack>
          </CardExpand>
        </Grid>
      </Grid>
      <ConfirmDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
    </Container>
  )
}
