import React from 'react'
import { Grid, Stack } from '@mui/material'
import CardExpand from '../../../components/common/CardExpand'
import ReceiverAlternativeDetailsContainer from './receiver-alternative-detail'
import ReceiverAdditionalInformationContainer from './receiver-additional-information'
import ReceiverBankDetailsContainer from './receiver-bank-details'

export default function ReceiverAdditionalInformationTab(props) {
  return (
    <div>
      <Stack spacing={3}>
        <Grid item sm={6}>
          <CardExpand title="Additional Infomation" defaultOpen="true">
            <ReceiverAlternativeDetailsContainer {...props} />
          </CardExpand>
        </Grid>
        <Grid item sm={6}>
          <CardExpand
            title="Additional Document Infomation"
            defaultOpen={props.receiverInfo !== undefined ? false : true}
          >
            <ReceiverAdditionalInformationContainer {...props} />
          </CardExpand>
        </Grid>
        <Grid item sm={6}>
          <CardExpand
            title="Additional Bank Infomation"
            defaultOpen={props.receiverInfo !== undefined ? false : true}
          >
            <ReceiverBankDetailsContainer {...props} />
          </CardExpand>
        </Grid>
      </Stack>
    </div>
  )
}
