import React from 'react'
import { makeStyles } from 'tss-react/mui'
import { Box, Typography } from '@mui/material'
import FileUpload from '../../../../components/common/UploadFiles'

const UploadProfileDocument = ({ control, data, setConfirmDialog }) => {
  const { classes } = useStyles()

  return (
    <div>
      <Box className={classes.fileUpload}>
        <Typography variant="Paragraph_md_Bold">Profile Image</Typography>
        <FileUpload limit={1} multiple={false} name="profile_img" />
      </Box>
    </div>
  )
}

export default UploadProfileDocument

const useStyles = makeStyles()((theme) => ({
  fileUpload: {
    [theme.breakpoints.up('md')]: {
      width: 640,
      margin: 'auto',
    },
    span: {
      padding: '16px 20px 0px',
      margin: 'initial',
      [theme.breakpoints.up('md')]: {
        padding: '0px 80px',
      },
    },
  },
}))
