import { alpha, styled } from '@mui/material/styles'
import { Box } from '@mui/material'

export const StyledLabel = styled(Box)(({ theme, ownerState }) => {
  const isLight = theme.palette.mode === 'light'

  const filledVariant = ownerState.variant === 'filled'

  const outlinedVariant = ownerState.variant === 'outlined'

  const softVariant = ownerState.variant === 'soft'

  const defaultStyle = {
    ...(ownerState.color === 'default' && {
      // OUTLINED
      ...(outlinedVariant && {
        backgroundColor: 'transparent',
        color: theme.palette.text.primary,
        border: `1px solid ${alpha(theme.palette.grey[500], 0.32)}`,
      }),
      // SOFT
      ...(softVariant && {
        color: isLight ? theme.palette.text.primary : theme.palette.common.white,
        backgroundColor: alpha(theme.palette.grey[500], 0.16),
      }),
    }),
  }

  const colorStyle = {
    ...(ownerState.color !== 'default' && {
      // FILLED
      ...(filledVariant && {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.status[ownerState.color],
      }),
      // OUTLINED
      ...(outlinedVariant && {
        backgroundColor: 'transparent',
        color: theme.palette.status[ownerState.color],
        border: `1px solid ${theme.palette.status[ownerState.color]}`,
      }),
      // SOFT
      ...(softVariant && {
        color: theme.palette.status[ownerState.color],
        backgroundColor: alpha(theme.palette.status[ownerState.color], 0.1),
      }),
    }),
  }

  return {
    height: 24,
    minWidth: 22,
    lineHeight: 0,
    borderRadius: 30,
    cursor: 'default',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    display: 'flex',
    justifyContent: 'center',
    textTransform: 'capitalize',
    padding: theme.spacing(2, 3),
    color: theme.palette.grey[800],
    fontSize: '14px',
    fontFamily: 'Overpass',
    backgroundColor: theme.palette.grey[300],
    fontWeight: 300,
    letterSpacing: '0.0015em',
    ...colorStyle,
    ...defaultStyle,
  }
})
