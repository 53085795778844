import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Grid, Stack, Card, Typography } from '@mui/material'
import PatternNumberField from '../../../components/common/PatternNumberField'
import AutocompleteField from '../../../components/common/AutocompleteField'
import PhoneNumberField from '../../../components/common/PhoneNumberField'
import NumberField from '../../../components/common/NumberField'
import EmailField from '../../../components/common/EmailField'
import CardExpand from '../../../components/common/CardExpand'
import InputField from '../../../components/common/InputField'
import RadioField from '../../../components/common/RadioField'
import DateField from '../../../components/common/DateField'
import Switch from '../../../components/common/Switch'
import { ValidateModel } from '../../../utils/app'
import isEmpty from 'lodash/isEmpty'

const ReceiverDetail = ({ control, watch }) => {
  const [isOpenMode, setIsOpenMode] = useState(false)
  const GENDERLIST = useSelector((state) => state?.master?.genders)
  const COUNTRYLIST = useSelector((state) => state?.master?.countries)
  const BENEFICOUNTRY = useSelector((state) => state?.master?.beneficountries)
  const OPTION_BANK = useSelector((state) => state?.master?.banks)
  const OPTION_ID_TYPE = useSelector((state) => state?.master?.id_types)
  const OPTION_RELATIONSHIP = useSelector((state) => state?.master?.relationships)
  const OPTION_ACCOUNT_TYPE = useSelector((state) => state?.master?.account_type)
  const OPTION_TEST_QUESTION = useSelector((state) => state?.master?.test_questions)
  const RECEIVER_DETAIL = useSelector((state) => state?.master?.receiver_detail)
  console.log('RECEIVER_DETAIL', RECEIVER_DETAIL)
  const CUSTOMER_DETAIL = useSelector((state) => state?.master?.customer_detail)
  const OPTION_OCCUPATION = useSelector((state) => state?.master?.occupations)
  const OPTION_MODEOFREMITTANCE = useSelector((state) => state?.master?.modeofremittance)
  const RECEIVER_DELIVERY = useSelector((state) => state?.master?.receiver_delivery)
  const OPTION_PURPOSE_OF_REMITTANCE = useSelector(
    (state) => state?.master?.purpose_of_remittances,
  )
  const RECEIV_FIELD_RULES = useSelector((state) => state?.master?.receiver_fieldvalidate)
  const handleChange = () => setIsOpenMode((isOpenMode) => !isOpenMode)

  return (
    <div>
      <Stack spacing={3}>
        <Grid item sm={8}>
          <Card
            sx={{
              padding: '30px 36px',
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              gap: 2,
            }}
          >
            <Typography variant="Paragraph_lg_Bold">
              {isEmpty(RECEIVER_DETAIL)
                ? `New Receiver`
                : `Receiver ID : ${RECEIVER_DETAIL?.receiversno}`}
            </Typography>
            <Typography variant="Paragraph_md_Light">
              {!isEmpty(CUSTOMER_DETAIL)
                ? `Member ID : ${CUSTOMER_DETAIL?.customerDetail?.senderfullname} [
                  ${CUSTOMER_DETAIL?.customerDetail?.customerid}]`
                : null}
            </Typography>
          </Card>
        </Grid>

        <Grid item sm={8}>
          <CardExpand title="receiver details" defaultOpen>
            <Grid container spacing={{ xs: 1, sm: 2 }} columns={{ xs: 1, sm: 8, md: 8 }}>
              <Grid item xs={2} sm={4} md={4}>
                <InputField
                  control={control}
                  name="r_firstName"
                  label="First Name"
                  placeholder="First Name"
                  required={ValidateModel(RECEIV_FIELD_RULES, 'beneficiary_first_name')}
                  disabled={!isEmpty(RECEIVER_DETAIL)}
                />
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <InputField
                  control={control}
                  name="r_middleName"
                  label="Middle Name"
                  placeholder="-"
                  disabled={!isEmpty(RECEIVER_DETAIL)}
                />
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <InputField
                  control={control}
                  name="r_lastName"
                  label="Last Name"
                  placeholder="Last Name"
                  disabled={!isEmpty(RECEIVER_DETAIL)}
                />
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <RadioField
                  control={control}
                  name="r_gender"
                  label="Gender"
                  options={GENDERLIST}
                  // required
                />
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <PhoneNumberField
                  control={control}
                  name="r_phoneNumber"
                  label="Phone Number"
                  placeholder="Phone Number"
                  // required
                />
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <DateField
                  control={control}
                  name="r_dateOfBirth"
                  label="Date of Birth"
                  placeholder="Date of Birth"
                  // required
                />
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <EmailField
                  control={control}
                  name="r_email"
                  label="Email"
                  placeholder="Email"
                  // required
                />
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <AutocompleteField
                  control={control}
                  name="r_nationality"
                  label="Nationality"
                  placeholder="Select Nationality"
                  options={COUNTRYLIST}
                  // required
                />
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <AutocompleteField
                  control={control}
                  name="r_occupation"
                  label="Occupation"
                  placeholder="Select Occupation"
                  options={OPTION_OCCUPATION}
                  // required
                />
              </Grid>
              {watch('r_occupation') === '99' && (
                <Grid item xs={2} sm={4} md={4}>
                  <InputField
                    control={control}
                    name="r_occupation_other"
                    label="Other Occupation"
                    placeholder="Select Other Occupation"
                    // required
                  />
                </Grid>
              )}
              <Grid item xs={2} sm={4} md={4}>
                <AutocompleteField
                  control={control}
                  name="r_purposeofremittance"
                  label="Purpose of Remittance"
                  placeholder="Select Purpose of Remittance"
                  options={OPTION_PURPOSE_OF_REMITTANCE}
                  required={ValidateModel(RECEIV_FIELD_RULES, 'reason_for_remittance')}
                />
              </Grid>
              {watch('r_purposeofremittance') === '99' && (
                <Grid item xs={2} sm={4} md={4}>
                  <InputField
                    control={control}
                    name="r_purposeofremittance_other"
                    label="Other Purpose of Remittance"
                    placeholder="Select Other Purpose of Remittance"
                    // required
                  />
                </Grid>
              )}
              <Grid item xs={2} sm={4} md={4}>
                <AutocompleteField
                  control={control}
                  name="r_relationship"
                  label="Relationship"
                  placeholder="Select Relationship"
                  options={OPTION_RELATIONSHIP}
                  required={ValidateModel(RECEIV_FIELD_RULES, 'beneficiary_relation')}
                />
              </Grid>
              {watch('r_relationship') === '99' && (
                <Grid item xs={2} sm={4} md={4}>
                  <InputField
                    control={control}
                    name="r_relationship_other"
                    label="Other Relationship"
                    placeholder="Select Other Relationship"
                    // required
                  />
                </Grid>
              )}
            </Grid>
          </CardExpand>
        </Grid>
        <Grid item sm={6}>
          <CardExpand title="receiver address details" defaultOpen>
            <Grid container spacing={{ xs: 1, sm: 2 }} columns={{ xs: 1, sm: 8, md: 8 }}>
              <Grid item xs={2} sm={4} md={4}>
                <AutocompleteField
                  control={control}
                  name="r_country"
                  label="Country"
                  placeholder="Select country"
                  options={BENEFICOUNTRY}
                />
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <NumberField
                  control={control}
                  name="r_postalCode"
                  label="Postal code"
                  placeholder="Postal Code"
                  // required
                />
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <InputField
                  control={control}
                  name="r_state"
                  label="State"
                  placeholder="State"
                />
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <InputField
                  control={control}
                  name="r_city"
                  label="City"
                  placeholder="City"
                />
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <InputField
                  control={control}
                  name="r_district"
                  label="District"
                  placeholder="-"
                />
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <InputField
                  control={control}
                  name="r_street"
                  label="Street"
                  placeholder="-"
                />
              </Grid>
            </Grid>
          </CardExpand>
        </Grid>
        <Grid item sm={6}>
          <CardExpand title="receiver payment information" defaultOpen>
            <Grid container spacing={{ xs: 1, sm: 2 }} columns={{ xs: 1, sm: 8, md: 8 }}>
              <Grid item xs={2} sm={4} md={4}>
                <AutocompleteField
                  control={control}
                  name="r_country"
                  label="Receiver Country"
                  placeholder="Select country"
                  options={BENEFICOUNTRY}
                  required={ValidateModel(RECEIV_FIELD_RULES, 'beneficiary_country')}
                  disabled
                  // readOnly
                />
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <AutocompleteField
                  control={control}
                  name="r_modeofremittance"
                  label="Mode of Remittance"
                  placeholder="Bank Transfer"
                  options={OPTION_MODEOFREMITTANCE}
                  InputProp={RECEIVER_DELIVERY?.currency || '-'}
                  required={ValidateModel(RECEIV_FIELD_RULES, 'cmbPaymentType')}
                />
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <AutocompleteField
                  control={control}
                  name="remittance_bank"
                  label="Bank"
                  placeholder="Select Bank"
                  options={OPTION_BANK}
                  // required
                />
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <AutocompleteField
                  control={control}
                  name="r_account_type"
                  label="Account Type"
                  placeholder="Select Account Type"
                  options={OPTION_ACCOUNT_TYPE}
                  // required
                />
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <InputField
                  control={control}
                  name="r_branchname"
                  label="Branch"
                  placeholder="Branch"
                  // required
                />
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <PatternNumberField
                  control={control}
                  name="r_account_number"
                  label="Account Number"
                  placeholder="Account Number"
                />
              </Grid>
            </Grid>
          </CardExpand>
        </Grid>
        <Grid item sm={6}>
          <Switch
            checked={isOpenMode}
            onChange={handleChange}
            label="SHOW ADDITIONAL INFORMATION"
          />
        </Grid>
        {isOpenMode && (
          <>
            <Grid item sm={8}>
              <CardExpand title="additional information" defaultOpen>
                <Grid
                  container
                  spacing={{ xs: 1, sm: 2 }}
                  columns={{ xs: 1, sm: 8, md: 8 }}
                >
                  <Grid item xs={2} sm={4} md={4}>
                    <InputField
                      control={control}
                      name="r_add_alternative_name"
                      label="Alternative First Name"
                      placeholder="Alternative First Name"
                    />
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <InputField
                      control={control}
                      name="r_add_alternate_midName"
                      label="Alternative Middle Name"
                      placeholder="Alternative Middle Name"
                    />
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <InputField
                      control={control}
                      name="r_add_alternate_lastName"
                      label="Alternative Last Name"
                      placeholder="Alternative Last Name"
                    />
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <InputField
                      control={control}
                      name="r_add_alternative_address"
                      label="Alternative Address"
                      placeholder="Alternative Address"
                    />
                  </Grid>
                </Grid>
              </CardExpand>
            </Grid>
            <Grid item sm={6}>
              <CardExpand title="additional document information" defaultOpen>
                <Grid
                  container
                  spacing={{ xs: 1, sm: 2 }}
                  columns={{ xs: 1, sm: 8, md: 8 }}
                >
                  <Grid item xs={2} sm={4} md={4}>
                    <AutocompleteField
                      control={control}
                      name="r_add_id_type"
                      label="ID Type"
                      placeholder="Select ID Type"
                      options={OPTION_ID_TYPE}
                      // required
                    />
                  </Grid>
                  {watch('r_add_id_type') === '99' && (
                    <Grid item xs={2} sm={4} md={4}>
                      <InputField
                        control={control}
                        name="r_add_id_type_other"
                        label="Other ID Type"
                        placeholder="Other ID Type"
                        // required
                      />
                    </Grid>
                  )}
                  <Grid item xs={2} sm={4} md={4}>
                    <PatternNumberField
                      control={control}
                      name="r_add_id_number"
                      label="ID Number"
                      placeholder="ID Number"
                      // required
                    />
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <DateField
                      control={control}
                      name="r_add_issue_date"
                      label="ID Issue Date"
                      placeholder="ID Issue Date"
                      // required
                    />
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <DateField
                      control={control}
                      name="r_add_expire_date"
                      label="ID Expire Date"
                      placeholder="ID Expire Date"
                      // required
                    />
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <AutocompleteField
                      control={control}
                      name="r_add_id_issue_country"
                      label="ID Issue Country"
                      placeholder="Select ID Issue Country"
                      options={COUNTRYLIST}
                      // required
                    />
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <InputField
                      control={control}
                      name="r_add_id_issue_state"
                      label="ID Issue State"
                      placeholder="ID Issue State"
                    />
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <AutocompleteField
                      control={control}
                      name="r_add_testquestion"
                      label="Test Question"
                      placeholder="Test Question"
                      options={OPTION_TEST_QUESTION}
                      // required
                    />
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <InputField
                      control={control}
                      name="r_add_testanswer"
                      label="Test Answer"
                      placeholder="Test Answer"
                    />
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <InputField
                      control={control}
                      name="r_add_message1"
                      label="Message 1"
                      placeholder="Message 1"
                    />
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <InputField
                      control={control}
                      name="r_add_message2"
                      label="Message 2"
                      placeholder="Message 2"
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <InputField
                      control={control}
                      multiline
                      rows={4}
                      name="r_add_remark"
                      label="Remarks"
                      placeholder="Remarks"
                    />
                  </Grid>
                </Grid>
              </CardExpand>
            </Grid>
            <Grid item sm={8}>
              <CardExpand title="additional bank information" defaultOpen>
                <Grid
                  container
                  spacing={{ xs: 1, sm: 2 }}
                  columns={{ xs: 1, sm: 8, md: 8 }}
                >
                  <Grid item xs={2} sm={4} md={4}>
                    <InputField
                      control={control}
                      name="r_add_abbbankname"
                      label="Abbreviated Bank Name"
                      placeholder="Abbreviated Bank Name"
                    />
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <InputField
                      control={control}
                      name="r_add_BICB"
                      label="Receiver Bank BICB/Bank Code"
                      placeholder="Receiver Bank BICB/Bank Code"
                    />
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <InputField
                      control={control}
                      name="r_add_IBAN"
                      label="Receiver Bank IBAN"
                      placeholder="Receiver Bank IBAN"
                    />
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <InputField
                      control={control}
                      name="r_add_SWIFTBIC"
                      label="Receiver SWIFTBIC/Branch Code"
                      placeholder="Receiver SWIFTBIC/Branch Code"
                    />
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <InputField
                      control={control}
                      name="r_add_NID"
                      label="Receiver Bank National ID"
                      placeholder="Receiver Bank National ID"
                    />
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <InputField
                      control={control}
                      name="r_add_SUFFIX"
                      label="Receiver Bank Account Suffix"
                      placeholder="Receiver Bank Account Suffix"
                    />
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <InputField
                      control={control}
                      name="r_add_subranch"
                      label="Sub Branch Name"
                      placeholder="Sub Branch Name"
                    />
                  </Grid>
                </Grid>
              </CardExpand>
            </Grid>
          </>
        )}
      </Stack>
    </div>
  )
}

export default ReceiverDetail
