import React, { useEffect, useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useWatch } from 'react-hook-form'
import { makeStyles } from 'tss-react/mui'
import { isNil } from 'lodash'
import { Box, Typography, Grid } from '@mui/material'
import FileUpload from '../../../../components/common/UploadFiles'
import AutocompleteField from '../../../../components/common/AutocompleteField'
import { apiMasterTransactionDocumentType } from '../../../../api/master'
import { fetchDoctype } from '../../../../redux/master/action'

const UploadTransactionDocumentTypeFiles = ({ control }) => {
  const { classes } = useStyles()
  const dispatch = useDispatch()

  const [doctypeList, setDoctypeList] = useState([])
  const fieldname = useWatch({ control, name: 'transtype_id' })

  const fetchDocumentTypeList = useCallback(async () => {
    const response = await apiMasterTransactionDocumentType()
    if (response?.code !== 0 || !isNil(response?.error)) {
      return
    }
    const options = response?.data?.map((e) => {
      return { label: e?.text, value: e?.value }
    })

    setDoctypeList(options)
    dispatch(fetchDoctype(options))
  }, [dispatch])

  useEffect(() => {
    fetchDocumentTypeList()
  }, [fetchDocumentTypeList])

  return (
    <div>
      <Grid container justifyContent="flex-start" className={classes['& .MuiGrid-root']}>
        <Grid item sm={8}>
          <AutocompleteField
            control={control}
            name="transtype_id"
            label="Transaction Document Type"
            placeholder="Select Transaction Document Type"
            options={doctypeList}
            disableClearable
          />
        </Grid>
      </Grid>
      <Box className={classes.fileUpload}>
        <Typography variant="Paragraph_md_Bold">Transaction Document</Typography>
        {fieldname && (
          <FileUpload
            limit={doctypeList.length}
            multiple
            name={`${fieldname}`}
            options={doctypeList}
          />
        )}
      </Box>
    </div>
  )
}

export default UploadTransactionDocumentTypeFiles

const useStyles = makeStyles()((theme) => ({
  '& .MuiGrid-root': {
    padding: '16px 20px',
    margin: 'initial',
    [theme.breakpoints.up('md')]: {
      padding: '0px 80px',
    },
  },

  fileUpload: {
    [theme.breakpoints.up('md')]: {
      width: 640,
      margin: 'auto',
    },

    span: {
      padding: '16px 20px 0px',
      margin: 'initial',
      [theme.breakpoints.up('md')]: {
        padding: '0px 80px',
      },
    },
  },
}))
