import React from 'react'
import { Grid, Typography } from '@mui/material'
import { ArrowForward } from '@mui/icons-material'
import Button from '../../../components/common/Button'
import { useNavigate } from 'react-router-dom'

export default function QuickFilter() {
  const navigate = useNavigate()

  return (
    <React.Fragment>
      <Grid item sm={12} key="Topic">
        <Typography sx={{ mb: '30px' }} component={'div'} variant="H5_Bold">
          Quick Filter
        </Typography>
      </Grid>
      <Grid item sm={12} key="AllTxn">
        <Button
          onClick={() => {
            navigate(`/history/transaction-history`, {
              replace: false,
            })
          }}
          endIcon={<ArrowForward />}
          text="All Transaction"
          variant="outlined"
          size="large"
          sx={{ width: '100%', justifyContent: 'space-between' }}
        />
      </Grid>
      <Grid item sm={12} key="HoldTxn">
        <Button
          onClick={() => {
            navigate(`/history/transaction-history`, {
              replace: false,
            })
          }}
          endIcon={<ArrowForward />}
          text="Hold Transaction"
          variant="outlined"
          size="large"
          sx={{ width: '100%', justifyContent: 'space-between' }}
        />
      </Grid>
      <Grid item sm={12} key="PendingTxn">
        <Button
          onClick={() => {
            navigate(`/history/transaction-history`, {
              replace: false,
            })
          }}
          endIcon={<ArrowForward />}
          text="Pending Transaction"
          variant="outlined"
          size="large"
          sx={{ width: '100%', justifyContent: 'space-between' }}
        />
      </Grid>
      <Grid item sm={12} key="CancelTxn">
        <Button
          onClick={() => {
            navigate(`/history/transaction-history`, {
              replace: false,
            })
          }}
          endIcon={<ArrowForward />}
          text="Paid Transaction"
          variant="outlined"
          size="large"
          sx={{ width: '100%', justifyContent: 'space-between' }}
        />
      </Grid>
      <Grid item sm={12} key="CancelTxn">
        <Button
          onClick={() => {
            navigate(`/history/transaction-history`, {
              replace: false,
            })
          }}
          endIcon={<ArrowForward />}
          text="Cancel Transaction"
          variant="outlined"
          size="large"
          sx={{ width: '100%', justifyContent: 'space-between' }}
        />
      </Grid>
      <Grid item sm={12} key="BatchTxn">
        <Button
          onClick={() => {
            navigate(`/history/batch-file-transaction`, {
              replace: false,
            })
          }}
          endIcon={<ArrowForward />}
          text="Batch file Transaction"
          variant="outlined"
          size="large"
          sx={{ width: '100%', justifyContent: 'space-between' }}
        />
      </Grid>
      <Grid item sm={12} key="WeekTxn">
        <Button
          onClick={() => {
            navigate(`/history/transaction-history`, {
              replace: false,
            })
          }}
          endIcon={<ArrowForward />}
          text="This Week Transaction"
          variant="outlined"
          size="large"
          sx={{ width: '100%', justifyContent: 'space-between' }}
        />
      </Grid>
      <Grid item sm={12} key="MonthTxn">
        <Button
          onClick={() => {
            navigate(`/history/suspicious-report`, {
              replace: false,
            })
          }}
          endIcon={<ArrowForward />}
          text="This Month Transaction"
          variant="outlined"
          size="large"
          sx={{ width: '100%', justifyContent: 'space-between' }}
        />
      </Grid>
    </React.Fragment>
  )
}
