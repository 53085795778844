import { restApi } from '../utils/rest'
import { DDLabel, ConvertDateYYYYMMDD } from '../utils/app'

export const apiSearchCustomerList = async (body = {}, callback = {}, signal) => {
  try {
    return await restApi(
      {
        url: `${process.env.REACT_APP_SERVICE_API}/api/v1/customer/search`,
        method: 'POST',
        body,
        signal,
      },
      callback,
    )
  } catch (error) {
    return error
  }
}

export const apiGetReceiverListBysno = async (id, callback) => {
  try {
    return await restApi(
      {
        url: `${process.env.REACT_APP_SERVICE_API}/api/v1/customer/receiver/list?sno=${id}`,
        method: 'GET',
      },
      callback,
    )
  } catch (error) {
    return error
  }
}

export const apiGetDeliveryOption = async (
  countrycode,
  deleiveryoption,
  deliverymodename,
  // params = {},
  callback = {},
) => {
  try {
    return await restApi(
      {
        url: `${process.env.REACT_APP_SERVICE_API}/api/v1/customer/receiver-currency/delivery-option?Country=${countrycode}&PaymentMode=${deleiveryoption}&DeliveryModeName=${deliverymodename}`,
        method: 'GET',
      },
      callback,
    )
  } catch (error) {
    return error
  }
}

export const apiGetReceiverDetailBysno = async (id, callback) => {
  try {
    return await restApi(
      {
        url: `${process.env.REACT_APP_SERVICE_API}/api/v1/customer/receiver-info?ReceiverId=${id}`,
        method: 'GET',
      },
      callback,
    )
  } catch (error) {
    return error
  }
}

export const apiMasterTransactionType = async (country, callback) => {
  try {
    return await restApi(
      {
        url: `${process.env.REACT_APP_SERVICE_API}/api/v1/customer/transaction-type/${country}`,
        method: 'GET',
      },
      callback,
    )
  } catch (error) {
    return error
  }
}

export const apiGetFieldRules = async (
  delivery_option_id,
  customer_beneficiary,
  customertype,
  receivertype,
  callback,
) => {
  try {
    return await restApi(
      {
        url: `${process.env.REACT_APP_SERVICE_API}/api/v1/customer/field-rule?delivery_option_id=${delivery_option_id}&customer_beneficiary_fg=${customer_beneficiary}&customertype=${customertype}&receivertype=${receivertype}`,
        method: 'GET',
      },
      callback,
    )
  } catch (error) {
    return error
  }
}

export const apiMasterPurposeOfRemittanceList = async (callback = {}) => {
  try {
    return await restApi(
      {
        url: `${process.env.REACT_APP_SERVICE_API}/api/v1/customer/purpose-of-remittance`,
        method: 'GET',
      },
      callback,
    )
  } catch (error) {
    return error
  }
}

export const apiMasterOccupationList = async (callback = {}) => {
  try {
    return await restApi(
      {
        url: `${process.env.REACT_APP_SERVICE_API}/api/v1/customer/occupation`,
        method: 'GET',
      },
      callback,
    )
  } catch (error) {
    return error
  }
}

export const apiMasterRelationshipList = async (callback = {}) => {
  try {
    return await restApi(
      {
        url: `${process.env.REACT_APP_SERVICE_API}/api/v1/customer/relationship`,
        method: 'GET',
      },
      callback,
    )
  } catch (error) {
    return error
  }
}

export const apiMasterBankList = async (params = {}, callback = {}) => {
  try {
    return await restApi(
      {
        url: `${process.env.REACT_APP_SERVICE_API}/api/v1/customer/banklist?Mode=${params.mode}&ReceiveAgentID=${params.receiveAgentID}&PayoutCountry=${params.payoutCountry}`,
        method: 'GET',
      },
      callback,
    )
  } catch (error) {
    return error
  }
}

export const apiGetTestQuestionList = async (callback = {}) => {
  try {
    return await restApi(
      {
        url: `${process.env.REACT_APP_SERVICE_API}/api/v1/customer/test-question`,
        method: 'GET',
      },
      callback,
    )
  } catch (error) {
    return error
  }
}

export const apiGetCustomerDetailBysno = async (id, callback) => {
  try {
    return await restApi(
      {
        url: `${process.env.REACT_APP_SERVICE_API}/api/v1/customer/customer-bysno?customersno=${id}`,
        method: 'GET',
      },
      callback,
    )
  } catch (error) {
    return error
  }
}

export const apiGetVisaTypeList = async (callback = {}) => {
  try {
    return await restApi(
      {
        url: `${process.env.REACT_APP_SERVICE_API}/api/v1/customer/sender-visa-type`,
        method: 'GET',
      },
      callback,
    )
  } catch (error) {
    return error
  }
}

export const apiGetSourceOfFund = async (callback = {}) => {
  try {
    return await restApi(
      {
        url: `${process.env.REACT_APP_SERVICE_API}/api/v1/customer/sourceoffund`,
        method: 'GET',
      },
      callback,
    )
  } catch (error) {
    return error
  }
}

export const apiGetKYCMode = async (callback = {}) => {
  try {
    return await restApi(
      {
        url: `${process.env.REACT_APP_SERVICE_API}/api/v1/customer/kyc-mode`,
        method: 'GET',
      },
      callback,
    )
  } catch (error) {
    return error
  }
}

export const apiGetCustomerServiceCharge = async (payload, callback) => {
  const {
    Country,
    CalcMode,
    Currency,
    ReceiveAgentID,
    Modeofremittance,
    RemittanceAmt,
    EditServiceCharge,
    BankList,
  } = payload
  try {
    return await restApi(
      {
        url: `${process.env.REACT_APP_SERVICE_API}/api/v1/customer/service-charge?Country=${Country}&CalcMode=${CalcMode}&Currency=${Currency}&ReceiveAgentID=${ReceiveAgentID}&Modeofremittance=${Modeofremittance}&RemittanceAmt=${RemittanceAmt}&BankList=${BankList}&EditServiceCharge=${EditServiceCharge}`,
        method: 'GET',
      },
      callback,
    )
  } catch (error) {
    return error
  }
}

export const apiCheckEditServiceFee = async (callback = {}) => {
  try {
    return await restApi(
      {
        url: `${process.env.REACT_APP_SERVICE_API}/api/v1/customer/edit-service-fee-right`,
        method: 'GET',
      },
      callback,
    )
  } catch (error) {
    return error
  }
}

export const apiGetConfirmSendPreviewTransaction = async (
  data = {},
  OPTION_BANK = {},
  OPTION_ID_TYPE = {},
  RECEIVER_DELIVERY = {},
  DISPLAY_SERVICE_CHARGE = {},
  OPTION_TRANSACTION_TYPE = {},
  callback = {},
) => {
  const SESSION_ID = sessionStorage.getItem('PROCESS_SESSION_ID') || ''
  const body = {
    customerDetail: {
      customerid: null,
      sno: data?.customersno,
      customertype: null,
      corporateName: null,
      // senderfullname: null,
      sendingmethod: null,
      destination: null,
      allowweb: null,
      address: data?.s_street || null,
      email: data?.s_email || null,
      altEmail: null,
      mobileNum: data?.s_phoneNumber ? data?.s_phoneNumber.replace(/\s+/gi, '') : null,
      dob: ConvertDateYYYYMMDD(data?.s_dateOfBirth) || null,
      idType: data?.s_id_type || null,
      idTypeName: DDLabel(OPTION_ID_TYPE, data?.s_id_type) || null,
      idNumber: data?.s_id_number || null,
      expDate: ConvertDateYYYYMMDD(data?.s_expire_date) || null,
      nationality: data?.s_nationality || null,
      firstName: data?.s_firstName || null,
      middleName: data?.s_middleName || null,
      lastName: data?.s_lastName || null,
      lastName2: null,
      zipCode: data?.s_postalCode || null,
      city: data?.s_city || null,
      prefecture: data?.s_state || null,
      town: null,
      ssnCardId: null,
      sourceOfFund: data?.s_source_of_fund || null,
      sourceOfIncomeOther: data?.s_occupation_other || null,
      occupation: data?.s_occupation || null,
      occupationOther: data?.s_occupation_other || null,
      gender: data?.s_gender || null,
      idIssueCountry: data?.s_id_issue_country || null,
      issueDate: ConvertDateYYYYMMDD(data?.s_issue_date) || null,
      empType: null,
      country: data?.s_country || null,
      typeVisa: data?.s_visa_type || null,
      agencyName: null,
      agencyContactPerson: null,
      agentcyContactNumber: null,
      nameofEmployer: null,
      employmentType: null,
      kycMode: data?.kycmode || null,
      representiveName: null,
      representiveIDType: null,
      representiveIDNo: null,
      representiveNationality: null,
      representiveDOB: null,
      representiveGender: null,
      representiveRelation: null,
      representiveReason: null,
      representiveMobile: null,
      representiveAddress: null,
      riskProfile: null,
      walletAvailableBalance: null,
      walletCurrency: null,
      encryptMobile: null,
      encryptEmail: null,
      encryptIdNo: null,
      typeCustomer: data?.sender_type || null,
      idTypeOther: data?.s_id_type_other || null,
      visaTypeOther: data?.s_visa_type_other || null,
      // senderFullAddress: null,
    },
    receiver: {
      receiversno: data?.r_sno,
      receiverid: null,
      receivercardid: null,
      senderFullname: null,
      receiverFullName: null,
      sendesno: null,
      country: data?.r_country || null,
      modeofremittance: data?.r_modeofremittance || null,
      currency: RECEIVER_DELIVERY?.currency || null,
      receivertype: data?.receiver_type || null,
      location: data?.remittance_bank || null, //data?.r_bank || null,
      firstName: data?.r_firstName || null,
      middleName: data?.r_middleName || null,
      lastName: data?.r_lastName || null,
      lastName2: null,
      aLlternativeName: data?.r_add_alternative_name || null,
      alternateMidName: data?.r_add_alternate_midName || null,
      alternateLastName: data?.r_add_alternate_lastName || null,
      gender: data?.r_gender || null,
      nickname: null,
      nationality: data?.r_nationality || null,
      dateOfBirth: ConvertDateYYYYMMDD(data?.r_dateOfBirth) || null,
      eMail: data?.r_email || null,
      mobileNo: data?.r_phoneNumber ? data?.r_phoneNumber.replace(/\s+/gi, '') : null,
      // phoneNo: null,
      zipCode: data?.r_postalCode || null,
      prefecture: data?.r_state || null,
      city: data?.r_city || null,
      district: data?.r_district || null,
      address: data?.r_street || null,
      // receiverFullAddress: null,
      receiverCountry: data?.r_country, //data?.r_payment_country,
      purposeofremittance: data?.r_purposeofremittance || null,
      purposeofremittanceCode: null,
      additionalQuestion: null,
      additionalAnswer: null,
      listOfAnswer: null,
      purposeofremittanceCodeNew: null,
      purposeofremittanceother: data?.r_purposeofremittance_other || null,
      productDetail: null,
      originCountry: null,
      shipmentCountry: null,
      placeOfShipment: null,
      relationship: data?.r_relationship || null,
      relationshipother: data?.r_relationship_other || null,
      idType: data?.r_add_id_type || null,
      idTypeOther: data?.r_add_id_type_other || null,
      idNumber: data?.r_add_id_number || null,
      primaryIdPlaceOfIssue: data?.r_add_id_issue_country || null,
      idIssueDate: ConvertDateYYYYMMDD(data?.r_add_issue_date) || null,
      idExpirydate: ConvertDateYYYYMMDD(data?.r_add_expire_date) || null,
      idissuestate: data?.r_add_id_issue_state || null,
      testquestion: data?.r_add_testquestion || null,
      testanswer: data?.r_add_testanswer || null,
      message1: data?.r_add_message1 || null,
      message2: data?.r_add_message2 || null,
      remark: data?.r_add_remark || null,
      bankList: data?.remittance_bank || '',
      bankListNew: null,
      bankName: DDLabel(OPTION_BANK, data?.remittance_bank) || '',
      paymentType: null,
      branchname: data?.r_branchname || null,
      receiverAc: data?.r_account_number
        ? data?.r_account_number.replace(/\s+/gi, '')
        : null,
      locationBranch: data?.r_branchname || null,
      selectedBranchCode: null,
      receiverAccounttype: data?.r_account_type || null,
      abbbankname: data?.r_add_abbbankname || null,
      receiverBIc: data?.r_add_BICB || null,
      receiveriBan: data?.r_add_IBAN || null,
      receiverswift: data?.r_add_SWIFTBIC || null,
      receiverbanknationalID: data?.r_add_NID || null,
      receiversuffix: data?.r_add_SUFFIX || null,
      subranch: data?.r_add_subranch || null,
      commercial_bank_branch: null,
      commercial_bank_id: null,
      receiveAgentID: RECEIVER_DELIVERY?.receiveragentid || null,
      calcMode: data?.calcMode || null,
      remittanceAmt: data?.remittance_amount || null,
      collectedAmount: DISPLAY_SERVICE_CHARGE?.collect_Amount || null,
      transferAmount: DISPLAY_SERVICE_CHARGE?.payinAmount || null,
      additionalCharge: DISPLAY_SERVICE_CHARGE?.additionalCharge || null,
      serviceCharge: DISPLAY_SERVICE_CHARGE?.serviceFee || null,
      editServiceCharge: null, // fixx
      receiveFee: DISPLAY_SERVICE_CHARGE?.receiveFee || null,
      customerRate: DISPLAY_SERVICE_CHARGE?.customer_Rate || null,
      payoutAmount: DISPLAY_SERVICE_CHARGE?.payoutAmount || null,
      receiveTax: DISPLAY_SERVICE_CHARGE?.receiveTax || null,
      receiverLst: data?.r_sno,
      fieldValue: null,
      partnerName: DDLabel(OPTION_TRANSACTION_TYPE, data?.transaction_type),
      deliveryOptionId: RECEIVER_DELIVERY?.deliveryoptionid || null,
      deliveryOptionName: null,
      country2: null,
      zipCode2: null,
      address2: null,
      city2: null,
      prefecture2: null,
      collectedMethod: null,
      paymentFrom: null,
      discountFee: null,
      ref1: null,
      ref2: null,
      paymentDate: null,
      fileSTR: data?.fileSTR,
      notes: data?.notes || null,
      transAmt: null,
      transDate: null,
      receipT_CPF: null,
      accountValidationFG: null,
      accountValidationStatus: null,
      accountValidateMessage: null,
      corporateName: null,
      encryptMobile: null,
      encryptEmail: null,
      encryptIdNo: null,
      encryptAccountNumber: null,
      occupation: data?.r_occupation || null,
      occupationOther: data?.r_occupation_other || null,
      alternativeAddress: data?.r_add_alternative_address || null,
      beneficiary_permanent_address_fg: null,
      customerSNO: null,
      customerid: null,
    },
    promotionCode: data?.remittance_promoCode || '',
    pinno: data?.remittance_pinNo || '',
    customerNameMatched: false,
    receiverNameMatched: false,
    // deposit: [],
    ofacSearch: [],
    recentTransaction: [],
    processID: SESSION_ID,
  }

  try {
    return await restApi(
      {
        url: `${process.env.REACT_APP_SERVICE_API}/api/v1/Transaction/transaction/confirmsend`,
        method: 'POST',
        body,
      },
      callback,
    )
  } catch (error) {
    return error
  }
}

export const apiConfirmSendTransaction = async (data = {}, callback = {}) => {
  const body = {
    customerDetail: data?.customerDetail,
    receiver: data?.receiver,
    processID: data?.processID,
    promotionCode: data?.remittance_promoCode || '',
    pinno: data?.pinno || '',
    // customerNameMatched: false,
    // receiverNameMatched: false,
    // deposit: [],
    // ofacSearch: [],
    // recentTransaction: [],
  }

  try {
    return await restApi(
      {
        url: `${process.env.REACT_APP_SERVICE_API}/api/v1/Transaction/transaction/send`,
        method: 'POST',
        body,
      },
      callback,
    )
  } catch (error) {
    return error
  }
}

export const apiUploadImage = async (formData) => {
  try {
    return await restApi({
      url: `${process.env.REACT_APP_SERVICE_API}/api/v1/customer/upload/files`,
      method: 'POST',
      form: formData,
      contentType: 'multipart/form-data',
    })
  } catch (error) {
    return error
  }
}

export const apiGetBeneficiaryCountryList = async (callback = {}) => {
  try {
    return await restApi(
      {
        url: `${process.env.REACT_APP_SERVICE_API}/api/v1/customer/beneficiary-country`,
        method: 'GET',
      },
      callback,
    )
  } catch (error) {
    return error
  }
}

export const apiCheckCustomerDoc = async (id, callback = {}) => {
  try {
    return await restApi(
      {
        url: `${process.env.REACT_APP_SERVICE_API}/api/v1/transaction/check-customerdoc-bysno?customerSNO=${id}`,
        method: 'GET',
      },
      callback,
    )
  } catch (error) {
    return error
  }
}

export const apiSetsessionDeposit = async (body = {}, callback = {}) => {
  try {
    return await restApi(
      {
        url: `${process.env.REACT_APP_SERVICE_API}/api/v1/Transaction/set-session-deposit`,
        method: 'POST',
        body,
      },
      callback,
    )
  } catch (error) {
    return error
  }
}
