export function remToPx(value) {
  return Math.round(parseFloat(value) * 16)
}

export function pxToRem(value) {
  return `${value / 16}rem`
}

export function responsiveFontSizes({ sm, md, lg }) {
  return {
    '@media (min-width:300px)': {
      fontSize: pxToRem(sm),
    },
    '@media (min-width:900px)': {
      fontSize: pxToRem(md),
    },
    '@media (min-width:1200px)': {
      fontSize: pxToRem(lg),
    },
  }
}

export function responsiveLogoText({ sm, md, lg }) {
  return {
    '@media (min-width:300px)': {
      fontSize: pxToRem(sm),
      textAlign: 'center',
    },
    '@media (min-width:900px)': {
      fontSize: pxToRem(md),
      textAlign: 'inherit',
    },
    '@media (min-width:1200px)': {
      fontSize: pxToRem(lg),
      textAlign: 'inherit',
    },
  }
}

const FONT_PRIMARY = 'Overpass'

const typography = {
  fontFamily: FONT_PRIMARY,
  fontWeightRegular: 400,
  fontWeightMedium: 600,
  fontWeightBold: 700,

  H1_Bold: {
    fontWeight: 700,
    lineHeight: 64 / 48,
    fontStyle: 'normal',
    letterSpacing: '0.072px',
    textTransform: 'uppercase',
    ...responsiveFontSizes({ sm: 40, md: 44, lg: 48 }),
  },

  H1_Light: {
    fontWeight: 300,
    lineHeight: '100%',
    fontStyle: 'normal',
    letterSpacing: '0.072px',
    textTransform: 'uppercase',
    ...responsiveFontSizes({ sm: 40, md: 44, lg: 48 }),
  },

  H2_Bold: {
    fontWeight: 700,
    lineHeight: '100%',
    fontStyle: 'normal',
    letterSpacing: '0.072px',
    textTransform: 'uppercase',
    ...responsiveFontSizes({ sm: 28, md: 32, lg: 36 }),
  },

  H2_Light: {
    fontWeight: 300,
    lineHeight: '100%',
    fontStyle: 'normal',
    letterSpacing: '0.045px',
    textTransform: 'uppercase',
    ...responsiveFontSizes({ sm: 28, md: 32, lg: 36 }),
  },

  H3_Bold: {
    fontWeight: 700,
    lineHeight: '100%',
    fontStyle: 'normal',
    letterSpacing: '0.045px',
    textTransform: 'uppercase',
    ...responsiveFontSizes({ sm: 22, md: 26, lg: 30 }),
  },

  H3_Light: {
    fontWeight: 300,
    lineHeight: '100%',
    fontStyle: 'normal',
    letterSpacing: '0.045px',
    textTransform: 'uppercase',
    ...responsiveFontSizes({ sm: 22, md: 26, lg: 30 }),
  },

  H4_Bold: {
    fontWeight: 700,
    lineHeight: '100%',
    fontStyle: 'normal',
    letterSpacing: '0.036px',
    textTransform: 'uppercase',
    ...responsiveFontSizes({ sm: 18, md: 20, lg: 24 }),
  },

  H4_Light: {
    fontWeight: 300,
    lineHeight: '100%',
    fontStyle: 'normal',
    letterSpacing: '0.036px',
    textTransform: 'uppercase',
    ...responsiveFontSizes({ sm: 18, md: 20, lg: 24 }),
  },

  H5_Bold: {
    fontWeight: 700,
    lineHeight: '100%',
    fontStyle: 'normal',
    letterSpacing: '0.03px',
    textTransform: 'uppercase',
    ...responsiveFontSizes({ sm: 16, md: 18, lg: 20 }),
  },

  H5_Light: {
    fontWeight: 300,
    lineHeight: '100%',
    fontStyle: 'normal',
    letterSpacing: '0.03px',
    textTransform: 'uppercase',
    ...responsiveFontSizes({ sm: 16, md: 18, lg: 20 }),
  },

  Paragraph_lg_Bold: {
    fontWeight: 700,
    lineHeight: '100%',
    fontStyle: 'normal',
    letterSpacing: '0.027px',
    ...responsiveFontSizes({ sm: 16, md: 18, lg: 18 }),
  },

  Paragraph_lg_Light: {
    fontWeight: 300,
    lineHeight: '100%',
    fontStyle: 'normal',
    letterSpacing: '0.027px',
    ...responsiveFontSizes({ sm: 16, md: 18, lg: 18 }),
  },

  Paragraph_md_Bold: {
    fontWeight: 700,
    lineHeight: '100%',
    fontStyle: 'normal',
    letterSpacing: '0.024px',
    ...responsiveFontSizes({ sm: 14, md: 16, lg: 16 }),
  },

  Paragraph_md_Light: {
    fontWeight: 300,
    lineHeight: '100%',
    fontStyle: 'normal',
    letterSpacing: '0.024px',
    ...responsiveFontSizes({ sm: 14, md: 16, lg: 16 }),
  },

  Paragraph_sm_Bold: {
    fontWeight: 700,
    lineHeight: '100%',
    fontStyle: 'normal',
    letterSpacing: '0.021px',
    ...responsiveFontSizes({ sm: 12, md: 14, lg: 14 }),
  },

  Paragraph_sm_Light: {
    fontWeight: 300,
    lineHeight: '100%',
    fontStyle: 'normal',
    letterSpacing: '0.021px',
    ...responsiveFontSizes({ sm: 12, md: 14, lg: 14 }),
  },

  Paragraph_xs_Bold: {
    fontWeight: 700,
    lineHeight: '100%',
    fontStyle: 'normal',
    letterSpacing: '0.018px',
    ...responsiveFontSizes({ sm: 12, md: 12, lg: 12 }),
  },

  Paragraph_xs_Light: {
    fontWeight: 300,
    lineHeight: '100%',
    fontStyle: 'normal',
    letterSpacing: '0.018px',
    ...responsiveFontSizes({ sm: 12, md: 12, lg: 12 }),
  },

  Forgot_Password: {
    fontWeight: 600,
    lineHeight: '100%',
    fontStyle: 'normal',
    letterSpacing: '0.021px',
    ...responsiveFontSizes({ sm: 12, md: 14, lg: 14 }),
  },

  Langs: {
    fontWeight: 500,
    lineHeight: '100%',
    fontStyle: 'normal',
    letterSpacing: '0.021px',
    ...responsiveFontSizes({ sm: 12, md: 14, lg: 14 }),
  },

  AlertMsg: {
    fontWeight: 700,
    lineHeight: '100%',
    fontStyle: 'normal',
    letterSpacing: '0.021px',
    textTransform: 'uppercase',
    ...responsiveFontSizes({ sm: 16, md: 18, lg: 20 }),
  },
}

export default typography
