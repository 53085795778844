import React from 'react'
import { forwardRef } from 'react'
import { Stack, TextField, InputLabel, Typography } from '@mui/material'

const Input = forwardRef(
  ({ id, name, label, value, error = null, onChange, ...other }, ref) => (
    <Stack>
      <InputLabel htmlFor={name}>
        <Typography component="div">{label}</Typography>
      </InputLabel>
      <TextField
        ref={ref}
        id={id}
        name={name}
        value={value}
        onChange={onChange}
        fullWidth
        inputProps={{
          autoComplete: 'off',
        }}
        {...other}
        error={error}
      />
    </Stack>
  ),
)

export default Input
