import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useForm, useWatch } from 'react-hook-form'
import { makeStyles } from 'tss-react/mui'
import { Container, Tabs, Tab, Box, Stack, Paper, Typography, Grid } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { useTheme } from '@mui/material/styles'
import { isNil } from 'lodash'
import Button from '../../components/common/Button'
import Backdrop from '../../components/useBackdrop'
import AlertBar from '../../components/common/AlertBar'
import ConfirmDialog from '../../components/useDialog'
import AlertBox from '../../components/alertBox/alertBox'
import ReceiverDetailsTab from '../../containers/customers/receiver/receiver-details-tab'
import ReceiverAdditionalInformationTab from '../../containers/customers/receiver/receiver-additional-information-tab'
import { apiGetCustomerDetails, apiCreateReceiver } from '../../api/customer'
import moment from 'moment'

const useStyles = makeStyles()((theme) => ({
  stepBtn: {
    marginTop: '40px',
    marginBottom: '0px',
    display: 'flex',
    justifyContent: 'end',
  },
}))

export default function ReceiverNewPage() {
  const navigate = useNavigate()

  const location = useLocation()

  const theme = useTheme()

  const { classes } = useStyles()

  const { control, handleSubmit } = useForm()

  const [tabValue, setTabValue] = useState(0)
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    type: '',
    title: '',
    subTitle: '',
    container: '',
  })
  const [notify, setNotify] = useState({
    open: false,
    message: '',
    type: '',
  })
  const [openBackdrop, setOpenBackdrop] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const [customerInfo, setCustomerInfo] = useState(null)

  const TabPanel = (props) => {
    const { children, value, index, ...other } = props
    return (
      <Stack
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Container
            style={{ maxWidth: '-webkit-fill-available' }}
            sx={{
              '@media (min-width:600px)': {
                padding: '0px 0px',
              },
            }}
          >
            <Box
              sx={{
                p: 1,
                '& .MuiContainer-root': {
                  maxWidth: '100%',
                },
              }}
            >
              <Typography component={'div'}>{children}</Typography>
            </Box>
          </Container>
        )}
      </Stack>
    )
  }

  const onSubmit = async (data) => {
    addOrEdit(data)
  }

  const addOrEdit = async (data) => {
    setOpenBackdrop(true)
    const payload = {
      sendesno: customerInfo?.customerdetailModel?.customersno,
      // Receiver Type
      receivertype: data.receiverType ? data.receiverType : '',
      // Receiver Details
      firstName: data.receiverFirstName ? data.receiverFirstName : '',
      middleName: data.receiverMiddleName ? data.receiverMiddleName : '',
      lastName: data.receiverLastName ? data.receiverLastName : '',
      gender: data.receiverGender ? (data.receiverGender === 'Male' ? 'M' : 'F') : '',
      mobileNo: data.receiverMobileNo ? data.receiverMobileNo : '',
      eMail: data.receiverEmail ? data.receiverEmail : '',
      dateOfBirth: data.receiverDateOfBirth
        ? moment(data.receiverDateOfBirth).format('YYYY-MM-DD')
        : '',
      nationality: data.receiverNationality ? data.receiverNationality : '',
      occupation: data.receiverOccupation ? data.receiverOccupation : '',
      occupationOther: data.receiverOccupationOther ? data.receiverOccupationOther : '',
      purposeofremittance: data.receiverPurposeofremittance
        ? data.receiverPurposeofremittance
        : '',
      purposeofremittanceother: data.receiverPurposeofremittanceother
        ? data.receiverPurposeofremittanceother
        : '',
      relationship: data.receiverRelationship ? data.receiverRelationship : '',
      relationshipother: data.receiverRelationshipother
        ? data.receiverRelationshipother
        : '',

      // Receiver Address
      country: data.receiverAddressCountry ? data.receiverAddressCountry : '',
      zipCode: data.receiverAddressZipCode ? data.receiverAddressZipCode : '',
      prefecture: data.receiverAddressState ? data.receiverAddressState : '',
      city: data.receiverAddressCity ? data.receiverAddressCity : '',
      district: data.receiverAddressDistrict ? data.receiverAddressDistrict : '',
      // xxx: data.receiverAddressAddressStreet ? data.receiverAddressAddressStreet : '',

      // Receiver Payment Information
      countryofshipment: data.receiverPaymentCountryofshipment
        ? data.receiverPaymentCountryofshipment
        : '',
      modeofremittance: data.receiverPaymentModeofremittance
        ? data.receiverPaymentModeofremittance
        : '',
      bankList: data.receiverPaymentBankList ? data.receiverPaymentBankList : '',
      receiverAccounttype: data.receiverPaymentReceiverAccounttype
        ? data.receiverPaymentReceiverAccounttype
        : '',
      branchname: data.receiverPaymentBranchname ? data.receiverPaymentBranchname : '',
      receiverAc: data.receiverPaymentReceiverAc ? data.receiverPaymentReceiverAc : '',

      // Receiver Alternative
      aLlternativeName: data.receiverAlternativeALlternativeName
        ? data.receiverAlternativeALlternativeName
        : '',
      alternateMidName: data.receiverAlternativeAlternateMidName
        ? data.receiverAlternativeAlternateMidName
        : '',
      alternateLastName: data.receiverAlternativeAlternateLastName
        ? data.receiverAlternativeAlternateLastName
        : '',
      // xxx: data.receiverAlternativeAlternativeEmail ? data.receiverAlternativeAlternativeEmail : '',
      // xxx: data.receiverAlternativeAlternativeAddress ? data.receiverAlternativeAlternativeAddress : '',

      // Receiver Additional
      idType: data.receiverAdditionalIdType ? data.receiverAdditionalIdType : '',
      // xxx: data.receiverAdditionalPrimaryIdTypeOther ? data.receiverAdditionalPrimaryIdTypeOther : '',
      idNumber: data.receiverAdditionalIdNumber ? data.receiverAdditionalIdNumber : '',
      primaryIdPlaceOfIssue: data.receiverAdditionalPrimaryIdPlaceOfIssue
        ? data.receiverAdditionalPrimaryIdPlaceOfIssue
        : '',
      idissuestate: data.receiverAdditionalIdissuestate
        ? data.receiverAdditionalIdissuestate
        : '',
      idIssueDate: data.receiverAdditionalIdIssueDate
        ? moment(data.receiverAdditionalIdIssueDate).format('YYYY-MM-DD')
        : '',
      idExpirydate: data.receiverAdditionalIdExpirydate
        ? moment(data.receiverAdditionalIdExpirydate).format('YYYY-MM-DD')
        : '',
      testquestion: data.receiverAdditionalTestquestion
        ? data.receiverAdditionalTestquestion
        : '',
      testanswer: data.receiverAdditionalTestanswer
        ? data.receiverAdditionalTestanswer
        : '',
      message1: data.receiverAdditionalMessage1 ? data.receiverAdditionalMessage1 : '',
      message2: data.receiverAdditionalMessage2 ? data.receiverAdditionalMessage2 : '',
      receiremarkverAc: data.receiverAdditionalRemarks
        ? data.receiverAdditionalRemarks
        : '',

      // Receiver Bank
      abbbankname: data.receiverBankAbbbankname ? data.receiverBankAbbbankname : '',
      receiverBIc: data.receiverBankReceiverBIc ? data.receiverBankReceiverBIc : '',
      receiveriBan: data.receiverBankReceiveriBan ? data.receiverBankReceiveriBan : '',
      receiverswift: data.receiverBankReceiverswift ? data.receiverBankReceiverswift : '',
      receiverbanknationalID: data.receiverBankReceiverbanknationalID
        ? data.receiverBankReceiverbanknationalID
        : '',
      receiversuffix: data.receiverBankAccountsuffix
        ? data.receiverBankAccountsuffix
        : '',
      subranch: data.receiverBankSubranch ? data.receiverBankSubranch : '',
    }
    const response = await apiCreateReceiver(payload)
    setOpenBackdrop(false)
    if (response?.code !== 0) {
      setErrorMessage(response?.message)
      return
    }
    setConfirmDialog({
      isOpen: true,
      title: '',
      type: 'customDialogWithContainer',
      subTitle: '',
      container: await containerSubmitResultData(response.data),
    })
  }

  const containerSubmitResultData = async (data) => {
    return (
      <Grid container spacing={{ xs: 1, sm: 2 }} columns={{ xs: 1, sm: 8, md: 8 }}>
        <Grid item xs={4} sm={8} md={8} sx={{ textAlign: 'center' }}>
          <img
            src={`/assets/icons/create_successfully.svg`}
            alt="successfully"
            style={{
              display: 'block',
              marginLeft: 'auto',
              marginRight: 'auto',
              width: '50%',
            }}
          />
        </Grid>
        <Grid item xs={4} sm={8} md={8} sx={{ textAlign: 'center' }}>
          <Typography
            variant="H5_Bold"
            sx={{
              fontFamily: 'Overpass',
              fontsize: '24px',
              fontWeight: 700,
              lineHeight: '24px',
              letterSpacing: '0.0015em',
              color: '#FFFFFF',
            }}
          >
            ADD RECEIVER COMPLETE
          </Typography>
        </Grid>
        <Grid item xs={4} sm={8} md={8} sx={{ textAlign: 'center' }}>
          <Button
            text="view receiver list"
            size="medium"
            color="success"
            onClick={() => {
              setConfirmDialog({
                ...confirmDialog,
                isOpen: false,
              })
              navigate(
                `/customer/details?routeEdit=${location.state.customerRouteEditURL}`,
                {
                  replace: false,
                },
              )
            }}
            sx={{ width: '60%' }}
          />
        </Grid>
      </Grid>
    )
  }

  const tabProps = (index) => {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    }
  }
  const onTabChange = (e, value) => {
    e.preventDefault()
    setTabValue(value)
  }

  const tabList = [
    {
      key: 'personaldetails',
      label: 'Personal Details',
      tab: (
        <ReceiverDetailsTab control={control} useWatch={useWatch} setNotify={setNotify} />
      ),
    },
    {
      key: 'additionalinformation',
      label: 'Additional Information',
      tab: (
        <ReceiverAdditionalInformationTab
          control={control}
          useWatch={useWatch}
          setNotify={setNotify}
        />
      ),
    },
  ]

  const fetchGetCustomerDetails = async () => {
    setOpenBackdrop(true)
    const response = await apiGetCustomerDetails(location.state.customerRouteEditURL)
    if (response?.code !== 0 || !isNil(response?.error)) {
      setOpenBackdrop(false)
      setConfirmDialog({
        isOpen: true,
        title: '',
        type: 'alertError',
        subTitle: response?.message,
      })
      return
    }
    setCustomerInfo(response?.data)
    setOpenBackdrop(false)
  }

  useEffect(() => {
    fetchGetCustomerDetails()
  }, [])

  return (
    <>
      <Container>
        <React.Fragment>
          <Paper sx={{ paddingLeft: 6 }}>
            <Grid item sm={1} key="Back">
              <Button
                onClick={() => {
                  navigate(
                    `/customer/details?routeEdit=${location.state.customerRouteEditURL}`,
                    {
                      replace: false,
                    },
                  )
                }}
                startIcon={<ArrowBackIcon />}
                text="Back"
                variant="outlined"
                size="small"
              />
            </Grid>
          </Paper>
          <Paper sx={{ paddingLeft: 6, paddingTop: 6 }}>
            <Typography
              sx={{
                fontFamily: 'Overpass',
                'font-size': '30px',
                fontWeight: 600,
                lineHeight: '20px',
                letterSpacing: '0.0015em',
                color: '#FFFFFF',
              }}
            >
              CUSTOMER :{' '}
              {customerInfo?.customerdetailModel?.firstName +
                ' ' +
                customerInfo?.customerdetailModel?.middleName +
                ' ' +
                customerInfo?.customerdetailModel?.lastName}
            </Typography>
          </Paper>
          <Paper sx={{ paddingTop: 3 }}>
            <form control={control} onSubmit={handleSubmit(onSubmit)}>
              <Box
                sx={{
                  display: 'grid',
                  '@media (min-width:400px)': {
                    gridTemplateColumns: 'repeat(auto-fill, minmax(1fr 3fr))',
                  },
                  '@media (min-width:800px)': {
                    gridTemplateColumns: '1fr 3fr',
                  },
                  borderBottom: 0,
                  borderColor: theme.palette.background.basebg,
                }}
              >
                <Box>
                  <Tabs
                    value={tabValue}
                    onChange={onTabChange}
                    orientation="vertical"
                    centered
                    sx={{
                      '& .MuiTabs-flexContainer': {
                        display: 'flex',
                        justifyContent: 'flex-start',
                        padding: '18px 38px',
                        '& .Mui-selected': {
                          color: '#FFFFFF',
                          bgcolor: theme.palette.background.cardbg,
                          borderRadius: '4px',
                        },
                      },
                      '& .MuiTabs-indicator': {
                        height: 0,
                        width: 0,
                      },
                    }}
                  >
                    {tabList.map((e, i) => {
                      return (
                        <Tab
                          key={`tab-${i}`}
                          label={e.label}
                          icon={e.icon}
                          {...tabProps(i)}
                          sx={{
                            '@media (min-width:600px)': {
                              alignItems: 'start',
                            },
                            width: '240px',
                            height: '48px',
                            padding: '22px',
                            borderRadius: 2,
                            border: '1px solid #8655D1',
                            margin: '8px 0',
                            '&.Mui-selected': {
                              backgroundColor: '#8655D1',
                            },
                          }}
                        />
                      )
                    })}
                  </Tabs>
                </Box>
                <Box>
                  {tabList.map((e, i) => {
                    return (
                      <TabPanel key={i} value={tabValue} index={i}>
                        {e.tab}
                      </TabPanel>
                    )
                  })}
                </Box>
              </Box>
              <Backdrop openBackdrop={openBackdrop} />
              <AlertBox notify={notify} setNotify={setNotify} />
              <ConfirmDialog
                confirmDialog={confirmDialog}
                setConfirmDialog={setConfirmDialog}
              />
              <div className={`${classes.stepBtn}`}>
                <AlertBar type="error" message={errorMessage} />
                <Button type="submit" variant="contained" text="SAVE" />
              </div>
            </form>
          </Paper>
        </React.Fragment>
      </Container>
    </>
  )
}
