import React from 'react'
import {
  Stack,
  InputLabel,
  Typography,
  Autocomplete,
  autocompleteClasses,
} from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { styled } from '@mui/material/styles'
import SvgColor from '../../components/svg-color/svg-color'

const useStyles = makeStyles()((theme) => ({
  labelStyle: {
    whiteSpace: 'wrap',
  },
}))

const StyledAutocompletePopper = styled('div')(({ theme }) => ({
  [`& .${autocompleteClasses.paper}`]: {
    boxShadow: 'none',
    marginTop: 8,
    backgroundColor: theme.palette.background.dark,

    '.MuiAutocomplete-noOptions': {
      display: 'none',
    },
  },
  [`& .${autocompleteClasses.listbox}`]: {
    padding: 0,
    borderRadius: 25,
    border: `2px solid ${theme.palette.outline.default}`,

    [`& .${autocompleteClasses.option}`]: {
      minHeight: 'auto',
      alignItems: 'flex-start',
      padding: '14px 16px',

      '&[aria-selected="true"]': {
        borderRadius: 20,
        border: `2px solid ${theme.palette.outline.default}`,
        backgroundColor: theme.palette.background.popup,
      },

      [`&.${autocompleteClasses.focused}, &.${autocompleteClasses.focused}[aria-selected="true"]`]:
        {
          borderRadius: 20,
          backgroundColor: theme.palette.background.popup,
        },
    },
  },
  [`&.${autocompleteClasses.popperDisablePortal}`]: {
    position: 'relative',
  },
}))

function PopperComponent(props) {
  const { disablePortal, anchorEl, open, ...other } = props
  return <StyledAutocompletePopper {...other} />
}

export default function Autocompleted(props) {
  const { classes } = useStyles()
  const { label, id, options, name, value, onChange, loading, sx, ...other } = props

  return (
    <Stack sx={{ mb: 1 }}>
      <InputLabel className={classes?.labelStyle} htmlFor={name}>
        <Typography variant="Paragraph_sm_Light" component="div" sx={{ mb: 1 }}>
          {label}
        </Typography>
      </InputLabel>
      <Autocomplete
        id={id}
        options={options}
        fullWidth
        autoHighlight
        PopperComponent={PopperComponent}
        popupIcon={
          <SvgColor
            src={`/assets/icons/icon_arrow_down.svg`}
            sx={{ px: 3, color: 'outline.primary' }}
          />
        }
        {...other}
      />
    </Stack>
  )
}
