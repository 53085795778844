import React from 'react'
import { Controller } from 'react-hook-form'
import {
  FormHelperText,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  FormLabel,
  Typography,
} from '@mui/material'
import { isEmpty } from 'lodash'

const RadioField = ({ control, options, name, required, label, variant, disabled }) => (
  <Controller
    render={({ field: { ref, ...restField }, fieldState: { error } }) => (
      <FormControl error={!!error}>
        <FormLabel>
          <Typography
            variant={!isEmpty(variant) ? variant : 'Paragraph_sm_Light'}
            color="text.primary"
            component="div"
            sx={{ mb: 1 }}
          >
            {label ? `${label}${required ? ' *' : ''}` : ''}
          </Typography>
        </FormLabel>

        <RadioGroup {...restField} sx={{ flexDirection: 'row' }}>
          {options.map(({ value, label }) => (
            <FormControlLabel
              inputRef={ref}
              key={label}
              value={value}
              //Focus on error is not visible
              control={
                <Radio
                  icon={<img alt="unckeck" src={`/assets/icons/icon_uncheckbox.svg`} />}
                  checkedIcon={
                    <img alt="ckeck" src={`/assets/icons/icon_checkbox.svg`} />
                  }
                  checked={value === restField.value}
                />
              }
              label={label}
              disabled={disabled}
            />
          ))}
        </RadioGroup>
        <FormHelperText>{error?.message}</FormHelperText>
      </FormControl>
    )}
    name={name}
    control={control}
    rules={{
      required: required ? 'Required' : '',
    }}
  />
)

export default RadioField
