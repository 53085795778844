import React, { useState, useEffect } from 'react'
import { Grid } from '@mui/material'
import { isNil } from 'lodash'
import AutocompleteField from '../../../components/common/AutocompleteField'
import NumberField from '../../../components/common/NumberField'
import InputField from '../../../components/common/InputField'
import {
  apiGetStateListByCountryCode,
  apiGetCityListByState,
  apiGetDistrictListByCity,
} from '../../../api/master'
import { apiGetBeneficiaryCountryList } from '../../../api/customer'

export default function ReceiverAddressDetails(props) {
  const { control, setNotify, useWatch } = props

  var vCountry = useWatch({ control, name: 'receiverAddressCountry' })
  var vState = useWatch({ control, name: 'receiverAddressState' })
  var vCity = useWatch({ control, name: 'receiverAddressCity' })

  const [beneficiaryCountryRecords, setBeneficiaryCountryRecords] = useState([])
  const [stateRecords, setStateRecords] = useState([])
  const [cityRecords, setCityRecords] = useState([])
  const [districtRecords, setDistrictRecords] = useState([])

  const fetchBeneficiaryCountryList = async () => {
    const response = await apiGetBeneficiaryCountryList()
    if (response?.code !== 0 || !isNil(response?.error)) {
      setNotify({
        open: true,
        message: response?.message,
        type: 'error',
      })
      return
    }
    const optionBeneficiaryCountry = response?.data?.map((e) => {
      return { label: e?.rec_country, value: e?.country_code }
    })
    setBeneficiaryCountryRecords(optionBeneficiaryCountry)
  }

  const fetchStateList = async () => {
    const response = await apiGetStateListByCountryCode('CHN')
    if (response?.code !== 0 || !isNil(response?.error)) {
      setNotify({
        open: true,
        message: response?.message,
        type: 'error',
      })
      return
    }
    const optionState = response?.data?.map((e) => {
      return { label: e?.text, value: e?.value }
    })
    setStateRecords(optionState)
    setCityRecords([])
    setDistrictRecords([])
  }

  const fetchCityList = async () => {
    if (vState !== undefined && vState !== '') {
      const response = await apiGetCityListByState(vState)
      if (response?.code !== 0 || !isNil(response?.error)) {
        setNotify({
          open: true,
          message: response?.message,
          type: 'error',
        })
        return
      }
      const optionCity = response?.data?.map((e) => {
        return { label: e?.text, value: e?.value }
      })
      setCityRecords(optionCity)
      setDistrictRecords([])
    }
  }

  const fetchDistrictList = async () => {
    if (vCity !== undefined && vCity !== '') {
      const response = await apiGetDistrictListByCity(vCity)
      if (response?.code !== 0 || !isNil(response?.error)) {
        setNotify({
          open: true,
          message: response?.message,
          type: 'error',
        })
        return
      }
      const optionDistrict = response?.data?.map((e) => {
        return { label: e?.text, value: e?.value }
      })
      setDistrictRecords(optionDistrict)
    }
  }

  useEffect(() => {
    fetchBeneficiaryCountryList()
    fetchStateList()
  }, [])

  useEffect(() => {
    fetchCityList()
  }, [vState])

  useEffect(() => {
    fetchDistrictList()
  }, [vCity])

  return (
    <Grid container spacing={{ xs: 1, sm: 2 }} columns={{ xs: 1, sm: 8, md: 8 }}>
      <Grid item xs={2} sm={4} md={4}>
        <AutocompleteField
          control={control}
          name="receiverAddressCountry"
          label="Country"
          placeholder="Select country"
          options={beneficiaryCountryRecords}
          required
        />
      </Grid>
      <Grid item xs={2} sm={4} md={4}>
        <NumberField
          control={control}
          name="receiverAddressZipCode"
          label="Zip code"
          placeholder="Zip Code"
        />
      </Grid>
      {vCountry === 'CHN' ? (
        <Grid item xs={2} sm={4} md={4}>
          <AutocompleteField
            control={control}
            name="receiverAddressState"
            label="State"
            placeholder="Select state"
            options={stateRecords}
            required
          />
        </Grid>
      ) : (
        <Grid item xs={2} sm={4} md={4}>
          <InputField
            control={control}
            name="receiverAddressState"
            label="State"
            placeholder="State"
          />
        </Grid>
      )}
      {vCountry === 'CHN' ? (
        <Grid item xs={2} sm={4} md={4}>
          <AutocompleteField
            control={control}
            name="receiverAddressCity"
            label="City"
            placeholder="Select city"
            options={cityRecords}
            required
          />
        </Grid>
      ) : (
        <Grid item xs={2} sm={4} md={4}>
          <InputField
            control={control}
            name="receiverAddressCity"
            label="City"
            placeholder="City"
          />
        </Grid>
      )}
      {vCountry === 'CHN' ? (
        <Grid item xs={2} sm={4} md={4}>
          <AutocompleteField
            control={control}
            name="receiverAddressDistrict"
            label="District"
            placeholder="Select district"
            options={districtRecords}
            required
          />
        </Grid>
      ) : (
        <Grid item xs={2} sm={4} md={4}>
          <InputField
            control={control}
            name="receiverAddressDistrict"
            label="District"
            placeholder="District"
          />
        </Grid>
      )}

      <Grid item xs={2} sm={4} md={4}>
        <InputField
          control={control}
          name="receiverAddressAddressStreet"
          label="Street"
          placeholder="Street"
        />
      </Grid>
    </Grid>
  )
}
