import React from 'react'
import { IconButton } from '@mui/material'

export default function IconBtn(props) {
  const { src, name, color, children, onClick, ...other } = props

  return (
    <IconButton onClick={onClick} {...other}>
      <img src={`${src}`} alt={name} />
    </IconButton>
  )
}
